import { TableContainer, Thead, Th, Box, Text, Tr, Flex } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';

import {
  ParentOrderHistoryInterface,
  OrderItemInterface
} from '../../../../../../../shared/type/parentOrderHistory.type';
import translatePaymentType from '../../../../../../../utils/translatePaymentTyle';
import ParentOrderHistoryDetails from '../../../../../../ChildOwnerComponents/ParentOrderHistory/ParentOrderHistoryDetails/ParentOrderHistoryDetails';
import CustomChakraButton from '../../../../../../CustomChakraButton/CustomChakraButton';
import { DataTable } from '../../../../../../DataTable/DataTable';
import Pagination from '../../../../../../Pagination/Pagination';
import AddCashToSaldo from './AddCashToSaldo/AddCashToSaldo';
import { useEditParentContext } from '../../../../../../../context/EditParentProvider';

type OrderType = 'addToSaldo' | 'orderOnCash' | null;

const ListOfOrder = () => {
  const navigate = useNavigate();

  const { id: tenantId, userId: parentId } = useParams<{ id: string; userId: string }>();
  const { handlePageChange, page, listOfOrder, actualPage, fetchListOfOrder } =
    useEditParentContext();
  const columnHelper = createColumnHelper<ParentOrderHistoryInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cashDepositType, setCashDepositType] = useState<OrderType>(null);
  const [selectedItem, setSelectedItem] = useState<OrderItemInterface>();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [popUp, setPopUp] = useState<{
    showAddToSaldo: boolean;
  }>({
    showAddToSaldo: false
  });

  useEffect(() => {
    switch (cashDepositType) {
      case 'addToSaldo':
        setPopUp((prev) => ({ ...prev, showAddToSaldo: true }));
        break;
      case 'orderOnCash':
        navigate(`/tenantAdmin/id/${tenantId}/users/parents/single-parent/${parentId}/cash-order`);
        break;
      default:
        break;
    }

    setCashDepositType(null);
  }, [cashDepositType]);

  const rowHeight: number = 50;

  const calculateRowsNumber = (productsQuantity: number | undefined, isSummary: boolean) => {
    let rowNumber = 0;

    if (productsQuantity) {
      rowNumber += productsQuantity;
    }

    if (isSummary) {
      rowNumber++;
    }

    return rowNumber;
  };

  const borderColor = '#EAECF0';
  const columns = [
    columnHelper.accessor('orderDate', {
      cell: (info) => {
        const { orderItems, orderDate } = info.row.original;

        const date = new Date(orderDate);

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return (
          <td rowSpan={orderItems.length}>
            <p className="text-grayLight-600 font-normal text-sm">
              {date.toLocaleDateString()}
              <br />
              {hours}:{minutes}
            </p>
          </td>
        );
      },
      header: 'Data operacji'
    }),
    columnHelper.accessor('orderItems', {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => (
              <div key={index} className="flex justify-start">
                <div
                  className="flex flex-col items-start justify-center"
                  style={{
                    height: `${rowHeight * calculateRowsNumber(item.summaryItems.length, totalPayment !== null)}px`
                  }}>
                  <p className="text-grayLight-900 font-medium text-sm underline underline-offset-2">
                    {item.consumerDto.firstName} {item.consumerDto.lastName}
                  </p>
                  <p className="text-grayLight-600 font-normal text-sm">
                    {item.consumerDto.organisationUnitName}
                  </p>
                </div>
              </div>
            ))}
            {totalPayment !== null && (
              <div>
                <div className="flex items-center h-12"></div>
              </div>
            )}
          </>
        );
      },
      header: 'Dziecko'
    }),
    columnHelper.accessor('orderItems', {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => (
              <div
                key={index}
                style={{
                  borderBottom:
                    totalPayment !== null && index === 0
                      ? `1px solid ${borderColor}`
                      : index < orderItems.length - 1
                        ? `1px solid ${borderColor}`
                        : 'none'
                }}>
                <div
                  className="flex flex-col justify-center items-start"
                  style={{
                    height: `${rowHeight * calculateRowsNumber(item.summaryItems.length, totalPayment !== null)}px`
                  }}>
                  {item.summaryItems.map((summaryItem, summaryIndex) => (
                    <div
                      key={summaryIndex}
                      className="flex items-center"
                      style={{
                        height: `calc(100% / ${calculateRowsNumber(item.summaryItems.length, totalPayment !== null)})`
                      }}>
                      <p className="font-normal text-sm text-grayLight-600">
                        {summaryItem.amount}x {summaryItem.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {totalPayment !== null && (
              <div
                style={{
                  borderTop: `1px solid ${borderColor}`
                }}>
                <div className="flex items-center h-12"></div>
              </div>
            )}
          </>
        );
      },
      header: 'Zamówienie'
    }),
    columnHelper.accessor('orderItems', {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => (
              <div
                key={index}
                style={{
                  borderBottom:
                    totalPayment !== null && index === 0
                      ? `1px solid ${borderColor}`
                      : index < orderItems.length - 1
                        ? `1px solid ${borderColor}`
                        : 'none'
                }}>
                <div
                  className="flex items-center justify-start"
                  style={{
                    height: `${rowHeight * calculateRowsNumber(item.summaryItems.length, totalPayment !== null)}px`
                  }}>
                  <p className="text-grayLight-600 font-normal text-sm">
                    {translatePaymentType(item.paymentType)}
                  </p>
                </div>
              </div>
            ))}
            {totalPayment !== null && (
              <div
                style={{
                  borderTop: `1px solid ${borderColor}`
                }}>
                <div className="flex items-center h-12"></div>
              </div>
            )}
          </>
        );
      },
      header: 'Rodzaj transakcji'
    }),
    columnHelper.accessor('orderItems', {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => (
              <div
                key={index}
                style={{
                  borderBottom:
                    totalPayment !== null && index === 0
                      ? `1px solid ${borderColor}`
                      : index < orderItems.length - 1
                        ? `1px solid ${borderColor}`
                        : 'none'
                }}>
                <div
                  className="flex items-center justify-start"
                  style={{
                    height: `${rowHeight * calculateRowsNumber(item.summaryItems.length, totalPayment !== null)}px`
                  }}>
                  <p className="text-grayLight-600 font-normal text-sm">{item.saldoBefore}</p>
                </div>
              </div>
            ))}
            {totalPayment !== null && (
              <div
                style={{
                  borderTop: `1px solid ${borderColor}`
                }}>
                <div className="flex items-center h-12">
                  <p className="text-grayLight-900 text-sm font-medium uppercase">Razem</p>
                </div>
              </div>
            )}
          </>
        );
      },
      header: 'Saldo przed (zł)'
    }),
    columnHelper.accessor('orderDate', {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => (
              <div
                key={index}
                style={{
                  borderBottom:
                    totalPayment !== null && index === 0
                      ? `1px solid ${borderColor}`
                      : index < orderItems.length - 1
                        ? `1px solid ${borderColor}`
                        : 'none'
                }}>
                <div
                  className="flex items-center justify-start"
                  style={{
                    height: `${rowHeight * calculateRowsNumber(item.summaryItems.length, totalPayment !== null)}px`
                  }}>
                  <p className="text-grayLight-600 font-normal text-sm">{item.paymentValue}</p>
                </div>
              </div>
            ))}
            {totalPayment !== null && (
              <div
                style={{
                  borderTop: `1px solid ${borderColor}`
                }}>
                <div className="flex items-center h-12">
                  <p className="text-grayLight-900 text-sm font-medium">{totalPayment.payment}</p>
                </div>
              </div>
            )}
          </>
        );
      },
      header: 'Wpłata (zł)'
    }),
    columnHelper.accessor('orderDate', {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => (
              <div
                key={index}
                style={{
                  borderBottom:
                    totalPayment !== null && index === 0
                      ? `1px solid ${borderColor}`
                      : index < orderItems.length - 1
                        ? `1px solid ${borderColor}`
                        : 'none'
                }}>
                <div
                  className="flex items-center justify-start"
                  style={{
                    height: `${rowHeight * calculateRowsNumber(item.summaryItems.length, totalPayment !== null)}px`
                  }}>
                  <p className="text-grayLight-900 font-normal text-sm">{item.orderValueString}</p>
                </div>
              </div>
            ))}
            {totalPayment !== null && (
              <div>
                <div
                  className="flex items-center h-12"
                  style={{
                    borderTop: `1px solid ${borderColor}`
                  }}>
                  <p className="text-grayLight-900 text-sm font-medium">
                    {totalPayment.orderValue}
                  </p>
                </div>
              </div>
            )}
          </>
        );
      },
      header: 'Wartość zamówienia (zł)'
    }),
    columnHelper.accessor('orderDate', {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => {
              const numberOfRows = calculateRowsNumber(
                item.summaryItems.length,
                totalPayment !== null
              );

              return (
                <div
                  key={index}
                  style={{
                    borderBottom: index < orderItems.length - 1 ? '1px solid #e0e0e0' : 'none'
                  }}>
                  <div
                    className="flex items-center justify-start"
                    style={{
                      height: `${rowHeight * numberOfRows}px`
                    }}>
                    <p className="text-grayLight-600 font-normal text-sm">{item.saldoAfter}</p>
                  </div>
                </div>
              );
            })}
            {totalPayment !== null && (
              <div
                style={{
                  borderTop: `1px solid ${borderColor}`
                }}>
                <div className="flex items-center h-12"></div>
              </div>
            )}
          </>
        );
      },
      header: 'Saldo po (zł)'
    }),
    columnHelper.accessor('orderDate', {
      cell: (info) => {
        const { orderItems } = info.row.original;

        const { firstName, lastName } = orderItems[0].doneBy;

        return (
          <>
            <div>
              <div className="flex justify-center items-center">
                <p className="text-grayLight-600 font-normal text-sm">
                  {firstName} {lastName}
                </p>
              </div>
            </div>
          </>
        );
      },
      header: 'Wykonano przez'
    }),
    columnHelper.accessor('orderDate', {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => {
              const numberOfRows = calculateRowsNumber(
                item.summaryItems.length,
                totalPayment !== null
              );

              return (
                <div
                  key={index}
                  style={{
                    borderBottom: index < orderItems.length - 1 ? '1px solid #e0e0e0' : 'none'
                  }}>
                  <div
                    className="flex items-center justify-start"
                    style={{
                      height: `${rowHeight * numberOfRows}px`
                    }}>
                    <CustomChakraButton
                      buttonProps={{
                        onClick: () => {
                          setShowDetails(true);
                          setSelectedItem(item);
                        },
                        type: 'button'
                      }}
                      size="sm"
                      hierarchy="linkGray"
                      iconPosition="onlyText">
                      Szczegóły
                    </CustomChakraButton>
                  </div>
                </div>
              );
            })}
            {totalPayment !== null && (
              <div>
                <div className="flex items-center h-12"></div>
              </div>
            )}
          </>
        );
      },
      header: ''
    })
  ];

  return (
    <section className="ParentsTable p-spacing-xl w-full">
      <Flex direction="column" h="800px" className="ListOfOrder__table bg-white rounded-lg">
        <TableContainer h="100%" flex="1" minH="500px" overflowY="auto">
          <DataTable
            disableFilters={true}
            filterComponent={
              <div className="flex items-center gap-spacing-md">
                <CustomChakraButton
                  hierarchy="tertiaryColor"
                  size="lg"
                  iconPosition="onlyText"
                  buttonProps={{
                    type: 'button',
                    onClick: () =>
                      navigate(
                        `/tenantAdmin/id/${tenantId}/users/parents/single-parent/${parentId}/credit-order`
                      )
                  }}>
                  Zamówienie na kredyt
                </CustomChakraButton>
                <Select
                  className="w-52 h-11 border-amber-600"
                  placeholder="Wpłata gotówkowa"
                  value={
                    cashDepositType
                      ? {
                          value: cashDepositType,
                          label:
                            cashDepositType === 'addToSaldo'
                              ? 'Wpłata na saldo'
                              : 'Zamówienie posiłków'
                        }
                      : null
                  }
                  onChange={(selectedOption) => {
                    setCashDepositType(selectedOption?.value as OrderType);
                  }}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral50: '#44403C'
                    }
                  })}
                  styles={{
                    option: (baseStyles) => ({
                      ...baseStyles,
                      '&:hover': {
                        backgroundColor: '#fff7e6'
                      }
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: '#FFBB32'
                    })
                  }}
                  options={[
                    {
                      value: 'addToSaldo',
                      label: 'Wpłata na saldo'
                    },
                    {
                      value: 'orderOnCash',
                      label: 'Zamówienie posiłków'
                    }
                  ]}
                />
              </div>
            }
            extraThead={
              <Thead>
                <Tr>
                  <Th colSpan={columns.length}>
                    <div className="w-full flex items-center justify-between">
                      <div className="flex flex-col basis-4/5">
                        <Box>
                          <Text
                            whiteSpace="normal"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            className="font-semibold text-base text-grayLight-900">
                            Lista zamówień i wpłat
                          </Text>
                        </Box>
                      </div>
                      {/* <div className="flex gap-spacing-lg"> */}
                      {/*   <CustomChakraButton size="md" hierarchy="secondaryColor" iconPosition="left" icon="plus" buttonProps={{ */}
                      {/*     disabled: true */}
                      {/*   }}> */}
                      {/*     Dodaj zamówienie gotówkowe */}
                      {/*   </CustomChakraButton> */}
                      {/* </div> */}
                    </div>
                  </Th>
                </Tr>
              </Thead>
            }
            columns={columns}
            isLoading={isLoading}
            data={listOfOrder}
          />
        </TableContainer>
        <Box position="relative" paddingTop="10px" paddingBottom="20px">
          <Pagination
            pageNumber={page.pageNumber}
            maxPageNumber={page.maxPageNumber}
            hasNextPage={actualPage.hasNextPage}
            onPageChange={handlePageChange}
          />
        </Box>
      </Flex>
      {showDetails && (
        <ParentOrderHistoryDetails
          order={selectedItem}
          isOpen={showDetails}
          onClose={() => setShowDetails(false)}
        />
      )}

      {popUp.showAddToSaldo && (
        <AddCashToSaldo
          isOpen={popUp.showAddToSaldo}
          onClose={() => {
            setPopUp((prev) => ({ ...prev, showAddToSaldo: false }));
            fetchListOfOrder();
          }}
        />
      )}
    </section>
  );
};

export default ListOfOrder;
