import React, { useEffect, useState } from 'react';
import './AuthenticationScreen.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from '../../component/AutenticationComponents/Login/Login';
import RecoverPassword from '../../component/AutenticationComponents/RecoverPassword/RecoverPassword';
import ConfirmEmail from '../../component/AutenticationComponents/ConfirmEmail/ConfirmEmail';
import Registration from '../../component/AutenticationComponents/Registration/Registration';
import SetNewPassword from '../../component/AutenticationComponents/SetNewPassword/NewPassword';
import IconComponent from '../../component/IconComponent/IconComponent';
import { ResetPasswordProvider } from '../../context/ResetPasswordProvider';
import { LanguagesSelector } from '../../component/LanguagesSelectors/LanguagesSelector';

const authPathWithoutLogo = ['recover'];

const AuthenticationScreen = () => {
  const [showLogo, setShowLogo] = useState(true);
  const location = useLocation();

  const pathIsAllowedToShowLogo = (currentPath: string, paths: string[]) => {
    setShowLogo(!paths.some((path) => currentPath.includes(path)));
  };

  useEffect(() => {
    const currentPath = location.pathname;
    pathIsAllowedToShowLogo(currentPath, authPathWithoutLogo);
  }, [location.pathname]);

  return (
    <section className="LoginPage dark:bg-grayIron-800">
      <div>
        <header className="flex items-center justify-between px-container-padding-desktop">
          <div className="LoginPage__logo hidden lg:block">
            {showLogo && <IconComponent iconName="logo" className="w-28" />}
          </div>
          <div className="LoginPage__logo block lg:hidden">
            {showLogo && <IconComponent iconName="logoIcon" />}
          </div>
          <LanguagesSelector />
        </header>
        <div className="LoginPage__login-section min-h-screen flex items-center">
          <div className="LoginPage__container flex flex-col h-full w-full lg:pl-20 lg:pr-20 pb-spacing-9xl items-center justify-center">
            <ResetPasswordProvider>
              <Routes>
                <Route path="/" element={<Login />} />
                {/* <Route path="/confirm-email" element={<ConfirmChangeEmail />}/> */}
                <Route path="/account-activation" element={<Login />} />
                <Route path="/registration/*" element={<Registration />} />
                <Route path="/recover" element={<RecoverPassword />} />
                <Route path="/recover/confirm" element={<ConfirmEmail />} />
                <Route path="/recover/new-password/*" element={<SetNewPassword />} />
              </Routes>
            </ResetPasswordProvider>
            <div className="flex lg:flex-row flex-col items-center gap-2.5 justify-between px-8 pt-spacing-xl">
              <p className="text-sm text-grayLight-600">© Jemwszkole.pl 2024</p>
              <div className="flex gap-spacing-md">
                <p className="text-sm text-grayLight-600">help@jemwszkole.pl</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="LoginPage__prom bg-brand-100 min-h-screen flex-grow fl hidden lg:flex justify-end overflow-hidden relative">
        <div style={{ height: '900px', width: '900px', right: 0 }} className="relative hbg-red-300">
          <img
            src={`${process.env.PUBLIC_URL}/img/screen-greeting.png`}
            className="LoginPage__screen-greeting absolute"
            alt="Logo na dużych ekranach"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/screen-table.png`}
            className="LoginPage__screen-table absolute"
            alt="Logo na dużych ekranach"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/phone-greeting.png`}
            className="LoginPage__phone-greeting absolute"
            alt="Logo na dużych ekranach"
          />
        </div>
      </div>
    </section>
  );
};

export default AuthenticationScreen;
