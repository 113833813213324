import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useEditAdministratorContext } from "../../../../../../../context/EditAdministratorProvider";
import { AvailableTenantInterface } from "../../../../../../../shared/type/tenant.type";
import CustomChakraButton from "../../../../../../CustomChakraButton/CustomChakraButton";
import CheckboxInput from "../../../../../../Forms/CheckboxInput/CheckboxInput";
import FormInput from "../../../../../../Forms/FormInput/FormInput";
import {useAlertContext} from "../../../../../../../context/AlertProvider";
import {useApi} from "../../../../../../../context/ApiProvider";
import {HttpStatusCode} from "axios";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AssignNewBranch = ({ isOpen, onClose }: Props) => {
  const { adminEditContext, setAdminEditContext, modules } = useEditAdministratorContext();
  const { apiTenantAdminController } = useApi();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext()
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [filteredSchools, setFilteredSchools] = useState<AvailableTenantInterface[]>(adminEditContext?.availableTenant || []);

  const [selectedTenants, setSelectedTenants] = useState<AvailableTenantInterface[]>([]);

  const assignedTenants = adminEditContext?.adminUserDto.assignedTenants || [];
  const assignedTenantIds = assignedTenants.map(tenant => tenant.id);

  const handleClose = () => {
    onClose();
  };

  const { firstName, lastName } = adminEditContext!.adminUserDto;
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchedValue(value);

    setFilteredSchools(
        (adminEditContext?.availableTenant ?? [])
            .filter(school => !assignedTenantIds.includes(school.id))
            .filter(school => school.name.toLowerCase().includes(value))
    );
  };

  const handleCheckboxChange = (tenant: AvailableTenantInterface, isChecked: boolean) => {
    setSelectedTenants(prev =>
        isChecked
            ? [...prev, tenant]
            : prev.filter(t => t.id !== tenant.id)
    );
  }

  const handleSubmitAndClose = async () => {
    await handleAssignTenants();
    onClose();
  };

  const handleAssignTenants = async () => {
    if (!adminEditContext) return;

    const updatedAssignedTenants = [...adminEditContext.adminUserDto.assignedTenants, ...selectedTenants];

    setAdminEditContext(prev => prev ? {
      ...prev,
      adminUserDto: {
        ...prev.adminUserDto,
        assignedTenants: updatedAssignedTenants
      }
    } : prev);

    try {
      const tenantIds = updatedAssignedTenants.map(tenant => tenant.id);
      const moduleIds = modules
          .filter((module) => adminEditContext?.adminUserDto.availableModules.includes(module.name))
          .map((module) => module.id);

      const { firstName, lastName, phone } = adminEditContext.adminUserDto;

      console.log("Wysyłane tenantIds:", tenantIds);

      const response = await apiTenantAdminController('').put(`/${Number(adminEditContext.adminUserDto.id)}`, {
        tenantIds,
        moduleIds,
        firstName,
        lastName,
        phone,
      });

      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 9000,
          title: 'Sukces! Przypisano nowe oddziały.',
          status: 'success',
        });
      }
    } catch (error) {
      console.error("Błąd przypisywania oddziałów:", error);
      setAlertProperties({
        timeout: 9000,
        title: 'Błąd przypisywania oddziałów',
        status: 'error',
      });
    } finally {
      setShowAlert(true);
    }
  };

  useEffect(() => {
    setFilteredSchools(
        (adminEditContext?.availableTenant ?? []).filter(
            school => !assignedTenantIds.includes(school.id)
        )
    );
  }, [adminEditContext]);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Przypisz oddział</h2>
            <p className="font-normal text-sm">
              Wybierz oddział lub oddziały, którymi może zarządzać administrator {firstName} {lastName}.
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <FormInput
              label=""
              placeholder="Wyszukaj"
              inputParams={{
                onChange: handleSearchChange,
                value: searchedValue
              }}
            />
            <div className='w-full h-full'>
              {filteredSchools.map(school => (
                <CheckboxInput
                  key={school.id}
                  text={school.name}
                  textSize='lg'
                  checkboxPosition='items-center'
                  checkboxParams={{
                    checked: assignedTenantIds.includes(school.id),
                    onChange: (e) => handleCheckboxChange(school, e.target.checked),
                  }}
                />
              ))}
              {filteredSchools.length === 0 && <p className='font-normal text-lg'>Brak oddziałów do wyświetlenia</p>}
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: handleClose,
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                onClick: handleSubmitAndClose,
                className: "grow",
              }}
            >
              Dodaj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
    </Modal>
  );
};

export default AssignNewBranch;
