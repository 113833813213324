import { Input } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import formatDate from '../../../utils/formatDate';
import IconComponent from '../../IconComponent/IconComponent';
import { useThemeContext } from '../../../context/ThemeProvider';
import { useTranslation } from 'react-i18next';

interface Props {
  setDate: React.Dispatch<React.SetStateAction<{ year: number; month: number; day: number }>>;
  minDate?: string;
  maxDate?: string;
}

const WideDateInput = ({ setDate, minDate, maxDate }: Props) => {
  const { i18n } = useTranslation();
  const { isMobile } = useThemeContext();
  const todayDate = new Date();

  const [chosenDate, setChosenDate] = useState<Date>(todayDate);
  const [initialized, setInitialized] = useState(false);

  const previousDay = new Date(chosenDate);
  previousDay.setDate(chosenDate.getDate() - 1);

  const nextDay = new Date(chosenDate);
  nextDay.setDate(chosenDate.getDate() + 1);

  useEffect(() => {
    if (!initialized && minDate) {
      const minDateObj = new Date(minDate);
      if (minDateObj > todayDate) {
        setChosenDate(minDateObj);
      }
      setInitialized(true);
    }
  }, [minDate, todayDate, initialized]);

  const changeDate = (newDate: Date) => {
    setDate({
      year: newDate.getFullYear(),
      month: newDate.getMonth() + 1,
      day: newDate.getDate()
    });
    setChosenDate(newDate);
  };

  const getDayName = (date: Date): string => {
    const dayName = date.toLocaleDateString(i18n.language, { weekday: 'long' });
    return dayName.charAt(0).toUpperCase() + dayName.slice(1);
  };

  const isMinDate = (): boolean => (minDate ? formatDate(chosenDate) === minDate : false);
  const isMaxDate = (): boolean => (maxDate ? formatDate(chosenDate) === maxDate : false);

  return (
    <div className="flex justify-between">
      <button
        disabled={isMinDate()}
        onClick={() => {
          changeDate(previousDay);
        }}
        className="flex gap-spacing-md items-center">
        <div className={`${isMinDate() && 'hidden'}`}>
          <IconComponent iconName="chevronLeft" />
        </div>
        {!isMobile && (
          <div className="flex flex-col" style={{ gap: '4px' }}>
            <p className="text-grayLight-600 text-sm font-semibold">{getDayName(previousDay)}</p>
            <p className="text-grayLight-500 text-sm font-normal">
              {formatDate(previousDay, 'dd-mmm-yyyy')}
            </p>
          </div>
        )}
      </button>

      <div className="flex flex-col justify-center items-center">
        <div className={`${isMobile && 'hidden'}`}>
          <p className="text-grayLight-900 hide lg:flex text-xl font-semibold">
            {getDayName(chosenDate)}
          </p>
        </div>
        <div>
          <Input
            type="date"
            name="date"
            backgroundColor="#FFFFFF"
            value={formatDate(chosenDate)}
            min={minDate}
            max={maxDate}
            onChange={(e) => changeDate(new Date(e.target.value))}
          />
        </div>
      </div>

      <button
        disabled={isMaxDate()}
        onClick={() => {
          changeDate(nextDay);
        }}
        className="flex gap-spacing-md items-center">
        {!isMobile && (
          <div className="flex flex-col" style={{ gap: '4px' }}>
            <p className="text-grayLight-600 text-sm font-semibold">{getDayName(nextDay)}</p>
            <p className="text-grayLight-500 text-sm font-normal">
              {formatDate(nextDay, 'dd-mmm-yyyy')}
            </p>
          </div>
        )}
        <div className={`${isMaxDate() && 'hidden'}`}>
          <IconComponent iconName="chevronRight" />
        </div>
      </button>
    </div>
  );
};

export default WideDateInput;
