import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useConsumerOrderContextContext } from '../../../context/ConsumerOrderContext';
import { useParentOrderContextContext } from '../../../context/ParentOrderContext';
import { useEditTeacherOrEmployeeContext } from '../../../context/EditTeacherOrEmployeeProvider';

export const useUserOrderContext = () => {
  const { id: tenantId, userId } = useParams<{ id: string; userId: string }>();
  const location = useLocation();
  const {
    parentOrderContext,
    setTenantId,
    setUserId,
    setSkipFetchingParentOrderContext,
    setParentOrderContextDate,
    ...rest
  } = useParentOrderContextContext();
  const {
    consumerOrderContext,
    setTenantId: setTenantIdForConsumer,
    setUserId: setUserIdForConsumer,
    setSkipFetchingConsumerOrderContext,
    setConsumerOrderContextDate,
    ...consumerRest
  } = useConsumerOrderContextContext();
  const { user } = useEditTeacherOrEmployeeContext();
  const isParentFlow = location.pathname.includes('parents') || location.pathname.includes('parent');

  useEffect(() => {
    if (tenantId && isParentFlow && userId) {
      setTenantId(Number(tenantId));
      setUserId(Number(userId));
    }

    if (tenantId && !isParentFlow && user.consumerId) {
      setTenantIdForConsumer(Number(tenantId));
      setUserIdForConsumer(user.consumerId);
    }
  }, [tenantId, userId, setTenantId, setUserId, isParentFlow, user]);

  const restContextValue = useMemo(
    () => (isParentFlow ? { ...rest } : { ...consumerRest }),
    [rest, consumerRest, isParentFlow]
  );

  const hookReturnValue = useMemo(
    () => ({
      userOrderContext: isParentFlow ? parentOrderContext : consumerOrderContext,
      setTenantId: isParentFlow ? setTenantId : setTenantIdForConsumer,
      setUserId: isParentFlow ? setUserId : setUserIdForConsumer,
      setSkipFetchingUserOrderContext: isParentFlow
        ? setSkipFetchingParentOrderContext
        : setSkipFetchingConsumerOrderContext,
      setUserOrderContextDate: isParentFlow
        ? setParentOrderContextDate
        : setConsumerOrderContextDate,
      isParentFlow,
      user,
      ...restContextValue
    }),
    [isParentFlow, parentOrderContext, consumerOrderContext, user]
  );

  return hookReturnValue;
};
