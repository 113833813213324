import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import CheckboxInput from '../../../../Forms/CheckboxInput/CheckboxInput';

interface CustomToolbarProps {
  label: string;
  onNavigate: (action: 'PREV' | 'NEXT' | 'TODAY' | Date) => void;
  onToggleSelectAll: (isSelected: boolean) => void;
  isSelectAllChecked: boolean;
  clearSelectedDates: () => void;
  disabled?: boolean;
  useTranslations?: boolean;
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  label,
  onNavigate,
  onToggleSelectAll,
  isSelectAllChecked,
  clearSelectedDates,
  disabled,
  useTranslations
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-spacing-xl">
      <div className="flex justify-between px-spacing-xl lg:px-spacing-0  items-center pb-spacing-xl">
        <div className="flex">
          <CheckboxInput
            text={
              useTranslations
                ? //@ts-ignore
                  t('parent_order.period_order.toolbar.select_all_month')
                : 'Zaznacz cały miesiąc'
            }
            checkboxParams={{
              isChecked: isSelectAllChecked,
              onChange: (e) => onToggleSelectAll(e.target.checked),
              isDisabled: !!disabled
            }}
          />
        </div>
        <div className="flex items-center justify-center gap-4">
          <button onClick={() => onNavigate('PREV')} className="p-2 rounded-md">
            &#8249;
          </button>
          <span className="text-md sm:text-xl font-semibold">{label}</span>
          <button onClick={() => onNavigate('NEXT')} className="p-2 rounded-md">
            &#8250;
          </button>
        </div>
        <div className="flex gap-spacing-md">
          <CustomChakraButton
            hierarchy="linkGray"
            iconPosition="left"
            icon="xClose"
            size="sm"
            buttonProps={{
              type: 'button',
              onClick: clearSelectedDates
            }}>
            {useTranslations
              ? //@ts-ignore
                t('parent_order.period_order.toolbar.clean_calendar')
              : 'Wyczyść kalendarz'}
          </CustomChakraButton>
        </div>
      </div>
    </div>
  );
};

export default CustomToolbar;
