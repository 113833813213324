import { useTranslation } from 'react-i18next';
import { TableContainer } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { AxiosResponse, HttpStatusCode } from 'axios';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAlertContext } from '../../../context/AlertProvider';
import { useApi } from '../../../context/ApiProvider';
import { useParentDashboardContextContext } from '../../../context/ParentDashboardContext';
import {ParentAbsenceInterfaceFormMoth, StakeInterface} from '../../../shared/type/absence.type';
import { CustomButtonType } from '../../../shared/type/customButton.type';
import GetButtonMessage from '../../../utils/GetButtonMessage';
import ChooseMonths from '../../ChooseMonths/ChooseMonths';
import CustomBadge from '../../CustomBadge/CustomBadge';
import CustomChakraButton from '../../CustomChakraButton/CustomChakraButton';
import CustomHorizontalTabs from '../../CustomHorizontalTabs/CustomHorizontalTabs';
import { DataTable } from '../../DataTable/DataTable';
import IconComponent from '../../IconComponent/IconComponent';
import ChooseMonthsIn from '../../ChooseMonths/ChooseMonthsIn';

import './ParentAbsences.css';

const ParentAbsences = () => {
  const today = new Date();
  const { t } = useTranslation();
  const { setAlertProperties, setShow: setShowAlert } = useAlertContext();
  const { setBasketStatus } = useParentDashboardContextContext();
  const { apiAbsenceAndCourseController } = useApi();
  const { parentDashboardContext, fetchParentDashboardContext } =
    useParentDashboardContextContext();
  const [chosenTenantId, setChosenTenantId] = useState<number | null>(null);
  const [chosenConsumerId, setChosenConsumerId] = useState<number | null>(null);
  const [currentDate, setCurrentDate] = React.useState<{
    year: number;
    month: number;
    day: number;
  }>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [absences, setAbsences] = useState<ParentAbsenceInterfaceFormMoth[]>([]);
  const columnHelper = createColumnHelper<ParentAbsenceInterfaceFormMoth>();

  const childrenToSelect = useMemo(() => {
    return parentDashboardContext
      ? parentDashboardContext.tenants
          .find((tenant) => tenant.tenantId === chosenTenantId)
          ?.consumers.map((consumer) => {
            const { firstName, lastName, id } = consumer.consumerDto;
            return {
              value: id,
              label: firstName + ' ' + lastName
            };
          })
      : null;
  }, [parentDashboardContext, chosenTenantId]);
 
  const reportOrRevokeAbsence = async (stakeId: number, presence: boolean) => {
    try {
      const response = await apiAbsenceAndCourseController('report-or-revoke-absence').post('', {
        consumerId: chosenConsumerId,
        boughtStakesId: [stakeId],
        absence: presence
      });

      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          // @ts-ignore
          description: t('parent_absences.update_success'),
          timeout: 9000,
          // @ts-ignore
          title: t('parent_absences.success'),
          status: 'success'
        });
        setShowAlert(true);

        setAbsences((prevAbsences) =>
          prevAbsences.map((absence) => ({
            ...absence,
            stakesAndCourses: absence.stakesAndCourses.map((stake) =>
              stake.boughtStakeId === stakeId ? { ...stake, presence: !stake.presence } : stake
            )
          }))
        );
        fetchParentDashboardContext();
      }
    } catch (error: any) {
      setAlertProperties({
        // @ts-ignore
        description: t('parent_absences.update_error', {
          action: presence ? 'zgłaszania' : 'odwoływania',
          error: error.response.data.errors[0]
        }),
        timeout: 9000,
        // @ts-ignore
        title: t('parent_absences.error'),
        status: 'error'
      });
      setShowAlert(true);
    }
  };

  const getButton = (absencePossible: boolean, presence: boolean, boughtStakeId: number) => {
    let button: CustomButtonType = {
      size: 'sm',
      iconPosition: 'left',
      icon: 'xClose',
      iconColor: '#B42318',
      hierarchy: 'destructiveSecondary'
    };

    // @ts-ignore
    let buttonText = t('parent_absences.revoke_absence');

    if (!absencePossible && presence) {
      button.iconColor = '#98A2B3';
      button.buttonProps = {
        disabled: true
      };
    }

    if (absencePossible && !presence) {
      // @ts-ignore
      buttonText = t('parent_absences.restore_absence');
      button.hierarchy = 'primary';
      button.icon = 'rollBack';
      button.iconPosition = 'right';
      button.iconColor = '#292524';
    }

    if (!absencePossible && !presence) {
      // @ts-ignore
      buttonText = t('parent_absences.restore_absence');
      button.hierarchy = 'primary';
      button.icon = 'rollBack';
      button.iconPosition = 'right';
      button.iconColor = '#98A2B3';
      button.buttonProps = {
        disabled: true
      };
    }

    return (
      <CustomChakraButton
        buttonProps={{
          type: 'button',
          className: 'grow',
          ...button.buttonProps,
          onClick: () => {
            reportOrRevokeAbsence(boughtStakeId, presence);
          }
        }}
        size={button.size}
        iconPosition={button.iconPosition}
        iconColor={button.iconColor}
        icon={button.icon}
        hierarchy={button.hierarchy}>
        {buttonText}
      </CustomChakraButton>
    );
  };

  const rowHeight = 64;

  const getTotalAssignedCourses = (stakes: StakeInterface[]) =>
      Math.max(1, stakes.reduce((acc, stake) => acc + stake.takenCourses.length, 0));

  const columns = [
    columnHelper.display({
      id: 'when',
      cell: (info) => {
        const date = new Date(info.row.original.when);

        const isWeekend = (str: string): boolean => {
          if (str === 'Niedziela') return true;
          return str === 'Sobota';
        };

        // @ts-ignore
        info.row.original.rowBackgroundColor =
          isWeekend(info.row.original.dayOfTheWeek) && '#F9FAFB';

        return (
          <div className="flex flex-col items-start">
            <span className="text-sm font-medium text-grayLight-900">
              {info.row.original.dayOfTheWeek}
            </span>
            <span className="text-sm font-normal text-grayLight-600">
              {date.getDate()}.
              {date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}
            </span>
          </div>
        );
      },
      //@ts-ignore
      header: t('parent_absences.day')
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        if (stakesAndCourses.length === 0) {
          return (
            <div className="flex items-center justify-start">
              <p>-</p>
            </div>
          );
        }

        const totalAssignedCourses = getTotalAssignedCourses(stakesAndCourses);

        return (
          <div
            className="flex flex-col justify-center bg-red"
            style={{ height: totalAssignedCourses * rowHeight + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div
                key={`stakeName-${stakeIndex}`}
                className={`flex flex-col justify-center`}
                style={{ height: (stake.takenCourses.length / totalAssignedCourses) * 100 + '%' }}>
                <p className="text-grayLight-900 font-medium text-sm">{stake.stakeName}</p>
              </div>
            ))}
          </div>
        );
      },
      //@ts-ignore
      header: t('parent_absences.meal_names')
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        if (stakesAndCourses.length === 0) {
          return (
            <div className="flex items-center justify-start">
              <p>-</p>
            </div>
          );
        }

        const totalAssignedCourses = getTotalAssignedCourses(stakesAndCourses);

        return (
          <div
            className="flex flex-col justify-center bg-red"
            style={{ height: totalAssignedCourses * rowHeight + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div
                key={`stakeCategory-${stakeIndex}`}
                className={`flex flex-col justify-center`}
                style={{ height: (stake.takenCourses.length / totalAssignedCourses) * 100 + '%' }}>
                {stake.takenCourses.map((course, takeIndex) => (
                  <div
                    key={`singleStakeCategory-${takeIndex}`}
                    className="flex flex-col justify-center"
                    style={{ height: (1 / stakesAndCourses.length) * 100 + '%' }}>
                    <p className="text-grayLight-900 font-medium text-sm">{course.courseName}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        );
      },
      //@ts-ignore
      header: t('parent_absences.meal_categories')
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        if (stakesAndCourses.length === 0) {
          return (
            <div className="flex items-center justify-center">
              <p>-</p>
            </div>
          );
        }

        const totalAssignedCourses = getTotalAssignedCourses(stakesAndCourses);

        return (
          <div
            className="flex flex-col justify-center"
            style={{ height: totalAssignedCourses * rowHeight + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div
                key={`stakeTaken-${stakeIndex}`}
                className={`flex flex-col justify-center`}
                style={{ height: (stake.takenCourses.length / totalAssignedCourses) * 100 + '%' }}>
                {stake.takenCourses.map((course, courseIndex) => (
                  <div
                    key={`singleStakeTaken-${courseIndex}`}
                    className="flex flex-col justify-center items-center"
                    style={{ height: (1 / stakesAndCourses.length) * 100 + '%' }}>
                    <CustomBadge size="sm" color={course.taken ? 'success' : 'gray'}>
                      <div className="flex items-center gap-spacing-xs">
                        {course.taken
                          ? //@ts-ignore
                            t('parent_absences.yes')
                          : // @ts-ignore
                            t('parent_absences.no')}
                        <IconComponent
                          iconName={course.taken ? 'check' : 'xClose'}
                          className="h-2"
                        />
                      </div>
                    </CustomBadge>
                  </div>
                ))}
              </div>
            ))}
          </div>
        );
      },
      //@ts-ignore
      header: t('parent_absences.pickup_status')
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses, reportingAbsencePossible } = info.row.original;

        if (stakesAndCourses.length == 0) return;

        const totalAssignedCourses = getTotalAssignedCourses(stakesAndCourses);

        return (
          <div
            className="flex flex-col justify-center"
            style={{ height: totalAssignedCourses * rowHeight + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div
                key={`stakeButton-${stake.boughtStakeId}-${stakeIndex}`}
                className={`flex flex-col justify-center`}
                style={{ height: (stake.takenCourses.length / totalAssignedCourses) * 100 + '%' }}>
                <div className="flex flex-col w-full items-center justify-center">
                  <div className="flex items-center">
                    {getButton(reportingAbsencePossible, stake.presence, stake.boughtStakeId)}
                  </div>
                  <div className="flex w-full justify-center">
                    <GetButtonMessage
                      absencePossible={reportingAbsencePossible}
                      presence={stake.presence}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      },
      header: ''
    })
  ];

  const fetchAbsencesForCustomer = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<ParentAbsenceInterfaceFormMoth[]> =
        await apiAbsenceAndCourseController('absences-for-consumer-for-month').post('', {
          consumerId: chosenConsumerId,
          tenantId: chosenTenantId,
          month: currentDate.month,
          year: currentDate.year
        });
      setAbsences(response.data);
    } catch (error) {
      setAlertProperties({
        //@ts-ignore
        description: t('parent_absences.fetch_error'),
        timeout: 9000,
        title: 'Błąd',
        status: 'error'
      });
      setShowAlert(true);
    } finally {
      setIsLoading(false);
    }
  }, [chosenConsumerId, chosenTenantId, currentDate]);

  useEffect(() => {
    setBasketStatus(() => ({ show: false, forceShow: false }));
  }, []);

  useEffect(() => {
    if (parentDashboardContext) {
      setChosenTenantId(parentDashboardContext.tenants[0].tenantId);
    }
  }, [parentDashboardContext]);

  useEffect(() => {
    if (chosenTenantId && parentDashboardContext) {
      const firstConsumerId =
        parentDashboardContext?.tenants.find((tenant) => tenant.tenantId === chosenTenantId)
          ?.consumers[0].consumerDto.id || null;

      setChosenConsumerId(firstConsumerId);
    }
  }, [chosenTenantId]);

  useEffect(() => {
    if (chosenTenantId !== null && chosenConsumerId !== null) {
      const isChildFromSelectedSchool = parentDashboardContext?.tenants
        .find((tenant) => tenant.tenantId === chosenTenantId)
        ?.consumers.find((consumer) => consumer.consumerDto.id === chosenConsumerId);

      if (isChildFromSelectedSchool) fetchAbsencesForCustomer();
    }
  }, [chosenTenantId, chosenConsumerId, currentDate]);

  return (
    <section className="ParentAbsences flex flex-col bg-grayLight-50 overflow-y-auto">
      <div className="pt-spacing-3xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <h1 className="p-0 m-0 font-semibold text-grayLight-900" style={{ fontSize: '30px' }}>
              {/*  @ts-ignore */}
              {t('parent_absences.title')}
            </h1>
            <p className="font-normal text-grayLight-600" style={{ fontSize: '16px' }}>
              {/*  @ts-ignore */}
              {t('parent_absences.subtitle')}
            </p>
          </div>
          <div className="hidden lg:block">
            <ChooseMonthsIn setDate={setCurrentDate} currentDate={currentDate} mode="month" />
          </div>
        </div>
      </div>
      <div className="flex flex-col pt-spacing-xl pr-spacing-4xl pl-spacing-3xl pb-spacing-xl gap-spacing-xl">
        <CustomHorizontalTabs
          onChange={(value) => {
            setChosenTenantId(value);
          }}
          data={
            parentDashboardContext
              ? parentDashboardContext.tenants.map((tenant) => ({
                  label: tenant.tenantName,
                  value: tenant.tenantId
                }))
              : null
          }
        />

        <CustomHorizontalTabs
          onChange={(value) => {
            setChosenConsumerId(value);
          }}
          data={childrenToSelect}
        />
      </div>
      <div
        className="ParentAbsences__absenceTable hidden lg:flex flex-col pb-spacing-5xl"
        style={{ flexGrow: 1, overflowY: 'auto', marginBottom: '20px' }}>
        <TableContainer
          h="100%"
          flex="1"
          minH="500px"
          className="Branches__table bg-white rounded-lg pb-spacing-8xl">
          <DataTable
            hideSearch={true}
            striped={false}
            disableFilters={true}
            columns={columns}
            isLoading={isLoading}
            data={absences}
            noVerticalLineIndices={[1, 2, 3]}
          />
        </TableContainer>
      </div>
      <div
        className="ParentAbsences__absenceTable flex lg:hidden flex-col pb-spacing-5xl"
        style={{ flexGrow: 1, overflowY: 'auto', marginBottom: '20px' }}>
        <div className="flex justify-center ">
          <ChooseMonths
            hideTodayButton={true}
            setDate={setCurrentDate}
            currentDate={currentDate}
            mode="month"
          />
        </div>
        <div className="flex flex-col gap-spacing-xl pt-spacing-md pr-spacing-xl pb-spacing-md pl-spacing-xl">
          {absences?.map((absence) => (
            <div className="flex flex-col p-spacing-lg rounded-xl gap-spacing-md bg-white shadow-[0px_4px_6px_-2px_#10182808,0px_12px_16px_-4px_#10182814]">
              <div className="flex flex-col justify-between">
                <div className="flex gap-spacing-lg text-sm font-normal">
                  <p className="text-grayLight-900">{absence.dayOfTheWeek}</p>
                  <p className="text-grayLight-600">{absence.when}</p>
                </div>
              </div>
              {absence.stakesAndCourses.map((stakeAndCourse) => (
                <div className="flex flex-col gap-spacing-lg">
                  <div>
                    {/* <p className='text-base font-semibold text-grayLight-800'>{stakeAndCourse.stakeName}</p> */}
                  </div>
                  <div>
                    {stakeAndCourse.takenCourses.map((course) => {
                      return (
                        <div className="flex flex-col">
                          <div className="flex justify-between">
                            <p className="text-base font-semibold text-grayLight-800">
                              {course.courseName}
                            </p>
                            <div>
                              <CustomBadge size="sm" color={course.taken ? 'success' : 'gray'}>
                                <div className="flex items-center gap-spacing-xs">
                                  {course.taken ? 'Odebrany' : 'Nieodebrany'}
                                  <IconComponent
                                    iconName={course.taken ? 'check' : 'xClose'}
                                    className="h-2"
                                  />
                                </div>
                              </CustomBadge>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex flex-col w-full items-center justify-center">
                    <div className="w-full flex">
                      {getButton(
                        absence.reportingAbsencePossible,
                        stakeAndCourse.presence,
                        stakeAndCourse.boughtStakeId
                      )}
                    </div>
                    <div className="w-full">
                      <GetButtonMessage
                        absencePossible={absence.reportingAbsencePossible}
                        presence={stakeAndCourse.presence}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ParentAbsences;
