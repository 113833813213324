import { Text } from '@chakra-ui/react';
import { KitchenSection } from './KitchenSection';
import { StatisticSection } from './StatisticSection';
import { StakesReportSection } from './StakesRaportSection';
import { useDashboard } from './useDashboard';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { AdminCalendar } from './AdminCalendar/AdminCalendar';

export const Dashboard = () => {
  const { loading, dashboardData, tenantId, onDownloadReport, onSeeMoreClick } = useDashboard();

  return (
    <section className="max-w-screen-2xl p-4 pr-4 md:pr-6">
      <header className="mb-4">
        <h1 className="text-3xl font-semibold mb-1">Witaj!</h1>
        <Text className="text-gray-600">
          W tym miejscu sprawdzisz najważniejsze informacje dotyczące zamówień i nieobecności w
          wybranej placówce.
        </Text>
      </header>

      <KitchenSection onSeeMoreClick={onSeeMoreClick} />

      {loading || !dashboardData ? (
        <LoadingSpinner state={loading}>
          <span>Ładowanie danych</span>
        </LoadingSpinner>
      ) : (
        <StatisticSection data={dashboardData} tenantId={Number(tenantId)} />
      )}

      <StakesReportSection onDownloadReport={onDownloadReport} />
      <section className="mt-4 w-full">
        <AdminCalendar />{' '}
      </section>
    </section>
  );
};
