import { Input } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import {
  MessageInterface, MessageInterfaceWithPaginationInterface
} from "../../../../shared/type/message.type";
import { PaginationInterface } from "../../../../shared/type/paginationInterface.type";
import CustomAlert from "../../../CustomAlert/CustomAlert";
import IconComponent from "../../../IconComponent/IconComponent";

interface Props {
  tenantId: string;
  setRefreshMessages: React.Dispatch<React.SetStateAction<() => void>>;
}

const defaultDeleteMessagePopUpContext = {
  isOpen: false,
  threadId: 0
}

const Messages = ({ tenantId, setRefreshMessages }: Props) => {
  const { apiTenantMessageController } = useApi();
  const [messages, setMessages] = useState<MessageInterface[]>([]);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState(searchPhrase);
  const { setShow, setAlertProperties } = useAlertContext();
  const [deleteMessagePopUp, setDeleteMessagePopUp] = useState<{
    isOpen: boolean;
    threadId: number;
  }>(defaultDeleteMessagePopUpContext);
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });

  const fetchMessages = async (pageNumber: number) => {
    try {
      const response: AxiosResponse<MessageInterfaceWithPaginationInterface> = await apiTenantMessageController('topic-list').get(`/${tenantId}`, {
        params: {
          pageNumber: pageNumber,
          pageSize: 10,
          searchPhrase,
        }
      });

      const { data, next, hasNextPage, totalCount } = response.data;

      setActualPage({ next, hasNextPage, totalCount });
      setMessages(data);

    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const goToNextPage = () => {
    if (actualPage.hasNextPage) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now.getTime() - date.getTime();

    if (timeDiff < 24 * 60 * 60 * 1000) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return date.toLocaleDateString('pl-PL');
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchPhrase(searchPhrase);
    }, 400);

    return () => {
      clearTimeout(handler);
    };
  }, [searchPhrase]);

  useEffect(() => {
    fetchMessages(currentPage);
  }, [tenantId, currentPage, debouncedSearchPhrase]);

  useEffect(() => {
    setRefreshMessages(() => () => fetchMessages(currentPage));
  }, [fetchMessages, currentPage, setRefreshMessages]);

  return (
    <div className="ParentMessage flex flex-col">
      <div className='pt-spacing-md pr-spacing-4xl pb-spacing-md pl-spacing-4xl'>
        <div className='flex justify-between'>
          <div className='w-96'>
            <Input
              type="text"
              placeholder="Szukaj po adresacie lub temacie"
              className="font-normal text-base"
              onChange={(e) => setSearchPhrase(e.target.value)}
            />
          </div>
          <div className='flex gap-spacing-lg items-center'>
            <p className='text-sm font-medium text-grayLight-700'>
              Strona {currentPage} z {Math.ceil(actualPage.totalCount / 10)}
            </p>
            <div className='flex gap-spacing-lg'>
              <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                <IconComponent iconName='chevronLeft' className='h-5 w-5' />
              </button>
              <button onClick={goToNextPage} disabled={!actualPage.hasNextPage}>
                <IconComponent iconName='chevronRight' className='h-5 w-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        {messages.length === 0 ? (
          <p className="text-center text-gray-500 mt-4">Brak wiadomości do wyświetlenia.</p>
        ) : (
          messages
            .sort((a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime())
            .map((message, messageIndex) => (
              <div
                key={`Message-${messageIndex}`}
                className={`flex pt-spacing-lg pr-spacing-4xl pb-spacing-lg pl-spacing-4xl hover:bg-brand-100 ${
                  !message.hasBeanRead && "bg-grayLight-100"
                }`}
              >
                <Link
                  to={`message/${message.id}?title=${encodeURIComponent(message.topic)}`}
                  className="basis-11/12 flex items-center gap-spacing-lg"
                >
                  <div className='w-96 flex items-start'>
                    <p className={`text-lg font-${message.hasBeanRead ? "normal" : "semibold"} text-grayLight-700`}>
                      {message.customerUser.firstName} {message.customerUser.lastName}
                    </p>
                  </div>
                  <div>
                    <p className={`text-lg items-start font-${message.hasBeanRead ? "normal" : "semibold"} text-grayLight-700`}>
                      {message.topic}
                    </p>
                  </div>
                </Link>
                <div className="basis-1/12 flex items-center justify-center group">
                  <p className="group-hover:hidden">{formatDate(message.lastUpdate)}</p>
                  <button
                    onClick={() => {
                      setDeleteMessagePopUp({ isOpen: true, threadId: message.id });
                    }}
                  >
                    <IconComponent color="#171412" iconName="trash" className="hidden group-hover:block" />
                  </button>
                </div>
              </div>
            ))
        )}
      </div>
      <CustomAlert
        handleOpen={deleteMessagePopUp.isOpen}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" color='#D92D20' />
          </div>
        }
        header={`Usunąć wiadomość?`}
        content="Usunięcie wiadomości nieodwracalne."
        cancelButtonText="Anuluj"
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        confirmButtonText="Usuń"
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        onCancelAction={() => {
          setDeleteMessagePopUp(defaultDeleteMessagePopUpContext);
        }}
        onConfirmAction={async () => {
          try {
            await apiTenantMessageController('thread').delete(``, {
              params: {
                threadId: deleteMessagePopUp.threadId
              }
            });
            setAlertProperties({
              status: 'success',
              timeout: 9000,
              description: 'Wiadomość usunięta',
              title: 'Sukces'
            });
            setShow(true);
          } catch (error) {
            setAlertProperties({
              status: 'error',
              timeout: 9000,
              description: 'Nie udało się usunąć wiadomości',
              title: 'Błąd'
            });
            setShow(true);
          } finally {
            fetchMessages(currentPage);
            setDeleteMessagePopUp(defaultDeleteMessagePopUpContext);
          }
        }}
      />
    </div>
  );
};

export default Messages;