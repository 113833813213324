import { Wrap, WrapItem, Button } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useApi } from '../../../../context/ApiProvider';
import { useSideNavContext } from '../../../../context/SideNavProvider';
import { TenantInterface } from '../../../../shared/type/tenant.type';

import './SingleBranch.css';
import FormInput from '../../../Forms/FormInput/FormInput';
import IconComponent from '../../../IconComponent/IconComponent';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';

export const defaultTenant: TenantInterface = {
  id: 0,
  parentCompanyId: 0,
  name: '',
  shortName: '',
  urlPart: '',
  parentAutoRegistrationUrl: '',
  address: '',
  city: '',
  postCode: '',
  email: '',
  supportEmail: '',
  supportPhone: '',
  contactPerson: '',
  phone: '',
  mealDistributionMode: '',
  creditLimit: 0,
  creditTakingByParents: false,
  creditTakingByMealDistribution: false,
  transferMode: 'ALL_MONTHS',
  transferOnlyForCurrentYear: false,
  salesReferenceHour: 0,
  salesReferenceMinute: 0,
  endOfSalesHourLimit: 0,
  absenceReportHour: 0,
  absenceReportMinute: 0,
  absenceReportHourLimit: 0,
  absenceReportSkipWeekend: false,
  orderToDay: null,
  orderToDayMode: false,
  orderSizeMode: 'DAILY',
  absenceReturnPercentage: 0,
  totalSalesLimit: 0,
  allMealsCheckIn: false,
  enforceEmail: false,
  enforceCardUpdate: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
  parentRegistrationActive: false,
  enforcePhoneNumber: false,
  childrenRegistrationActive: false,
  tpayId: '',
  tpaySecret: ''
};

const SingleBranch = () => {
  const { apiTenantController } = useApi();
  const { id: tenant } = useParams();
  const { toggleShowNav } = useSideNavContext();
  const [isLoading, setIsLoading] = useState(true);
  const [branch, setBranch] = useState<TenantInterface>(defaultTenant);

  const fetchBranch = async () => {
    try {
      const response: AxiosResponse<any, TenantInterface> = await apiTenantController('').get(
        `/${tenant}`
      );
      setBranch(response.data);
    } catch (error) {
      console.warn('Bład pobrania elementu');
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    fetchBranch();
  };

  const handleChangeBranch = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { name, shortName, urlPart, address, postCode, city, contactPerson, email, phone ,  tpaySecret,tpayId} =
        branch;
      await apiTenantController('').put(`/${tenant}`, {
        ...branch,
        name,
        shortName,
        urlPart,
        address,
        postCode,
        city,
        contactPerson,
        email,
        phone,
        tpaySecret,

      });
    } catch (error) {
      console.error('Błąd aplikacji: ', error);
    } finally {
      fetchBranch();
    }
  };

  useEffect(() => {
    toggleShowNav(true);
    fetchBranch();
  }, [toggleShowNav]);

  useEffect(() => {
    fetchBranch();
  }, [tenant]);

  return (
    <section className="SingleBranch w-full h-screen flex flex-row overflow-y-scroll">
      <div className="SingleBranch__goBackButton w-full h-full">
        <Link
          to="/superAdmin/branches"
          className="pt-spacing-xl p-spacing-xl flex items-center gap-spacing-sm font-semibold text-base"
        >
          <IconComponent iconName="arrowLeft" />
          <p className="m-0 p-0">Panel SuperAdmina</p>
        </Link>
        <div className="SingleBranch__contentContainer h-full pt-spacing-xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl">
          <div className="w-full h-full">
            <p className="font-semibold text-xl text-grayLight-900 mb-spacing-3xl">
              {branch?.name}
            </p>
            <div>
              <div className="border-b pr-spacing-md pl-spacing-md mb-spacing-xl">
                <div className="inline-block border-b-2 border-brand-700">
                  <p className="pt-spacing-xs pr-spacing-lg pb-spacing-xs pl-spacing-md font-semibold text-base text-brand-700">
                    Dane oddziału
                  </p>
                </div>
              </div>
              <LoadingSpinner state={isLoading}>
                <form className="flex flex-row" onSubmit={handleChangeBranch}>
                  <div className="SingleBranch__form basis-6/12 grid gap-8">
                    <div className="grid gap-spacing-xl">
                      <FormInput
                        label="Nazwa oddziału"
                        inputParams={{
                          value: branch?.name,
                          name: 'name',
                          onChange: (e) => {
                            setBranch((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value
                            }));
                          }
                        }}
                      />
                      <FormInput
                        label="Skrócona nazwa"
                        inputParams={{
                          value: branch?.shortName || '',
                          name: 'shortName',
                          onChange: (e) => {
                            setBranch((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value
                            }));
                          }
                        }}
                      />
                      <FormInput
                        label="Identyfikator URL"
                        inputParams={{
                          value: branch?.urlPart,
                          name: 'urlPart',
                          onChange: (e) => {
                            setBranch((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="grid gap-spacing-xl">
                      <div>
                        <p className="font-medium text-sm text-grayLight-500">Adres oddziału</p>
                        <hr />
                      </div>
                      <FormInput
                        label="Ulica"
                        inputParams={{
                          value: branch?.address,
                          name: 'address',
                          onChange: (e) => {
                            setBranch((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value
                            }));
                          }
                        }}
                      />
                      <div className="flex flex-row justify-between">
                        <FormInput
                          label="Kod pocztowy"
                          inputParams={{
                            value: branch?.postCode,
                            maxLength: 6,
                            name: 'postCode',
                            onChange: (e) => {
                              setBranch((prev) => ({
                                ...prev,
                                [e.target.name]: e.target.value
                              }));
                            }
                          }}
                        />
                        <FormInput
                          label="Miasto"
                          inputParams={{
                            value: branch?.city,
                            name: 'city',
                            onChange: (e) => {
                              setBranch((prev) => ({
                                ...prev,
                                [e.target.name]: e.target.value
                              }));
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="grid gap-spacing-xl">
                      <div>
                        <p className="font-medium text-sm text-grayLight-500">Osoba kontaktowa</p>
                        <hr />
                      </div>
                      <FormInput
                        label="Imię i nazwisko"
                        inputParams={{
                          value: branch?.contactPerson,
                          name: 'contactPerson',
                          onChange: (e) => {
                            setBranch((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value
                            }));
                          }
                        }}
                      />
                      <FormInput
                        label="Adres email"
                        inputParams={{
                          value: branch?.email,
                          name: 'email',
                          onChange: (e) => {
                            setBranch((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value
                            }));
                          }
                        }}
                      />
                      <FormInput
                        label="Numer telefonu"
                        inputParams={{
                          value: branch?.phone,
                          name: 'phone',
                          onChange: (e) => {
                            setBranch((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="grid gap-spacing-xl">
                      <div>
                        <p className="font-medium text-sm text-grayLight-500">Dane kontaktowe wsparcia</p>
                        <hr />
                      </div>
                      <FormInput
                        label="Adres email wsparcia"
                        inputParams={{
                          value: branch?.supportEmail,
                          name: 'supportEmail',
                          onChange: (e) => {
                            setBranch((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value
                            }));
                          }
                        }}
                      />
                      <FormInput
                        label="Numer telefonu wsparcia"
                        inputParams={{
                          value: branch?.supportPhone,
                          name: 'supportPhone',
                          onChange: (e) => {
                            setBranch((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="grid gap-spacing-xl">
                      <div>
                        <p className="font-medium text-sm text-grayLight-500">Dane T-pay</p>
                        <hr />
                      </div>
                      <FormInput
                        label="T-Pay Id"
                        inputParams={{
                          value: branch?.tpayId,
                          name: 'tpayId',
                          onChange: (e) => {
                            setBranch((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value
                            }));
                          }
                        }}
                      />
                      <FormInput
                        label="T-pay secret"
                        inputParams={{
                          value: branch?.tpaySecret,
                          name: 'tpaySecret',
                          onChange: (e) => {
                            setBranch((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className='h-40'></div>
                  </div>
                  <div className="SingleBranch__form basis-6/12">
                    <div className="flex justify-end">
                      <Wrap spacing={4}>
                        <WrapItem>
                          <Button
                            backgroundColor="transparent"
                            border="none"
                            fontSize="14px"
                            fontWeight="600"
                            onClick={handleCancel}
                          >
                            Anuluj
                          </Button>
                        </WrapItem>
                        <WrapItem>
                          <Button
                            color="#292524"
                            backgroundColor="#FFDB94"
                            fontSize="14px"
                            fontWeight="600"
                            type="submit"
                          >
                            Zapisz zmiany
                          </Button>
                        </WrapItem>
                      </Wrap>
                    </div>

                  </div>
                </form>
              </LoadingSpinner>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleBranch;
