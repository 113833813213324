import React, { useEffect, useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { NavOptionsWithElementInterface } from '../../../shared/type/navOptions.type';
import Navigation from '../../Navigation/Navigation';
import ParentAccountSettings from './ParentAccountSettings/ParentAccountSettings';
import { useParentDashboardContextContext } from '../../../context/ParentDashboardContext';
import { useTranslation } from 'react-i18next';
import ChangePasswordSettingsIn from '../../SettingsComponent/ChangePasswordSettingsIn';

const ParentSettings = () => {
  const { t, i18n } = useTranslation();

  const navOptions: NavOptionsWithElementInterface[] = useMemo(
    () => [
      {
        link: 'account-settings',
        //@ts-ignore
        name: t('parent_account_settings.account_settings'),
        component: <ParentAccountSettings />
      },
      {
        link: 'password-settings',
        //@ts-ignore
        name: t('parent_account_settings.password'),
        component: <ChangePasswordSettingsIn />
      }
    ],
    [i18n.language]
  );

  const { setBasketStatus } = useParentDashboardContextContext();

  useEffect(() => {
    setBasketStatus(() => ({ show: false, forceShow: false }));
  }, []);

  return (
    <section className="flex flex-col pt-spacing-3xl pb-12 gap-spacing-3xl">
      <div className="flex flex-col gap-6 px-spacing-lg lg:px-spacing-3xl">
        <div className="gap-spacing-3xl">
          <h2 className="font-semibold text-grayLight-900" style={{ fontSize: '30px' }}>
            {/* @ts-ignore */}
            {t('parent_account_settings.title')}
          </h2>
        </div>
        <Navigation navOptions={navOptions} />
        <Routes>
          <Route path="/" element={<Navigate to="account-settings" replace />} />
          {navOptions.map((item) => (
            <Route key={item.link} path={`/${item.link}`} element={item.component} />
          ))}
        </Routes>
      </div>
    </section>
  );
};

export default ParentSettings;
