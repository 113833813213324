import { HttpStatusCode, AxiosResponse } from "axios";
import React, { ReactNode, useState, createContext, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  EditAdministratorContextInterface,
} from "../shared/type/administrator.type";
import { ModuleInterface } from "../shared/type/module.type";
import { useAlertContext } from "./AlertProvider";
import { useApi } from "./ApiProvider";

interface Props {
  children: ReactNode;
}

interface EditButtonStateInterface {
  isTopButtonActive: boolean,
  isBottomButtonActive: boolean,
}

interface EditAdministratorContextProps {
  updateAdministrator:  (e?: React.SyntheticEvent) => Promise<void>;
  fetchEditAdministratorContext: () => void;
  updateEmailPassword: () => void;
  adminEditContext: EditAdministratorContextInterface | undefined;
  setAdminEditContext: React.Dispatch<React.SetStateAction<EditAdministratorContextInterface | undefined>>;
  unassignTenantFromAdministrator: (tenantId: number) => void;
  editButtonState: EditButtonStateInterface;
  setEditButtonState: React.Dispatch<React.SetStateAction<EditButtonStateInterface>>;
  setId: React.Dispatch<React.SetStateAction<number | undefined>>;
  modules: ModuleInterface[];
  setNewPassword: React.Dispatch<React.SetStateAction<string>>;
}


const EditAdministratorContext = createContext<EditAdministratorContextProps | undefined>(undefined);

export const useEditAdministratorContext = () => {
  const context = useContext(EditAdministratorContext);
  if (!context) {
    throw new Error('useEditAdministratorContext must be used within a EditAdministratorProvider');
  }
  return context;
};

export const EditAdministratorProvider = ({ children }: Props) => {
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const [ adminEditContext, setAdminEditContext ] = useState<EditAdministratorContextInterface | undefined>(undefined);
  const [ id, setId ] = useState<number | undefined>(undefined);
  const [ modules, setModules] = useState<ModuleInterface[]>([]);
  const { apiTenantAdminController, apiTenantUserController } = useApi();
  const [ newPassword, setNewPassword ] = useState<string>("");
  const { id: tenantId, userId } = useParams();

  const [ editButtonState, setEditButtonState ] = useState<EditButtonStateInterface>({
    isTopButtonActive: false,
    isBottomButtonActive: false,
  })

  const fetchAvailableModules = async () => {
    try {
      const result = await apiTenantAdminController('modules').get('');
      setModules(result.data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  const fetchEditAdministratorContext = async () => {
    try{
      const contextResponse: AxiosResponse<EditAdministratorContextInterface> = await apiTenantAdminController('edit-context').get('', {
        params: {
          userId: Number(userId)
        }
      });
      setAdminEditContext(contextResponse.data);

    } catch (error) {

    }
  }

  const unassignTenantFromAdministrator = async (tenantId: number) => {
    try {
      const moduleIds = modules
          .filter((module) => adminEditContext?.adminUserDto.availableModules.includes(module.name))
          .map((module) => module.id);

      const tenantIds = adminEditContext?.adminUserDto.assignedTenants
          .filter((tenant) => tenant.id !== tenantId)
          .map((tenant) => tenant.id) || [];

      const { firstName, lastName, phone } = adminEditContext!.adminUserDto;

      const response = await apiTenantAdminController('').put(`/${Number(userId)}`, {
        tenantIds,
        moduleIds,
        firstName,
        lastName,
        phone,
      });

      if(response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 9000,
          title: 'Sukces. Poprawnie zaktualizowano listę oddziałów administratora',
          status: 'success',
        })
      }

    } catch (error) {
      setAlertProperties({
        timeout: 9000,
        title: 'Błąd. Nie udało się zaktualizować listy oddziałów administratora',
        status: 'error',
      })
    } finally {
      setShowAlert(true);
      fetchEditAdministratorContext();
    }
  }

  const updateAdministrator = async (e?: React.SyntheticEvent): Promise<void> => {
    if (e) {
      e.preventDefault();
    }

    try {
      const moduleIds = modules
          .filter((module) => adminEditContext?.adminUserDto.availableModules.includes(module.name))
          .map((module) => module.id);

      const tenantIds = adminEditContext?.adminUserDto.assignedTenants.map((tenant) => tenant.id) || [];

      const { firstName, lastName, phone } = adminEditContext!.adminUserDto;

      const response = await apiTenantAdminController('').put(`/${Number(userId)}`, {
        tenantIds,
        moduleIds,
        firstName,
        lastName,
        phone,
      });

      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 9000,
          title: 'Sukces. Poprawnie zaktualizowano ustawienia administratora',
          status: 'success',
        });
      }
    } catch (error) {
      console.error("Błąd aktualizacji administratora", error);
    } finally {
      setShowAlert(true);
    }
  };

  const updateEmailPassword = async () => {
    try {
      const { login, password } = adminEditContext?.adminUserDto || {};

      const requestBody: any = {
        tenantId: Number(tenantId),
        userId: Number(id),
        email: login || '',
      };

      if (newPassword && newPassword !== password) {
        requestBody.newPassword = newPassword;
      }

      await apiTenantUserController('email-or-password-update').put('', requestBody)
      setAlertProperties({
        timeout: 9000,
        title: "Sukces",
        description: `Poprawnie zaktualizowano ustawienia administratora`,
        status: "success"
      });
      setEditButtonState((prev => ({...prev, isBottomButtonActive: false})));
    } catch (error: any) {
      const { status, data } = error.response;
      if(status === HttpStatusCode.Conflict) {
        setAlertProperties({
          timeout: 9000,
          title: "Niepowodzenie",
          description: `Administrator o podanym adresie email już istnieje w systemie.`,
          status: "warning"
        });
      }
      if(data.violations){
        setAlertProperties({
          timeout: 9000,
          title: "Błąd",
          description: (
              <div className='flex flex-col'>
                <p>Błąd aktualizacji ustawień administratora:</p>
                <ul>
                  {data.violations.map((item : any) => <li key={item.message}>{item.message}</li>)}
                </ul>
              </div>
          ),
          status: "error"
        });
      } else {
        setAlertProperties({
          timeout: 9000,
          title: "Błąd aktualizacji administratora",
          status: "error"
        });
      }


    } finally {
      setShowAlert(true);
    }
  }

  useEffect(() => {
    fetchAvailableModules()
    fetchEditAdministratorContext()
    if(adminEditContext){
      setNewPassword(adminEditContext.adminUserDto.password)
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      fetchEditAdministratorContext,
      updateAdministrator,
      updateEmailPassword,
      adminEditContext,
      setAdminEditContext,
      unassignTenantFromAdministrator,
      editButtonState,
      setEditButtonState,
      setId,
      modules,
      setNewPassword
    }),
    [
      fetchEditAdministratorContext,
      updateAdministrator,
      updateEmailPassword,
      adminEditContext,
      setAdminEditContext,
      unassignTenantFromAdministrator,
      editButtonState,
      setEditButtonState,
      setId,
      modules,
      setNewPassword
    ]
  );

  return (
    <EditAdministratorContext.Provider value={contextValue}>
      <form onSubmit={updateAdministrator}>
        {children}
      </form>
    </EditAdministratorContext.Provider>
  );
};
