import { useState, useEffect } from 'react';
import calculatePrice from '../../../../../../../../../utils/calculators/calculatePrice';

interface Props {
  consumer: { consumerName: string; currentSaldo: number; consumerId: number };
  updateConsumerAmount: (consumerId: number, amount: string) => void;
}

const TenantAdminSaldoUserCard = ({ consumer, updateConsumerAmount }: Props) => {
  const { consumerName, currentSaldo, consumerId } = consumer;
  const [moneyAmount, setMoneyAmount] = useState('0,00');
  const [calculation, setCalculation] = useState(currentSaldo);

  const validateAndFormatAmount = (value: string, finalize: boolean = false) => {
    let formattedValue = value
      .replace(/[^0-9,]/g, '')
      .replace(/,(?=.*?,)/g, '')
      .replace(/^(\d*,\d{0,2}|\d*).*/, '$1');

    if (finalize && formattedValue.includes(',')) {
      const parts = formattedValue.split(',');
      if (parts[1].length === 1) {
        formattedValue = `${parts[0]},${parts[1]}0`;
      } else if (parts[1].length === 0) {
        formattedValue = `${parts[0]},00`;
      }
    }

    return formattedValue;
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const parsedAmount = parseFloat(moneyAmount.replace(',', '.')) || 0;
      setCalculation(currentSaldo + parsedAmount * 100);
    }, 200);
    return () => clearTimeout(timeout);
  }, [moneyAmount, currentSaldo]);

  return (
    <div className="flex flex-col gap-spacing-xl w-full">
      <p className="text-lg text-grayLight-900 font-semibold">{consumerName}</p>
      <div className="flex flex-col gap-spacing-xl">
        <div className="flex justify-between items-center w-52">
          <label className="text-sm text-grayLight-700 font-medium">Saldo:</label>
          <p className="text-lg text-grayLight-700 font-normal">
            {calculatePrice(currentSaldo, 'zł')}
          </p>
        </div>
        <div className="flex justify-between items-center w-52">
          <label className="text-sm text-grayLight-700 font-medium">Wpłacam:</label>
          <input
            onChange={(e) => {
              const formattedValue = validateAndFormatAmount(e.target.value);
              setMoneyAmount(formattedValue);

              const amountWithDot = formattedValue.replace(',', '.');
              const amountToSend = Number(amountWithDot).toFixed(2);
              updateConsumerAmount(consumerId, amountToSend);

              setCalculation(currentSaldo + parseFloat(amountToSend) * 100 || 0);
            }}
            onBlur={() => {
              setMoneyAmount(validateAndFormatAmount(moneyAmount, true));
            }}
            value={moneyAmount}
            type="text"
            className="rounded-md border box-border w-24"
            style={{ padding: '10px 14px' }}
          />
        </div>
        <div className="border-b border-grayLight-300 w-full" />
        <div className="flex justify-between items-center w-52">
          <label className="text-sm text-grayLight-700 font-medium">Saldo po wpłacie:</label>
          <p className="text-lg text-grayLight-700 font-normal">
            {calculatePrice(calculation, 'zł')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TenantAdminSaldoUserCard;
