import React, { useEffect, useState } from 'react';
import { useParams, Link, Routes, Route } from 'react-router-dom';
import { useEditParentContext } from '../../../../../../context/EditParentProvider';
import useAuth from '../../../../../../hooks/useAuth';
import { NavOptionsWithElementInterface } from '../../../../../../shared/type/navOptions.type';
import Breadcrumbs from '../../../../../Breadcrumbs/Breadcrumbs';
import IconComponent from '../../../../../IconComponent/IconComponent';
import Navigation from '../../../../../Navigation/Navigation';
import ListOfOrder from './ListOfOrder/ListOfOrder';
import ParentData from './ParentData/ParentData';
import ParentKids from '../../../ParentKids/ParentKids';
import UserProfileLink from '../../../UserProfileLink/UserProfileLink';
import CustomChakraButton from '../../../../../CustomChakraButton/CustomChakraButton';

const SingleParent = () => {
  const { setParentId } = useEditParentContext();
  const { parent, kids } = useEditParentContext();
  const { auth } = useAuth();
  const { id: tenantId, userId: parentId } = useParams<{ userId: string; id: string }>();
  const [showingAllChildrens, setShowAllChildrens] = useState(false);
  const kidsToShow = showingAllChildrens ? kids : kids.slice(0, 2);

  useEffect(() => {
    setParentId(Number(parentId));
  }, [tenantId, parentId]);

  const navOptions: NavOptionsWithElementInterface[] = [
    {
      link: 'list-of-orders',
      name: 'Lista zamówień',
      component: <ListOfOrder />
    },
    {
      link: 'parent-kinds',
      name: 'Dzieci',
      component: <ParentKids />
    },
    {
      link: 'parent-data',
      name: 'Dane użytkownika',
      component: <ParentData />
    }
  ];

  return (
    <section className="SingleCompany">
      <div className="SingleCompany__container pt-spacing-xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl gap-spacing-4xl flex flex-col">
        <div className="SingleCompany__actualCompanyStatus flex justify-between">
          <Breadcrumbs
            divider={<IconComponent iconName="chevronRight" />}
            elements={[
              <IconComponent iconName="home" />,
              <p className="font-medium text-sm text-grayLight-600">Rodzice</p>,
              <p className="font-semibold text-sm text-grayWarm-800">
                {parent?.firstName} {parent?.lastName}
              </p>
            ]}
          />
          <div className="text-right">
            <p className="text-gray-500 text-xs">Profile dzieci</p>
            <div className="mt-1 flex items-center gap-4">
              {kids.length > 2 && (
                <CustomChakraButton
                  hierarchy="linkGray"
                  size="sm"
                  iconPosition="onlyText"
                  buttonProps={{
                    onClick: () => setShowAllChildrens((prev) => !prev)
                  }}>
                  {showingAllChildrens ? 'Hide' : <> +{kids.length - 2}</>}
                </CustomChakraButton>
              )}
              {kidsToShow.map((kid) => (
                <UserProfileLink
                  href={`/tenantAdmin/id/${tenantId}/users/children/single-kid/${kid.id}/orders`}
                  fullName={`${kid.firstName} ${kid.lastName}`}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="SingleCompany__container flex flex-col gap-spacing-4xl">
          <Link
            to={
              auth.loggedUserDto.superAdmin
                ? `/superAdmin/branches/id/${tenantId}/users/parents/parents-table`
                : `/tenantAdmin/id/${tenantId}/users/parents/parents-table`
            }
            className="SingleCompany__backButtonContainer flex gap-spacing-md font-semibold text-base text-grayWarm-950">
            <IconComponent iconName="arrowLeft" />
            <p>Wróć</p>
          </Link>
          <div className="SingleCompany__contextContainer flex flex-col gap-spacing-xl">
            <Navigation navOptions={navOptions} />
            <Routes>
              {navOptions.map((item) => (
                <Route key={item.link} path={`/${item.link}`} element={item.component} />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleParent;
