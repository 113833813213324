import React, { useState } from 'react';
import Calendar from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { pl } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import getMonthFromNumber from '../../utils/getMonth';
import CustomChakraButton from '../CustomChakraButton/CustomChakraButton';
import IconComponent from '../IconComponent/IconComponent';
import { ChooseMonthsProps } from './ChooseMonths';

import './ChooseMonths.css';

const ChooseMonthsIn = ({ setDate, currentDate, mode, hideTodayButton }: ChooseMonthsProps) => {
  const { t, i18n } = useTranslation();
  const [showCalendar, setShowCalendar] = useState(false);

  registerLocale(i18n.language, pl);

  const handleChangeDate = (value: number) => {
    setDate((prev) => {
      if (mode === 'day') {
        const newDate = new Date(prev.year, prev.month - 1, prev.day + value);
        return {
          year: newDate.getFullYear(),
          month: newDate.getMonth() + 1,
          day: newDate.getDate()
        };
      } else {
        const newMonth = prev.month + value;
        const newYear = newMonth < 1 ? prev.year - 1 : newMonth > 12 ? prev.year + 1 : prev.year;
        const adjustedMonth = ((newMonth - 1 + 12) % 12) + 1;
        return { year: newYear, month: adjustedMonth, day: 1 };
      }
    });
  };

  const handleDateChange = (date: Date | null) => {
    if (date === null) return;
    if (mode === 'day') {
      setDate({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      });
    } else {
      setDate({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: 1
      });
    }
    setShowCalendar(false);
  };

  return (
    <div className="flex flex-col gap-spacing-2xl bg-white h-auto relative">
      <div className="flex gap-spacing-2xl h-10">
        {!hideTodayButton && (
          <CustomChakraButton
            size="sm"
            hierarchy="linkGray"
            iconPosition="onlyText"
            icon="plus"
            buttonProps={{
              onClick: () => {
                const today = new Date();
                setDate({
                  year: today.getFullYear(),
                  month: today.getMonth() + 1,
                  day: today.getDate()
                });
              }
            }}>
            {/* @ts-ignore */}
            {t('choose_months_selector.today')}
          </CustomChakraButton>
        )}

        <div className="flex">
          <div
            className="border cursor-pointer border-grayLigh-300 rounded-tl-lg rounded-bl-lg pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg flex items-center justify-center"
            onClick={() => {
              handleChangeDate(-1);
            }}>
            <IconComponent iconName="chevronLeft" className="w-5 h-5" color="#344054" />
          </div>
          <div
            className="border border-grayLigh-300 w-40 bg-grayNeutral-50 pt-spacing-md pr-spacing-xl pb-spacing-md pl-spacing-xl flex items-center justify-center gap-spacing-md cursor-pointer"
            onClick={() => setShowCalendar((prev) => !prev)}>
            <IconComponent iconName="calendar" className="h-5 w-5" />
            <p className="text-sm font-semibold text-grayLight-700">
              {mode === 'day' && currentDate.day}{' '}
              {getMonthFromNumber(currentDate.month, i18n.language)}{' '}
            </p>
            <p className="text-sm font-semibold text-grayLight-700">{currentDate.year}</p>
          </div>
          <div
            className="border cursor-pointer border-grayLigh-300 rounded-tr-lg rounded-br-lg pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg flex items-center justify-center"
            onClick={() => {
              handleChangeDate(1);
            }}>
            <IconComponent iconName="chevronRight" className="w-5 h-5" color="#344054" />
          </div>
        </div>
      </div>

      {showCalendar && (
        <div className="absolute z-10 mt-10 bg-white shadow-lg rounded-lg p-4">
          <Calendar
            locale={i18n.language}
            selected={new Date(currentDate.year, currentDate.month - 1, currentDate.day)}
            onChange={handleDateChange}
            useWeekdaysShort={false}
            dateFormat={mode === 'day' ? 'dd/MM/yyyy' : 'MM/yyyy'}
            showMonthYearPicker={mode === 'month'}
            inline
            calendarClassName="custom-calendar"
          />
        </div>
      )}
    </div>
  );
};

export default ChooseMonthsIn;
