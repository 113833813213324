import { Link } from 'react-router-dom';
import { Divider } from '@chakra-ui/react';
import IconComponent from '../../IconComponent/IconComponent';
import { AdminDashboardDataInterface } from '../../../shared/type/administrator.type';

type StatisticSectionProps = {
  data: AdminDashboardDataInterface;
  tenantId?: number;
};

export const StatisticSection = ({ data, tenantId }: StatisticSectionProps) => {
  return (
    <div className="w-full flex items-center gap-4 mt-4 flex-wrap xl:flex-nowrap">
      <StatisticBox
        title="Zamówione posiłki"
        count={data.totalBoughtCount}
        date={data.targetDate}
      />
      <StatisticBox
        title="Zgłoszone nieobecności"
        count={data.absenceCount}
        action={
          <Link
            to={`/tenantAdmin/id/${tenantId}/absences`}
            className="flex gap-1 text-error-700 font-semibold">
            Zgłoszenie nieobecności <IconComponent color="#b42318" iconName="arrowRight" />
          </Link>
        }
      />
      <StatisticBox title="Wydane posiłki" count={data.distributed} date={data.targetDate} />
      <StatisticBox title="Pozostało do wydania" count={data.toDistribute} date={data.targetDate} />
    </div>
  );
};

type StatisticBoxProps = {
  title: string;
  count: number;
  date?: string;
  action?: React.ReactNode;
};

export const StatisticBox = ({ title, count, date, action }: StatisticBoxProps) => {
  return (
    <div className="relative w-full lg:w-1/2 xl:w-1/4 h-hull min-w-72 max-h-48 bg-white border border-gray-200 rounded-xl p-6 flex flex-col justify-between">
      <p className="text-lg font-semibold mb-6">{title}</p>
      <p className="text-4xl font-semibold mb-8">{count}</p>
      {date && <p className="text-sm text-gray-600">{date}</p>}
      {action && (
        <div>
          <Divider className="w-full absolute left-0 bottom-14" />
          {action}
        </div>
      )}
    </div>
  );
};
