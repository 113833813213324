import { useState } from 'react';
import { HttpStatusCode } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTenantAdminBasketContext } from '../../../../../../../../../../context/TenantAdminBasketProvider';
import { useApi } from '../../../../../../../../../../context/ApiProvider';
import { OrderedItemInterface } from '../../../../../../../../../../shared/type/orderForParent.type';

export const useBasketForCashOrder = () => {
  const navigate = useNavigate();
  const { apiOrderController } = useApi();
  const {
    isEmpty,
    parentBasketContext,
    changeProductQuantity,
    resetBasket,
    calculatedPayment,
    deleteProductFromBasket,
    placeOrderForPayment
  } = useTenantAdminBasketContext();
  const [collapsedConsumers, setCollapsedConsumers] = useState<{ [consumerId: number]: boolean }>(
    {}
  );
  const toggleCollapse = (consumerId: number) => {
    setCollapsedConsumers((prevState) => ({
      ...prevState,
      [consumerId]: !prevState[consumerId]
    }));
  };

  const clearBasket = async () => {
    try {
      const response = await apiOrderController('clear-basket').delete('');
      if (response.status === HttpStatusCode.Ok) resetBasket();
    } catch (e: any) {}
  };

  const handleDelete = (consumerId: number, item: OrderedItemInterface) => {
    deleteProductFromBasket(consumerId, item);
  };

  const handleQuantityChange = (
    consumerId: number,
    item: OrderedItemInterface,
    increment: boolean
  ) => {
    changeProductQuantity(consumerId, item, increment);
  };

  const onSummaryClick = () => {
    placeOrderForPayment();
    navigate('summary');
  };

  return {
    handleQuantityChange,
    handleDelete,
    clearBasket,
    toggleCollapse,
    collapsedConsumers,
    isEmpty,
    parentBasketContext,
    calculatedPayment,
    onSummaryClick
  };
};
