import React from 'react';
import { useLanguageContext } from '../../context/LanguageProvider'; // Nowy hook!
import IconComponent from '../IconComponent/IconComponent';
import { AVAILABLE_LANGUAGE_OPTIONS } from './helpers';

export const MobileLanguageSelector = ({ closeMenu }: { closeMenu: () => void }) => {
  const { language, setLanguage } = useLanguageContext();

  return (
    <div className="flex flex-col w-full">
      {AVAILABLE_LANGUAGE_OPTIONS.map(({ code, label, flag }) => (
        <button
          key={code}
          className="flex items-center justify-between w-full p-3"
          onClick={() => {
            setLanguage(code);
            closeMenu();
          }}>
          <div className="flex items-center gap-3">
            {flag}
            <span>{label}</span>
          </div>
          {language === code && <IconComponent iconName="check" />}
        </button>
      ))}
    </div>
  );
};
