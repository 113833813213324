import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ConfirmChangeEmail from './component/AutenticationComponents/ConfirmChangeEmail/ConfirmChangeEmail';
import { AlertProvider } from './context/AlertProvider';
import { ApiProvider } from './context/ApiProvider';
import { LocalStorageProvider } from './context/LocalStorageProvider';
import { ParentDashboardContextProvider } from './context/ParentDashboardContext';
import { TenantIdProvider } from './context/TenantIdProvider';
import AuthenticationScreen from './page/AuthenticationScreen/AuthenticationScreen';
import PersistLogin from './component/PersistLogin/PersistLogin';
import RequireAuth from './component/RequireAuth/RequireAuth';
import ChildOwnerDashboard from './page/ChildOwnerDashboard/ChildOwnerDashboard';
import TenantAdminDashboard from './page/TenantAdminDashboard/TenantAdminDashboard';
import SuperAdminDashboard from './page/SuperAdminDashboard/SuperAdminDashboard';
import AdminDashboard from './page/AdminDashboard/AdminDashboard';
import { AuthProvider } from './context/AuthProvider';
import { SideNavProvider } from './context/SideNavProvider';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { LanguageProvider } from './context/LanguageProvider';

const App = () => {
  const [isI18nInitialized, setIsI18nInitialized] = useState(i18n.isInitialized);

  useEffect(() => {
    const handleInitialized = () => setIsI18nInitialized(true);

    if (!i18n.isInitialized) {
      i18n.on('initialized', handleInitialized);
    }

    return () => {
      i18n.off('initialized', handleInitialized);
    };
  }, []);

  if (!isI18nInitialized) {
    return <div>Loading translations...</div>;
  }

  return (
    <div className={`transition-all duration-2000 w-screen h-screen overflow-hidden`}>
      <LocalStorageProvider>
        <AlertProvider>
          <AuthProvider>
            <ApiProvider>
              <I18nextProvider i18n={i18n}>
                <LanguageProvider>
                  <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/*" element={<AuthenticationScreen />} />
                    <Route path="/confirm-email" element={<ConfirmChangeEmail />} />
                    <Route path="/login/*" element={<AuthenticationScreen />} />
                    {/* Protected routes */}
                    <Route element={<PersistLogin />}>
                      <Route element={<RequireAuth allowedRoles={['admin']} />}>
                        <Route path="/admin/*" element={<AdminDashboard />} />
                      </Route>
                      <Route element={<RequireAuth allowedRoles={['childOwner']} />}>
                        <Route
                          path="/parent/*"
                          element={
                            <ParentDashboardContextProvider>
                              <SideNavProvider>
                                <ChildOwnerDashboard />
                              </SideNavProvider>
                            </ParentDashboardContextProvider>
                          }
                        />
                      </Route>
                      <Route element={<RequireAuth allowedRoles={['tenantAdmin']} />}>
                        <Route
                          path="/tenantAdmin/*"
                          element={
                            <SideNavProvider>
                              <TenantIdProvider>
                                <TenantAdminDashboard />
                              </TenantIdProvider>
                            </SideNavProvider>
                          }
                        />
                      </Route>
                      <Route element={<RequireAuth allowedRoles={['superAdmin']} />}>
                        <Route
                          path="/superAdmin/*"
                          element={
                            <TenantIdProvider>
                              <SuperAdminDashboard />
                            </TenantIdProvider>
                          }
                        />
                      </Route>
                    </Route>
                  </Routes>
                </LanguageProvider>
              </I18nextProvider>
            </ApiProvider>
          </AuthProvider>
        </AlertProvider>
      </LocalStorageProvider>
    </div>
  );
};

export default App;
