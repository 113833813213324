import { TableContainer, Thead, Tr, Th, Box, Text, Checkbox } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAlertContext } from '../../../../../../context/AlertProvider';
import { useApi } from '../../../../../../context/ApiProvider';
import { AbsenceInterfaceForMonth } from '../../../../../../shared/type/absence.type';
import { CustomAlertDialogInterface } from '../../../../../../shared/type/CustomAlertDialog.type';
import ChooseMonths from '../../../../../ChooseMonths/ChooseMonths';
import { DataTable } from '../../../../../DataTable/DataTable';

const KidOrders = () => {
  const { setAlertProperties, setShow: setShowAlert } = useAlertContext();
  const { id: tenantId, kidId } = useParams<{ id: string; kidId: string }>();
  const { apiAbsenceAndCourseController, apiTenantAdminBoughtStakesController } = useApi();
  const columnHelper = createColumnHelper<AbsenceInterfaceForMonth>();
  const today = new Date();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [absences, setAbsences] = useState<AbsenceInterfaceForMonth[]>([]);
  const [currentChosenDate, setCurrentChosenDate] = useState<{
    month: number;
    year: number;
    day: number;
  }>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: 1
  });

  const getAlertBody = (string: string): CustomAlertDialogInterface => ({
    status: 'error',
    title: 'Błąd',
    description: `Nie udało się zmienić ${string}`,
    timeout: 9000
  });

  const fetchAbsences = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<AbsenceInterfaceForMonth[]> =
        await apiAbsenceAndCourseController('absences-for-consumer-for-month').post('', {
          tenantId: Number(tenantId),
          consumerId: Number(kidId),
          month: currentChosenDate.month,
          year: currentChosenDate.year
        });
      setAbsences(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleLocalAbsenceChange = (boughtStakeId: number, absence: boolean) => {
    setAbsences((prevAbsences) =>
      prevAbsences.map((absenceEntry) => ({
        ...absenceEntry,
        stakesAndCourses: absenceEntry.stakesAndCourses.map((stake) =>
          stake.boughtStakeId === boughtStakeId ? { ...stake, presence: absence } : stake
        )
      }))
    );
  };

  const handleLocalTakeChange = (boughtStakeId: number, courseId: number, taken: boolean) => {
    setAbsences((prevAbsences) =>
      prevAbsences.map((absenceEntry) => ({
        ...absenceEntry,
        stakesAndCourses: absenceEntry.stakesAndCourses.map((stake) =>
          stake.boughtStakeId === boughtStakeId
            ? {
                ...stake,
                takenCourses: stake.takenCourses.map((course) =>
                  course.courseId === courseId ? { ...course, taken: taken } : course
                )
              }
            : stake
        )
      }))
    );
  };

  const handleAbsence = async (boughtStakesId: number[], absence: boolean, consumerId: number) => {
    boughtStakesId.forEach((boughtStakeId) => {
      handleLocalAbsenceChange(boughtStakeId, absence);
    });

    try {
      await apiAbsenceAndCourseController('report-or-revoke-absence').post('', {
        consumerId,
        boughtStakesId,
        absence: !absence
      });
    } catch (error: any) {
      boughtStakesId.forEach((boughtStakeId) => {
        handleLocalAbsenceChange(boughtStakeId, !absence);
      });
      setAlertProperties(
        getAlertBody(`obecności: ${error.response.data.errors[0] || error.response.data.errors[0]}`)
      );
      setShowAlert(true);
    }
  };

  const handleTakeStack = async (boughtStakeId: number, courseId: number, taken: boolean) => {
    handleLocalTakeChange(boughtStakeId, courseId, taken);
    try {
      await apiTenantAdminBoughtStakesController('mark-course-as-taken').post('', {
        boughtStakeId,
        courseId,
        taken
      });
    } catch (error: any) {
      handleLocalTakeChange(boughtStakeId, courseId, !taken);
      setAlertProperties(getAlertBody(`odbioru: ${error.response.data.errorMessage}`));
      setShowAlert(true);
    }
  };

  const columns = [
    columnHelper.accessor('dayOfTheWeek', {
      cell: (info) => {
        const { dayOfTheWeek } = info.row.original;

        return <p className="text-grayLight-900 font-medium text-sm">{dayOfTheWeek}</p>;
      },
      header: ''
    }),
    columnHelper.accessor('when', {
      cell: (info) => {
        const { when } = info.row.original;

        return <p className="text-grayLight-900 font-medium text-sm">{when}</p>;
      },
      header: 'Data operacji'
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const stakesAndCourses = info.row.original.stakesAndCourses;

        if (stakesAndCourses.length === 0) {
          return <p className="text-grayLight-900 font-medium text-sm"></p>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div
            className="flex flex-col justify-center "
            style={{ height: totalAssignedCourses * 30 + 'px' }}>
            {stakesAndCourses.map((item, stakeIndex) => (
              <div  
                key={`stake-${stakeIndex}`}
                className={`flex flex-col justify-center ${stakeIndex !== stakesAndCourses.length - 1 ? 'border-b' : ''}`}
                style={{ height: `${(item.takenCourses.length / totalAssignedCourses) * 100}%` }}>
                <p className="text-grayLight-900 font-medium text-sm">{item.stakeName}</p>
              </div>
            ))}
          </div>
        );
      },
      header: 'Zamówienie'
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        if (stakesAndCourses.length === 0) {
          return <p className="text-grayLight-900 font-medium text-sm"></p>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div
            className="flex flex-col justify-center"
            style={{ height: totalAssignedCourses * 30 + 'px' }}>
            {stakesAndCourses.map((item, stakeIndex) => (
              <div
                key={`stake-${stakeIndex}`}
                className={`flex flex-col justify-center ${stakeIndex !== stakesAndCourses.length - 1 ? 'border-b' : ''}`}
                style={{ height: `${(item.takenCourses.length / totalAssignedCourses) * 100}%` }}>
                <Checkbox
                  colorScheme="customOrange"
                  isChecked={item.presence}
                  onChange={() => {
                    handleAbsence([item.boughtStakeId], !item.presence, Number(kidId));
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
      header: 'Obecność'
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        if (stakesAndCourses.length === 0) {
          return <p className="text-grayLight-900 font-medium text-sm"></p>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div
            className="flex flex-col justify-center bg-red"
            style={{ height: totalAssignedCourses * 30 + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div
                key={`stake-${stakeIndex}`}
                className={`flex flex-col justify-center ${stakeIndex !== stakesAndCourses.length - 1 ? 'border-b' : ''}`}
                style={{ height: (stake.takenCourses.length / totalAssignedCourses) * 100 + '%' }}>
                {stake.takenCourses.map((course) => (
                  <div
                    className="flex flex-col justify-center"
                    style={{ height: (1 / stakesAndCourses.length) * 100 + '%' }}>
                    <p className="text-grayLight-900 font-medium text-sm">{course.courseName}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        );
      },
      header: 'Kategorie posiłków'
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        if (stakesAndCourses.length === 0) {
          return <p className="text-grayLight-900 font-medium text-sm"></p>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div
            className="flex flex-col justify-center"
            style={{ height: totalAssignedCourses * 30 + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div
                key={`stake-${stakeIndex}`}
                className={`flex flex-col justify-center ${stakeIndex !== stakesAndCourses.length - 1 ? 'border-b' : ''}`}
                style={{ height: (stake.takenCourses.length / totalAssignedCourses) * 100 + '%' }}>
                {stake.takenCourses.map((course) => (
                  <div
                    className="flex flex-col justify-center items-center"
                    style={{ height: (1 / stakesAndCourses.length) * 100 + '%' }}>
                    <Checkbox
                      colorScheme="customOrange"
                      isChecked={course.taken}
                      onChange={() => {
                        handleTakeStack(stake.boughtStakeId, course.courseId, !course.taken);
                      }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        );
      },
      header: 'Odbiór'
    })
  ];

  useEffect(() => {
    fetchAbsences();
  }, [tenantId, kidId, currentChosenDate]);

  return (
      <section className="KidOrder p-spacing-xl w-full">
      <TableContainer
      h="100%"
      flex="1"
      minH="500px"
      className="Branches__table bg-white rounded-lg pb-spacing-8xl">
      <DataTable
        disableFilters={true}
        filterComponent={
          <div className="flex gap-spacing-md">
            <ChooseMonths
              mode="month"
              currentDate={currentChosenDate}
              setDate={setCurrentChosenDate}
            />
          </div>
        }
        extraThead={
          <Thead>
            <Tr>
              <Th colSpan={columns.length + 1}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base text-grayLight-900">
                        Zamówione Stawki
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm text-grayLight-700">
                        Tutaj możesz łatwo zarządzać nieobecnościami oraz odbiorem posiłków
                        konsumentów w danym dniu
                      </Text>
                    </Box>
                  </div>
                  <div className="flex gap-spacing-lg"></div>
                </div>
              </Th>
            </Tr>
          </Thead>
        }
        columns={columns}
        isLoading={isLoading}
        data={absences}
      />
    </TableContainer>
      </section>
  );
};

export default KidOrders;
