import React, { useEffect } from "react";
import { useParams, Routes, Route, Link } from "react-router-dom";
import { useEditAdministratorContext } from "../../../../../context/EditAdministratorProvider";
import useAuth from "../../../../../hooks/useAuth";
import { NavOptionsWithElementInterface } from "../../../../../shared/type/navOptions.type";
import Breadcrumbs from "../../../../Breadcrumbs/Breadcrumbs";
import IconComponent from "../../../../IconComponent/IconComponent";
import Navigation from "../../../../Navigation/Navigation";
import AdministratorData from "./AdministratorData/AdministratorData";
import AdministratorLog from "./AdministratorLog/AdministratorLog";
import AdministratorRights from "./AdministratorRights/AdministratorRights";
import AssignedBranches from "./AssignedBranches/AssignedBranches";
import {Spinner} from "@chakra-ui/react";

const SingleAdministrator: React.FC = () => {
  const { adminId }  = useParams<{ adminId: string }>();
  const { auth } = useAuth();
  const { setId, adminEditContext } = useEditAdministratorContext();
  const { id } = useParams()

  useEffect(() => {
    setId(Number(adminId));
  }, [adminId, setId]);

  if (!adminEditContext) {
    return <Spinner/>;
  }

  const { firstName, lastName } = adminEditContext!.adminUserDto;

  const navOptions: NavOptionsWithElementInterface[] = [
    {
      link: 'administrator-rights',
      name: 'Uprawnienia',
      component: <AdministratorRights />
    },
    {
      link: 'assigned-branches',
      name: 'Przypisane oddziały',
      component: <AssignedBranches />
    },
    auth.loggedUserDto.superAdmin && {
      link: 'administrator-log',
      name: 'Historia konta',
      component: <AdministratorLog />
    },
    {
      link: 'administrator-data',
      name: 'Dane administratora',
      component: <AdministratorData />
    }
  ].filter((item): item is NavOptionsWithElementInterface => Boolean(item));

  return (
    <section className="SingleCompany">
      <div className="SingleCompany__container pt-spacing-xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl gap-spacing-4xl flex flex-col">
        <div className="SingleCompany__actualCompanyStatus">
          <Breadcrumbs
            divider={<IconComponent iconName="chevronRight" />}
            elements={[
              <IconComponent iconName="home" />,
              <p className="font-medium text-sm text-grayLight-600">Lista Administratorów</p>,
              <p className="font-semibold text-sm text-grayWarm-800">{firstName} {lastName}</p>
            ]}
          />
        </div>
        <div className="SingleCompany__container flex flex-col gap-spacing-4xl">
          <Link to={auth.loggedUserDto.superAdmin ? `/superAdmin/branches/id/${id}/users/administrators/administrators-table` : `/tenantAdmin/id/${id}/users/administrators/administrators-table`} className="SingleCompany__backButtonContainer flex gap-spacing-md font-semibold text-base text-grayWarm-950">
            <IconComponent iconName="arrowLeft" />
            <p>Wróć</p>
          </Link>
          <div className="SingleCompany__contextContainer flex flex-col gap-spacing-xl">
            <Navigation navOptions={navOptions} />
            <Routes>
              {navOptions.map((item) => (
                <Route key={item.link} path={`/${item.link}`} element={item.component} />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleAdministrator;
