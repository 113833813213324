import { Select } from 'chakra-react-select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type StakeSelectorProps = {
  stakesToSelect: { label: string; value: number }[];
  selectedStake: number | undefined;
  onStakeSelect: (stakeId: number) => void;
  useTranslations?: boolean;
};

export const StakeSelector = ({
  stakesToSelect,
  selectedStake,
  onStakeSelect,
  useTranslations
}: StakeSelectorProps) => {
  const { t, i18n } = useTranslation();
  const noOptionMessage = useMemo(
    () =>
      useTranslations
        ? //@ts-ignore
          t('parent_order.period_order.stake_selector.no_stakes_message')
        : 'Brak wspólnych posiłków na wybrane dni',
    [i18n.language, useTranslations]
  );
  const selectorPlaceholder = useMemo(
    () =>
      useTranslations
        ? //@ts-ignore
          t('parent_order.period_order.stake_selector.selector_placeholder')
        : 'Wybierz posiłek',
    [i18n.language, useTranslations]
  );

  return (
    <div>
      <p className="font-semibold mb-2 text-base text-slate-700">Wybierz posiłek:</p>
      <Select
        variant="outline"
        className="bg-white"
        colorScheme="whiteAlpha"
        menuPlacement="top"
        noOptionsMessage={() => noOptionMessage}
        placeholder={selectorPlaceholder}
        value={stakesToSelect.find((item) => item.value === selectedStake) || null}
        onChange={(value) => {
          if (value) {
            onStakeSelect(value.value);
          }
        }}
        options={stakesToSelect}
        styles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: 'white'
          })
        }}
      />
    </div>
  );
};
