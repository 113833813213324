import React, { useState, useRef, useEffect } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Divider } from '@chakra-ui/react';
import { useLanguageContext } from '../../context/LanguageProvider'; // Nowy hook!
import IconComponent from '../IconComponent/IconComponent';
import { AVAILABLE_LANGUAGE_OPTIONS } from './helpers';

import './LanguagesSelector.css';

export const LanguagesMenuSelector = () => {
  const { language, setLanguage } = useLanguageContext();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLButtonElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const selectedLanguage =
    AVAILABLE_LANGUAGE_OPTIONS.find((lang) => lang.code === language) ||
    AVAILABLE_LANGUAGE_OPTIONS[0];

  // Opóźnione zamykanie menu (zapobiega natychmiastowemu zamknięciu)
  const closeMenuWithDelay = () => {
    timeoutRef.current = setTimeout(() => setIsOpen(false), 300);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <>
      <Menu isOpen={isOpen} onClose={() => setIsOpen(false)} placement="start-start">
        <MenuButton
          as={MenuItem}
          ref={menuRef}
          onMouseEnter={() => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            setIsOpen(true);
          }}
          onMouseLeave={closeMenuWithDelay}>
          <span className="flex items-center justify-between w-full">
            <span>{selectedLanguage.label}</span>
            <IconComponent iconName="chevronRight" />
          </span>
        </MenuButton>

        {isOpen && (
          <MenuList
            onMouseEnter={() => {
              if (timeoutRef.current) clearTimeout(timeoutRef.current);
              setIsOpen(true);
            }}
            onMouseLeave={closeMenuWithDelay}>
            {AVAILABLE_LANGUAGE_OPTIONS.map(({ code, label, flag }) => (
              <MenuItem
                key={code}
                onClick={() => {
                  setLanguage(code); // Aktualizujemy język przez context
                  setIsOpen(false);
                }}>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center gap-2">
                    {flag}
                    <span className="ml-2">{label}</span>
                  </div>
                  {selectedLanguage.code === code && <IconComponent iconName="check" />}
                </div>
              </MenuItem>
            ))}
          </MenuList>
        )}
      </Menu>
      <Divider />
    </>
  );
};
