import { Text } from '@chakra-ui/react';
import ChooseMonths, { MonthDateType } from '../../../ChooseMonths/ChooseMonths';

type ToolbarProps = {
  currentDate: MonthDateType;
  setDate: React.Dispatch<React.SetStateAction<MonthDateType>>;
};

export const Toolbar: React.FC<ToolbarProps> = ({ currentDate, setDate }) => {
  return (
    <div className="w-full flex justify-between items-center mb-2">
      <div>
        <h3 className="text-lg font-semibold mb-1">Zamówienia i nieobecności </h3>
        <Text className="text-gray-600">Wybierz datę aby przejść do szczegółowego widoku</Text>
      </div>
      <ChooseMonths setDate={setDate} currentDate={currentDate} mode="month" />
    </div>
  );
};
