import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { SideMenuOptionInterface } from '../../shared';
import IconComponent from '../IconComponent/IconComponent';
import {Tooltip} from "@chakra-ui/react";

interface Props {
  menuOption: SideMenuOptionInterface;
  isMinimal: boolean;
}

const SideMenuButton = ({ menuOption, isMinimal }: Props) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [showText, setShowText] = useState(!isMinimal);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isMinimal) {
      timer = setTimeout(() => setShowText(true), 300);
    } else {
      setShowText(false);
    }

    return () => clearTimeout(timer);
  }, [isMinimal]);

  useEffect(() => {
    let sectionIsChosen: boolean = false;

    if(!menuOption.children) return

    for (const option of menuOption.children) {
      if(location.pathname.includes(option.link)){
        sectionIsChosen = true;
        break;
      }
    }

    setIsOpen(sectionIsChosen);
  }, [location.pathname]);

  const buttonChildren = () =>
      menuOption.children?.map((option) => {
        const isActive = location.pathname.includes(option.link);
        const content = (
            <div
                className={`${
                    isActive ? 'bg-brand-100' : ''
                } grid font-semibold grid-cols-[auto,1fr] items-center p-4 rounded-lg cursor-pointer hover:bg-brand-100 w-full pr-spacing-lg ${isMinimal ? 'pl-spacing-lg' : 'pl-spacing-6xl'} pt-spacing-md pb-spacing-md`}
            >
              {option.icon && (
                  <IconComponent iconName={option.icon} className="h-6 w-6" color="#667085" />
              )}
              {showText && <div className="ml-2">{option.header}</div>}
            </div>
        );

        return (
            <Link key={option.link} className="flex rounded-lg w-full" to={option.link}>
              {isMinimal ? (
                  <Tooltip
                      label={option.header}
                      aria-label="A tooltip"
                      placement="right"
                      padding='8px'
                      rounded='8px'
                      background="#FFF7E6"
                      color="#101828"
                  >
                    {content}
                  </Tooltip>
              ) : (
                  content
              )}
            </Link>
        );
      });

  const navContent = (
      <Link to={menuOption.link || '#'} className="flex rounded-lg w-full">
        <div
            className={`${
                menuOption.link && location.pathname.includes(menuOption.link) ? 'bg-brand-100' : ''
            } grid grid-cols-[auto,1fr,auto] items-center p-4 rounded-lg cursor-pointer hover:bg-brand-100 w-full pr-spacing-lg pl-spacing-lg pt-spacing-md pb-spacing-md`}
        >
          <div className="w-6 h-6 flex items-center justify-center">
            <IconComponent iconName={menuOption.icon} color="#101828" />
          </div>
          <div
              className={`ml-2 transition-opacity font-semibold duration-300 ease-in-out ${
                  showText ? 'opacity-100' : 'hidden'
              }`}
          >
            {menuOption.header}
          </div>
          {menuOption.children && (
              <div className="w-6 h-6 flex items-center justify-center ml-auto">
                <IconComponent iconName={isOpen ? 'chevronUp' : 'chevronDown'} />
              </div>
          )}
        </div>
      </Link>
  )

  return (
    <div
      className={`SideMenuButton grid w-full gap-spacing-md ${isMinimal ? 'SideMenuButton--minimal' : ''}`}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      {isMinimal ? (
          <Tooltip
              isDisabled={!isMinimal}
              label={menuOption.header}
              aria-label="A tooltip"
              placement="right"
              padding='8px'
              rounded='8px'
              background="#FFF7E6"
              color="#101828"
          >
            {navContent}
          </Tooltip>
      ) : (
          navContent
      )}
      {menuOption.children && isOpen && (
        <div className="SideMenuButton__childrenContainer grid gap-spacing-xs">
          {buttonChildren()}
        </div>
      )}
    </div>


  );
};

export default SideMenuButton;
