import { Box, TableContainer, Thead, Th, Text, Flex } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../../context/ApiProvider';
import { LogInterface, LogEntriesInterface } from '../../../../shared/type/logs.type';
import { PaginationInterface } from '../../../../shared/type/paginationInterface.type';
import { DataTable } from '../../../DataTable/DataTable';
import Pagination from '../../../Pagination/Pagination';

const AccountHistory = () => {
  const columnHelper = createColumnHelper<LogInterface>();
  const { apiTenantLogEntitiesController } = useApi();
  const { id, userId, kidId } = useParams();
  const [accountHistory, setAccountHistory] = useState<LogInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [parentUserId, setParentUserId] = useState<string>(userId || '0');
  const [isSpecialConsumer, setIsSpecialConsumer] = useState<boolean>(false);
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 30,
    maxPageNumber: 0
  });

  const columns = [
    columnHelper.accessor('operationDateTime', {
      cell: (info) => {
        const operationDateTime = new Date(info.getValue());
        return (
          <Box whiteSpace="normal" className="flex flex-col">
            <p>{`${operationDateTime.toLocaleDateString()}`}</p>
            <p>{`${operationDateTime.toLocaleTimeString()}`}</p>
          </Box>
        );
      },
      header: 'Data operacji'
    }),
    columnHelper.accessor('description', {
      cell: (info) => <Box whiteSpace="normal">{info.getValue()}</Box>,
      header: 'Opis'
    }),
    columnHelper.display({
      id: 'firstNameOfUserPerformingOperation',
      header: 'Wykonano przez',
      cell: (info) => {
        const { firstNameOfUserPerformingOperation, lastNameOfUserPerformingOperation } =
          info.row.original;
        return (
          <Box whiteSpace="normal">
            {firstNameOfUserPerformingOperation} {lastNameOfUserPerformingOperation}
          </Box>
        );
      }
    }),
    columnHelper.accessor('consumer', {
      cell: (info) => {
        const { consumer } = info.row.original;
        return <Box whiteSpace="normal">{consumer}</Box>;
      },
      header: 'Powiązany konsument'
    })
  ];

  const fetchAccountHistory = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<LogEntriesInterface> = await apiTenantLogEntitiesController(
        ''
      ).get(
        `/${id}/${isSpecialConsumer ? 'special-consumer-entries' : 'consumer'}/${parentUserId}`,
        {
          params: {
            pageNumber: page.pageNumber,
            pageSize: page.pageSize
          }
        }
      );
      const { data, next, hasNextPage, totalCount } = response.data;

      setAccountHistory(data);
      setActualPage({ next, hasNextPage, totalCount });
      setPage((prev) => ({
        ...prev,
        maxPageNumber: Math.ceil(totalCount / page.pageSize)
      }));
    } catch (error) {
      console.error('Failed to fetch account history', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (pageNum: number) => {
    setPage((prev) => ({
      ...prev,
      pageNumber: pageNum
    }));
  };

  useEffect(() => {
    if (userId) {
      setIsSpecialConsumer(true);
      setParentUserId(userId);
    }

    if (kidId) {
      setIsSpecialConsumer(false);
      setParentUserId(kidId);
    }
  }, [userId, kidId]);

  useEffect(() => {
    if (parentUserId !== '0') {
      fetchAccountHistory();
    }
  }, [parentUserId, page.pageNumber]);

  return (
    <section className="AccountHistory p-spacing-xl w-full">
      <Flex direction="column" h="800px" className="Branches__table bg-white rounded-lg">
        <TableContainer h="100%" flex="1" minH="500px" overflowY="auto">
          <DataTable
            disableFilters={true}
            extraThead={
              <Thead>
                <Th colSpan={columns.length}>
                  <div className="w-full flex items-center justify-between">
                    <div className="flex flex-col basis-4/5">
                      <Box>
                        <Text
                          whiteSpace="normal"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          className="font-semibold text-base text-grayLight-900">
                          Historia konta
                        </Text>
                      </Box>
                    </div>
                    <div className="flex flex-row items-end gap-spacing-md"></div>
                  </div>
                </Th>
              </Thead>
            }
            columns={columns}
            isLoading={isLoading}
            data={accountHistory}
          />
        </TableContainer>
        <Box position="relative" paddingTop="10px" paddingBottom="20px">
          <Pagination
            pageNumber={page.pageNumber}
            maxPageNumber={page.maxPageNumber}
            hasNextPage={actualPage.hasNextPage}
            onPageChange={handlePageChange}
          />
        </Box>
      </Flex>
    </section>
  );
};

export default AccountHistory;
