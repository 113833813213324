import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { AxiosResponse, HttpStatusCode } from 'axios';
import { useTranslation } from 'react-i18next';
import { useAlertContext } from '../../../../../context/AlertProvider';
import { useApi } from '../../../../../context/ApiProvider';
import {
  MessageConversationInterfaceWithPaginationInterface,
  MessageConversationInterface
} from '../../../../../shared/type/message.type';
import { PaginationInterface } from '../../../../../shared/type/paginationInterface.type';
import ChatComponent from '../../../../ChatComponent/ChatComponent';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import IconComponent from '../../../../IconComponent/IconComponent';

const SingleMessage = () => {
  const { t } = useTranslation();
  const { apiParentMessageController } = useApi();
  const { id: threadId } = useParams();
  const { setShow, setAlertProperties } = useAlertContext();
  const [messages, setMessages] = useState<MessageConversationInterface[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get('title');

  const fetchMessages = async () => {
    try {
      const response: AxiosResponse<MessageConversationInterfaceWithPaginationInterface> =
        await apiParentMessageController('message-list').get('', {
          params: {
            messageThreadId: threadId,
            pageNumber: 1,
            pageSize: 10000
          }
        });

      const { data, next, hasNextPage, totalCount } = response.data;

      setActualPage({ next, hasNextPage, totalCount });
      setMessages(data);
    } catch (error) {
      setAlertProperties({
        status: 'error',
        timeout: 9000,
        /* @ts-ignore */
        description: t('parent_mailbox.single_message.error_fetching_messages'),
        /* @ts-ignore */
        title: t('parent_mailbox.single_message.error_fetching_messages_title')
      });
      setShow(true);
    }
  };

  const sendNewMessage = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!newMessage.trim()) {
      setAlertProperties({
        status: 'warning',
        timeout: 9000,
        /* @ts-ignore */
        description: t('parent_mailbox.single_message.empty_message_warning'),
        /* @ts-ignore */
        title: t('parent_mailbox.single_message.empty_message_warning_title')
      });
      setShow(true);
      return;
    }

    try {
      const response = await apiParentMessageController('add-message-to-thread').post('', {
        messageThreadId: Number(threadId),
        content: newMessage
      });

      if (response.status === HttpStatusCode.Ok) {
        setNewMessage('');
        fetchMessages();
      }
    } catch (error) {
      setAlertProperties({
        status: 'error',
        timeout: 9000,
        /* @ts-ignore */
        description: t('parent_mailbox.single_message.error_sending_message'),
        /* @ts-ignore */
        title: t('parent_mailbox.single_message.error_sending_message_title')
      });
      setShow(true);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(
      async () => {
        try {
          const response: AxiosResponse<MessageConversationInterfaceWithPaginationInterface> =
            await apiParentMessageController('message-list').get('', {
              params: {
                messageThreadId: threadId,
                pageNumber: 1,
                pageSize: 50
              }
            });

          const { data } = response.data;

          if (data.length !== messages.length) {
            setMessages(data);
            setAlertProperties({
              status: 'info',
              timeout: 3000,
              /* @ts-ignore */
              description: t('parent_mailbox.single_message.new_message_notification_description'),
              /* @ts-ignore */
              title: t('parent_mailbox.single_message.new_message_notification_title')
            });
            setShow(true);
          }
        } catch (error) {
          console.error('Błąd podczas pobierania nowych wiadomości:', error);
        }
      },
      1000 * 60 * 2
    ); // 2 min

    return () => clearInterval(intervalId);
  }, [messages, threadId]);

  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <section className="flex flex-col h-full gap-spacing-xl w-full pb-20">
      <div className="pt-spacing-md pr-spacing-3xl pb-spacing-md pl-spacing-3xl gap-spacing-md">
        <Link
          to="/parent/mail-box/messages"
          className="flex items-center gap-spacing-sm pt-2.5 pr-spacing-xl pb-2.5 pl-spacing-xl">
          <IconComponent iconName="arrowLeft" />
          <p style={{ fontSize: '16px' }} className="font-semibold text-grayWarm-950">
            {/* @ts-ignore */}
            {t('parent_mailbox.single_message.back')}
          </p>
        </Link>
        <h2 className="text-2xl font-semibold text-grayLight-900">
          {/* @ts-ignore  */}
          {title || t('parent_mailbox.single_message.default_title')}
        </h2>
      </div>

      <div className="lg:px-spacing-7xl px-spacing-xl flex-grow overflow-y-auto">
        <ChatComponent messages={messages} actor="parent" />
      </div>

      <div className="flex items-center border-t pr-spacing-5xl pl-spacing-5xl border-grayLight-200">
        <form
          onSubmit={sendNewMessage}
          className="flex w-full pt-spacing-md pr-spacing-3xl pb-spacing-md pl-spacing-3xl gap-spacing-lg">
          <textarea
            name=""
            id=""
            required={true}
            rows={5}
            value={newMessage}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                sendNewMessage(e);
              }
            }}
            onChange={(e) => {
              setNewMessage(e.target.value);
            }}
            /* @ts-ignore */
            placeholder={t('parent_mailbox.single_message.write_message_placeholder')}
            className="border resize-none border-grayLight-300 w-full flex-grow gap-spacing-md rounded-lg pt-2.5 pr-3.5 pb-2.5 pl-3.5"
          />
          <div>
            <CustomChakraButton
              buttonProps={{
                type: 'submit'
              }}
              size="lg"
              hierarchy="primary"
              iconPosition="right"
              iconColor="#292524"
              icon="send">
              {/* @ts-ignore */}
              {t('parent_mailbox.single_message.send')}
            </CustomChakraButton>
          </div>
        </form>
      </div>
    </section>
  );
};

export default SingleMessage;
