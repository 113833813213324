import { HttpStatusCode } from 'axios';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import './Basket.css';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../context/ApiProvider';
import { useParentBasketContext } from '../../context/ParentBasketProvider';
import { useParentDashboardContextContext } from '../../context/ParentDashboardContext';
import { useParentOrderContextContext } from '../../context/ParentOrderContext';
import { OrderedItemInterface } from '../../shared/type/orderForParent.type';
import { ConsumerDto } from '../../shared/type/parentDashboardContext.type';
import calculatePrice from '../../utils/calculators/calculatePrice';
import CustomBadge from '../CustomBadge/CustomBadge';
import CustomChakraButton from '../CustomChakraButton/CustomChakraButton';
import CheckboxInput from '../Forms/CheckboxInput/CheckboxInput';
import IconComponent from '../IconComponent/IconComponent';
import EmptyBasket from './EmptyBasket/EmptyBasket';

const Basket = () => {
    const {t, i18n} = useTranslation();
    const {
        isEmpty,
        resetBasket,
        basketAlerts,
        calculatedPayment,
        parentBasketContext,
        deleteProductFromBasket,
        changeProductQuantity,
        placeOrderForPayment,
        handleUseCreditToggle,
        scrollToItemKey,
        setScrollToItemKey
    } = useParentBasketContext();
    const navigate = useNavigate();
    const {parentOrderContext, setSkipFetchingParentOrderContext} = useParentOrderContextContext();
    const {parentDashboardContext} = useParentDashboardContextContext();
    const {apiOrderController} = useApi();
    const [collapsedConsumers, setCollapsedConsumers] = useState<{ [consumerId: number]: boolean }>(
        {}
    );
    const scrollRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const basketScrollContainerRef = useRef<HTMLDivElement | null>(null);

    const toggleCollapse = (consumerId: number) => {
        setCollapsedConsumers((prevState) => ({
            ...prevState,
            [consumerId]: !prevState[consumerId]
        }));
    };

    const clearBasket = async () => {
        try {
            const response = await apiOrderController('clear-basket').delete('');
            if (response.status === HttpStatusCode.Ok) resetBasket();
        } catch (e: any) {
        }
    };

    const consumerMap = parentDashboardContext?.tenants.reduce(
        (acc, tenant) => {
            tenant.consumers.forEach((consumer) => {
                acc[consumer.consumerDto.id] = consumer.consumerDto;
            });
            return acc;
        },
        {} as { [consumerId: number]: ConsumerDto }
    );

    const groupedOrders: { [consumerId: number]: OrderedItemInterface[] } =
        parentBasketContext?.ordersForChildren.reduce(
            (acc, order) => {
                const {consumerId, orderedItems} = order;
                if (!acc[consumerId]) {
                    acc[consumerId] = [];
                }
                acc[consumerId].push(...orderedItems);
                return acc;
            },
            {} as { [consumerId: number]: OrderedItemInterface[] }
        );

    const handleDelete = (consumerId: number, item: OrderedItemInterface) => {
        deleteProductFromBasket(consumerId, item);
    };

    const goToOrderSummary = () => {
        placeOrderForPayment();
        navigate('/parent/order/summary');
    };

    const totalToPay = calculatedPayment?.totalToPay || '0,00';
    const totalOrderValue = calculatedPayment?.totalOrderValue || '0,00';

    useEffect(() => {
        setSkipFetchingParentOrderContext(false);

        return () => {
            setSkipFetchingParentOrderContext(true);
        };
    }, []);

    useEffect(() => {
        if (
            scrollToItemKey &&
            scrollRefs.current[scrollToItemKey] &&
            basketScrollContainerRef.current
        ) {
            const element = scrollRefs.current[scrollToItemKey];
            const container = basketScrollContainerRef.current;

            const elementTop = element!.offsetTop;
            const elementHeight = element!.offsetHeight;
            const containerHeight = container.offsetHeight;

            const scrollPosition = elementTop - containerHeight / 2 + elementHeight / 2;

            container.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });

            setTimeout(() => setScrollToItemKey(null), 1500);
        }
    }, [groupedOrders, scrollToItemKey]);


    return (
        <div
            className="Basket flex flex-col gap-spacing-xl w-full h-full pt-spacing-xl pr-spacing-xl pb-spacing-4xl pl-spacing-xl">
            <div className="Basket__contextContainer flex flex-col grow gap-spacing-lg bg-white">
                <div className="Basket__header flex justify-between">
                    <p className="font-semibold text-grayLight-900" style={{fontSize: '18px'}}>
                        {/* @ts-ignore */}
                        {t('basket.title')}
                    </p>
                    {!isEmpty && (
                        <button className="flex gap-spacing-sm items-center" onClick={clearBasket}>
                            <IconComponent iconName="xClose" color="#B42318"/>
                            <p className="text-error-700 font-semibold text-sm">
                                {/* @ts-ignore */}
                                {t('basket.clean_basket')}
                            </p>
                        </button>
                    )}
                </div>

                <div
                    ref={basketScrollContainerRef}
                    className={`Basket__context grow flex flex-col ${
                        isEmpty ? 'justify-center items-center' : 'justify-start'
                    } overflow-y-scroll max-h-[calc(100vh-8rem)]`}>

                    {isEmpty && <EmptyBasket useTranslations/>}

                    {!isEmpty &&
                        groupedOrders &&
                        Object.keys(groupedOrders).map((consumerId) => {
                            const consumer = consumerMap?.[parseInt(consumerId)];
                            const isCollapsed = collapsedConsumers[parseInt(consumerId)];

                            const paymentData = calculatedPayment?.consumerCalculations?.find(
                                (item) => item.consumerId === parseInt(consumerId)
                            );
                            const consumerOrderContext = parentOrderContext?.consumerOrderContexts.find(
                                (consumer) => consumer.consumerId.toString() == consumerId.toString()
                            );

                            return (
                                <div
                                    key={consumerId}
                                    className="flex flex-col gap-spacing-xl pt-spacing-4xl pb-spacing-xl">
                                    <div className="flex items-center justify-between gap-spacing-md">
                                        <div
                                            className="flex flex-col w-full gap-spacing-md"
                                            style={{fontSize: '16px'}}>
                                            <div className="flex w-full justify-between">
                                                <p className="text-grayLight-700 font-medium">
                                                    {consumer && `${consumer.firstName} ${consumer.lastName}`}
                                                </p>
                                                <div className="flex items-center gap-spacing-md">
                                                    <p
                                                        className="font-semibold text-grayLight-900"
                                                        style={{fontSize: '16px'}}>
                                                        ({calculatePrice(paymentData?.calculation.totalToPay, null)})
                                                    </p>
                                                    <button onClick={() => toggleCollapse(parseInt(consumerId))}>
                                                        <div className="rotate-90">
                                                            <IconComponent
                                                                iconName={isCollapsed ? 'arrowRight' : 'arrowRight'}
                                                                color="#667085"
                                                            />
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex w-full justify-between">
                                                <div>
                                                    {consumerOrderContext?.creditButtonActive && (
                                                        <CheckboxInput
                                                            //@ts-ignore
                                                            text={`${t('basket.order_with_credit')} ${calculatePrice(consumerOrderContext?.creditLimit, 'zł')})`}
                                                            checkboxParams={{
                                                                onChange: (event) => {
                                                                    handleUseCreditToggle(Number(consumerId), event.target.checked);
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <div>
                                                    <CustomBadge color="success" size="sm">
                                                        <p>
                                                            {/* @ts-ignore */}
                                                            {t('basket.balance_after')}:{' '}
                                                            {calculatePrice(paymentData?.calculation.afterSaldo, 'zł')}
                                                        </p>
                                                    </CustomBadge>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {!isCollapsed && (
                                        <div className="flex flex-col gap-spacing-xl">
                                            {Object.entries(
                                                groupedOrders[parseInt(consumerId)].reduce(
                                                    (acc, item) => {
                                                        const dateKey = new Date(item.when).toLocaleDateString();
                                                        if (!acc[dateKey]) acc[dateKey] = [];
                                                        acc[dateKey].push(item);
                                                        return acc;
                                                    },
                                                    {} as { [key: string]: OrderedItemInterface[] }
                                                )
                                            )
                                                .sort(([dateA], [dateB]) => {
                                                    const dateObjA = new Date(dateA.split('.').reverse().join('-'));
                                                    const dateObjB = new Date(dateB.split('.').reverse().join('-'));
                                                    return dateObjA.getTime() - dateObjB.getTime();
                                                })
                                                .map(([date, items], index) => (
                                                    <div key={index} className="flex flex-col gap-spacing-md">
                                                        <p className="text-grayLight-700 text-sm font-normal">
                                                            {(() => {
                                                                const dateObj = new Date(date.split('.').reverse().join('-'));
                                                                const weekday = dateObj.toLocaleDateString(i18n.language, {
                                                                    weekday: 'long'
                                                                });
                                                                return `${weekday.charAt(0).toUpperCase()}${weekday.slice(1)} (${date})`;
                                                            })()}
                                                        </p>
                                                        {items.map((item: OrderedItemInterface, itemIndex: number) => {
                                                            const refKey = `${consumerId}-${item.when}-${item.purchasableItem.name}`; // lub np. item.stakeId

                                                            return (
                                                                <div
                                                                    key={itemIndex}
                                                                    ref={(el) => (scrollRefs.current[refKey] = el)}
                                                                    className="relative flex flex-col gap-spacing-sm overflow-x-hidden">                                        {scrollToItemKey === refKey && (
                                                                    <div
                                                                        className="basket-item-highlight-overlay rounded-2xl"/>
                                                                )}
                                                                    <div
                                                                        className="flex justify-between border-b box-border border-grayLight-200">
                                                                        <p
                                                                            className="text-grayLight-900 font-medium"
                                                                            style={{fontSize: '16px'}}>
                                                                            {item.purchasableItem.name}
                                                                        </p>
                                                                        <div className="flex gap-spacing-md">
                                                                            {item.purchasableItem.originalPrice !==
                                                                                item.purchasableItem.price && (
                                                                                    <p
                                                                                        className="text-grayLight-500 font-medium line-through"
                                                                                        style={{fontSize: '16px'}}>
                                                                                        {calculatePrice(item.purchasableItem.originalPrice, null)}
                                                                                    </p>
                                                                                )}
                                                                            <p
                                                                                className="text-grayLight-900 font-semibold"
                                                                                style={{fontSize: '16px'}}>
                                                                                {calculatePrice(item.purchasableItem.price, null)}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="flex justify-between pt-spacing-md pb-spacing-md">
                                                                        <button
                                                                            onClick={() => handleDelete(parseInt(consumerId), item)}
                                                                            className="flex gap-spacing-sm items-center">
                                                                            <IconComponent iconName="xClose"
                                                                                           color="#475467"/>
                                                                            <p className="text-sm font-semibold text-grayLight-600">
                                                                                {/* @ts-ignore */}
                                                                                {t('basket.delete')}
                                                                            </p>
                                                                        </button>
                                                                        <div
                                                                            className="flex items-center border box-border border-grayLight-300 rounded-md gap-spacing-md">
                                                                            <button
                                                                                className='h-full py-2.5 pl-3.5'
                                                                                disabled={item.count === 1}
                                                                                onClick={() =>
                                                                                    changeProductQuantity(
                                                                                        parseInt(consumerId),
                                                                                        item,
                                                                                        false,
                                                                                        item.count
                                                                                    )
                                                                                }
                                                                            >
                                                                                <IconComponent iconName="minus"
                                                                                               color="#667085"/>
                                                                            </button>
                                                                            <div>
                                                                                <p
                                                                                    className="text-grayLight-500 font-normal"
                                                                                    style={{fontSize: '16px'}}>
                                                                                    {item.count}
                                                                                </p>
                                                                            </div>
                                                                            <button
                                                                                className='h-full py-2.5 pr-3.5'
                                                                                onClick={() =>
                                                                                    changeProductQuantity(
                                                                                        parseInt(consumerId),
                                                                                        item,
                                                                                        true,
                                                                                        item.count
                                                                                    )
                                                                                }>
                                                                                <IconComponent iconName="plus"
                                                                                               color="#667085"/>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {basketAlerts
                                                                            .filter(
                                                                                (alert) =>
                                                                                    Number(alert.consumerId) === Number(consumerId) &&
                                                                                    Number(alert.stakeId) ===
                                                                                    Number(item.purchasableItem.stakeId) &&
                                                                                    alert.when === item.when
                                                                            )
                                                                            .map((alert, index) => (
                                                                                <div
                                                                                    key={`BasketAlert-${index}`}
                                                                                    className={`flex items-center alert gap-2 ${alert.visible ? 'visible' : 'hidden'}`}>
                                                                                    <IconComponent
                                                                                        iconName="alertCircle"
                                                                                        color="#F04438"
                                                                                        className="w-3 h-3"
                                                                                    />
                                                                                    <p className="text-sm font-normal text-error-600">
                                                                                        {alert.text}
                                                                                    </p>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>

            <div>
                {isEmpty &&
                    Array.from(new Map(basketAlerts.map((alert) => [alert.text, alert])).values()).map(
                        (alert, index) => (
                            <div
                                key={`BasketAlert-${index}`}
                                className={`flex items-center alert gap-2 ${alert.visible ? 'visible' : 'hidden'}`}>
                                <IconComponent iconName="alertCircle" color="#F04438" className="w-3 h-3"/>
                                <p className="text-sm font-normal text-error-600">{alert.text}</p>
                            </div>
                        )
                    )}
            </div>

            {/* Podsumowanie */}
            {!isEmpty && (
                <div className="Basket__summary flex justify-end pr-spacing-xl">
                    <div className="flex flex-col items-end gap-spacing-xxs">
                        <div className="flex gap-spacing-md items-center">
                            <p className="text-grayLight-600 text-sm font-normal">
                                {/* @ts-ignore */}
                                {t('basket.order_value')}:
                            </p>
                            <p className="text-grayLight-600 text-sm font-normal">{totalOrderValue} PLN</p>
                        </div>
                        <div className="flex gap-spacing-md items-center text-grayLight-400 text-sm font-normal">
                            <p className="text-grayLight-900 font-semibold" style={{fontSize: '20px'}}>
                                {/* @ts-ignore */}
                                {t('basket.to_pay')}:
                            </p>
                            <p className="text-grayLight-900 font-semibold" style={{fontSize: '24px'}}>
                                {totalToPay} PLN
                            </p>
                        </div>
                    </div>
                </div>
            )}

            {/* Przyciski na dole - zawsze widoczne */}
            <div className="Basket_button sticky bottom-0 bg-white flex items-center w-full justify-center">
                <div className="w-full">
                    <div className="Basket_button flex items-center w-full justify-center">
                        <div className="flex flex-col w-full px-spacing-xl gap-2">
                            <CustomChakraButton
                                buttonProps={{
                                    onClick: goToOrderSummary,
                                    disabled: isEmpty,
                                    className: 'grow'
                                }}
                                size="xl"
                                hierarchy="primary"
                                iconPosition="onlyText"
                                fullWidth>
                                {/* @ts-ignore */}
                                {t('basket.to_summary')}
                            </CustomChakraButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Basket;
