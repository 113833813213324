import { AxiosResponse, HttpStatusCode } from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useAlertContext } from '../../../../context/AlertProvider';
import { useApi } from '../../../../context/ApiProvider';
import { ParentDetailInterface } from '../../../../shared/type/parent.type';
import CustomAlert from '../../../CustomAlert/CustomAlert';
import CustomChakraButton from '../../../CustomChakraButton/CustomChakraButton';
import IconComponent from '../../../IconComponent/IconComponent';
import AddKidToParent from './AddKidToParent/AddKidToParent';
import SingleKidTab from './SingleKidTab/SingleKidTab';
import { BasicKidInterface } from '../../../../shared/type/kid.type';

const ParentKids = () => {
  const { id: tenantId, userId } = useParams<{ id: string; userId: string }>();
  const { setShow, setAlertProperties } = useAlertContext();
  const [parentUserId, setParentUserId] = useState<number>();
  const [unassignChildId, setUnassignChildId] = useState<number>(0);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const { apiTenantConsumerController, apiTenantParentController } = useApi();
  const [kids, setKids] = useState<BasicKidInterface[]>([]);
  const location = useLocation();
  const [showAddKidToParentPopUp, setShowAddKidToParentPopUp] = useState<boolean>(false);

  // const baseKidPath = location.pathname.replace(`parents/single-parent/${parentUserId}/parent-kinds`, '');

  const baseKidPath = location.pathname.replace(
    new RegExp(
      `(parents/single-parent|employees/single-employee|teachers/single-teacher)/${parentUserId}/(parent-kinds|employee-kids|teacher-kids)`
    ),
    ''
  );

  const unassignChild = (kidId: number) => {
    setUnassignChildId(kidId);
    setShowDeleteAlert(true);
  };

  const fetchParent = async () => {
    try {
      const result: AxiosResponse<ParentDetailInterface> = await apiTenantParentController(
        'details'
      ).get(`/${tenantId}/${parentUserId}`);

      const parent = result.data as ParentDetailInterface;

      if (parent) {
        setKids(parent.consumers);
      }
    } catch (error) {
      console.error(error);
      throw new Error(`${error}`);
    } finally {
    }
  };

  useEffect(() => {
    const newParentUserId = Number(userId);

    if (!isNaN(newParentUserId)) {
      setParentUserId(newParentUserId);
    }
  }, [tenantId, userId, fetchParent]);

  useEffect(() => {
    if (parentUserId !== undefined) {
      fetchParent();
    }
  }, [parentUserId]);

  return (
    <>
      <div className="flex w-full h-full justify-between pb-spacing-9xl">
        <div className="flex flex-col gap-spacing-xl flex-wrap">
          {kids?.map((kid) => (
            <SingleKidTab key={kid.id} kid={kid} unassignChild={unassignChild} url={baseKidPath} />
          ))}
        </div>
        <div className="">
          <CustomChakraButton
            size="md"
            hierarchy="primary"
            iconPosition="left"
            icon="plus"
            buttonProps={{
              type: 'button',
              onClick: () => {
                setShowAddKidToParentPopUp(true);
              }
            }}>
            Dodaj dziecko
          </CustomChakraButton>
        </div>
        {showAddKidToParentPopUp ? (
          <AddKidToParent
            parentId={Number(parentUserId)}
            onClose={() => {
              setShowAddKidToParentPopUp(false);
              fetchParent();
            }}
            isOpen={showAddKidToParentPopUp}
          />
        ) : null}
      </div>
      <CustomAlert
        header="Rozłączanie dziecka i rodzica"
        content="Czy na pewno odłączyć dziecko od rodzica?"
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        confirmButtonText="Usuń"
        onConfirmAction={async () => {
          try {
            const response = await apiTenantConsumerController(
              'unassign-consumer-from-parent'
            ).post(`/${tenantId}/${unassignChildId}/${parentUserId}`);
            if (response.status === HttpStatusCode.Ok) {
              setAlertProperties({
                timeout: 9000,
                title: 'Sukces',
                description: `Poprawnie rozłączono dziecko od rodzica`,
                status: 'success'
              });
              setShow(true);
              setShowDeleteAlert(false);
              fetchParent();
            }
          } catch (error: any) {
            console.error(error);
            setAlertProperties({
              timeout: 9000,
              title: 'Błąd',
              description: `Nie udało się rozłączyć dziecka od rodzica`,
              status: 'error'
            });
            setShow(true);
          }
        }}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText="Anuluj"
        onCancelAction={() => {
          setShowDeleteAlert(false);
          setUnassignChildId(0);
          fetchParent();
        }}
        handleOpen={showDeleteAlert}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" />
          </div>
        }
      />
    </>
  );
};

export default ParentKids;
