import { useTranslation } from "react-i18next";
import { TableContainer } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import React, { useState, useEffect, useCallback } from 'react';
import { useApi } from '../../../context/ApiProvider';
import { useParentDashboardContextContext } from '../../../context/ParentDashboardContext';
import {
  ParentMealToDayInterface,
  MealToDayDayInterface
} from '../../../shared/type/mealToDay.type';
import CustomHorizontalTabs from '../../CustomHorizontalTabs/CustomHorizontalTabs';
import { MealsTable } from './MealsTable/MealTable';
import ChooseMonthsIn from '../../ChooseMonths/ChooseMonthsIn';

const ParentMealPlan = () => {
  const { t } = useTranslation();
  const today = new Date();
  const { apiParentMealToDayController } = useApi();
  const { parentDashboardContext } = useParentDashboardContextContext();
  const { setBasketStatus } = useParentDashboardContextContext();

  const [mealPlans, setMealPlans] = useState<MealToDayDayInterface[]>([]);
  const [mealPlanDate, setMealPlanDate] = useState<{ year: number; month: number; day: number }>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });

  const [selectedTenant, setSelectedTenant] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchMealPlans = useCallback(async () => {
    try {
      setIsLoading(true);
      if (Number(selectedTenant) === 0) return;
      const response: AxiosResponse<ParentMealToDayInterface> = await apiParentMealToDayController(
        ''
      ).post('', {
        tenantId: Number(selectedTenant),
        year: mealPlanDate.year,
        month: mealPlanDate.month
      });
      setMealPlans(response.data.days);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setMealPlanDate, apiParentMealToDayController, selectedTenant]);

  useEffect(() => {
    setBasketStatus(() => ({ show: false, forceShow: false }));
  }, []);

  useEffect(() => {
    if (Number(selectedTenant) !== 0) {
      fetchMealPlans();
    }
  }, [selectedTenant, mealPlanDate]);

  return (
    <section className="ParentMealPlan flex flex-col bg-grayLight-50 overflow-y-auto">
      <div className="pt-spacing-3xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <h1 className="p-0 m-0 font-semibold text-grayLight-900" style={{ fontSize: '30px' }}>
              {/* @ts-ignore */}
              {t('parent_meal_plan.title')}
            </h1>
            <p className="font-normal text-grayLight-600" style={{ fontSize: '16px' }}>
              {/* @ts-ignore */}
              {t('parent_meal_plan.subtitle')}
            </p>
          </div>
          <div className="hidden lg:block">
            <ChooseMonthsIn setDate={setMealPlanDate} currentDate={mealPlanDate} mode="month" />
          </div>
        </div>
      </div>
      <div className="flex flex-col pt-spacing-xl pr-spacing-4xl pl-spacing-3xl pb-spacing-xl gap-spacing-xl">
        <CustomHorizontalTabs
          onChange={(value) => {
            setSelectedTenant(value);
          }}
          data={
            parentDashboardContext
              ? parentDashboardContext.tenants.map((tenant) => ({
                  label: tenant.tenantName,
                  value: tenant.tenantId
                }))
              : null
          }
        />
      </div>
      <div
        className="ParentMealPlan flex lg:hidden flex-col pb-spacing-5xl gap-spacing-xl"
        style={{ flexGrow: 1, overflowY: 'auto', marginBottom: '20px' }}>
        <div className="flex justify-center ">
          <ChooseMonthsIn
            hideTodayButton={true}
            setDate={setMealPlanDate}
            currentDate={mealPlanDate}
            mode="month"
          />
        </div>
      </div>
      <div
        className="ParentMealPlan__absenceTable hidden lg:flex flex-col pb-spacing-5xl"
        style={{ flexGrow: 1, overflowY: 'auto', marginBottom: '20px' }}>
        <TableContainer
          h="100%"
          flex="1"
          minH="500px"
          className="Branches__table bg-white rounded-lg pb-spacing-8xl">
          <MealsTable mealPlans={mealPlans} isLoading={isLoading} selectedTenant={selectedTenant} />
        </TableContainer>
      </div>
    </section>
  );
};

export default ParentMealPlan;
