import React, { useState } from 'react';
import { CustomButtonType } from '../../shared/type/customButton.type';
import IconComponent from '../IconComponent/IconComponent';

import './CustomChakraButton.css';

interface DropdownOption {
  label: string;
  action: () => void;
}

interface CustomButtonTypeProps extends CustomButtonType {
  children: string | JSX.Element;
  dropdownOptions?: DropdownOption[];
  fullWidth?: boolean;
}

const CustomChakraButton = ({
  children,
  size,
  hierarchy,
  iconPosition,
  icon,
  buttonProps,
  textProps,
  iconColor,
  dropdownOptions,
  fullWidth
}: CustomButtonTypeProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const buttonClasses = [
    `CustomChakraButton`,
    `flex`,
    `flex-row`,
    `items-center`,
    `justify-center`,
    `gap-spacing-sm`,
    `box-border`,
    `border`,
    `CustomChakraButton--${size}`,
    `CustomChakraButton__text--${size}`,
    `CustomChakraButton--${hierarchy}`,
    fullWidth ? 'w-full' : 'w-fit'
  ];

  if (buttonProps?.className) {
    buttonClasses.push(buttonProps.className);
  }

  const { className, onClick, ...restButtonProps } = buttonProps || {};
  const textClasses = [
    'CustomChakraButton__text',
    `CustomChakraButton__text--${size}`,
    textProps?.className || ''
  ];

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (dropdownOptions) {
      setIsDropdownOpen((prev) => !prev);
      return;
    }
    onClick?.(e);
  };

  return (
    <div className={`relative ${fullWidth ? 'w-full' : 'w-fit'}`}>
      {' '}
      <button className={buttonClasses.join(' ')} {...restButtonProps} onClick={onClickHandler}>
        {['left-right', 'left', 'onlyIcon'].includes(iconPosition) && icon ? (
          <IconComponent iconName={icon} color={iconColor} />
        ) : null}
        {['left-right', 'left', 'right', 'onlyText'].includes(iconPosition) ? (
          <p className={textClasses.join(' ')} {...textProps}>
            {children}
          </p>
        ) : null}
        {['left-right', 'right'].includes(iconPosition) && icon ? (
          <IconComponent iconName={icon} color={iconColor} />
        ) : null}
        {dropdownOptions && (
          <IconComponent iconName="chevronDown" color={iconColor} className="ml-2" />
        )}
      </button>
      {/* Dropdown menu */}
      {isDropdownOpen && dropdownOptions && (
        <div className="absolute left-0 mt-2 min-w-max bg-white border border-gray-200 rounded-md shadow-lg z-10">
          {dropdownOptions.map((option, index) => (
            <button
              key={index}
              className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
              onClick={() => {
                option.action();
                setIsDropdownOpen(false);
              }}>
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomChakraButton;
