import React, { useState, useEffect, useRef } from 'react';
import { useLanguageContext } from '../../context/LanguageProvider'; // Nowy hook!
import { AVAILABLE_LANGUAGE_OPTIONS } from './helpers';

import './LanguagesSelector.css';

export const LanguagesSelector = () => {
  const { language, setLanguage } = useLanguageContext();
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const selectedLanguage =
    AVAILABLE_LANGUAGE_OPTIONS.find((lang) => lang.code === language) ||
    AVAILABLE_LANGUAGE_OPTIONS[0];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      timeoutRef.current = setTimeout(() => setIsOpen(false), 4000);
    }
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="h-8 flex items-center gap-2 rounded-lg bg-white shadow-sm hover:bg-gray-100 transition">
        {selectedLanguage.flag}
        <span className="text-[14px] font-semibold text-[#344054] underline uppercase">
          {selectedLanguage.code}
        </span>
      </button>

      {isOpen && (
        <div className="absolute left-0 mt-2 w-32 bg-white rounded-lg shadow-lg z-10">
          {AVAILABLE_LANGUAGE_OPTIONS.map(({ code, flag }) => (
            <button
              key={code}
              onClick={() => {
                setLanguage(code); // Używamy `setLanguage()` z `useLanguage`
                setIsOpen(false);
              }}
              className="flex w-full items-center gap-2 px-4 py-2 hover:bg-gray-100 transition">
              {flag}
              <span className="text-[14px] font-semibold text-[#344054] uppercase">{code}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
