import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSideNavContext } from '../../context/SideNavProvider';
import useAuth from '../../hooks/useAuth';
import { SideMenuOptionInterface } from '../../shared';
import CustomChakraButton from '../CustomChakraButton/CustomChakraButton';
import SideMenuButton from './SideMenuButton';
import IconComponent from '../IconComponent/IconComponent';
import './SideMenu.css';

interface Props {
  menuOptions: SideMenuOptionInterface[];
}

const SideMenu = ({ menuOptions }: Props) => {
  const { t } = useTranslation();
  const { toggleShowNav, toggleMinimalNav, toggleMode, isMinimal } = useSideNavContext();
  const { auth } = useAuth();
  const handleToggleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (toggleMode === 'hide') {
      toggleShowNav(false);
    }
    if (toggleMode === 'minimal') {
      toggleMinimalNav(!isMinimal);
    }
  };

  return (
    <nav
      className={`SideMenu w-full flex flex-col h-full justify-between ${isMinimal ? 'SideMenu--collapsed' : 'SideMenu--expanded'}`}>
      <div className="w-full">
        <div className="SideMenu__logoContainer pl-spacing-2xl pt-spacing-3xl pb-spacing-2xl">
          <IconComponent iconName="logo" className={isMinimal ? 'w-20' : 'w-56'} />
        </div>
        <div className="SideMenu__menuContainer grid gap-spacing-md pl-spacing-xl pr-spacing-xl">
          {auth.loggedUserDto.childOwner && (
            <button
              className="flex items-center border-b box-border border-brand-300 justify-start cursor-pointer w-full px-spacing-lg py-spacing-md"
              onClick={handleToggleMenu}>
              <IconComponent iconName="arrowLeft" className={`SideMenu__iconRotate`} />
              {!isMinimal && (
                <p className="ml-2 text-sm font-semibold text-grayLight-600 whitespace-nowrap">
                  {isMinimal
                    ? //@ts-ignore
                      t('child_owner_dashboard_element.show_nav')
                    : //@ts-ignore
                      t('child_owner_dashboard_element.hide_nav')}
                </p>
              )}
            </button>
          )}
          {menuOptions.map((menuOption) => (
            <SideMenuButton
              key={menuOption.header + menuOption.icon}
              menuOption={menuOption}
              isMinimal={isMinimal}
            />
          ))}
        </div>
      </div>
      <div className="SideMenu__bottomButtonsContainer gap-spacing-md pl-spacing-xl pr-spacing-xl">
        <div className="SideMenuButton grid w-full gap-spacing-md">
          <div className="flex rounded-lg w-full"></div>
        </div>
        <div className="SideMenuButton--expanded grid w-full">
          {auth.loggedUserDto.childOwner ? (
            <Link className="w-full my-spacing-lg" to="/parent/order">
              <CustomChakraButton
                size="lg"
                hierarchy="primary"
                iconPosition="left"
                icon="plus"
                iconColor="#292524"
                buttonProps={{
                  className: 'w-full'
                }}
                fullWidth>
                {isMinimal ? (
                  <></>
                ) : (
                  //@ts-ignore
                  t('child_owner_dashboard_element.order_nav_button')
                )}
              </CustomChakraButton>
            </Link>
          ) : (
            <button
              className="flex items-center justify-center rounded-lg cursor-pointer bg-brand-100 w-full px-spacing-lg py-spacing-md my-spacing-lg"
              onClick={handleToggleMenu}>
              <div className="w-6 h-6 flex items-center justify-center">
                <IconComponent iconName="arrowLeft" className={`SideMenu__iconRotate`} />
              </div>
              {!isMinimal && (
                <p className="ml-2 whitespace-nowrap">
                  {isMinimal ? 'Pokaż menu' : 'Schowaj menu'}
                </p>
              )}
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default SideMenu;
