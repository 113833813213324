import { BasicKidInterface } from "../../../../../shared/type/kid.type";

import './SingleKidTab.css';
import CustomBadge from "../../../../CustomBadge/CustomBadge";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import IconComponent from "../../../../IconComponent/IconComponent";
import { Link } from "react-router-dom";
interface Props{
  kid: BasicKidInterface,
  url: any,
  unassignChild: (kidId: number) => void
}

const SingleKidTab = ({kid, unassignChild, url}: Props) => {
  const isTheBalanceNegative = kid.saldo.includes('-');
  const newPath = (kidId: number) => url + `children/single-kid/${kidId}/orders`;
  return (
    <div className='SingleKidTab p-spacing-3xl rounded-lg bg-base-white flex'>
      <div className="SingleKidTab__contextContainer w-96 flex flex-col gap-spacing-3xl">
        <div className="SingleKidTab__context flex flex-col gap-spacing-lg">
          <div className="SingleKidTab__context__id">
            <p className='text-grayLight-700 text-sm font-normal'>
              Identyfikator: {kid.id}
            </p>
          </div>
          <div className="SingleKidTab__context__consumer">
            <p className='text-lg font-semibold text-grayWarm-900 underline underline-offset-4 mb-2'>
            <Link to={newPath(kid.id)}>{kid.firstName} {kid.lastName}</Link></p>
            <CustomBadge color={kid.organisationUnitName ? 'blue' : 'warning'} children={kid.organisationUnitName || ''} size='sm'/>
          </div>
        </div>
        {kid.remarks ? <div className="SingleKidTab__comment flex gap-spacing-md">
          <div className='w-6 h-6'>
            <IconComponent iconName='annotation-alert'/>
          </div>
          <p className='text-grayLight-700 font-normal text-base'>Uwagi: {kid.remarks}</p>
        </div> : null}
      </div>
      <div className="SingleKidTab__saldoContainer flex flex-col justify-between">
        <div className='flex flex-col gap-spacing-md'>
          <CustomBadge color={isTheBalanceNegative  ? 'warning' : 'success'} children={`saldo: ${kid.saldo}`} size='lg'/>
          {isTheBalanceNegative ? (
            <div className='flex gap-spacing-xs'>
              <IconComponent iconName='wallet' color='#292524'/>
              <p className='text-grayWarm-800 text-sm font-semibold'> Doładuj konto</p>
            </div>
          ) : null}
        </div>
        <CustomChakraButton
         hierarchy='linkGray' size='lg' iconPosition='onlyText' buttonProps={{
            onClick: () => {
              unassignChild(kid.id)
            },
            type: 'button',
            style: {padding: 0, margin: 0}
        }}>
          Rozłącz konta</CustomChakraButton>
      </div>
    </div>
  )
};

export default SingleKidTab;