import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { useApi } from '../../../../context/ApiProvider';
import { useParams } from 'react-router-dom';
import { LogEntriesInterface, LogInterface } from '../../../../shared/type/logs.type';

const DEFAULT_PAGE_SIZE = 10;
const SEARCH_DEBOUNCE_MS = 500; // Opóźnienie dla throttlingu

export const useFetchTenantHistory = () => {
  const { id: tenantId } = useParams();
  const { apiTenantLogEntitiesController } = useApi();

  const [data, setData] = useState<LogInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState<string>('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchPhrase(searchPhrase);
    }, SEARCH_DEBOUNCE_MS);

    return () => clearTimeout(handler);
  }, [searchPhrase]);

  const fetchTenantHistory = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response: AxiosResponse<LogEntriesInterface> = await apiTenantLogEntitiesController(
        ''
      ).post(`/search`, {
        tenantId: Number(tenantId),
        pageNumber: page,
        pageSize: DEFAULT_PAGE_SIZE,
        searchPhrase: debouncedSearchPhrase || ''
      });

      setData(response.data.data);
      setTotalCount(response.data.totalCount);
      setHasNextPage(response.data.hasNextPage);
    } catch (err) {
      console.warn('Błąd pobierania danych:', err);
      setError('Nie udało się pobrać danych');
    } finally {
      setIsLoading(false);
    }
  }, [tenantId, page, debouncedSearchPhrase]);

  useEffect(() => {
    fetchTenantHistory();
  }, [fetchTenantHistory]);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0) {
      setPage(newPage);
    }
  };

  return {
    data,
    isLoading,
    error,
    page,
    totalCount,
    hasNextPage,
    searchPhrase,
    setSearchPhrase,
    handlePageChange
  };
};
