import { createColumnHelper } from '@tanstack/react-table';
import { Box, Flex, TableContainer } from '@chakra-ui/react';

import { LogInterface } from '../../../../shared/type/logs.type';
import Pagination from '../../../Pagination/Pagination';
import { DataTable } from '../../../DataTable/DataTable';

import { useFetchTenantHistory } from './useHistoryOperation';

export const HistoryOperation = () => {
  const { data, isLoading, page, totalCount, hasNextPage, setSearchPhrase, handlePageChange } =
    useFetchTenantHistory();
  const columnHelper = createColumnHelper<LogInterface>();

  const columns = [
    columnHelper.accessor('operationDateTime', {
      cell: (info) => {
        const operationDateTime = new Date(info.getValue());
        return (
          <Box whiteSpace="normal" className="flex flex-col">
            <p>{`${operationDateTime.toLocaleDateString()}`}</p>
            <p>{`${operationDateTime.toLocaleTimeString()}`}</p>
          </Box>
        );
      },
      header: 'Data operacji'
    }),
    columnHelper.accessor('description', {
      cell: (info) => <Box whiteSpace="normal">{info.getValue()}</Box>,
      header: 'Opis'
    }),
    columnHelper.display({
      id: 'firstNameOfUserPerformingOperation',
      header: 'Wykonano przez',
      cell: (info) => {
        const { firstNameOfUserPerformingOperation, lastNameOfUserPerformingOperation } =
          info.row.original;
        return (
          <Box whiteSpace="normal">
            {firstNameOfUserPerformingOperation} {lastNameOfUserPerformingOperation}
          </Box>
        );
      }
    }),
    columnHelper.accessor('consumer', {
      cell: (info) => {
        const { consumer } = info.row.original;
        return <Box whiteSpace="normal">{consumer}</Box>;
      },
      header: 'Powiązany konsument'
    })
  ];

  return (
    <div className="Branches__tableContainer h-full flex flex-col justify-start lg:p-spacing-4xl ">
      <div className="w-full lg:p-spacing-3xl bg-white rounded-lg">
        <header className="mb-spacing-xl">
          <h1 className="text-lg uppercase font-semibold">Historia operacji</h1>
          <p className="font-normal text-sm text-grayLight-600">
            Tutaj sprawdzisz historię operacji w wybranym oddziale
          </p>
        </header>

        <div>
          <Flex direction="column" h="800px" className="Branches__table bg-white rounded-lg">
            <TableContainer h="100%" flex="1" minH="500px" overflowY="auto">
              <DataTable
                disableFilters={true}
                customSearchAction={(value) => {
                  setSearchPhrase(value);
                }}
                columns={columns}
                isLoading={isLoading}
                data={data}
              />
            </TableContainer>
            <Box position="relative" paddingTop="10px" paddingBottom="20px">
              <Pagination
                pageNumber={page}
                maxPageNumber={totalCount}
                hasNextPage={hasNextPage}
                onPageChange={handlePageChange}
              />
            </Box>
          </Flex>
        </div>
      </div>
    </div>
  );
};
