import { Text } from '@chakra-ui/react';
import CustomChakraButton from '../../CustomChakraButton/CustomChakraButton';

export const KitchenSection = ({ onSeeMoreClick }: { onSeeMoreClick: () => void }) => {
  return (
    <section className="flex justify-between items-center px-4 py-5 gap-4 rounded-xl border border-gray-200">
      <div>
        <h2 className="text-lg font-semibold mb-1">Kuchnia: Podsumowanie posiłków do wydania </h2>
        <Text className="text-gray-600">
          Tu znajdziesz informacje na temat produkcji na wybrany dzień (statystyki zamówionych
          stawek oraz kategorii posiłków)
        </Text>
      </div>
      <div>
        <CustomChakraButton
          hierarchy="primary"
          iconPosition="right"
          size="sm"
          icon="arrowRight"
          buttonProps={{
            onClick: onSeeMoreClick
          }}>
          Zobacz więcej
        </CustomChakraButton>
      </div>
    </section>
  );
};
