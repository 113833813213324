import { useCallback, useState } from 'react';
import { HttpStatusCode } from 'axios';
import { useNavigate } from 'react-router-dom';

import { useApi } from '../../../../../../../../../../context/ApiProvider';
import { useTenantAdminBasketContext } from '../../../../../../../../../../context/TenantAdminBasketProvider';
import { OrderedItemInterface } from '../../../../../../../../../../shared/type/orderForParent.type';

export const useBasketForCreditOrder = () => {
  const navigate = useNavigate();
  const {
    isEmpty,
    parentBasketContext,
    changeProductQuantity,
    resetBasket,
    clearBasketForConsumer,
    calculatedPayment,
    deleteProductFromBasket,
    placeOrderForPayment
  } = useTenantAdminBasketContext();
  const { apiOrderController } = useApi();
  const [collapsedConsumers, setCollapsedConsumers] = useState<{ [consumerId: number]: boolean }>(
    {}
  );

  const toggleCollapse = (consumerId: number) => {
    setCollapsedConsumers((prevState) => ({
      ...prevState,
      [consumerId]: !prevState[consumerId]
    }));
  };

  const clearBasket = async () => {
    try {
      const response = await apiOrderController('clear-basket').delete('');
      if (response.status === HttpStatusCode.Ok) resetBasket();
    } catch (e: any) {
      //toDo add error handling
    }
  };

  const handleDelete = useCallback(
    (consumerId: number, item: OrderedItemInterface) => {
      deleteProductFromBasket(consumerId, item);
    },
    [deleteProductFromBasket]
  );

  const handleQuantityChange = useCallback(
    (consumerId: number, item: OrderedItemInterface, increment: boolean) => {
      changeProductQuantity(consumerId, item, increment);
    },
    [changeProductQuantity]
  );

  const onSummaryClick = () => {
    placeOrderForPayment();
    navigate('summary');
  };

  return {
    handleQuantityChange,
    handleDelete,
    clearBasket,
    toggleCollapse,
    collapsedConsumers,
    clearBasketForConsumer,
    calculatedPayment,
    isEmpty,
    parentBasketContext,
    onSummaryClick
  };
};
