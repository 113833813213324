import { TableContainer, Thead, Th, Box, Text, Divider } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../context/AlertProvider";
import { useApi } from "../../../../../context/ApiProvider";
import { CourseInterface } from "../../../../../shared/type/course.type";
import CustomAlert from "../../../../CustomAlert/CustomAlert";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../../../DataTable/DataTable";
import IconComponent from "../../../../IconComponent/IconComponent";
import AddEditMealCategory from "./AddEditMealCategory/AddEditMealCategory";

const defaultDeleteElement = {
  id: 0,
  isOpen: false
}


const MealCategories = () => {
  const { apiTenantCourseController } = useApi();
  const { id: tenantId } = useParams();
  const { setShow, setAlertProperties } = useAlertContext();
  const columnHelper = createColumnHelper<CourseInterface>();
  const [mealCategories, setMealCategories] = useState<CourseInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ deleteElement, setDeleteElement ] = useState(defaultDeleteElement)
  const [ addEditCategoryPopUp, setAddEditCategoryPopUp ] = useState<{
    isOpen: boolean,
    mode: 'add' | 'edit'
  }>({
    isOpen: false,
    mode: 'add'
  });
  const [ editedMealCategory, setEditedMealCategory ] = useState<CourseInterface>();

  const fetchMealCategories = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<CourseInterface[]> = await apiTenantCourseController('').get(`/${tenantId}`);
      setMealCategories(response.data)
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => {
        const name = info.getValue();

        return(
          <p className='text-grayLight-900 font-medium text-sm'>
            {name}
          </p>
        )
      },
      header: 'Nazwa i kategoria posiłku'
    }),
    columnHelper.accessor('mealDistributionDto.name', {
      cell: (info) => {
        const mealDistributionDtoName = info.getValue();

        return(
          <p className='text-grayLight-6 00 font-medium text-sm'>
            {mealDistributionDtoName}
          </p>
        )
      },
      header: 'Przypisana wydawka'
    }),
    columnHelper.accessor('stakes', {
      cell: (info) => {
        const stakes = info.getValue();

        return (
          <div className="h-full flex flex-col">
            {stakes.map((item, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0"/>}
                <div className="grow flex items-center pt-4 pr-spacing-md pb-spacing-xl pl-spacing-md">
                  <p className='text-grayLight-900 text-sm font-medium p-0 m-0'>
                    {item.name}
                  </p>
                </div>
              </React.Fragment>
            ))}
          </div>

        )
      },
      header: "Przypisana stawka"
    }),
    columnHelper.display({
      id: "actions",
      header: "Edycja",
      cell: (info) => {
        const { id } = info.row.original;

        return (
          <div className="flex gap-spacing-lg pt-spacing-xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl ">
           <button
            onClick={() => {
              setDeleteElement({
                isOpen: true,
                id
              })
            }}
            className="font-semibold text-sm text-grayLight-600 bg-transparent border-none">
              Usuń
            </button>
            <button
              onClick={() => {
                setAddEditCategoryPopUp({
                  isOpen: true,
                  mode: 'edit'
                })
                setEditedMealCategory(info.row.original)
              }}
              className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none">
              Edytuj
            </button>
          </div>
        );
      }
    })
  ];

  useEffect(() => {
    fetchMealCategories()
  }, [tenantId]);

  return (
    <section className="MealCategories p-spacing-xl w-full">
      <TableContainer h="100%" flex="1" minH="500px" className="Branches__table bg-white rounded-lg pb-spacing-11xl">
        <DataTable
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5 gap-spacing-xxs">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base normal-case text-grayLight-900"
                      >
                        Kategorie posiłków
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm normal-case text-grayLight-700"
                      >
                        Tutaj możesz tworzyć i edytować kategorie posiłków jakie będą obowiązywać w oddziale.
                        Kategoria posiłków pozwala określić co dokładnie dostanie użytkownik za zakupioną stawkę oraz kiedy dany posiłek zostanie wydany.
                        Przykładowe kategorie posiłków to np. "danie śniadaniowe", "zupa obiadowa", "Drugie danie obiadowe".
                        Kategoria posiłku jest przypisana do wydawki i do stawek. Np. Jeśli ktoś wykupi stawkę o nazwie "cały dzień posiłków", wówczas podczas wydawki "śniadanie" dostanie "danie śniadaniowe" a podczas obiadu "zupę obiadową" oraz "drugie danie obiadowe".
                        Kategorie posiłków są również używane do tworzenia jadłospisu. Np. Do kategorii "Danie śniadaniowe" będzie można przypisać jadłospis "Zupa mleczna + bułka z dżemem"
                      </Text>
                    </Box>
                  </div>
                  <CustomChakraButton size="md" hierarchy="primary" iconColor="#292524" iconPosition="left" icon="plus" buttonProps={{
                    onClick: () => {
                      setAddEditCategoryPopUp({
                        isOpen: true,
                        mode: 'add'
                      })
                    }
                  }}>
                    Dodaj kategorię
                  </CustomChakraButton>
                </div>
              </Th>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={mealCategories}
        />
      </TableContainer>
      {deleteElement.isOpen ? (
        <CustomAlert
          header="Usunąć kategorię?"
          content="Usunięcie kategorii ma wpływ na jadłospis."
          confirmButton={{
            iconPosition: 'onlyText',
            size: 'lg',
            hierarchy: 'warning'
          }}
          confirmButtonText="Usuń"
          onConfirmAction={async () => {
            try {
              const response = await apiTenantCourseController('').delete(`/${deleteElement.id}`);
              if (response.status === HttpStatusCode.Ok) {
                setAlertProperties({
                  timeout: 9000,
                  title: "Sukces",
                  description: `Kategoria została usunięta`,
                  status: "success"
                });
                setShow(true);
                setDeleteElement(defaultDeleteElement);
                fetchMealCategories();
              } else {
                setAlertProperties({
                  timeout: 9000,
                  title: "Błąd",
                  description: `Nie udało się usunąć kategorii`,
                  status: "error"
                });
                setShow(true);
              }
            } catch (error) {
              setAlertProperties({
                timeout: 9000,
                title: "Błąd",
                description: `Nie udało się usunąć kategorii`,
                status: "error"
              });
              setShow(true);
            }
          }}
          cancelButton={{
            iconPosition: 'onlyText',
            size: 'lg',
            hierarchy: 'secondaryGray'
          }}
          cancelButtonText="Anuluj"
          onCancelAction={() => {
            setDeleteElement(defaultDeleteElement)
            fetchMealCategories();
          }}
          handleOpen={deleteElement.isOpen}
          icon={
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
              <IconComponent iconName="trash" />
            </div>
          }
        />
      ) : null}
      {addEditCategoryPopUp.isOpen ? (
        <AddEditMealCategory
          initData={editedMealCategory}
          mode={addEditCategoryPopUp.mode}
          isOpen={addEditCategoryPopUp.isOpen}
          onClose={() => {
            setAddEditCategoryPopUp({
              isOpen: false,
              mode: 'add'
            });
            fetchMealCategories();
          }} />
      ) : null}
    </section>
  );
};

export default MealCategories;
