import { useEffect, useMemo } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';

import IconComponent from '../../../../../../../IconComponent/IconComponent';
import { useTenantAdminBasketContext } from '../../../../../../../../context/TenantAdminBasketProvider';
import { ParentBasketContextProvider } from '../../../../../../../../context/ParentBasketProvider';
import { ParentOrderContextProvider } from '../../../../../../../../context/ParentOrderContext';
import { ConsumerOrderContextProvider } from '../../../../../../../../context/ConsumerOrderContext';

import { useSideNavContext } from '../../../../../../../../context/SideNavProvider';

import CompleteCashOrder from './CompleteCashOrder/CompleteCashOrder';
import AdminCashOrderSummary from './AdminCashOrderSummary/AdminCashOrderSummary';
import { useEditTeacherOrEmployeeContext } from '../../../../../../../../context/EditTeacherOrEmployeeProvider';

const SetOrderOnCash = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: tenantId, userId } = useParams<{ id: string; userId: string }>();
  const { setId, setMode, user } = useEditTeacherOrEmployeeContext();
  const { setOrderType } = useTenantAdminBasketContext();
  const { setIsMinimal } = useSideNavContext();

  const isParentFlow = location.pathname.includes('parents');
  const isTeacherFlow = location.pathname.includes('teachers');
  const isEmployeeFlow = location.pathname.includes('employees');

  const singleUserTypePath = useMemo(() => {
    if (isParentFlow) {
      return 'parents/single-parent';
    }

    if (isTeacherFlow) {
      return 'teachers/single-teacher';
    }

    if (isEmployeeFlow) {
      return 'employees/single-employee';
    }
  }, [isParentFlow, isTeacherFlow, isEmployeeFlow]);

  useEffect(() => {
    if (isTeacherFlow && userId) {
      setId(Number(userId));
      setMode('teacher');
    }

    if (isEmployeeFlow && userId) {
      setId(Number(userId));
      setMode('employee');
    }
  }, [userId, isTeacherFlow, isEmployeeFlow]);

  useEffect(() => {
    if (!isParentFlow && !user?.consumerId) {
      navigate(`/tenantAdmin/id/${tenantId}/users/${singleUserTypePath}/${userId}/list-of-orders`);
      return;
    }
    setOrderType('cash');
  }, [isParentFlow, user?.consumerId]);

  useEffect(() => {
    setIsMinimal(true);

    return () => {
      setIsMinimal(false);
    };
  }, []);

  return (
    <ParentBasketContextProvider>
      <ConsumerOrderContextProvider>
        <ParentOrderContextProvider>
          <section className="Order flex flex-col h-full bg-grayLight-50">
            <div className="flex items-start pt-spacing-xl lg:p-0 justify-center w-full h-full">
              <Routes>
                <Route
                  path="/summary"
                  element={
                    <AdminCashOrderSummary
                      navigationSlot={
                        <OrderNavigation
                          onBackClick={() =>
                            navigate(
                              `/tenantAdmin/id/${tenantId}/users/${singleUserTypePath}/${userId}/cash-order`
                            )
                          }
                        />
                      }
                    />
                  }
                />
                <Route
                  path="/*"
                  element={
                    <CompleteCashOrder
                      navigationSlot={
                        <OrderNavigation
                          onBackClick={() =>
                            navigate(
                              `/tenantAdmin/id/${tenantId}/users/${singleUserTypePath}/${userId}/list-of-orders`
                            )
                          }
                        />
                      }
                    />
                  }
                />
              </Routes>
            </div>
          </section>
        </ParentOrderContextProvider>
      </ConsumerOrderContextProvider>
    </ParentBasketContextProvider>
  );
};

const OrderNavigation = ({ onBackClick }: { onBackClick: () => void }) => {
  return (
    <div className="pl-spacing-3xl pr-spacing-3xl">
      <button
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        className="flex pt-spacing-xl pb-spacing-xl gap-spacing-sm"
        onClick={onBackClick}>
        <IconComponent iconName="arrowLeft" />
        <p className="text-grayWarm-950 font-semibold" style={{ fontSize: '16px' }}>
          Wróć
        </p>
      </button>
    </div>
  );
};

export default SetOrderOnCash;
