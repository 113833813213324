import { HttpStatusCode } from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Radio,
  Stack,
  RadioGroup,
  Input
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useParams } from 'react-router-dom';
import { useAlertContext } from '../../../../../context/AlertProvider';
import { useApi } from '../../../../../context/ApiProvider';
import {
  OrganisationUnitInterface,
  DayOffInterface,
  NewDayOffInterface,
  FreeDayType
} from '../../../../../shared';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import CustomInputRadio from '../../../../Forms/CustomInputRadio/CustomInputRadio';
import FormInput from '../../../../Forms/FormInput/FormInput';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  initData?: DayOffInterface;
}

const AddEditDayOff = ({ isOpen, onClose, initData }: Props) => {
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const { id: tenant } = useParams();
  const { apiTenantOrganisationUnitController, apiTenantFreeWorkingDayController } = useApi();

  const isEditMode = !!initData;

  const [dayOff, setDayOff] = useState<DayOffInterface | NewDayOffInterface>(
    initData || {
      tenantId: Number(tenant),
      name: '',
      from: new Date().toISOString().split('T')[0],
      to: new Date().toISOString().split('T')[0],
      freeDayType: FreeDayType.WORKING_DAY,
      organisationIds: [-1],
      organisationUnits: []
    }
  );

  const [organizationUnits, setOrganizationUnits] = useState<OrganisationUnitInterface[]>([]);

  useEffect(() => {
    const fetchOrganizationUnits = async () => {
      try {
        const response = await apiTenantOrganisationUnitController('').get(`/${tenant}`);
        setOrganizationUnits(response.data);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchOrganizationUnits();
  }, [apiTenantOrganisationUnitController, tenant]);

  useEffect(() => {
    if (initData) setDayOff(initData);
  }, [initData]);

  const updateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDayOff((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isEditMode) {
        await apiTenantFreeWorkingDayController('').put(`/${(dayOff as DayOffInterface).id}`, {
          tenantId: Number(tenant),
          ...dayOff,
          organisationIds: (dayOff as DayOffInterface).organisationUnits.map((unit) => unit.id)
        });
      } else {
        await apiTenantFreeWorkingDayController('').post('', dayOff);
      }
      {
        setAlertProperties({
          status: 'success',
          timeout: 1000,
          title: `Operacja zakończona sukcesem`
        });
        setShowAlert(true);
      }
      onClose();
    } catch (error: any) {
      if (error.response.status === HttpStatusCode.BadRequest) {
        const { fieldName, message } = error.response.data.violations[0];
        setAlertProperties({
          status: 'warning',
          timeout: 4000,
          title: `Pole ${fieldName} ${message}`
        });
        setShowAlert(true);
      }
    }
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={handleSubmit}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader>
            <h2 className="font-semibold text-lg">
              {isEditMode ? 'Edytuj' : 'Dodaj'} dzień wolny/pracujący
            </h2>
            <p className="font-normal text-sm">
              {isEditMode
                ? 'Edytowanie dnia wolnego/pracującego.'
                : 'Dodanie nowego dnia wolnego/pracującego.'}
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <RadioGroup
              value={`${dayOff.freeDayType}`}
              onChange={(value) =>
                setDayOff((prev) => ({
                  ...prev,
                  freeDayType: value as FreeDayType
                }))
              }>
              <Stack>
                <CustomInputRadio
                  header="Dzień wolny (Sprzedaż zamknięta + blokada zgłaszania obecności przez rodzica)"
                  message="Zamknięcie sprzedaży w przerwy świąteczne i dni dyrektorskie.">
                  <Radio
                    size="md"
                    value={FreeDayType.FREE_DAY}
                    name="freeDay"
                    colorScheme="green"
                  />
                </CustomInputRadio>
                <CustomInputRadio
                  header="Blokada sprzedaży"
                  message="Zamknięcie sprzedaży w danym dniu.">
                  <Radio
                    size="md"
                    value={FreeDayType.SALES_BLOCKADE}
                    name="freeDay"
                    colorScheme="green"
                  />
                </CustomInputRadio>
                <CustomInputRadio
                  header="Dzień pracujący (Sprzedaż otwarta)"
                  message="otwarcie sprzedaży w dni, które są zwykle dniami wolnymi np. odpracowywanie dnia w sobotę">
                  <Radio
                    size="md"
                    value={FreeDayType.WORKING_DAY}
                    name="freeDay"
                    colorScheme="green"
                  />
                </CustomInputRadio>
              </Stack>
            </RadioGroup>

            <FormInput
              label="Nazwa"
              placeholder="Podaj nazwę"
              bottomElement={
                <p className="text-sm font-normal text-grayLight-500">
                  Ta nazwa będzie wyświetlać się w kalendarzach użytkowników.
                </p>
              }
              inputParams={{
                required: true,
                value: dayOff.name,
                name: 'name',
                onChange: updateState
              }}
            />

            <div className="flex flex-col gap-spacing-xs">
              <label className="text-sm font-medium text-grayLight-700">Klasa</label>
              <Select
                isMulti
                placeholder="Wybierz klasy, których dotyczą dni."
                value={
                  isEditMode
                    ? (dayOff as DayOffInterface).organisationUnits.length ===
                      organizationUnits.length
                      ? [{ label: 'Wszyscy', value: -1 }]
                      : (dayOff as DayOffInterface).organisationUnits.map((unit) => ({
                          label: unit.name,
                          value: unit.id
                        }))
                    : (dayOff as NewDayOffInterface).organisationIds.includes(-1)
                      ? [{ label: 'Wszyscy', value: -1 }]
                      : organizationUnits
                          .filter((unit) =>
                            (dayOff as NewDayOffInterface).organisationIds.includes(unit.id)
                          )
                          .map((unit) => ({ label: unit.name, value: unit.id }))
                }
                onChange={(selectedOptions) => {
                  let selectedValues = selectedOptions.map((option) => option.value);

                  if (selectedValues.includes(-1)) {
                    selectedValues = [-1];
                  } else if (selectedValues.length > 0) {
                    selectedValues = selectedValues.filter((val) => val !== -1);
                  }

                  setDayOff((prev) => ({
                    ...prev,
                    organisationIds: selectedValues,
                    organisationUnits: selectedValues.includes(-1)
                      ? organizationUnits
                      : organizationUnits.filter((unit) => selectedValues.includes(unit.id))
                  }));
                }}
                options={[
                  { label: 'Wszyscy', value: -1 },
                  ...organizationUnits.map((unit) => ({
                    label: unit.name,
                    value: unit.id
                  }))
                ]}
              />
            </div>

            <div className="flex flex-row items-start">
              <div className="flex basis-1/2 gap-spacing-xs">
                <div className="flex flex-col gap-spacing-xs">
                  <label className="text-sm font-medium text-grayLight-700">Data od</label>
                  <Input
                    type="date"
                    required
                    value={dayOff.from}
                    name="from"
                    onChange={updateState}
                  />
                </div>
                <div className="flex flex-col gap-spacing-xs">
                  <label className="text-sm font-medium text-grayLight-700">Data do</label>
                  <Input type="date" required value={dayOff.to} name="to" onChange={updateState} />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: 'button',
                onClick: onClose
              }}>
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: 'submit'
              }}>
              {isEditMode ? 'Zapisz zmiany' : 'Dodaj'}
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddEditDayOff;
