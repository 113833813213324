import React from "react";
import { useEditParentContext } from "../../../../../../../context/EditParentProvider";
import handlePhoneNumberChange from "../../../../../../../utils/handlePhoneNumberChange";
import CustomChakraButton from "../../../../../../CustomChakraButton/CustomChakraButton";
import FormInput from "../../../../../../Forms/FormInput/FormInput";

const ParentData = () => {
  const {
    parent,
    setParent,
    editButtonState,
    setEditButtonState,
    setShowDeleteAccount,
    setShowActivateAccount,
    updateEmailPassword,
    setShowPasswordReset} = useEditParentContext();

  const isPasswordNull = parent.password === null;


  return (
    <div className="ParentData_formContainer pt-spacing-xl flex flex-col gap-spacing-5xl">
      <div className='flex'>
        <div className='basis-3/5 flex flex-col gap-spacing-3xl'>
          <FormInput
            label="Imię"
            placeholder="Podaj imię"
            inputParams={{
              name: 'firstName',
              value: parent?.firstName,
              onChange: (e) => {
                setParent(prev => ({ ...prev, [e.target.name]: e.target.value }))
                setEditButtonState((prev) => ({...prev, isTopButtonActive: true}));
              },
              required: true,
              minLength: 3,
            }}
          />
          <FormInput
            label="Nazwisko"
            placeholder="Podaj nazwisko"
            inputParams={{
              name: 'lastName',
              value: parent?.lastName,
              onChange: (e) => {
                setParent(prev => ({ ...prev, [e.target.name]: e.target.value }))
                setEditButtonState((prev) => ({...prev, isTopButtonActive: true}));
              },
              required: true,
              minLength: 3,
            }}
          />
          <FormInput
            label="Numer konta"
            placeholder="Podaj numer konta"
            inputParams={{
              disabled: true,
              value: parent?.bankAccountNumber || '',
              required: false,
              minLength: 6,
            }}
          />
          <FormInput
            label="Numer telefonu"
            placeholder="Podaj numer telefonu"
            inputParams={{
              name: 'phone',
              value: parent?.phone || '',
              onChange: (e) => {
                setParent(prev => ({ ...prev, [e.target.name]: handlePhoneNumberChange((e.target.value)) }))
                setEditButtonState((prev) => ({...prev, isTopButtonActive: true}));
              },
              required: false,
            }}
          />
        </div>
        <div className="basis-2/5 flex items-start justify-end">
          <div className="AdministratorSettings__actionButtons flex gap-spacing-lg">
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="tertiaryColor"
              size="md"
              buttonProps={{
                disabled: !editButtonState.isTopButtonActive,
                type: 'button',
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="primary"
              size="md"
              buttonProps={{
                disabled: !editButtonState.isTopButtonActive,
                type: 'submit',
              }}
            >
              Zapisz zmiany
            </CustomChakraButton>
          </div>
        </div>
      </div>
      <hr className="bg-grayLight-200" />
      <div className="flex">
        <div className="basis-3/5 flex flex-col gap-spacing-3xl">
          <FormInput
            label="Adres email"
            placeholder="Podaj email"
            inputParams={{
              type: 'text',
              value: parent?.login,
              onChange: (e) => {
                setParent(prev => ({ ...prev, [e.target.name]: e.target.value }))
                setEditButtonState((prev) => ({...prev, isBottomButtonActive: true}));
              },
              name: 'login',
              minLength: 3,
              autoComplete: "off",
            }}
          />
          <div>
            <FormInput
              label="Hasło"
              placeholder="******"
              bottomElement={
                isPasswordNull ? (
                  <p className="text-sm font-normal text-grayLight-500">
                    Użytkownik zmienił hasło tymczasowe, nie masz już podglądu hasła.
                  </p>
                ) : <p className="text-sm font-normal text-grayLight-500 text-transparent">{' .'}</p>
              }
              inputParams={{
                type: "text",
                name: 'password',
                onChange: (e) => {
                  setParent(prev => ({ ...prev, [e.target.name]: e.target.value }))
                  setEditButtonState((prev) => ({...prev, isBottomButtonActive: true}));
                },
                value: parent?.password || '',
                minLength: 5,
                autoComplete: "new-password",
              }}
            />
            <div className='relative' style={{
              left: "calc(100% + 24px)",
              top: '-50%'
            }}><CustomChakraButton
              iconPosition='onlyText'
              hierarchy="linkGray"
              size="md"
              buttonProps={{
                onClick: () => {setShowPasswordReset(true)},
                type: 'button',
              }}
              textProps={{
                style: { color: '#D92D20' },
              }}
            >
              Resetuj hasło
            </CustomChakraButton></div>
          </div>
          <CustomChakraButton
            iconPosition='left'
            hierarchy="linkGray"
            icon='trash'
            size="md"
            buttonProps={{
              onClick: () => {setShowDeleteAccount(true)},
              type: 'button',
            }}
            textProps={{
              style: { color: '#D92D20' },
            }}
          >
            Usuń użytkownika
          </CustomChakraButton>
          {!parent?.active &&
          <CustomChakraButton
            iconPosition='left'
            hierarchy="linkGray"
            icon='check'
            size="md"
            buttonProps={{
              onClick: () => {setShowActivateAccount(true)},
              type: 'button',
            }}
            textProps={{
              style: { color: '#228B22' },
            }}
          >
            Aktywuj użytkownika
          </CustomChakraButton>
          }
        </div>
        <div className='basis-2/5'>
          <div className="basis-2/5 flex items-start justify-end">
            <div className="AdministratorSettings__actionButtons flex gap-spacing-lg">
              <CustomChakraButton
                iconPosition="onlyText"
                hierarchy="tertiaryColor"
                size="md"
                buttonProps={{
                  disabled: !editButtonState.isBottomButtonActive,
                  type: 'button',
                  onClick: updateEmailPassword
                }}
              >
                Anuluj
              </CustomChakraButton>
              <CustomChakraButton
                iconPosition="onlyText"
                hierarchy="primary"
                size="md"
                buttonProps={{
                  disabled: !editButtonState.isBottomButtonActive,
                  type: 'button',
                  onClick: updateEmailPassword
                }}
              >
                Zapisz zmiany
              </CustomChakraButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ParentData;