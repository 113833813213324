import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParentDashboardContextContext } from '../../../../context/ParentDashboardContext';
import { useThemeContext } from '../../../../context/ThemeProvider';
import { ParentDashboardContextInterface } from '../../../../shared/type/parentDashboardContext.type';

import './ChooseSchool.css';

interface Props {
  parentDashboardContext: ParentDashboardContextInterface;
  updateTenantId: (id: number) => void;
}

const ChooseSchool = ({ parentDashboardContext, updateTenantId }: Props) => {
  const { t } = useTranslation();
  const { setBasketStatus } = useParentDashboardContextContext();
  const { isMobile } = useThemeContext();

  useEffect(() => {
    setBasketStatus({
      forceShow: false,
      show: false
    });
  }, []);

  useEffect(() => {
    if (parentDashboardContext.tenants.length === 1) {
      updateTenantId(parentDashboardContext.tenants[0].tenantId);
    }
  }, []);

  return (
    <section className="flex flex-col self-center items-center justify-center">
      <div
        style={{ transform: isMobile ? 'translateY(0)' : 'translateY(-50%)' }}
        className="flex flex-col gap-spacing-6xl lg:pb-spacing-6xl pr-spacing-3xl pl-spacing-3xl">
        <div className="flex flex-col items-center justify-center gap-spacing-lg">
          <h2 className="text-2xl font-semibold text-grayLight-900">
            {/* @ts-ignore */}
            {t('parent_order.choose_school.title')}
          </h2>
          <p className="font-normal text-grayLight-600" style={{ fontSize: '16px' }}>
            {/* @ts-ignore */}
            {t('parent_order.choose_school.subtitle')}
          </p>
        </div>
        <div className="flex lg:flex-row items-center flex-col gap-10 justify-around">
          {parentDashboardContext.tenants.map((tenant) => (
            <button
              key={`Key-${tenant.tenantId}`}
              onClick={() => {
                updateTenantId(tenant.tenantId);
              }}
              className="ChooseSchool__schoolTab border box-border rounded-xl p-spacing-xl border-brand-300 max-w-96">
              <p className="text-grayLight-900 text-lg font-semibold text-wrap">
                {tenant.tenantName}
              </p>
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ChooseSchool;
