import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import IconComponent from '../component/IconComponent/IconComponent';

const GetButtonMessage = ({
  absencePossible,
  presence
}: {
  absencePossible: boolean;
  presence: boolean;
}) => {
  const { t } = useTranslation();

  const message = useMemo(
    () =>
      !absencePossible && presence
        ? //@ts-ignore
          t('parent_dashboard.stake_character_card.button_message_revoke_term_outdated')
        : absencePossible && !presence
          ? //@ts-ignore
            t('parent_dashboard.stake_character_card.button_message_meal_revoked')
          : null,
    [absencePossible, presence, t]
  );

  if (!message) return <p className="text-sm font-normal text-transparent">.</p>;

  return (
    <div className="flex items-center justify-center gap-spacing-xs grow">
      <IconComponent iconName="alertCircle" color="#D92D20" />
      <p className="text-sm font-normal text-error-500">{message}</p>
    </div>
  );
};

export default GetButtonMessage;
