import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
// @ts-ignore
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';

import {
  handleSelectSlot,
  toggleSelectAllDaysInMonth,
  eventPropGetter,
  dayPropGetter
} from '../../../../../utils/calendarOrderHelper';
import { useThemeContext } from '../../../../../context/ThemeProvider';

import CustomDayCell from '../CustomDayCell/CustomDayCell';
import CustomToolbar from '../CustomToolbar/CustomToolbar';

import { useOrderingCalendar } from './useOrderingCalendar';
import { OrderForParentInterface } from '../../../../../shared/type/orderForParent.type';

type OrderingCalendarProps = {
  clearSelectedDates: () => void;
  selectAllDaysInMonth: boolean;
  setSelectAllDaysInMonth: React.Dispatch<React.SetStateAction<boolean>>;
  selectedConsumerId: number | undefined;
  selectedDates: Date[];
  setSelectedDates: React.Dispatch<React.SetStateAction<Date[]>>;
  minOrderDate: Moment;
  maxOrderDate: Moment;
  basketContext: OrderForParentInterface;
  letOrderInPast: boolean;
  useTranslations?: boolean;
  selectedStake?: number;
};

export const OrderingCalendar = ({
  clearSelectedDates,
  selectAllDaysInMonth,
  setSelectAllDaysInMonth,
  selectedConsumerId,
  selectedDates,
  setSelectedDates,
  minOrderDate,
  maxOrderDate,
  basketContext,
  letOrderInPast,
  useTranslations,
  selectedStake
}: OrderingCalendarProps) => {
  const { isMobile } = useThemeContext();
  const { events, fixedEvents, handleNavigate, viewDate, userOrderContext } = useOrderingCalendar({
    minOrderDate,
    maxOrderDate,
    setSelectedDates,
    selectedConsumerId,
    selectedDates,
    basketContext,
    useTranslations
  });
  const localizer = momentLocalizer(moment);
  const views = useMemo(() => [Views.MONTH], []);

  return (
    <div>
      <Calendar
        selectable={true}
        date={viewDate}
        longPressThreshold={3}
        views={views}
        defaultView={Views.MONTH}
        localizer={localizer}
        onSelectSlot={(slotInfo: any) => {
          handleSelectSlot(
            slotInfo,
            minOrderDate,
            maxOrderDate,
            userOrderContext,
            selectedConsumerId,
            setSelectedDates,
            viewDate,
            letOrderInPast,
            selectedStake
          );
        }}
        onNavigate={handleNavigate}
        min={minOrderDate.toDate()}
        max={maxOrderDate.toDate()}
        style={{ height: isMobile ? 1200 : 700 }}
        components={{
          month: {
            dateHeader: (props: any) => (
              <CustomDayCell
                date={props.date}
                events={events.filter((event) => moment(event.start).isSame(props.date, 'day'))}
              />
            )
          },
          toolbar: () => (
            <CustomToolbar
              label={moment(viewDate).format('MMMM YYYY')}
              onNavigate={handleNavigate}
              clearSelectedDates={clearSelectedDates}
              onToggleSelectAll={(isSelected: boolean) => {
                toggleSelectAllDaysInMonth(
                  isSelected,
                  selectedConsumerId,
                  viewDate,
                  setSelectAllDaysInMonth,
                  userOrderContext,
                  setSelectedDates,
                  letOrderInPast,
                  minOrderDate.toDate(),
                  maxOrderDate.toDate()
                );
              }}
              isSelectAllChecked={selectAllDaysInMonth}
              useTranslations={useTranslations}
            />
          )
        }}
        eventPropGetter={(event: any) => eventPropGetter(event, fixedEvents)}
        dayPropGetter={(date: Date) =>
          dayPropGetter(date, selectedDates, userOrderContext, selectedConsumerId, selectedStake)
        }
      />
    </div>
  );
};
