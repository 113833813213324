import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import CustomChakraButton from "../../../CustomChakraButton/CustomChakraButton";
import React, {useCallback, useEffect, useState} from "react";
import {useSafeTranslation} from "../../../../hooks/useSafeTranslation";
import {AxiosResponse} from "axios";
import {ParentSupportData} from "../../../../shared/type/support.type";
import {useApi} from "../../../../context/ApiProvider";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    tenantId: number | null
}

const AddKidNotPossibleNotification = ({ isOpen, onClose, tenantId }: Props) => {
    const { t } = useSafeTranslation();
    const { apiParentSupportController } = useApi();
    const [supportData, setSupportData] = useState<ParentSupportData | null>(null);

    const fetchTenantContactData = useCallback(async () => {
        try {
            const response: AxiosResponse<ParentSupportData> = await apiParentSupportController('').get(
                '',
                {
                    params: {
                        tenantId: tenantId
                    }
                }
            );
            setSupportData(response.data);
        } catch (error) {
            console.error(error);
        } finally {
        }
    }, [tenantId]);

    useEffect(() => {
        if (tenantId) {
            fetchTenantContactData();
        }
    }, [tenantId]);

    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
                <ModalOverlay
                    sx={{
                        backdropFilter: 'blur(10px)',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    }}
                />
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-spacing-xl">
                        <div className="flex flex-col gap-spacing-xs">
                            <h2 className="font-semibold text-lg">
                                {t('parent_dashboard.add_kid_not_possible.header')}
                            </h2>
                            <p className="font-normal text-sm text-grayLight-600">
                                {t('parent_dashboard.add_kid_not_possible.desc')}
                            </p>
                        </div>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody className="grid gap-spacing-3xl">
                        <div className="flex items-center">
                            <span className='basis-4/12 text-sm text-grayLight-600'>
                                {t('parent_dashboard.add_kid_not_possible.email')}
                            </span>
                            <span className='basis-4/12 text-lg font-bold text-grayLight-600'>
                                {supportData?.supportEmail}
                            </span>
                        </div>
                        <div className="flex items-center">
                            <span className='basis-4/12 text-sm text-grayLight-600'>
                                {t('parent_dashboard.add_kid_not_possible.phone')}
                            </span>
                            <span className='basis-4/12 text-lg font-bold text-grayLight-600'>
                                {supportData?.supportPhone}
                            </span>
                        </div>
                        <div>
                            <p className="font-normal text-sm text-grayLight-600">
                                {t('parent_dashboard.add_kid_not_possible.contact')}
                            </p>
                        </div>
                    </ModalBody>
                    <ModalFooter className="w-full flex justify-between gap-spacing-lg">
                        <CustomChakraButton
                            hierarchy="secondaryColor"
                            size="lg"
                            iconPosition="onlyText"
                            buttonProps={{
                                className: 'grow',
                                type: 'button',
                                onClick: onClose
                            }}>
                            {/* @ts-ignore */}
                            {t('parent_add_kid_popup.back')}
                        </CustomChakraButton>
                    </ModalFooter>
                </ModalContent>
        </Modal>
    );
};

export default AddKidNotPossibleNotification;