import { useMemo } from 'react';
import moment from 'moment';
// @ts-ignore
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import { Toolbar } from './Toolbar';
import { useAdminCalendar } from './useAdminCalendar';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import { DayCell } from './DayCell';

export const AdminCalendar = () => {
  const { loading, dashboardDays, currentDate, setCurrentDate, onSlotClick } = useAdminCalendar();

  moment.locale('pl');
  const localizer = momentLocalizer(moment);

  const views = useMemo(() => [Views.MONTH], []);

  return (
    <div>
      {loading || !dashboardDays ? (
        <LoadingSpinner state={loading}>
          <span>Ładowanie danych</span>
        </LoadingSpinner>
      ) : (
        <Calendar
          selectable={true}
          date={new Date(`${currentDate.year}-${currentDate.month}-${currentDate.day}`)}
          longPressThreshold={3}
          views={views}
          defaultView={Views.MONTH}
          localizer={localizer}
          onSelectSlot={(slotInfo: any) => {
            return onSlotClick(slotInfo.start);
          }}
          components={{
            month: {
              dateHeader: (props: any) => {
                return (
                  <DayCell
                    dayCount={dashboardDays.find((day) =>
                      moment(day.date).isSame(props.date, 'day')
                    )}
                    date={props.date}
                  />
                );
              }
            },
            toolbar: () => <Toolbar currentDate={currentDate} setDate={setCurrentDate} />
          }}
          style={{ height: 700 }}
        />
      )}
    </div>
  );
};
