import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal
} from '@chakra-ui/react';
import { AxiosResponse, HttpStatusCode } from 'axios';
import { Select } from 'chakra-react-select';
import React, { useState, useEffect } from 'react';
import { useAlertContext } from '../../../../../../context/AlertProvider';
import { useApi } from '../../../../../../context/ApiProvider';
import useAuth from '../../../../../../hooks/useAuth';
import {
  NewMealBaseInterface,
  EditMealBaseInterface
} from '../../../../../../shared/type/mealBase.type';
import CustomChakraButton from '../../../../../CustomChakraButton/CustomChakraButton';
import FormInput from '../../../../../Forms/FormInput/FormInput';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  mode: 'add' | 'edit';
  initData?: EditMealBaseInterface;
}

const defaultMealBase = {
  id: 0,
  name: '',
  tenantIds: []
};

const AddEditDishesDatabase = ({ isOpen, initData, onClose, mode }: Props) => {
  const { setShow, setAlertProperties } = useAlertContext();
  const { auth } = useAuth();
  const { apiTenantMealMealController } = useApi();
  const [mealBase, setMealBase] = useState<NewMealBaseInterface>(defaultMealBase);

  const handleRequest = async (e: React.FormEvent) => {
    e.preventDefault();
    let response: AxiosResponse;
    try {
      if (mode === 'add') {
        response = await apiTenantMealMealController('').post('', mealBase);
      } else {
        response = await apiTenantMealMealController('').put('', mealBase);
      }

      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 9000,
          title: 'Sukces',
          description: `Poprawnie dodano nową bazę`,
          status: 'success'
        });
        setShow(true);
        onClose();
      } else {
        setAlertProperties({
          timeout: 9000,
          title: 'Nieowodzenia',
          description: `Błąd dodawania bazy`,
          status: 'warning'
        });
        setShow(true);
      }
    } catch (error) {
      setAlertProperties({
        timeout: 9000,
        title: 'Nieowodzenia',
        description: `Błąd dodawania bazy`,
        status: 'warning'
      });
      setShow(true);
    }
  };

  useEffect(() => {
    if (mode === 'edit' && initData) {
      setMealBase(initData);
    }
  }, [isOpen, initData, mode]);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
      <form onSubmit={handleRequest}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">{mode === 'add' ? 'Dodaj' : 'Edytuj'} bazę</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <FormInput
              label="Nazwa"
              placeholder="Wpisz nazwę np.Bezglutenowa"
              inputParams={{
                value: mealBase.name,
                onChange: (e) => {
                  setMealBase((prev) => ({ ...prev, name: e.target.value }));
                },
                required: true,
                name: 'name'
              }}
            />
            <Select
              isMulti
              required
              placeholder="Wybierz oddziały, które będą mieć dostęp do bazy"
              value={auth.availableTenants
                .filter((unit) => mealBase.tenantIds.includes(unit.id))
                .map((unit) => ({
                  label: unit.name,
                  value: unit.id
                }))}
              onChange={(value) => {
                setMealBase((prev) => ({
                  ...prev,
                  tenantIds: value.map((item: any) => item.value)
                }));
              }}
              options={auth.availableTenants.map((unit) => ({
                label: unit.name,
                value: unit.id,
                variant: 'outline'
              }))}
            />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                onClick: onClose
              }}>
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                disabled: mealBase.name.length === 0 || mealBase.tenantIds.length === 0,
                className: 'grow',
                type: 'submit'
              }}>
              Dodaj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddEditDishesDatabase;
