import React from 'react';

import './FormInput.css';

interface Props {
  label: string;
  inputParams?: React.InputHTMLAttributes<HTMLInputElement>;
  bottomElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  state?: 'normal' | 'error';
  placeholder?: string;
}

const FormInput = ({ label, bottomElement, placeholder, inputParams, state = 'normal', rightElement }: Props) => {
  const normalClasses = 'FormInput__input--normal focus:border-brand-300 shadow-outline-brand-300';
  const errorClasses = 'FormInput__input--error focus:border-error-300 shadow-outline-red';

  const chooseClass = (): string => {
    switch (state) {
      case 'normal':
        return normalClasses;
      case 'error':
        return errorClasses;
      default:
        return '';
    }
  };

  return (
    <div className="flex flex-col gap-1.5">
      <label htmlFor="" className="text-sm font-medium text-grayLight-700">
        {label}
      </label>
      <div className="relative w-full">
        <input
            type="text"
            placeholder={placeholder}
            {...inputParams}
            className={`${chooseClass()} FormInput__input border box-border border-grayLight-300 rounded-lg text-grayLight-700 w-full`}
            style={{ padding: '10px 14px' }}
        />
        {rightElement && (
            <div className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer">
              {rightElement}
            </div>
        )}
      </div>
      {bottomElement}
    </div>
  );
};

export default FormInput;
