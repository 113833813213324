import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../context/ApiProvider';
import { MessageInterface } from '../../../../shared/type/message.type';
import { PaginationInterface } from '../../../../shared/type/paginationInterface.type';
import IconComponent from '../../../IconComponent/IconComponent';
import DeleteMessageAlert, {
  defaultDeleteMessagePopUpContext
} from '../DeleteMessageAlert/DeleteMessageAlert';
import useAuth from "../../../../hooks/useAuth";

interface Props {
  tenantId: string;
  setRefreshMessages: React.Dispatch<React.SetStateAction<() => void>>;
}

const ParentMessage = ({ tenantId, setRefreshMessages }: Props) => {
  const { t } = useTranslation();
  const { apiParentMessageController } = useApi();
  const { auth } = useAuth();
  const [messages, setMessages] = useState<MessageInterface[]>([]);
  const [deleteMessagePopUp, setDeleteMessagePopUp] = useState<{
    isOpen: boolean;
    threadId: number;
  }>(defaultDeleteMessagePopUpContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });

  const fetchMessages = async (pageNumber: number) => {
    try {
      const response = await apiParentMessageController('thread-list').get(`/${tenantId}`, {
        params: {
          pageNumber: pageNumber,
          pageSize: 10
        }
      });

      const { data, next, hasNextPage, totalCount } = response.data;

      setActualPage({ next, hasNextPage, totalCount });
      setMessages(data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    fetchMessages(currentPage);
  }, [tenantId, currentPage]);

  useEffect(() => {
    setRefreshMessages(() => () => fetchMessages(currentPage));
  }, [fetchMessages, currentPage, setRefreshMessages]);

  const goToNextPage = () => {
    if (actualPage.hasNextPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now.getTime() - date.getTime();

    if (timeDiff < 24 * 60 * 60 * 1000) {
      return date.toLocaleTimeString('pl-PL', { hour: '2-digit', minute: '2-digit' });
    } else {
      return date.toLocaleDateString('pl-PL', { year: 'numeric', month: '2-digit', day: '2-digit' }) +
          ' ' +
          date.toLocaleTimeString('pl-PL', { hour: '2-digit', minute: '2-digit' });
    }
  };

  return (
    <div className="ParentMessage flex flex-col">
      <div className="pt-spacing-md pr-spacing-4xl pb-spacing-md pl-spacing-4xl">
        <div className="flex justify-end">
          <div className="flex gap-spacing-lg">
            <p className="text-sm font-medium text-grayLight-700">
              {/* @ts-ignore */}
              {t('parent_mailbox.parent_message.page_info', {
                currentPage: currentPage,
                totalPages: Math.ceil(actualPage.totalCount / 10)
              })}
            </p>
            <div className="flex gap-spacing-lg">
              <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                <IconComponent iconName="chevronLeft" className="h-5 w-5" />
              </button>
              <button onClick={goToNextPage} disabled={!actualPage.hasNextPage}>
                <IconComponent iconName="chevronRight" className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div
            className={`flex pt-spacing-lg pr-spacing-4xl pb-spacing-lg pl-spacing-4xl`}
        >
         <div className='basis-11/12 flex items-center gap-spacing-lg'>
           <p
               className={`basis-3/12 text-left text-sm font-semibold text-grayLight-700`}
           >
             Nadawca
           </p>
           <p
               className={`basis-9/12 text-left text-sm font-semibold text-grayLight-700`}>
             Tytuł
           </p>
         </div>
          <div
            className='basis-1/12 text-left text-sm font-semibold text-grayLight-700 flex items-center justify-center group'
          >
            Data wysłania
          </div>
        </div>
        {messages
          .sort((a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime())
          .map((message, messageIndex) => (
            <div
              key={`Message-${messageIndex}`}
              className={`flex pt-spacing-lg pr-spacing-4xl pb-spacing-lg pl-spacing-4xl hover:bg-brand-100 ${
                !message.hasBeanRead && 'bg-grayLight-100'
              }`}>
              <Link
                to={`message/${message.id}?title=${encodeURIComponent(message.topic)}`}
                className="basis-11/12 flex items-center gap-spacing-lg">
                <p
                    className={`basis-3/12 text-left text-sm font-${message.hasBeanRead ? 'normal' : 'semibold'} text-grayLight-700`}
                >
                  {auth.availableTenants.find((tenant) => Number(tenant.id) === Number(tenantId))?.name}
                </p>
                <p
                  className={`basis-9/12 text-left text-sm font-${message.hasBeanRead ? 'normal' : 'semibold'} text-grayLight-700`}>
                  {message.topic}
                </p>
              </Link>
              <div className="basis-1/12 flex items-center justify-center group">
                <p
                  className={`group-hover:hidden font-${
                    message.hasBeanRead ? 'normal' : 'semibold'
                  }`}>
                  {formatDate(message.lastUpdate)}
                </p>
                <button
                  onClick={() => {
                    setDeleteMessagePopUp({
                      isOpen: true,
                      threadId: message.id
                    });
                  }}
                  //@ts-ignore
                  title={t('parent_mailbox.parent_message.delete_tooltip')}>
                  <IconComponent
                    color="#171412"
                    iconName="trash"
                    className="hidden group-hover:block"
                  />
                </button>
              </div>
            </div>
          ))}
      </div>
      {deleteMessagePopUp.isOpen && (
        <DeleteMessageAlert
          setDeleteMessagePopUp={setDeleteMessagePopUp}
          isOpen={deleteMessagePopUp.isOpen}
          threadId={deleteMessagePopUp.threadId}
          onFinish={() => {
            fetchMessages(currentPage);
          }}
        />
      )}
    </div>
  );
};

export default ParentMessage;
