import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import { HttpStatusCode } from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import { NewParentInterface } from "../../../../../../shared/type/parent.type";
import handlePhoneNumberChange from "../../../../../../utils/handlePhoneNumberChange";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import FormInput from "../../../../../Forms/FormInput/FormInput";
import CustomAlert from "../../../../../CustomAlert/CustomAlert";
import IconComponent from "../../../../../IconComponent/IconComponent";


interface Props {
  isOpen: boolean;
  onClose: () => void;
  kidId?: number;
}

const defaultParent: NewParentInterface = {
  tenantId: 0,
  firstName: '',
  lastName: '',
  email: '',
  newPassword: null,
  phoneNumber: null
}

const defaultAssignToTenantPopUp = {
  isOpen: false,
  tenantId: "0",
  email: ''
}

const AddParent  = ({ isOpen, onClose, kidId }: Props) => {
  const { apiTenantParentController, apiTenantConsumerController } = useApi();
  const { id: tenantId } = useParams<{id: string}>();
  const { setShow, setAlertProperties } = useAlertContext();
  const [assignToTenantPopUp, setAssignToTenantPopUp] = useState(defaultAssignToTenantPopUp);
  const [ parent, setParent ] = useState<NewParentInterface>(defaultParent);

  const addParent = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    let { firstName, lastName, email, newPassword, phoneNumber} = parent;

    let parentObj = {
      tenantId,
      firstName,
      lastName,
      email,
      newPassword,
      phoneNumber
    }

    if(phoneNumber?.length === 0){
      parentObj = {...parentObj, phoneNumber: null};
    }

    try {
      const {status, data} = await apiTenantParentController('').post('', parentObj);
      if(status === HttpStatusCode.Ok){
        if(kidId){
          const {status: assignStatus} = await apiTenantConsumerController('assign-consumer-to-parent').post(`/${tenantId}/${kidId}/${data.id}`);
          if(assignStatus === HttpStatusCode.Ok){
            setAlertProperties({
              timeout: 9000,
              title: "Sukces",
              description: `Poprawnie dodano rodzica oraz przypisano go do dziecko`,
              status: "success"
            });
            onClose();
          }
        } else {
          setAlertProperties({
            timeout: 9000,
            title: "Sukces",
            description: `Poprawnie dodano rodzica`,
            status: "success"
          });
          onClose();
        }
      } else {
        setAlertProperties({
          timeout: 9000,
          title: "Błąd",
          description: `Błąd dodawania rodzica`,
          status: "error"
        });
      }
    } catch (error: any) {
      console.log(error);
      if (error.response.data.errorMessage.endsWith(" już istnieje.")){
        setAssignToTenantPopUp({
          isOpen: true,
          tenantId: `${tenantId}`,
          email: parentObj.email
        });
      } else {
        setAlertProperties({
          timeout: 9000,
          title: "Błąd",
          description: `Błąd dodawania rodzica: ${error.response.data.errorMessage}`,
          status: "error"
        });
        setShow(true)
      }
    }
  };

  const mergeParent = async () => {
    try {
      await apiTenantParentController('').post('merge-parent-by-email',
              {
                tenantId: tenantId,
                email:assignToTenantPopUp.email,
              });
    } catch (errorResp: any) {
      console.error(errorResp);
      setAlertProperties({
        timeout: 9000,
        title: "Błąd",
        description: `Błąd przypisania rodzica: ${errorResp.response.data}`,
        status: "error"
      });
    } finally {
      setShow(true)
    }
  }

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={addParent}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex">
            <div className='flex flex-col text-grayLight-900 font-semibold text-lg border-r border-grayLight-200'>
              <h2 className="font-semibold text-lg">Dodaj rodzica</h2>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col gap-spacing-xl">
            <FormInput
              label="Imię"
              placeholder="Wpisz imię"
              inputParams={{
                name: "firstName",
                type: 'text',
                value: parent.firstName,
                onChange: (e) => {setParent((prev) => ({ ...prev, [e.target.name]: e.target.value }))},
                required: true,
                minLength: 3,
              }}
            />
            <FormInput
              label="Nazwisko"
              placeholder="Wpisz nazwisko"
              inputParams={{
                name: "lastName",
                type: 'text',
                value: parent?.lastName,
                onChange: (e) => {setParent((prev) => ({ ...prev, [e.target.name]: e.target.value }))},
                required: true,
                minLength: 3,
              }}
            />
            <FormInput
              label="Numer telefonu"
              placeholder="000-000-000"
              inputParams={{
                name: "phoneNumber",
                value: parent?.phoneNumber || '',
                onChange: (e) => {
                  setParent(prev => ({ ...prev, [e.target.name]: handlePhoneNumberChange((e.target.value)) }))
                },
                required: false,
              }}
            />
            <FormInput
              label="Adres email"
              placeholder="Wpisz adres email"
              bottomElement={
              <p className='text-sm font-normal text-grayLight-600'>Jeśli użytkownik nie posiada adresu zostanie mu nadany automatycznie login tymczasowy.</p>
              }
              inputParams={{
                name: "email",
                type: 'email',
                value: parent.email,
                onChange: (e) => {setParent((prev) => ({ ...prev, [e.target.name]: e.target.value }))},
                required: false,
                minLength: 3,
              }}
            />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "submit"
              }}
            >
              Dodaj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
      <CustomAlert
        handleOpen={assignToTenantPopUp.isOpen}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-brand-100">
            <IconComponent iconName="checkCircle" />
          </div>
        }
        header={"Przypisać rodzica z innego do oddziału?"}
        content={"Rodzic ze wskazanym adresem email jest przypisany do innego oddziału. Czy chcesz przypisać rodzica do tego odziału?"}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText={'Anuluj'}
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'primary'
        }}
        confirmButtonText={'Przypisz'}
        onConfirmAction={() => {
          mergeParent();
          setAssignToTenantPopUp(defaultAssignToTenantPopUp);
        }}
        onCancelAction={() => {
          setAssignToTenantPopUp(defaultAssignToTenantPopUp)
        }} />
    </Modal>
  );
};

export default AddParent;