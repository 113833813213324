import {Instruction} from "../../../shared/type/instruction.interface";
import SingleTutorial from "./SingleTutorial/SingleTutorial";

interface TutorialsProps {
    instructions: Instruction[]
}

const Tutorials = ({instructions}: TutorialsProps) => {

    return (
        <section className="p-spacing-xs lg:p-spacing-3xl gap-spacing-3xl lg:pb-spacing-11xl">
            <div className="flex flex-col gap-spacing-3xl">
                {instructions.map((instruction) => (
                    <SingleTutorial url={instruction.url} title={instruction.title}
                                    description={instruction.description}/>
                ))}
            </div>
        </section>
    )
};

export default Tutorials;