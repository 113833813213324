import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAlertContext } from "../../../context/AlertProvider";
import { useApi } from "../../../context/ApiProvider";
import CustomChakraButton from "../../CustomChakraButton/CustomChakraButton";
import IconComponent from "../../IconComponent/IconComponent";

import './ConfirmChangeEmail.css';

const ConfirmChangeEmail = () => {
  const navigate = useNavigate();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const { apiPublicSecurityController } = useApi();
  const [searchParams] = useSearchParams();

  const newEmail = searchParams.get("new-email");
  const confirmationHash = searchParams.get("confirmationHash");

  const handleConfirmEmail = async () => {
    try {
      await apiPublicSecurityController('confirm-new-email').post('', null, {
        params: {
          email: newEmail,
          confirmationHash,
          language: "pl"
        },
      });
    } catch (error: any) {
      setAlertProperties({
        title: 'Bład',
        description: `${error.response.data}`,
        timeout: 9000,
        status: 'error'
      })
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if(newEmail || confirmationHash){
      handleConfirmEmail()
    } else {
      setAlertProperties({
        title: 'Bład',
        description: 'Nie udało się potwierdzić zmiany adresu email, skontaktuj się z administratorem.',
        timeout: 9000,
        status: 'error'
      })
      setShowAlert(true);
    }
  }, [])

  return (
    <section
      className="ConfirmRegistrationEmail flex flex-col gap-10 justify-between lg:justify-center items-center w-screen h-screen bg-white px-4 py-6 sm:px-8 sm:py-12">
      <img
          alt='background'
          src={`${process.env.PUBLIC_URL}/img/dot-grid.png`}
          className="background-image fixed z-10 top-0"
          style={{ width: "50vw", height: "70vh" }}/>
      <div className="flex flex-col z-50 items-center justify-center gap-8 w-full lg:w-96">
        <div>
          <IconComponent iconName="mail" />
        </div>
        <div className="flex flex-col items-center gap-4 text-center">
          <h2 className="text-grayLight-900 font-semibold text-2xl sm:text-3xl">
            Potwierdzono zmianę adresu email
          </h2>
          <div className="flex flex-col text-grayLight-600 gap-2 text-base sm:text-lg">
            <p>
              Do Twojego konta został przypisany nowy adres: {newEmail}

            </p>
            <p>Jeśli ta zmiana nie została wprowadzona przez Ciebie, skontaktuj się z Administratorem.</p>
          </div>
        </div>
        <div>
          <CustomChakraButton
            hierarchy="primary"
            iconPosition="left"
            size="sm"
            icon="arrowLeft"
            buttonProps={{
              onClick: () => navigate('/login'),
            }}
          >
            Przejdź do logowania
          </CustomChakraButton>
        </div>
      </div>
      <div className="flex justify-between items-center w-full lg:w-96 text-grayLight-600 text-sm">
        <p>© Jemwszkole.pl 2024</p>
        <a href="mailto:help@jemwszkole.pl" className="flex items-center gap-2">
          <IconComponent iconName="send" />
          <span>help@jemwszkole.pl</span>
        </a>
      </div>
    </section>

  )
};

export default ConfirmChangeEmail;