import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import pl from './locales/pl.json';
import en from './locales/en.json';
import uk from './locales/uk.json';

// Definiowanie lokalnych tłumaczeń
const localTranslation = {
  pl: { translation: pl },
  en: { translation: en },
  uk: { translation: uk }
};

// Inicjalizacja `i18n`
i18n.use(initReactI18next).init({
  resources: localTranslation,
  lng: 'pl',
  fallbackLng: 'pl',
  interpolation: { escapeValue: false },
});

export default i18n;
