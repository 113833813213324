import { OrderedItemInterface } from '../../shared/type/orderForParent.type';

interface OrderCalculationRequestDto {
  orderedItems: OrderedItemInterface[];
  initialSaldo: number;
  credit: number;
  provision: string;
  useCredit: boolean;
}

interface CalculationRequestForSingleUser {
  consumerId: number;
  orderCalculationRequestDto: OrderCalculationRequestDto;
}

interface Props {
  consumerCalculationRequests: (CalculationRequestForSingleUser | null)[];
}

interface CalculatePaymentInterface {
  afterSaldo: number;
  initialSaldo: number;
  basicCost: number;
  financing: number;
  basicToPay: number;
  totalPaymentToTenant: number;
  totalToPay: number;
  provision: number;
  initialSaldoStr: string;
  afterSaldoStr: string;
  basicCostStr: string;
  financingStr: string;
  basicToPayStr: string;
  totalPaymentToTenantStr: string;
  totalToPayStr: string;
}

interface CalculatedPaymentForConsumer {
  consumerId: number;
  calculation: CalculatePaymentInterface;
}

interface CalculatePaymentInterfaceAssignedToConsumer {
  consumerCalculations: CalculatedPaymentForConsumer[];
  totalToPay: string;
  totalOrderValue: string;
}

function calculateParentUpPayment({
  consumerCalculationRequests
}: Props): CalculatePaymentInterfaceAssignedToConsumer {
  const outcome: CalculatePaymentInterfaceAssignedToConsumer = {
    consumerCalculations: [],
    totalToPay: '0,00',
    totalOrderValue: '0,00'
  };

  const toSimpleAmountString = (amountInPolishGrosze: number): string => {
    const zlotyCount = Math.floor(amountInPolishGrosze / 100);
    const grCount = Math.abs(amountInPolishGrosze % 100);
    return `${zlotyCount},${grCount < 10 ? '0' : ''}${grCount}`;
  };

  const calculateUpPayment = (order: OrderCalculationRequestDto): CalculatePaymentInterface => {
    const { basicCost, financing, basicToPay } = getBasicOrderCalculations(order);

    const totalPaymentToTenant = Math.round(basicToPay);

    const provision = Math.round(totalPaymentToTenant * Number(order.provision));

    let totalToPay = totalPaymentToTenant - order.initialSaldo;

    if (totalToPay > 0) {
      totalToPay += provision;
    }

    const afterSaldo = order.initialSaldo - totalPaymentToTenant;

    if (order.useCredit && order.credit > 0) {
      const availableCredit = Math.min(order.credit, totalToPay);
      totalToPay = Math.max(0, totalToPay - availableCredit);
    }

    if (totalToPay < 0) {
      totalToPay = 0;
    }

    return {
      basicCost,
      financing,
      basicToPay,
      initialSaldo: order.initialSaldo,
      afterSaldo: afterSaldo < 0 ? 0 : afterSaldo,
      totalPaymentToTenant,
      provision,
      totalToPay,
      initialSaldoStr: toSimpleAmountString(order.initialSaldo),
      afterSaldoStr: toSimpleAmountString(afterSaldo),
      basicCostStr: toSimpleAmountString(basicCost),
      financingStr: toSimpleAmountString(financing),
      basicToPayStr: toSimpleAmountString(basicToPay),
      totalPaymentToTenantStr: toSimpleAmountString(totalPaymentToTenant),
      totalToPayStr: toSimpleAmountString(totalToPay)
    };
  };

  let totalToPay = 0;
  let totalOrderValue = 0;

  for (const consumerCalculationRequest of consumerCalculationRequests) {
    if (!consumerCalculationRequest) continue;

    const { consumerId, orderCalculationRequestDto } = consumerCalculationRequest;
    const calculation = calculateUpPayment(orderCalculationRequestDto);

    outcome.consumerCalculations.push({
      consumerId,
      calculation
    });

    totalToPay += calculation.totalToPay;
    totalOrderValue += calculation.basicCost;
  }

  outcome.totalToPay = toSimpleAmountString(totalToPay);
  outcome.totalOrderValue = toSimpleAmountString(totalOrderValue);

  return outcome;
}

function getBasicOrderCalculations(order: OrderCalculationRequestDto): {
  basicCost: number;
  financing: number;
  basicToPay: number;
} {
  const basicCost = order.orderedItems.reduce(
    (sum, item) => sum + item.purchasableItem.price * item.count,
    0
  );

  const financing = 0;
  const basicToPay = basicCost;

  return {
    basicCost,
    financing,
    basicToPay
  };
}

export default calculateParentUpPayment;
