import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal
} from '@chakra-ui/react';
import { AxiosResponse, HttpStatusCode } from 'axios';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { useAlertContext } from '../../../../../../context/AlertProvider';
import { useApi } from '../../../../../../context/ApiProvider';
import useAuth from '../../../../../../hooks/useAuth';
import CustomChakraButton from '../../../../../CustomChakraButton/CustomChakraButton';
import ErrorBox from '../../../../../ErrorBox/ErrorBox';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  year: number;
  month: number;
}
const ExportMealPlanToOtherBranch = ({ isOpen, onClose, year, month }: Props) => {
  const { auth } = useAuth();
  const { setShow, setAlertProperties } = useAlertContext();
  const { id: sourceTenantId } = useParams();
  const { apiTenantMealToDayController } = useApi();
  const [targetTenantIds, setTargetTenantIds] = useState<number[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const handleExport = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      const response = await apiTenantMealToDayController('copy-assignment-to-other-tenants').post(
        '',
        {
          sourceTenantId,
          targetTenantIds,
          year,
          month
        }
      );
      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 9000,
          title: 'Sukces',
          description: `Poprawnie dodano nową bazę`,
          status: 'success'
        });
        setShow(true);
        onClose();
      }
    } catch (error: any) {
      setErrors(error.response.data.errors);
    }
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <form onSubmit={handleExport}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Eksportuj do oddziału lub oddziałów</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <Select
              isMulti
              required
              placeholder="Wybierz oddziały, które będą mieć dostęp do bazy"
              value={auth.availableTenants
                .filter((unit) => targetTenantIds.includes(unit.id))
                .map((unit) => ({
                  label: unit.name,
                  value: unit.id
                }))}
              onChange={(value) => {
                setTargetTenantIds(value.map((item: any) => item.value));
              }}
              options={auth.availableTenants.map((unit) => ({
                label: unit.name,
                value: unit.id,
                variant: 'outline'
              }))}
            />

            {errors && errors.length > 0 && <ErrorBox message={errors} />}
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                onClick: onClose
              }}>
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: 'submit'
              }}>
              Eksportuj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default ExportMealPlanToOtherBranch;
