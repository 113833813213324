import { HttpStatusCode } from 'axios';
import React, { ReactNode, useState, createContext, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import CustomAlert from '../component/CustomAlert/CustomAlert';
import IconComponent from '../component/IconComponent/IconComponent';
import { EditSpecialConsumerInterface } from '../shared/type/consumer.type';
import { useAlertContext } from './AlertProvider';
import { useApi } from './ApiProvider';

interface Props {
  children: ReactNode;
  withForm?: boolean;
}

interface EditButtonStateInterface {
  isTopButtonActive: boolean;
  isBottomButtonActive: boolean;
}

type OperationMode = 'teacher' | 'employee';

interface EditTeacherOrEmployeeContextProps {
  fetchUsers: () => void;
  updateEmailPassword: () => void;
  editButtonState: EditButtonStateInterface;
  setEditButtonState: React.Dispatch<React.SetStateAction<EditButtonStateInterface>>;
  user: EditSpecialConsumerInterface;
  setUser: React.Dispatch<React.SetStateAction<EditSpecialConsumerInterface>>;
  mode: OperationMode;
  setMode: React.Dispatch<React.SetStateAction<OperationMode>>;
  id: number;
  setId: React.Dispatch<React.SetStateAction<number>>;
  showRestartPasswordPopUp: boolean;
  setShowActivateAccount: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRestartPasswordPopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultSpecialConsumer: EditSpecialConsumerInterface = {
  consumerId: 0,
  userId: 0,
  consumerCategory: 'TEACHER',
  active: false,
  firstName: '',
  lastName: '',
  cardNumber: '',
  login: '',
  phoneNumber: '',
  password: ''
};

const EditTeacherOrEmployeeContext = createContext<EditTeacherOrEmployeeContextProps | undefined>(
  undefined
);

export const useEditTeacherOrEmployeeContext = () => {
  const context = useContext(EditTeacherOrEmployeeContext);
  if (!context) {
    throw new Error(
      'useEditTeacherOrEmployeeContextProps must be used within a EditTeacherOrEmployeeProvider'
    );
  }
  return context;
};

export const EditTeacherOrEmployeeProvider = ({ children, withForm = true }: Props) => {
  const { setShow, setAlertProperties } = useAlertContext();
  const {
    apiTenantSpecialConsumerController,
    apiTenantUserController,
    apiPublicSecurityController
  } = useApi();
  const { id: tenant } = useParams();
  const [user, setUser] = useState<EditSpecialConsumerInterface>(defaultSpecialConsumer);
  const [showActivateAccount, setShowActivateAccount] = useState<boolean>(false);
  const [showRestartPasswordPopUp, setShowRestartPasswordPopUp] = useState<boolean>(false);
  const [editButtonState, setEditButtonState] = useState<EditButtonStateInterface>({
    isTopButtonActive: false,
    isBottomButtonActive: false
  });

  const [id, setId] = useState<number>(0);
  const [mode, setMode] = useState<OperationMode>('teacher');

  const fetchUsers = async () => {
    try {
      const result = await apiTenantSpecialConsumerController(
        mode === 'teacher' ? 'teacher-list' : 'employee-list'
      ).get(`/${tenant}`);

      const resultUser = result.data.find((user: any) => user.userId === id);

      setUser(resultUser);
    } catch (error) {
      console.error(error);
      throw new Error(`${error}`);
    } finally {
    }
  };

  const updateEmailPassword = async () => {
    try {
      const response = await apiTenantUserController('email-or-password-update').put('', {
        tenantId: tenant,
        userId: user.userId,
        email: user.login,
        newPassword: user.password
      });
      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 9000,
          title: 'Sukces',
          description: 'Poprawnie zaktualizowano hasło i/lub email użytkownika',
          status: 'success'
        });
        setShow(true);
        setEditButtonState((prev) => ({ ...prev, isBottomButtonActive: false }));
      }
    } catch (error: any) {
      const { status, data } = error.response;
      if (status === HttpStatusCode.BadRequest) {
        setAlertProperties({
          timeout: 9000,
          title: `Błąd pola ${error.response?.data.violations[0].fieldName}`,
          description: `Podpowiedź: ${error.response?.data.violations[0].message}`,
          status: 'warning'
        });
        setShow(true);
      }
      if (status === HttpStatusCode.Conflict) {
        setAlertProperties({
          timeout: 9000,
          title: 'Błąd',
          description: `${data.errorMessage}`,
          status: 'error'
        });
        setShow(true);
      }
    } finally {
    }
  };

  const upgradeUser = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      const response = await apiTenantSpecialConsumerController('special-consumer').put('', {
        tenantId: tenant,
        userId: user.userId,
        consumerId: user.consumerId,
        firstName: user.firstName,
        lastName: user.lastName,
        cardNumber: user.cardNumber,
        phoneNumber: user.phoneNumber
      });
      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 9000,
          title: 'Sukces',
          description: 'Poprawnie zaktualizowano użytkownika',
          status: 'success'
        });
        setShow(true);
      }
    } catch (error: any) {
      const { status, data } = error.response;
      if (status === HttpStatusCode.Conflict) {
        setAlertProperties({
          timeout: 9000,
          title: 'Błąd',
          description: `${data.errorMessage}`,
          status: 'error'
        });
        setShow(true);
      }
    }
  };

  useEffect(() => {
    if (!tenant) return;
    fetchUsers();
  }, [id, mode]);

  const contextValue = useMemo(
    () => ({
      updateEmailPassword,
      fetchUsers,
      user,
      setUser,
      editButtonState,
      setEditButtonState,
      id,
      setId,
      mode,
      setMode,
      setShowActivateAccount,
      showRestartPasswordPopUp,
      setShowRestartPasswordPopUp
    }),
    [
      updateEmailPassword,
      fetchUsers,
      user,
      setUser,
      editButtonState,
      setEditButtonState,
      id,
      setId,
      mode,
      setMode,
      setShowActivateAccount,
      showRestartPasswordPopUp,
      setShowRestartPasswordPopUp
    ]
  );

  return (
    <EditTeacherOrEmployeeContext.Provider value={contextValue}>
      {withForm ? (
        <form id="update-employee-or-teacher-form" onSubmit={upgradeUser}>
          {children}
        </form>
      ) : (
        children
      )}
      {showRestartPasswordPopUp ? (
        <CustomAlert
          header="Zresetować hasło użytkownika?"
          content=""
          confirmButton={{
            iconPosition: 'onlyText',
            size: 'lg',
            hierarchy: 'warning'
          }}
          confirmButtonText="Resetuj"
          onConfirmAction={async () => {
            try {
              await apiPublicSecurityController('reset').post(
                '',
                {},
                {
                  params: {
                    email: user.login
                  }
                }
              );
              setAlertProperties({
                timeout: 9000,
                title: 'Sukces',
                description: `Poprawnie zresetowano hasła`,
                status: 'success'
              });
              setEditButtonState((prev) => ({ ...prev, isBottomButtonActive: false }));
              setShowRestartPasswordPopUp(false);
            } catch (error) {
              setAlertProperties({
                timeout: 9000,
                title: 'Błąd',
                description: `Błąd zresetowano hasła: ${error}`,
                status: 'error'
              });
            } finally {
              setShow(true);
            }
          }}
          cancelButton={{
            iconPosition: 'onlyText',
            size: 'lg',
            hierarchy: 'secondaryGray'
          }}
          cancelButtonText="Anuluj"
          onCancelAction={() => {
            fetchUsers();
          }}
          handleOpen={showRestartPasswordPopUp}
          icon={
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
              <IconComponent iconName="trash" color="#D92D20" />
            </div>
          }
        />
      ) : null}
      {showActivateAccount ? (
        <CustomAlert
          header="Aktywować konto użytkownika?"
          content="Aktywacja konta pozwoli użytkowinikowi zalogować się bez korzystania z maila aktywacyjnego."
          confirmButton={{
            iconPosition: 'onlyText',
            size: 'lg',
            hierarchy: 'warning'
          }}
          confirmButtonText="Aktywuj"
          onConfirmAction={async () => {
            try {
              await apiTenantUserController('').post(`/activate/${user.userId}`);
              fetchUsers();
              setAlertProperties({
                timeout: 9000,
                title: 'Sukces',
                description: `Poprawnie aktywowano rodzica`,
                status: 'success'
              });
              setShowActivateAccount(false);
            } catch (error) {
              setAlertProperties({
                timeout: 9000,
                title: 'Błąd',
                description: `Błąd aktualizacji ustawień rodzica ${error}`,
                status: 'error'
              });
            } finally {
              setShow(true);
            }
          }}
          cancelButton={{
            iconPosition: 'onlyText',
            size: 'lg',
            hierarchy: 'secondaryGray'
          }}
          cancelButtonText="Anuluj"
          onCancelAction={() => {
            setShowActivateAccount(false);
          }}
          handleOpen={showActivateAccount}
          icon={
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
              <IconComponent iconName="trash" color="#D92D20" />
            </div>
          }
        />
      ) : null}
    </EditTeacherOrEmployeeContext.Provider>
  );
};
