import { ParentOrderContextInterface } from '../../shared/type/parentOrderContext.type';
import moment from 'moment';
moment.locale('pl');

export const handleSelectSlot = (
  slotInfo: any,
  minDate: any,
  maxDate: any,
  parentOrderContext: ParentOrderContextInterface | undefined,
  consumerId: number | undefined,
  setSelectedDates: React.Dispatch<React.SetStateAction<Date[]>>,
  viewDate: Date,
  letOrderInPast: boolean,
  selectedStake: number | undefined
) => {
  const selectedDate = moment(slotInfo.start);

  if (!selectedStake) {
    return;
  }
  if (!selectedDate.isSame(moment(viewDate), 'month')) {
    return;
  }

  if (!letOrderInPast && (selectedDate.isBefore(minDate) || selectedDate.isAfter(maxDate))) {
    return;
  }

  const date = selectedDate.toDate();

  const consumerOrderContext = parentOrderContext?.consumerOrderContexts.find(
    (consumer) => consumer.consumerId === consumerId
  );

  const orderDay = consumerOrderContext?.orderDays.find((day) =>
    moment(day.when).isSame(date, 'day')
  );

  const selectedStakeForDay = orderDay?.purchasableItems?.find(
    (item) => item.stakeId === selectedStake
  );
  const isLimitForDayExceeded =
    selectedStakeForDay &&
    (!selectedStakeForDay.itemPurchaseLimit ||
      selectedStakeForDay.itemPurchaseLimit !== selectedStakeForDay.alreadyBoughtCount);

  if (
    orderDay?.salesBlocked ||
    orderDay?.workingDay === false ||
    (!!selectedStake && !isLimitForDayExceeded)
  ) {
    return;
  }


  const daySelection = consumerOrderContext?.daySelection || [];

  const selectedGroup = daySelection.find((group) =>
    group.some((d) => moment(d).isSame(selectedDate, 'day'))
  );

  let datesToSelect = [date];

  if (selectedGroup) {
    datesToSelect = selectedGroup.map((d) => moment(d).toDate());
  }

  setSelectedDates((prevDates) => {
    const newDates = datesToSelect.filter((d) => {
      const orderDay = consumerOrderContext?.orderDays.find((day) =>
        moment(day.when).isSame(d, 'day')
      );

      return (
        !prevDates.some((existingDate) => moment(existingDate).isSame(d, 'day')) &&
        orderDay?.workingDay !== false
      );
    });

    const shouldDeselectGroup = datesToSelect.some((d) =>
      prevDates.some((existingDate) => moment(existingDate).isSame(d, 'day'))
    );

    if (shouldDeselectGroup) {
      return prevDates.filter(
        (existingDate) => !datesToSelect.some((d) => moment(existingDate).isSame(d, 'day'))
      );
    }

    return [...prevDates, ...newDates];
  });
};
