import { Routes, Route } from 'react-router-dom';

import { EditAdministratorProvider } from '../../../../context/EditParentProvider';
import { TenantAdminBasketContextProvider } from '../../../../context/TenantAdminBasketProvider';

import ParentsTable from './ParentsTable/ParentsTable';
import SingleParent from './ParentsTable/SingleParents/SingleParent';
import SetOrderOnCredit from './ParentsTable/SingleParents/ListOfOrder/SetOrderOnCredit/SetOrderOnCredit';
import SetOrderOnCash from './ParentsTable/SingleParents/ListOfOrder/SetOrderOnCash/SetOrderOnCash';
import { EditTeacherOrEmployeeProvider } from '../../../../context/EditTeacherOrEmployeeProvider';

const Parents = () => {
  const parentsLink = [
    {
      link: 'parents-table',
      element: <ParentsTable />
    },
    {
      link: 'single-parent/:userId',
      element: (
        <EditAdministratorProvider>
          <TenantAdminBasketContextProvider>
            <SingleParent />
          </TenantAdminBasketContextProvider>
        </EditAdministratorProvider>
      )
    },
    {
      link: 'single-parent/:userId/credit-order',
      element: (
        <EditAdministratorProvider>
          <TenantAdminBasketContextProvider>
            <SetOrderOnCredit />
          </TenantAdminBasketContextProvider>
        </EditAdministratorProvider>
      )
    },
    {
      link: 'single-parent/:userId/cash-order',
      element: (
        <EditAdministratorProvider>
          <TenantAdminBasketContextProvider>
            <SetOrderOnCash />
          </TenantAdminBasketContextProvider>
        </EditAdministratorProvider>
      )
    }
  ];

  return (
    <EditTeacherOrEmployeeProvider>
      <Routes>
        {parentsLink.map((link) => (
          <Route key={link.link} path={`/${link.link}/*`} element={link.element} />
        ))}
      </Routes>
    </EditTeacherOrEmployeeProvider>
  );
};

export default Parents;
