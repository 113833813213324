import { MenuItem, Divider } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ParentAbsences from '../../component/ChildOwnerComponents/ParentAbsences/ParentAbsences';
import ParentAssignedAccounts from '../../component/ChildOwnerComponents/ParentAssignedAccounts/ParentAssignedAccounts';
import ParentFoodMenu from '../../component/ChildOwnerComponents/ParentFoodMenu/ParentFoodMenu';
import SingleMessage from '../../component/ChildOwnerComponents/ParentMailBox/ParentMessage/SingleMessage/SingleMessage';
import SingleNotification from '../../component/ChildOwnerComponents/ParentMailBox/ParentNotification/SingleNotification/SingleNotification';
import ParentMainPage from '../../component/ChildOwnerComponents/ParentMain/ParentMain';
import ParentMessages from '../../component/ChildOwnerComponents/ParentMailBox/ParentMailBox';
import ParentMealPlan from '../../component/ChildOwnerComponents/ParentMealPlan/ParentMealPlan';
import ParentOrder from '../../component/ChildOwnerComponents/ParentOrder/ParentOrder';
import ParentOrderHistory from '../../component/ChildOwnerComponents/ParentOrderHistory/ParentOrderHistory';
import ParentOrders from '../../component/ChildOwnerComponents/ParentOrders/ParentOrders';
import ParentSettings from '../../component/ChildOwnerComponents/ParentSettings/ParentSettings';
import ParentHeader from '../../component/Headers/ParentHeader/ParentHeader';
import IconComponent from '../../component/IconComponent/IconComponent';
import { useApi } from '../../context/ApiProvider';
import { ParentBasketContextProvider } from '../../context/ParentBasketProvider';
import { ParentOrderContextProvider } from '../../context/ParentOrderContext';
import { useSideNavContext } from '../../context/SideNavProvider';
import { useThemeContext } from '../../context/ThemeProvider';
import useAuth from '../../hooks/useAuth';
import { SideMenuOptionInterface } from '../../shared';
import { ParentPayOffTheBalance } from '../../component/ChildOwnerComponents/PayOffTheBalance/ParentPayOffTheBalance';
import { LanguagesMenuSelector } from '../../component/LanguagesSelectors/LanguagesMenuSelector';
import Tutorials from '../../component/ChildOwnerComponents/Tutorials/Tutorials';
import { Instruction } from '../../shared/type/instruction.interface';
import { Contact } from '../../component/ChildOwnerComponents/Contact/Contact';
import { EditTeacherOrEmployeeProvider } from '../../context/EditTeacherOrEmployeeProvider';

const ChildOwner = () => {
  const { t, i18n } = useTranslation();
  const { setNavOptions, toggleShowNav, showNav, setToggleMode } = useSideNavContext();
  const { apiPrivateSecurityController } = useApi();
  const { logout } = useAuth();
  const { isMobile } = useThemeContext();
  const navigate = useNavigate();

  const menuOptions: SideMenuOptionInterface[] = useMemo(
    () => [
      {
        /* @ts-ignore */
        header: t('child_owner_dashboard_element.home_page'),
        icon: 'happyHomeIcon',
        link: 'main'
      },
      {
        /* @ts-ignore */
        header: t('child_owner_dashboard_element.absences'),
        icon: 'calendar',
        link: 'absences'
      },
      {
        /* @ts-ignore */
        header: t('child_owner_dashboard_element.meal_plan'),
        icon: 'cutlery',
        link: 'meal-plan'
      },
      {
        /* @ts-ignore */
        header: t('child_owner_dashboard_element.assigned_accounts'),
        icon: 'users',
        link: 'assigned-accounts'
      },
      {
        /* @ts-ignore */
        header: t('child_owner_dashboard_element.order_history'),
        icon: 'history',
        link: 'order-history'
      },
      {
        /* @ts-ignore */
        header: t('child_owner_dashboard_element.messages'),
        icon: 'message',
        link: 'mail-box/messages'
      },
      {
        /* @ts-ignore */
        header: t('child_owner_dashboard_element.tutorials'),
        icon: 'question',
        link: 'instructions'
      },
      {
        /* @ts-ignore */
        header: t('child_owner_dashboard_element.contact'),
        icon: 'phone',
        link: 'contact'
      }
    ],
    [i18n.language]
  );

  const menuList = (
    <>
      <MenuItem
        icon={
          <div className="Header__iconContainer">
            <IconComponent iconName="settings" className="w-full h-full" />
          </div>
        }
        onClick={function () {
          navigate('/parent/settings');
        }}>
        {/* @ts-ignore */}
        {t('child_owner_dashboard_element.settings')}
      </MenuItem>
      <MenuItem>
        <LanguagesMenuSelector />
      </MenuItem>
      <Divider />
      <MenuItem
        icon={
          <div className="Header__iconContainer">
            <IconComponent iconName="logOutDoor" className="w-full h-full" />
          </div>
        }
        onClick={async function () {
          await apiPrivateSecurityController('logout').get('');
          logout();
        }}>
        {/* @ts-ignore */}
        {t('child_owner_dashboard_element.logout')}
      </MenuItem>
    </>
  );

  const instructions: Instruction[] = [
    {
      url: 'https://www.youtube.com/watch?v=xO11goSN2NM',
      title: 'Film bez opisu'
    },
    {
      url: 'https://www.youtube.com/watch?v=5F0ugWJNNv4',
      title:
        'Bardzo długi tytuł. Bardzo długi tytuł. Bardzo długi tytuł. Bardzo długi tytuł. Bardzo długi tytuł. Bardzo długi tytuł. Bardzo długi tytuł. Bardzo długi tytuł.',
      description:
        'Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis.'
    },
    {
      url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      title: 'Film z opisem',
      description: 'Krótki opis'
    }
  ];

  useEffect(() => {
    setNavOptions(menuOptions);
  }, [menuOptions]);

  useEffect(() => {
    setToggleMode('minimal');
    toggleShowNav(true);
  }, []);

  return (
    <ParentBasketContextProvider>
      <ParentOrderContextProvider>
        <EditTeacherOrEmployeeProvider withForm={false}>
          <section className={`ParentDashboard flex flex-row bg-secondary h-screen w-full`}>
            <div className="ParentDashboard__contextContainer h-full w-full ">
              <div className="ParentDashboard__headerContainer border-b border-brand-600">
                <ParentHeader notificationList={null} dropdownMenuList={menuList} /> {showNav}
              </div>
              <div
                className={`ParentDashboard__greetingsContainer h-full overflow-auto ${isMobile ? 'h-[calc(100%-72px)]' : 'h-screen'}`}>
                <div className="ParentDashboard__contextContainer h-full">
                  <Routes>
                    {/* tpay routes */}
                    <Route path="/main/tpay/success" element={<ParentMainPage />} />
                    <Route path="/main/tpay/error" element={<ParentMainPage />} />
                    {/* other routes */}
                    <Route path="/main/*" element={<ParentMainPage />} />
                    <Route path="/settings/*" element={<ParentSettings />} />
                    <Route path="/order/*" element={<ParentOrder />} />
                    <Route path="/orders/*" element={<ParentOrders />} />
                    <Route path="/food-menu/*" element={<ParentFoodMenu />} />
                    <Route path="/assigned-accounts/*" element={<ParentAssignedAccounts />} />
                    <Route path="/absences/*" element={<ParentAbsences />} />
                    <Route path="/mail-box/messages/message/:id/*" element={<SingleMessage />} />
                    <Route
                      path="/mail-box/notifications/notification/:id"
                      element={<SingleNotification />}
                    />
                    <Route path="/mail-box/*" element={<ParentMessages />} />
                    <Route path="/order-history/*" element={<ParentOrderHistory />} />
                    <Route path="/meal-plan" element={<ParentMealPlan />} />
                    <Route path="/payoff-balance" element={<ParentPayOffTheBalance />} />
                    <Route
                      path="/instructions"
                      element={<Tutorials instructions={instructions} />}
                    />
                    <Route path="/contact" element={<Contact />} />
                  </Routes>
                </div>
              </div>
            </div>
          </section>
        </EditTeacherOrEmployeeProvider>
      </ParentOrderContextProvider>
    </ParentBasketContextProvider>
  );
};

export default ChildOwner;
