import { AxiosResponse } from "axios";
import React, { ReactNode, useState, createContext, useContext, useEffect, useMemo } from "react";
import {ClosestStakeToTakeInterface, ParentDashboardContextInterface} from "../shared/type/parentDashboardContext.type";
import { useApi } from "./ApiProvider";

interface Props {
  children: ReactNode;
}

interface BasketStatusInterface {
  show: boolean,
  forceShow: boolean,
}

interface ParentDashboardContextProps {
  parentDashboardContext: ParentDashboardContextInterface | undefined;
  basketStatus: BasketStatusInterface;
  setBasketStatus:  React.Dispatch<React.SetStateAction<BasketStatusInterface>>;
  fetchParentDashboardContext: () => void;
  updateStake: (consumerId: number, stakeId: number, updatedStake: ClosestStakeToTakeInterface) => void
}

const ParentDashboardContextContext = createContext<ParentDashboardContextProps | undefined>(undefined);

export const useParentDashboardContextContext = () => {
  const context = useContext(ParentDashboardContextContext);
  if (!context) {
    throw new Error('useParentDashboardContextContext must be used within a ParentDashboardContextProvider');
  }
  return context;
};

export const ParentDashboardContextProvider = ({ children }: Props) => {
  const { apiParentDashboardContextController } = useApi();
  const [ parentDashboardContext, setParentDashboardContext ] = useState<ParentDashboardContextInterface>()
  const [ basketStatus, setBasketStatus ] = useState<BasketStatusInterface>({
    show: false,
    forceShow: false,
  });

  const updateStake = (consumerId: number, stakeId: number, updatedStake: ClosestStakeToTakeInterface) => {
    setParentDashboardContext(prevContext => {
      if (!prevContext) return prevContext;

      const updatedTenants = prevContext.tenants.map(tenant => {
        const updatedConsumers = tenant.consumers.map(consumer => {
          if (consumer.consumerDto.id === consumerId) {
            const updatedStakes = consumer.closestStakesToTake.map(stake => {
              if (stake.boughtStakeId === stakeId) {
                return updatedStake;
              }
              return stake;
            });
            return { ...consumer, closestStakesToTake: updatedStakes };
          }
          return consumer;
        });
        return { ...tenant, consumers: updatedConsumers };
      });

      return { ...prevContext, tenants: updatedTenants };
    });
  };

  const fetchParentDashboardContext = async () => {
    try {
      const response: AxiosResponse<ParentDashboardContextInterface> = await apiParentDashboardContextController('').get('');
      setParentDashboardContext(response.data);
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchParentDashboardContext()
  }, []);

  const contextValue = useMemo(
    () => ({
      parentDashboardContext,
      basketStatus,
      setBasketStatus,
      fetchParentDashboardContext,
      updateStake
    }),
    [
      parentDashboardContext,
      basketStatus,
      setBasketStatus,
      fetchParentDashboardContext,
      updateStake
    ]
  );

  return (
    <ParentDashboardContextContext.Provider value={contextValue}>
        {children}
    </ParentDashboardContextContext.Provider>
  );
};
