import { Checkbox, TableContainer, Box, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, Link, useSearchParams } from 'react-router-dom';

import { useAlertContext } from '../../../context/AlertProvider';
import { useApi } from '../../../context/ApiProvider';
import { AbsenceInterface } from '../../../shared/type/absence.type';
import { CustomAlertDialogInterface } from '../../../shared/type/CustomAlertDialog.type';
import ChooseMonths from '../../ChooseMonths/ChooseMonths';
import { DataTable } from '../../DataTable/DataTable';
import { OrganisationUnitInterface } from '../../../shared';
import { MultipleSelect } from '../../Forms/MultipleSelect/MultipleSelect';
import CustomChakraButton from '../../CustomChakraButton/CustomChakraButton';

const Absences = () => {
  const location = useLocation();
  const [showPeople, setShowPeople] = useState({
    withOrderingStakes: false,
    withoutOrderingStakes: false
  });
  const { setAlertProperties, setShow: setShowAlert } = useAlertContext();
  const { id: tenantId } = useParams<{ id: string }>();
  const {
    apiAbsenceAndCourseController,
    apiTenantAdminBoughtStakesController,
    apiTenantOrganisationUnitController
  } = useApi();
  const columnHelper = createColumnHelper<AbsenceInterface>();

  const [classes, setClasses] = useState<{ label: string; value: number }[]>([]);
  const [selectedClasses, setSelectedClasses] = useState<number[]>([]);
  const selectedClassesName = classes
    .filter((cls) => selectedClasses.includes(cls.value))
    .map((cls) => cls.label);
  const [searchParams] = useSearchParams();
  const currentDateParam = searchParams.get('currentDate');
  const defaultCurrentDate = currentDateParam ? new Date(currentDateParam) : new Date();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [absencesForDay, setAbsencesForDay] = useState<AbsenceInterface[]>([]);
  const absenceToDisplay = useMemo(() => {
    if (showPeople.withOrderingStakes) {
      return absencesForDay.filter((absence) => absence.stakesAndCourses.length > 0);
    }
    if (showPeople.withoutOrderingStakes) {
      return absencesForDay.filter((absence) => absence.stakesAndCourses.length === 0);
    }
    return absencesForDay;
  }, [showPeople, absencesForDay]);

  const filteredAbsencesByClass =
    selectedClassesName.length === 0
      ? absenceToDisplay
      : absenceToDisplay.filter((user) => selectedClassesName.includes(user.organisationName));
  const [currentDate, setCurrentDate] = useState<{ year: number; month: number; day: number }>({
    year: defaultCurrentDate.getFullYear(),
    month: defaultCurrentDate.getMonth() + 1,
    day: defaultCurrentDate.getDate()
  });

  const getAlertBody = (string: string): CustomAlertDialogInterface => ({
    status: 'error',
    title: 'Błąd',
    description: `Nie udało się zmienić ${string}`,
    timeout: 9000
  });

  const updateAbsenceLocally = (
    consumerId: number,
    boughtStakeId: number[],
    newPresence: boolean
  ) => {
    setAbsencesForDay((prevAbsences) =>
      prevAbsences.map((absence) => {
        if (absence.consumerId === consumerId) {
          return {
            ...absence,
            stakesAndCourses: absence.stakesAndCourses.map((stake) => {
              if (boughtStakeId.includes(stake.boughtStakeId)) {
                return {
                  ...stake,
                  presence: newPresence
                };
              }
              return stake;
            })
          };
        }
        return absence;
      })
    );
  };

  const updateTakeStackLocally = (boughtStakeId: number, courseId: number, newTaken: boolean) => {
    setAbsencesForDay((prevAbsences) =>
      prevAbsences.map((absence) => ({
        ...absence,
        stakesAndCourses: absence.stakesAndCourses.map((stake) => {
          if (stake.boughtStakeId === boughtStakeId) {
            return {
              ...stake,
              takenCourses: stake.takenCourses.map((course) => {
                if (course.courseId === courseId) {
                  return {
                    ...course,
                    taken: newTaken
                  };
                }
                return course;
              })
            };
          }
          return stake;
        })
      }))
    );
  };

  const handleAbsence = async (boughtStakesId: number[], absence: boolean, consumerId: number) => {
    updateAbsenceLocally(consumerId, boughtStakesId, absence);
    try {
      await apiAbsenceAndCourseController('report-or-revoke-absence').post('', {
        consumerId,
        boughtStakesId,
        absence: !absence
      });
    } catch (error: any) {
      updateAbsenceLocally(consumerId, boughtStakesId, !absence);
      setAlertProperties(
        getAlertBody(`obecności: ${error.response.data.errors[0] || error.response.data.errors[0]}`)
      );
      setShowAlert(true);
    }
  };

  const handleMassAbsence = async (
    consumers: { consumerId: number; boughtStakesId: number[] }[]
  ) => {
    try {
      await Promise.all(
        consumers.map(({ consumerId, boughtStakesId }) =>
          handleAbsence(boughtStakesId, false, consumerId)
        )
      );
    } catch (error) {
      setAlertProperties(getAlertBody(`Błąd masowego zgłaszania obecności`));
      setShowAlert(true);
    }
  };

  const handleTakeStack = async (boughtStakeId: number, courseId: number, taken: boolean) => {
    updateTakeStackLocally(boughtStakeId, courseId, taken);
    try {
      await apiTenantAdminBoughtStakesController('mark-course-as-taken').post('', {
        boughtStakeId,
        courseId,
        taken
      });
    } catch (error: any) {
      updateTakeStackLocally(boughtStakeId, courseId, !taken);
      setAlertProperties(getAlertBody(`odbioru: ${error.response.data.errorMessage}`));
      setShowAlert(true);
    }
  };

  const handleMassTakeStack = async (
    courses: { boughtStakeId: number; courseId: number; taken: boolean }[]
  ) => {
    try {
      await Promise.all(
        courses.map(({ courseId, boughtStakeId, taken }) =>
          handleTakeStack(boughtStakeId, courseId, taken)
        )
      );
    } catch (error) {
      setAlertProperties(getAlertBody(`Błąd masowego odbioru zamówień`));
      setShowAlert(true);
    }
  };

  const columns = [
    columnHelper.display({
      id: 'rowNumber',
      cell: (info) => <span>{info.row.index + 1}.</span>,
      header: 'Lp.'
    }),
    columnHelper.accessor('consumerName', {
      cell: (info) => {
        const { consumerName, consumerId } = info.row.original;
        const newPath = location.pathname.replace(
          'absences',
          `users/children/single-kid/${consumerId}/orders`
        );

        return (
          <p className="w-1/12 text-grayLight-900 font-medium text-sm underline underline-offset-4 py-3">
            <Link to={newPath}>{consumerName}</Link>
          </p>
        );
      },
      header: 'Imię i nazwisko'
    }),
    columnHelper.accessor('organisationName', {
      cell: (info) => {
        const { organisationName } = info.row.original;

        return <p className="w-12 text-grayLight-900 font-normal text-sm">{organisationName}</p>;
      },
      header: 'Klasa'
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const stakesAndCourses = info.row.original.stakesAndCourses;

        if (stakesAndCourses.length === 0) {
          return <p className="text-grayLight-900 font-normal text-sm"></p>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div
            className="flex flex-col justify-center "
            style={{ height: totalAssignedCourses * 50 + 'px' }}>
            {stakesAndCourses.map((item, stakeIndex) => (
              <div
                key={`stake-${stakeIndex}`}
                className={`flex flex-col justify-center ${stakeIndex !== stakesAndCourses.length - 1 ? 'border-b' : ''}`}
                style={{
                  height: `${((item.takenCourses.length || 1) / totalAssignedCourses) * 100}%`
                }}>
                <p className="text-grayLight-900 font-normal text-sm">
                  {item.stakeName} {item.takenCourses.length}
                </p>
              </div>
            ))}
          </div>
        );
      },
      header: 'Stawka i kategorie'
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { consumerId, stakesAndCourses } = info.row.original;

        if (stakesAndCourses.length === 0) {
          return <p className="w-1/12 text-grayLight-900 font-normal text-sm"></p>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div
            className="w-1/12 flex flex-col justify-center"
            style={{ height: totalAssignedCourses * 50 + 'px' }}>
            {stakesAndCourses.map((item, stakeIndex) => (
              <div
                key={`stake-${stakeIndex}`}
                className={`flex flex-col justify-center ${stakeIndex !== stakesAndCourses.length - 1 ? 'border-b' : ''}`}
                style={{
                  height: `${((item.takenCourses.length || 1) / totalAssignedCourses) * 100}%`
                }}>
                <Checkbox
                  colorScheme="customOrange"
                  isChecked={item.presence}
                  onChange={() => {
                    handleAbsence([item.boughtStakeId], !item.presence, consumerId);
                  }}
                />
              </div>
            ))}
          </div>
        );
      },
      header: 'Obecność'
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        if (stakesAndCourses.length === 0) {
          return <p className="text-grayLight-900 font-normal text-sm"></p>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div
            className="flex flex-col justify-center bg-red"
            style={{ height: totalAssignedCourses * 50 + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div
                key={`stake-${stakeIndex}`}
                className={`flex flex-col justify-center ${stakeIndex !== stakesAndCourses.length - 1 ? 'border-b' : ''}`}
                style={{
                  height: ((stake.takenCourses.length || 1) / totalAssignedCourses) * 100 + '%'
                }}>
                {stake.takenCourses.map((course) => (
                  <div
                    className="flex flex-col justify-center"
                    style={{ height: (1 / stakesAndCourses.length) * 100 + '%' }}>
                    <p className="text-grayLight-900 font-normal text-sm">{course.courseName}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        );
      },
      header: 'Kategorie posiłków'
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        if (stakesAndCourses.length === 0) {
          return <p className="w-1/12 text-grayLight-900 font-normal text-sm"></p>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div
            className="w-1/12 flex flex-col justify-center"
            style={{ height: totalAssignedCourses * 50 + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div
                key={`stake-${stakeIndex}`}
                className={`flex flex-col justify-center ${stakeIndex !== stakesAndCourses.length - 1 ? 'border-b' : ''}`}
                style={{
                  height: ((stake.takenCourses.length || 1) / totalAssignedCourses) * 100 + '%'
                }}>
                {stake.takenCourses.map((course) => (
                  <div
                    className="flex flex-col justify-center items-center"
                    style={{ height: (1 / stakesAndCourses.length) * 100 + '%' }}>
                    <Checkbox
                      colorScheme="customOrange"
                      isChecked={course.taken}
                      onChange={() => {
                        handleTakeStack(stake.boughtStakeId, course.courseId, !course.taken);
                      }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        );
      },
      header: 'Odbiór'
    })
  ];

  const fetchAbsences = async () => {
    try {
      setIsLoading(true);

      const formattedMonth = String(currentDate.month).padStart(2, '0');
      const formattedDay = String(currentDate.day).padStart(2, '0');

      const response: AxiosResponse<AbsenceInterface[]> = await apiAbsenceAndCourseController(
        'all-absences-for-day'
      ).post('', {
        tenantId,
        forWhen: `${currentDate.year}-${formattedMonth}-${formattedDay}`
      });

      setAbsencesForDay(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOrganizationUnits = async () => {
    try {
      setIsLoading(true);
      const response: AxiosResponse<OrganisationUnitInterface[]> =
        await apiTenantOrganisationUnitController('').get(`/${tenantId}`);
      const classes = response.data.map((organisation) => ({
        label: organisation.name,
        value: organisation.id
      }));

      setClasses(classes);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAbsences();
  }, [tenantId, currentDate]);

  useEffect(() => {
    fetchOrganizationUnits();
  }, [tenantId]);

  return (
    <section className="Absences p-spacing-xl w-full h-screen overflo-y-hidden">
      <header className="w-full flex items-center justify-between mb-spacing-4xl">
        <div className="flex flex-col basis-4/5">
          <Box>
            <Text
              whiteSpace="normal"
              overflow="hidden"
              textOverflow="ellipsis"
              className="font-semibold text-base text-grayLight-900">
              Zamówienia
            </Text>
          </Box>
          <Box>
            <Text
              whiteSpace="normal"
              overflow="hidden"
              textOverflow="ellipsis"
              className="font-normal text-sm text-grayLight-700">
              Tutaj możesz oznaczyć obecności i nieobecności użytkowników a także odebrane i
              nieodebrane posiłki.
            </Text>
          </Box>
        </div>
        <div className="flex gap-spacing-lg"></div>
      </header>

      <TableContainer h={750} overflowY="auto" className="Branches__table bg-white rounded-lg">
        <DataTable
          disableFilters={true}
          additionalFilters={
            <div className="w-full flex gap-2 items-center">
              <MultipleSelect
                options={classes}
                selected={selectedClasses}
                setSelected={setSelectedClasses}
                placeholder="Wybierz klasę"
              />
              <CustomChakraButton
                hierarchy="secondaryColor"
                iconPosition="right"
                size="md"
                buttonProps={{
                  onClick: () => {
                    const allCoursesToMassTaken = filteredAbsencesByClass.flatMap((consumer) =>
                      consumer.stakesAndCourses.flatMap((stake) =>
                        stake.takenCourses.map((course) => ({
                          taken: true,
                          boughtStakeId: stake.boughtStakeId,
                          courseId: course.courseId
                        }))
                      )
                    );
                    return handleMassTakeStack(allCoursesToMassTaken);
                  }
                }}>
                Odbierz
              </CustomChakraButton>
              <CustomChakraButton
                  hierarchy="secondaryColor"
                  iconPosition="right"
                  size="md"
                  buttonProps={{
                    onClick: () => {
                      const peopleToReportAbsence =
                          filteredAbsencesByClass
                              .map((consumer) => ({
                                consumerId: consumer.consumerId,
                                boughtStakesId: consumer.stakesAndCourses
                                  .filter((stake) => stake.presence)
                                  .map((stake) => stake.boughtStakeId),
                              }))
                              .filter((person) => person.boughtStakesId.length > 0);
                      return handleMassAbsence(peopleToReportAbsence);
                    }
                  }}>
                Nieobecność
              </CustomChakraButton>
            </div>
          }
          filterComponent={
            <div className="flex items-center gap-spacing-md">
              <div className="flex items-center gap-2">
                <span className="text-sm font-medium">Pokaż osoby:</span>
                <Checkbox
                  colorScheme="customOrange"
                  isChecked={showPeople.withOrderingStakes}
                  onChange={() =>
                    setShowPeople((prev) => ({
                      withoutOrderingStakes: false,
                      withOrderingStakes: !prev.withOrderingStakes
                    }))
                  }
                />
                <label className="text-sm font-medium">z zamówieniem</label>
                <Checkbox
                  colorScheme="customOrange"
                  isChecked={showPeople.withoutOrderingStakes}
                  onChange={() =>
                    setShowPeople((prev) => ({
                      withOrderingStakes: false,
                      withoutOrderingStakes: !prev.withoutOrderingStakes
                    }))
                  }
                />
                <label className="text-sm font-medium">bez zamówienia</label>
              </div>
              <ChooseMonths mode="day" currentDate={currentDate} setDate={setCurrentDate} />
            </div>
          }
          columns={columns}
          isLoading={isLoading}
          data={filteredAbsencesByClass}
        />
      </TableContainer>
    </section>
  );
};

export default Absences;
