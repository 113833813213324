import { TableContainer, Thead, Th, Box, Text, Checkbox, Tr, Flex } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useApi } from '../../../../../context/ApiProvider';
import { ParentInterface } from '../../../../../shared/type/parent.type';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import { DataTable } from '../../../../DataTable/DataTable';
import AddParent from './AddParent/AddParent';
import ImportParentsFromFilePopUp from './ImportParentsFromFilePopUp/ImportParentsFromFilePopUp';
import CustomAlert from "../../../../CustomAlert/CustomAlert";
import IconComponent from "../../../../IconComponent/IconComponent";
import CreateNewAdminThread from "../../../MailBox/CreateNewAdminThread/CreateNewAdminThread";

const ParentsTable = () => {
  const location = useLocation();
  const columnHelper = createColumnHelper<ParentInterface>();
  const {apiTenantParentController} = useApi();
  const {id: tenantId} = useParams();
  const [parents, setParents] = useState<ParentInterface[]>([]);
  const [selectedParents, setSelectedParents] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState<{
    showImportParentsFromFilePopUp: boolean;
    showAddParentPopUp: boolean;
    deleteParents: boolean;
  }>({
    showImportParentsFromFilePopUp: false,
    showAddParentPopUp: false,
    deleteParents: false
  });
  const [ sendMessagePopUp, setSendMessagePopUp] = useState<{
    show: boolean,
    userId: number,
  }>({
    show: false,
    userId: 0,
  });

  const handleShowPopUpValue = (name: string, value: boolean) => {
    setShowPopUp((prev) => ({...prev, [name]: value}));
  };

  const fetchParents = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<ParentInterface[]> = await apiTenantParentController(
          'parent-list'
      ).post(`/${tenantId}`, {});

      setParents(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const deleteParents = useCallback(
      (parentsToDelete: number[]) => {
        Promise.all(
            parentsToDelete.map(async (parentId) => {
              try {
                const response = await apiTenantParentController('').delete(`/${tenantId}/${parentId}`);
                if (response.status === 204) {
                  return parentId;
                }
              } catch (error) {
                console.error(`Błąd usuwania rodzica ${parentId}:`, error);
              }
            })
        ).then((deletedParentIds) => {
          const successfullyDeletedIds = deletedParentIds.filter((id) => id !== undefined);

          setSelectedParents((prev) => prev.filter((el) => !successfullyDeletedIds.includes(el)));
          fetchParents();
        });
      },
      [tenantId]
  );

  const columns = [
    columnHelper.display({
      id: 'select',
      header: () => {
        const isEmpty = parents.length === 0;
        const isAllSelected = !isEmpty && selectedParents.length === parents.length;

        return (
            <Checkbox
                onChange={() => {
                  if (isAllSelected) {
                    setSelectedParents([]);
                  } else {
                    setSelectedParents(parents.map((parent) => parent.id));
                  }
                }}
                isChecked={isAllSelected}
                isDisabled={isEmpty}
                colorScheme="customOrange"
            />
        );
      },
      cell: (info) => {
        const {id} = info.row.original;
        return (
            <Checkbox
                isChecked={selectedParents.includes(id)}
                onChange={() => {
                  setSelectedParents((prev) => {
                    if (prev.includes(id)) {
                      return prev.filter((el) => el !== id);
                    } else {
                      return [...prev, id];
                    }
                  });
                }}
                colorScheme="customOrange"
            />
        );
      }
    }),
    columnHelper.accessor('name', {
      cell: (info) => {
        const {name} = info.row.original;
        const {id} = info.row.original;
        const newPath = location.pathname.replace(
            'parents-table',
            `single-parent/${id}/list-of-orders`
        );
        return (
            <p className="text-grayLight-900 font-medium text-sm underline underline-offset-4">
              <Link to={newPath}>{name}</Link>
            </p>
        );
      },
      header: 'Nazwisko imię'
    }),
    columnHelper.accessor('name', {
      cell: (info) => {
        const {id} = info.row.original;

        return (
            <button
                onClick={() => {setSendMessagePopUp({show: true, userId: Number(id)})}}
                className='m-auto'>
              <IconComponent iconName='message' color='#475467'/>
            </button>
        );
      },
      header: ''
    }),
    columnHelper.accessor('login', {
      cell: (info) => {
        const login = info.getValue();

        return <p className="text-grayLight-900 font-medium text-sm">{login}</p>;
      },
      header: 'Adres email / login'
    }),
    columnHelper.accessor('phone', {
      cell: (info) => {
        const phone = info.getValue();

        return <p className="text-grayLight-900 font-normal text-sm">{phone ? phone : ''}</p>;
      },
      header: 'Telefon'
    }),
    columnHelper.accessor('children', {
      cell: (info) => {
        const children = info.getValue();

        const newPath = (kidId: number) =>
            location.pathname.replace('parents/parents-table', `children/single-kid/${kidId}/orders`);

        return (
            <ul>
              {children.map((kid) => (
                  <li className="text-grayLight-900 font-medium text-sm underline underline-offset-4 pt-1 pb-1">
                    <Link to={newPath(kid.id)}>
                      {kid.lastName} {kid.firstName}
                    </Link>
                  </li>
              ))}
            </ul>
        );
      },
      header: 'Przypisane dzieci'
    }),
    columnHelper.accessor('bankAccountPresent', {
      cell: (info) => {
        const bankAccountNumber = info.getValue();

        return (
            <p className="text-grayLight-900 font-normal text-sm">
              {bankAccountNumber ? 'tak' : 'nie'}
            </p>
        );
      },
      header: 'Numer konta'
    }),
    columnHelper.accessor('creationDate', {
      cell: (info) => {
        const creationDate = info.getValue();

        return (
            <p className="text-grayLight-900 font-normal text-sm">
              {creationDate}
            </p>
        );
      },
      header: 'Data stworzenia'
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Edycja',
      cell: (info) => {
        const {id} = info.row.original;

        const newPath = location.pathname.replace(
            'parents-table',
            `single-parent/${id}/list-of-orders`
        );

        return (
            <div className="flex gap-spacing-sm">
              <Link to={newPath}>
                <button className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none">
                  Otwórz
                </button>
              </Link>
            </div>
        );
      }
    })
  ];

  useEffect(() => {
    fetchParents();
  }, [tenantId]);


  return (
      <>
        <section className="ParentsTable p-spacing-xl w-full pb-28">
          <Flex direction="column" className="Branches__table bg-white rounded-lg">
            <TableContainer h="100%" flex="1" minH="500px">
              <DataTable
                  noVerticalLineIndices={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
                  disableFilters={true}
                  filterComponent={
                    <CustomChakraButton
                        buttonProps={{
                          disabled: selectedParents.length === 0,
                          onClick: () => {
                            setShowPopUp((prev) => ({...prev, deleteParents: true}))
                          }
                        }}
                        size="sm"
                        hierarchy="tertiaryColor"
                        iconPosition="onlyText">
                      Usuń zaznaczone
                    </CustomChakraButton>
                  }
                  extraThead={
                    <Thead>
                      <Tr>
                        <Th colSpan={columns.length + 1}>
                          <div className="w-full flex items-center justify-between">
                            <div className="flex flex-col basis-4/5">
                              <Box>
                                <Text
                                    whiteSpace="normal"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    className="font-semibold text-base normal-case text-grayLight-900">
                                  Rodzice
                                </Text>
                              </Box>
                              <Box>
                                <Text
                                    whiteSpace="normal"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    className="font-normal text-sm normal-case text-grayLight-700">
                                  Lista rodziców
                                </Text>
                              </Box>
                            </div>
                            <div className="flex gap-spacing-lg">
                              <CustomChakraButton
                                  size="md"
                                  hierarchy="linkGray"
                                  iconPosition="left"
                                  icon="import"
                                  buttonProps={{
                                    onClick: () => {
                                      handleShowPopUpValue('showImportParentsFromFilePopUp', true);
                                    }
                                  }}>
                                Importuj listę
                              </CustomChakraButton>
                              <CustomChakraButton
                                  size="md"
                                  hierarchy="primary"
                                  iconPosition="left"
                                  icon="plus"
                                  buttonProps={{
                                    onClick: () => {
                                      handleShowPopUpValue('showAddParentPopUp', true);
                                    }
                                  }}>
                                Dodaj rodzica
                              </CustomChakraButton>
                            </div>
                          </div>
                        </Th>
                      </Tr>
                    </Thead>
                  }
                  columns={columns}
                  isLoading={isLoading}
                  data={parents}
              />
            </TableContainer>
          </Flex>
        </section>
        {showPopUp.showImportParentsFromFilePopUp ? (
            <ImportParentsFromFilePopUp
                isOpen={showPopUp.showImportParentsFromFilePopUp}
                onClose={() => {
                  handleShowPopUpValue('showImportParentsFromFilePopUp', false);
                  fetchParents();
                }}
            />
        ) : null}
        {showPopUp.showAddParentPopUp ? (
            <AddParent
                isOpen={showPopUp.showAddParentPopUp}
                onClose={() => {
                  handleShowPopUpValue('showAddParentPopUp', false);
                  fetchParents();
                }}
            />
        ) : null}
        {sendMessagePopUp.show && (
            <CreateNewAdminThread
              onClose={() => {setSendMessagePopUp({show: false, userId: 0})}}
              isOpen={sendMessagePopUp.show}
              initialTargetUserId={sendMessagePopUp.userId}
            />
        )}
        <CustomAlert
            handleOpen={showPopUp.deleteParents}
            icon={
              <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
                <IconComponent iconName="trash"/>
              </div>
            }
            header={`Usunąć zaznaczonych rodziców?`}
            content={
              <div className='flex flex-col gap-spacing-xl'>
                <p className="font-normal text-sm">Usunięcie rodziców jest nieodwracalne i wiążę się z utratą
                  danych.</p>
                <ul className="list-inside text-sm text-grayLight-900">
                  {parents
                      .filter((parent) => selectedParents.includes(parent.id))
                      .map((parent) => (
                          <li>
                            <Checkbox
                                key={parent.id}
                                isChecked={selectedParents.includes(parent.id)}
                                onChange={() => {
                                  setSelectedParents((prev) =>
                                      prev.includes(parent.id)
                                          ? prev.filter((id) => id !== parent.id)
                                          : [...prev, parent.id]
                                  );
                                }}
                                colorScheme="customOrange"
                                className="text-sm text-grayLight-900"
                            >
                              {parent.name}
                            </Checkbox>
                          </li>
                      ))}
                </ul>
              </div>
            }
            cancelButtonText="Anuluj"
            cancelButton={{
              iconPosition: 'onlyText',
              size: 'lg',
              hierarchy: 'secondaryGray'
            }}
            confirmButtonText="Usuń"
            confirmButton={{
              iconPosition: 'onlyText',
              size: 'lg',
              hierarchy: 'primary'
            }}
            onCancelAction={() => {
              setShowPopUp((prev) => ({...prev, deleteParents: false}));
            }}
            onConfirmAction={() => {
              deleteParents(selectedParents);
              setShowPopUp((prev) => ({...prev, deleteParents: false}));
            }}
        />
      </>
  );
};

export default ParentsTable;
