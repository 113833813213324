import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import { HttpStatusCode, AxiosResponse } from "axios";
import { Select } from "chakra-react-select";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import { RecipientInterface } from "../../../../shared/type/recipient.type";
import CustomChakraButton from "../../../CustomChakraButton/CustomChakraButton";
import FormInput from "../../../Forms/FormInput/FormInput";

interface Props {
  isOpen: boolean,
  onClose: () => void,
  initialTargetUserId?: number,
}

const CreateNewAdminThread = ({ isOpen, onClose, initialTargetUserId }: Props) => {
  const { apiTenantUserController, apiTenantMessageController } = useApi();
  const { id: tenantId } = useParams();
  const [ recipients, setRecipients ] = useState<RecipientInterface[]>()
  const { setShow, setAlertProperties } = useAlertContext();

  const [message, setMessage] = useState<{
    tenantId: number
    targetUserId: number,
    topic: string,
    content: string;
  }>({
    tenantId: Number(tenantId),
    targetUserId: 0,
    topic: '',
    content: ''
  });

  const fetchRecipients = async () => {
    try {
      const response: AxiosResponse<RecipientInterface[]> = await apiTenantUserController('simple-users').get(`/${tenantId}`);

      setRecipients(response.data)
    } catch (error) {

    }
  }

  const confirmMessage = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      await apiTenantMessageController('create-thread').post('', message);
      if (HttpStatusCode.Ok) {
        setAlertProperties({
          status: 'success',
          timeout: 9000,
          description: 'Poprawnie wysłano wiadomość',
          title: 'Sukces'
        });
        onClose();
      }
    } catch (error) {
      setAlertProperties({
        status: 'error',
        timeout: 9000,
        description: 'Błąd wysłania wiadomości',
        title: 'Błąd'
      });
    } finally {
      setShow(true);
    }
  };

  useEffect(() => {
    fetchRecipients()
  }, []);

  useEffect(() => {
    if(initialTargetUserId){
      setMessage((prev) => ({...prev, targetUserId: initialTargetUserId}))
    }
  }, []);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
      <form onSubmit={confirmMessage}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col gap-spacing-xl">
            <h2 className="font-semibold text-lg text-grayLight-900">Nowa wiadomość</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-xl">

            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">Do</label>
              <Select
                placeholder="Wybierz adresata"
                isRequired
                onChange={(option) => {
                  setMessage((prev) => ({
                    ...prev,
                    targetUserId: option ? option.id : 0
                  }));
                }}
                value={recipients?.find(recipient => recipient.id === message.targetUserId)}
                // value={parentDashboardContext?.tenants.find(tenant => tenant.tenantId === message.tenantId)}
                getOptionLabel={(recipient) => recipient.firstName + ' ' + recipient.lastName}
                getOptionValue={(recipient) => recipient.id.toString()}
                options={recipients}
              />
            </div>

            <div className="flex flex-col gap-spacing-md">
              <FormInput
                label="Temat"
                inputParams={{
                  required: true,
                  onChange: (e) => {
                    setMessage((prev) => ({ ...prev, topic: e.target.value }));
                  }
                }}
              />
            </div>

            <div className="flex flex-col gap-spacing-md">
              <textarea placeholder='Napisz wiadomość' id="" cols={30} rows={10}
                        required
                        onChange={(e) => {
                          setMessage((prev) => ({ ...prev, content: e.target.value }));
                        }}
                        className='resize-none gap-spacing-3xl rounded-md border pt-2.5 pr-3.5 pb-2.5 pl-3.5 border-grayLight-300'/>
            </div>

          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "submit"
              }}
            >
              Wyślij
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default CreateNewAdminThread;
