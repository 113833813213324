import { AxiosResponse } from 'axios';
import React, {
  ReactNode,
  useState,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import { OrderForChildren } from '../shared/type/orderForParent.type';
import { ParentOrderContextInterface } from '../shared/type/parentOrderContext.type';
import { useApi } from './ApiProvider';
import { useParentBasketContext } from './ParentBasketProvider';

interface Props {
  children: ReactNode;
}

interface ParentOrderContextDateInterface {
  year: number;
  month: number;
}

interface ParentOrderContextProps {
  parentOrderContext: ParentOrderContextInterface | undefined;
  setParentOrderContextDate: React.Dispatch<React.SetStateAction<ParentOrderContextDateInterface>>;
  parentOrderContextDate: ParentOrderContextDateInterface;
  tenantId: number | undefined;
  setTenantId: React.Dispatch<React.SetStateAction<number | undefined>>;
  isParentOrderLoading: boolean;
  setLoadMeals: React.Dispatch<React.SetStateAction<boolean>>;
  setSkipFetchingParentOrderContext: React.Dispatch<React.SetStateAction<boolean>>;
  setUserId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const ParentOrderContextContext = createContext<ParentOrderContextProps | undefined>(undefined);

export const useParentOrderContextContext = () => {
  const context = useContext(ParentOrderContextContext);
  if (!context) {
    throw new Error(
      'useParentOrderContextContext must be used within a ParentOrderContextProvider'
    );
  }
  return context;
};

export const ParentOrderContextProvider = ({ children }: Props) => {
  const today = new Date();
  const { apiOrderController } = useApi();
  const [isParentOrderLoading, setIsParentOrderLoading] = useState(false);
  const { setUserData, addItemToBasket } = useParentBasketContext();
  const [parentOrderContext, setParentOrderContext] = useState<ParentOrderContextInterface>();
  const [tenantId, setTenantId] = useState<number | undefined>(undefined);
  const [loadMeals, setLoadMeals] = useState(false);
  const [skipFetchingParentOrderContext, setSkipFetchingParentOrderContext] = useState(true);
  const [userId, setUserId] = useState<number | undefined>();
  const [skipDateInRequest, setSkipDateInRequest] = useState(true);
  const [parentOrderContextDate, setParentOrderContextDate] =
    useState<ParentOrderContextDateInterface>({
      year: today.getFullYear(),
      month: today.getMonth() + 1
    });

  const fetchParentOrderContext = useCallback(async () => {
    try {
      setIsParentOrderLoading(true);
      const requestBody: any = {
        tenantId,
        userId,
        loadMeals
      };

      if (!skipDateInRequest) {
        requestBody.year = parentOrderContextDate.year;
        requestBody.month = parentOrderContextDate.month;
      }

      const response: AxiosResponse<ParentOrderContextInterface> = await apiOrderController(
        'parent-order-context'
      ).post('', requestBody);

      setParentOrderContext(response.data);
      setUserData(
        response.data.consumerOrderContexts.map((consumer) => ({
          consumerId: consumer.consumerId,
          saldo: consumer.saldo,
          creditLimit: consumer.creditLimit,
          provision: consumer.provision
        }))
      );

      response.data.consumerOrderContexts.forEach((consumer) => {
        consumer.orderDays.forEach((orderDay) => {
          orderDay.purchasableItems?.forEach((item) => {
            if (item.orderCount > 1) {
              const newOrder: OrderForChildren = {
                tenantId: consumer.tenantId,
                consumerId: consumer.consumerId,
                useCredit: false,
                orderedItems: [
                  {
                    purchasableItem: item,
                    count: item.orderCount,
                    when: orderDay.when
                  }
                ]
              };
              addItemToBasket(newOrder);
            }
          });
        });
      });
    } catch (error) {
      console.error('Error fetching parent order context:', error);
    } finally {
      setIsParentOrderLoading(false);
      setSkipDateInRequest(false);
    }
  }, [tenantId, userId, loadMeals, parentOrderContextDate, setUserData, skipDateInRequest]);

  useEffect(() => {
    if (tenantId && !skipFetchingParentOrderContext) {
      fetchParentOrderContext();
    }
  }, [tenantId, skipFetchingParentOrderContext, parentOrderContextDate]);

  const contextValue = useMemo(
    () => ({
      parentOrderContext,
      setParentOrderContextDate,
      fetchParentOrderContext,
      tenantId,
      setTenantId,
      isParentOrderLoading,
      setLoadMeals,
      setSkipFetchingParentOrderContext,
      setUserId,
      parentOrderContextDate
    }),
    [
      parentOrderContext,
      setParentOrderContextDate,
      fetchParentOrderContext,
      tenantId,
      setTenantId,
      isParentOrderLoading,
      setLoadMeals,
      setSkipFetchingParentOrderContext,
      parentOrderContextDate
    ]
  );

  return (
    <ParentOrderContextContext.Provider value={contextValue}>
      {children}
    </ParentOrderContextContext.Provider>
  );
};
