import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import { HttpStatusCode } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../context/AlertProvider";
import { useApi } from "../../../../../context/ApiProvider";
import { CoFinancingInstitutionInterface } from "../../../../../shared/type/coFinancingInstitution.type";
import handlePhoneNumberChange from "../../../../../utils/handlePhoneNumberChange";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import CustomInput from "../../../../CustomInput/CustomInput";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  mode: 'add' | 'edit';
  initData?: CoFinancingInstitutionInterface;
}

const AddEditCoFinancialInstitution = ({ isOpen, onClose, mode, initData }: Props) => {
  const {id: tenantId} = useParams<{id: string}>();
  const { setAlertProperties, setShow } = useAlertContext();
  const [coFinancialInstitution, setCoFinancialInstitution] = useState<CoFinancingInstitutionInterface>({} as CoFinancingInstitutionInterface);
  const { apiTenantCoFinancingInstitutionController } = useApi();

  useEffect(() => {
    if (mode === 'edit' && initData) {
      setCoFinancialInstitution(initData);
    }
  }, [tenantId, mode, initData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCoFinancialInstitution((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (mode === 'add') {
      try{
        const response = await apiTenantCoFinancingInstitutionController('').post('', {
          tenantId: Number(tenantId),
          name: coFinancialInstitution.name,
          street: coFinancialInstitution.street,
          city: coFinancialInstitution.city,
          postcode: coFinancialInstitution.postcode,
          phone: coFinancialInstitution.phone,
          mail: coFinancialInstitution.mail,
          nip: coFinancialInstitution.nip
        })
        if(response.status === HttpStatusCode.Ok){
          setAlertProperties({
            timeout: 9000,
            title: "Sukces",
            description: "Poprawnie dodano instytucję finansową",
            status: "success"
          });
          setShow(true);
          onClose();
        }
      }  catch (error: any) {
        console.error(error);
        setAlertProperties({
          timeout: 9000,
          title: "Błąd",
          description: `Błąd dodania instytucji: ${error.response.data.violations[0].message}`,
          status: "error"
        });
        setShow(true);
      }
    } else if (mode === 'edit') {
      try {
        const response = await apiTenantCoFinancingInstitutionController('').put(`/${coFinancialInstitution.id}`, {
          tenantId: Number(tenantId),
          name: coFinancialInstitution.name,
          street: coFinancialInstitution.street,
          city: coFinancialInstitution.city,
          postcode: coFinancialInstitution.postcode,
          phone: coFinancialInstitution.phone,
          mail: coFinancialInstitution.mail,
          nip: coFinancialInstitution.nip
        })
        if(response.status === HttpStatusCode.Ok){
          setAlertProperties({
            timeout: 9000,
            title: "Sukces",
            description: "Poprawnie edytowano instytucję finansową",
            status: "success"
          });
          setShow(true);
          onClose();
        }
      }  catch (error: any) {
        console.error(error);
        setAlertProperties({
          timeout: 9000,
          title: "Błąd",
          description: `Błąd edytowania instytucji ${error.response.data.violations}`,
          status: "error"
        });
        setShow(true);
      }
    }
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <form onSubmit={handleSubmit}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex">
            <div className='basis-5/12 flex flex-col text-grayLight-900 font-semibold text-lg border-r border-grayLight-200'>
              <h2 className="font-semibold text-lg">{mode === 'add' ? 'Dodaj dofinansowanie' : 'Edytuj dane'}</h2>
              <p className='text-grayLight-600 text-sm font-normal'></p>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col gap-spacing-3xl">
            <CustomInput type="default" size="md" destructive={false} label="Nazwa Instytucji dofinansowującej" inputParams={{
              required: true,
              minLength: 3,
              name: 'name',
              value: coFinancialInstitution?.name,
              onChange: handleInputChange,
              placeholder: "Podaj nazwę instytucji dofinansowującej",
            }} />
            <CustomInput type="default" size="md" destructive={false} label="NIP" inputParams={{
              required: true,
              minLength: 10,
              maxLength: 10,
              name: 'nip',
              value: coFinancialInstitution?.nip,
              placeholder: "00 000 000 0",
              onChange: handleInputChange,
            }} />
            <CustomInput type="default" size="md" destructive={false} label="Ulica" inputParams={{
              required: true,
              minLength: 3,
              name: 'street',
              value: coFinancialInstitution?.street,
              placeholder: "Wpisz adres instytucji",
              onChange: handleInputChange,
            }} />
            <div className="flex justify-between gap-spacing-xl">
              <CustomInput
                type="default"
                size="md"
                destructive={false}
                label="Kod"
                inputParams={{
                  required: true,
                  minLength: 6,
                  maxLength: 6,
                  name: 'postcode',
                  value: coFinancialInstitution?.postcode,
                  placeholder: "00-000",
                  onChange: (e) => {
                    setCoFinancialInstitution((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value
                    }));
                  }
              }}
              />
              <CustomInput type="default" size="md" destructive={false} label="Miasto" inputParams={{
                required: true,
                minLength: 3,
                name: 'city',
                value: coFinancialInstitution?.city,
                placeholder: "Wpisz miasto",
                onChange: handleInputChange,
              }} />
            </div>
            <CustomInput type="default" size="md" destructive={false} label="Numer telefonu" inputParams={{
              required: true,
              minLength: 11,
              maxLength: 11,
              onChange: (e) => {
                setCoFinancialInstitution((prev) => ({ ...prev, [e.target.name]: handlePhoneNumberChange(e.target.value) }));
              },
              name: 'phone',
              value: coFinancialInstitution?.phone,
              placeholder: "000-000-000",
            }} />
            <CustomInput type="default" size="md" destructive={false} label="Adres email" inputParams={{
              required: true,
              minLength: 3,
              type: 'email',
              name: 'mail',
              value: coFinancialInstitution?.mail,
              placeholder: "Wpisz adres email instytucji",
              onChange: handleInputChange,
            }} />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                type: 'button',
                onClick: () => {onClose()}
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                type: 'submit'
              }}
            >
              {mode === 'add' ? 'Dodaj' : 'Zapisz'}
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddEditCoFinancialInstitution;
