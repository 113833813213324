import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAlertContext } from '../../../../context/AlertProvider';
import { useApi } from '../../../../context/ApiProvider';
import CustomAlert from '../../../CustomAlert/CustomAlert';
import IconComponent from '../../../IconComponent/IconComponent';

interface Props {
  isOpen: boolean;
  setDeleteMessagePopUp: React.Dispatch<
    React.SetStateAction<{ isOpen: boolean; threadId: number }>
  >;
  threadId: number;
  onFinish: () => void;
}

export const defaultDeleteMessagePopUpContext = {
  isOpen: false,
  threadId: 0
};

const DeleteMessageAlert = ({ isOpen, threadId, setDeleteMessagePopUp, onFinish }: Props) => {
  const { t } = useTranslation();
  const { apiParentMessageController } = useApi();
  const { setShow, setAlertProperties } = useAlertContext();

  return (
    <CustomAlert
      handleOpen={isOpen}
      icon={
        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
          <IconComponent iconName="trash" color="#D92D20" />
        </div>
      }
      //@ts-ignore
      header={t('parent_mailbox.delete_message_alert.header')}
      //@ts-ignore
      content={t('parent_mailbox.delete_message_alert.content')}
      //@ts-ignore
      cancelButtonText={t('parent_mailbox.delete_message_alert.cancel')}
      cancelButton={{
        iconPosition: 'onlyText',
        size: 'lg',
        hierarchy: 'secondaryGray'
      }}
      //@ts-ignore
      confirmButtonText={t('parent_mailbox.delete_message_alert.confirm')}
      confirmButton={{
        iconPosition: 'onlyText',
        size: 'lg',
        hierarchy: 'warning'
      }}
      onCancelAction={() => {
        setDeleteMessagePopUp(defaultDeleteMessagePopUpContext);
      }}
      onConfirmAction={async () => {
        try {
          await apiParentMessageController('thread').delete('', {
            params: {
              threadId: threadId
            }
          });
          setAlertProperties({
            status: 'success',
            timeout: 9000,
            /* @ts-ignore */
            description: t('parent_mailbox.delete_message_alert.success_description'),
            /* @ts-ignore */
            title: t('parent_mailbox.delete_message_alert.success_title')
          });
          setShow(true);
        } catch (error) {
          setAlertProperties({
            status: 'error',
            timeout: 9000,
            /* @ts-ignore */
            description: t('parent_mailbox.delete_message_alert.error_description'),
            /* @ts-ignore */
            title: t('parent_mailbox.delete_message_alert.error_title')
          });
          setShow(true);
        } finally {
          onFinish();
          setDeleteMessagePopUp(defaultDeleteMessagePopUpContext);
        }
      }}
    />
  );
};

export default DeleteMessageAlert;
