import React from "react";
import { useEditAdministratorContext } from "../../../../../../context/EditAdministratorProvider";
import handlePhoneNumberChange from "../../../../../../utils/handlePhoneNumberChange";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import FormInput from "../../../../../Forms/FormInput/FormInput";
import {Spinner} from "@chakra-ui/react";

const AdministratorData = () => {
  const {
      editButtonState,
      setEditButtonState,
      adminEditContext,
      setAdminEditContext,
      fetchEditAdministratorContext,
      updateEmailPassword,
      setNewPassword
  } = useEditAdministratorContext();

    if (!adminEditContext) {
        return <Spinner/>;
    }

    const { firstName, lastName, phone, login } = adminEditContext.adminUserDto;

  return (
    <div className="AdministratorSettings__formContainer pt-spacing-xl flex flex-col gap-spacing-5xl">
      <div className='flex'>
        <div className='basis-3/5 flex flex-col gap-spacing-3xl'>
          <FormInput
            label="Imię"
            placeholder="Podaj imię"
            inputParams={{
              value: firstName,
              required: true,
              minLength: 3,
              onChange: (e) => {
                  setAdminEditContext((prev) => {
                      if (!prev) return prev;

                      return {
                          ...prev,
                          adminUserDto: {
                              ...prev.adminUserDto,
                              firstName: e.target.value,
                          },
                      };
                  });
                  setEditButtonState((prev) => ({...prev, isTopButtonActive: true}));
              }
            }}
          />
          <FormInput
            label="Nazwisko"
            placeholder="Podaj nazwisko"
            inputParams={{
              value: lastName,
              required: true,
              minLength: 3,
              onChange: (e) => {
                  setAdminEditContext((prev) => {
                      if (!prev) return prev;

                      return {
                          ...prev,
                          adminUserDto: {
                              ...prev.adminUserDto,
                              lastName: e.target.value,
                          },
                      };
                  });
                setEditButtonState((prev) => ({...prev, isTopButtonActive: true}));
              }
            }}
          />
          <FormInput
            label="Numer telefonu"
            placeholder="Podaj numer telefonu"
            inputParams={{
              value: phone || '',
              required: true,
              maxLength: 11,
              onChange: (e) => {
                  setAdminEditContext((prev) => {
                      if (!prev) return prev;

                      return {
                          ...prev,
                          adminUserDto: {
                              ...prev.adminUserDto,
                              phone: handlePhoneNumberChange(e.target.value),
                          },
                      };
                  });
                setEditButtonState((prev) => ({...prev, isTopButtonActive: true}));
              }
            }}
          />
        </div>
        <div className="basis-2/5 flex items-start justify-end">
          <div className="AdministratorSettings__actionButtons flex gap-spacing-lg">
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="tertiaryColor"
              size="md"
              buttonProps={{
                  type: 'button',
                  onClick: fetchEditAdministratorContext,
                  disabled: !editButtonState.isTopButtonActive,
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="primary"
              size="md"
              buttonProps={{
                type: 'submit',
                disabled: !editButtonState.isTopButtonActive,
              }}
            >
              Zapisz zmiany
            </CustomChakraButton>
          </div>
        </div>
      </div>
      <hr className="bg-grayLight-200" />
      <div className="flex">
        <div className="basis-3/5 flex flex-col gap-spacing-3xl">
          <FormInput
            label="Adres email"
            placeholder="Podaj email"
            inputParams={{
              onChange: (e) => {
                  setAdminEditContext((prev) => {
                      if (!prev) return prev;

                      return {
                          ...prev,
                          adminUserDto: {
                              ...prev.adminUserDto,
                              login: e.target.value,
                          },
                      };
                  });
                  setEditButtonState((prev) => ({...prev, isBottomButtonActive: true}));
              },
              value: login,
              minLength: 3,
              autoComplete: "off",
            }}
          />
          <FormInput
            label="Hasło"
            placeholder="******"
            bottomElement={
              <p className="text-sm font-normal text-grayLight-500">
                Użytkownik zmienił hasło tymczasowe, nie masz już podglądu hasła.
              </p>
            }
            inputParams={{
              type: "password",
              minLength: 5,
              autoComplete: 'new-password',
              onChange: (e) => {
                  setNewPassword(e.target.value);
                  setEditButtonState((prev) => ({...prev, isBottomButtonActive: true}));
              }
            }}
          />
        </div>
        <div className='basis-2/5'>
          <div className="basis-2/5 flex items-start justify-end">
            <div className="AdministratorSettings__actionButtons flex gap-spacing-lg">
              <CustomChakraButton
                iconPosition="onlyText"
                hierarchy="tertiaryColor"
                size="md"
                buttonProps={{
                  type: 'button',
                  disabled: !editButtonState.isBottomButtonActive,
                }}
              >
                Anuluj
              </CustomChakraButton>
              <CustomChakraButton
                iconPosition="onlyText"
                hierarchy="primary"
                size="md"
                buttonProps={{
                    onClick: updateEmailPassword,
                    type: 'button',
                    disabled: !editButtonState.isBottomButtonActive,
                }}
              >
                Zapisz zmiany
              </CustomChakraButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AdministratorData;