import { useTranslation } from 'react-i18next';
import IconComponent from '../../IconComponent/IconComponent';

const EmptyBasket = ({ useTranslations }: { useTranslations?: boolean }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-spacing-xl items-center">
      <div>
        <div className="w-12 h-12 bg-brand-300 rounded-full flex items-center justify-center">
          <IconComponent iconName="basket" color="#101828" className="#101828" />
        </div>
      </div>
      <div className="flex flex-col gap-spacing-xs items-center">
        <p className="text-grayLight-900 font-semibold" style={{ fontSize: '16px' }}>
          {useTranslations
            ? //@ts-ignore
              t('basket.empty_basket.title')
            : 'Twój koszyk jest pusty'}
        </p>
        <p className="text-grayLight-600 text-sm text-center font-normal">
          {useTranslations
            ? //@ts-ignore
              t('basket.empty_basket.subtitle1')
            : 'Dodaj do koszyka interesujące Cię posiłki.'}{' '}
          <br />
          {useTranslations
            ? //@ts-ignore
              t('basket.empty_basket.subtitle2')
            : 'Wybrane pozycje pojawią się na liście.'}
        </p>
      </div>
    </div>
  );
};

export default EmptyBasket;
