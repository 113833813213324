import { OrderedItemInterface } from '../../../../../../../../../../shared/type/orderForParent.type';
import { groupedOrders } from '../../../../../../../../../../utils/calendarOrderHelper';
import EmptyBasket from '../../../../../../../../../Basket/EmptyBasket/EmptyBasket';
import IconComponent from '../../../../../../../../../IconComponent/IconComponent';
import calculatePrice from '../../../../../../../../../../utils/calculators/calculatePrice';
import { BasicKidInterface } from '../../../../../../../../../../shared/type/kid.type';

import { useBasketForCashOrder } from './useBasketForCashOrder';
import CustomChakraButton from '../../../../../../../../../CustomChakraButton/CustomChakraButton';

type BasketForCashOrderProps = {
  parentKids?: BasicKidInterface[];
  showMobile?: boolean;
};

export const BasketForCashOrder = ({ parentKids, showMobile }: BasketForCashOrderProps) => {
  const {
    handleQuantityChange,
    handleDelete,
    clearBasket,
    toggleCollapse,
    collapsedConsumers,
    isEmpty,
    parentBasketContext,
    calculatedPayment,
    onSummaryClick
  } = useBasketForCashOrder();

  return (
    <div
      className={`flex flex-col gap-spacing-xl w-full h-full overflow-y-scroll no-scrollbar bg-white box-border border-l shadow-custom-heavy rounded-2xl`}>
      <div className="Basket flex flex-col gap-spacing-xl w-full h-full pt-spacing-xl pr-spacing-xl pb-spacing-4xl pl-spacing-xl">
        <div className="Basket__contextContainer flex flex-col grow gap-spacing-lg bg-white">
          <div className="Basket__header flex justify-between">
            <p className="font-semibold text-grayLight-900" style={{ fontSize: '18px' }}>
              Koszyk
            </p>
            {!isEmpty && (
              <button
                className="flex gap-spacing-sm items-center"
                type="button"
                onClick={clearBasket}>
                <IconComponent iconName="xClose" color="#B42318" />
                <p className="text-error-700 font-semibold text-sm">Wyczyść koszyk</p>
              </button>
            )}
          </div>

          <div
            className={`Basket__context grow w-full flex flex-col ${isEmpty ? 'justify-center items-center' : 'justify-start'} self-center`}>
            {isEmpty && <EmptyBasket />}

            {!isEmpty &&
              groupedOrders(parentBasketContext) &&
              Object.keys(groupedOrders(parentBasketContext)).map((consumerId) => {
                const consumer = parentKids?.find((consumer) => consumer.id === Number(consumerId));
                const isCollapsed = collapsedConsumers[parseInt(consumerId)];

                const paymentData = calculatedPayment?.consumerCalculations?.find(
                  (item) => item.consumerId === parseInt(consumerId)
                );

                return (
                  <div
                    key={consumerId}
                    style={{ position: 'relative' }}
                    className="flex flex-col gap-spacing-xl pt-spacing-4xl pb-spacing-xl">
                    <div className="flex items-center justify-between gap-spacing-md">
                      <div
                        className="flex items-center text-grayLight-700 font-medium gap-spacing-md"
                        style={{ fontSize: '16px' }}>
                        <p>{consumer && `${consumer.firstName} ${consumer.lastName}`}</p>
                      </div>

                      <div className="flex items-center gap-spacing-md">
                        <p
                          className="font-semibold text-grayLight-900"
                          style={{ fontSize: '16px' }}>
                          suma: {calculatePrice(paymentData?.calculationDto.basicToPay, 'zł')}
                        </p>
                        <button type="button" onClick={() => toggleCollapse(parseInt(consumerId))}>
                          <div className="rotate-90">
                            <IconComponent
                              iconName={isCollapsed ? 'arrowRight' : 'arrowRight'}
                              color="#667085"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                    {!isCollapsed && (
                      <div className="flex flex-col gap-spacing-xl">
                        {Object.entries(
                          groupedOrders(parentBasketContext)[parseInt(consumerId)].reduce(
                            (acc, item) => {
                              const dateKey = new Date(item.when).toLocaleDateString();
                              if (!acc[dateKey]) acc[dateKey] = [];
                              acc[dateKey].push(item);
                              return acc;
                            },
                            {} as { [key: string]: OrderedItemInterface[] }
                          )
                        )
                          .sort(([dateA], [dateB]) => {
                            const dateObjA = new Date(dateA.split('.').reverse().join('-'));
                            const dateObjB = new Date(dateB.split('.').reverse().join('-'));
                            return dateObjA.getTime() - dateObjB.getTime();
                          })
                          .map(([date, items], index) => (
                            <div key={index} className="flex flex-col gap-spacing-md">
                              <p className="text-grayLight-700 text-sm font-normal">
                                {(() => {
                                  const dateObj = new Date(date.split('.').reverse().join('-'));
                                  const weekday = dateObj.toLocaleDateString('pl-PL', {
                                    weekday: 'long'
                                  });
                                  return `${weekday.charAt(0).toUpperCase()}${weekday.slice(1)} (${date})`;
                                })()}
                              </p>
                              {items.map((item: OrderedItemInterface, itemIndex: number) => (
                                <div key={itemIndex} className="flex flex-col gap-spacing-sm">
                                  <div className="flex justify-between border-b box-border border-grayLight-200">
                                    <p
                                      className="text-grayLight-900 font-medium"
                                      style={{ fontSize: '16px' }}>
                                      {item.purchasableItem.name}
                                    </p>
                                    <div className="flex gap-spacing-md">
                                      {item.purchasableItem.originalPrice !==
                                        item.purchasableItem.price && (
                                        <p
                                          className="text-grayLight-500 font-medium line-through"
                                          style={{ fontSize: '16px' }}>
                                          {calculatePrice(item.purchasableItem.originalPrice, null)}
                                        </p>
                                      )}
                                      <p
                                        className="text-grayLight-900 font-semibold"
                                        style={{ fontSize: '16px' }}>
                                        {calculatePrice(item.purchasableItem.price, null)}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex justify-between pt-spacing-md pb-spacing-md">
                                    <button
                                      onClick={() => handleDelete(parseInt(consumerId), item)}
                                      type="button"
                                      className="flex gap-spacing-sm items-center">
                                      <IconComponent iconName="xClose" color="#475467" />
                                      <p className="text-sm font-semibold text-grayLight-600">
                                        Usuń
                                      </p>
                                    </button>
                                    <div className="flex items-center pt-2.5 pr-3.5 pb-2.5 pl-3.5 border box-border border-grayLight-300 rounded-md gap-spacing-md">
                                      <button
                                        disabled={item.count === 1}
                                        type="button"
                                        onClick={() =>
                                          handleQuantityChange(parseInt(consumerId), item, false)
                                        }>
                                        <IconComponent iconName="minus" color="#667085" />
                                      </button>
                                      <div>
                                        <p
                                          className="text-grayLight-500 font-normal"
                                          style={{ fontSize: '16px' }}>
                                          {item.count}
                                        </p>
                                      </div>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleQuantityChange(parseInt(consumerId), item, true)
                                        }>
                                        <IconComponent iconName="plus" color="#667085" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {!isEmpty && (
        <div
          className={`bottom-0 bg-white flex items-center w-full justify-center ${showMobile ? 'relative' : 'Basket_button'}`}>
          <div className="w-full">
            <div
              className={`flex items-center w-full justify-center ${showMobile ? 'relative' : 'Basket_button'}`}>
              <div className="flex flex-col w-full px-spacing-xl pb-spacing-xl gap-2">
                <CustomChakraButton
                  buttonProps={{
                    onClick: onSummaryClick,
                    disabled: isEmpty,
                    className: 'grow'
                  }}
                  size="2xl"
                  hierarchy="primary"
                  iconPosition="onlyText"
                  fullWidth>
                  Przejdź do podsumowania
                </CustomChakraButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
