import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/pl';
import { useMemo, useEffect } from 'react';
import { MessageConversationInterface } from '../../shared/type/message.type';

import './ChatComponent.css';

type ActorType = 'parent' | 'admin';

const ChatComponent = ({
  messages,
  actor
}: {
  messages: MessageConversationInterface[];
  actor: ActorType;
}) => {
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language || 'pl');

  const formatDate = (dateString: string) => {
    const date = moment(dateString);
    const oneWeekAgo = moment().subtract(1, 'weeks');

    if (date.isBefore(oneWeekAgo)) {
      return date.format('dddd D MMMM HH:mm');
    } else {
      return date.format('dddd HH:mm');
    }
  };

  useEffect(() => {
    console.log();
  }, []);

  const sortedMessages = useMemo(() => {
    return [...messages].sort((a, b) => a.id - b.id);
  }, [messages]);

  const getMessagePosition = function (
    senderIsTenant: boolean,
    chatActor: ActorType
  ): 'justify-start' | 'justify-end' {
    if (chatActor === 'admin') {
      return senderIsTenant ? 'justify-end' : 'justify-start';
    }

    if (chatActor === 'parent') {
      return senderIsTenant ? 'justify-start' : 'justify-end';
    }
    return 'justify-start';
  };

  const getMessageStyle = (senderIsTenant: boolean, chatActor: ActorType): string => {
    if (senderIsTenant && chatActor === 'parent') {
      return 'rounded-tr-lg border border-grayLight-200';
    } else if (!senderIsTenant && chatActor === 'parent') {
      return 'rounded-tl-lg bg-brand-300';
    } else if (senderIsTenant && chatActor === 'admin') {
      return 'rounded-tl-lg bg-brand-300';
    } else {
      return 'rounded-tr-lg border border-grayLight-200';
    }
  };

  const getSignature = function (message: MessageConversationInterface, chatActor: ActorType) {
    const { senderIsTenant, sender } = message;

    const nameAndSurname = (
      <p className="text-sm font-medium text-grayLight-700">
        {sender?.firstName || ''} {sender?.lastName || ''}
      </p>
    );

    const yourSignature = (
      <p className="text-sm font-medium text-grayLight-700">
        {/* @ts-ignore */}
        {actor === 'parent' ? t('parent_mailbox.chat_component.your_message') : 'Twoja wiadomość'}
      </p>
    );

    if (chatActor === 'parent') {
      return senderIsTenant ? nameAndSurname : yourSignature;
    }

    if (chatActor === 'admin') {
      return senderIsTenant ? yourSignature : nameAndSurname;
    }
  };

  return (
    <div className="h-full w-full overflow-y-auto scrollbar-hide flex flex-col gap-spacing-lg">
      {sortedMessages.map((message) => {
        const { sent, content } = message;
        const formattedContent = content.replace(/\n/g, '<br/>');
        return (
          <div
            className={`w-full flex ${getMessagePosition(message.senderIsTenant, actor)}`}
            key={message.id}>
            <div className="basis-full lg:basis-5/12 flex flex-col gap-spacing-sm">
              <div className="flex justify-between">
                {getSignature(message, actor)}
                <p className="text-xs font-normal text-grayLight-600">{formatDate(sent)}</p>
              </div>
              <div
                style={{ fontSize: '16px' }}
                className={`
                  py-2.5 lg:px-3.5 px-spacing-xl
                  gap-spacing-md box-border  rounded-bl-lg rounded-br-lg
                  font-normal text-grayLight-900
                  ${getMessageStyle(message.senderIsTenant, actor)}`}
                dangerouslySetInnerHTML={{ __html: formattedContent }}></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChatComponent;
