import moment from 'moment';
import { ParentOrderContextInterface } from '../../shared/type/parentOrderContext.type';
import formatDateForComparison from '../formatDateForComarison/formatDateForComarison';
moment.locale('pl');

export const toggleSelectAllDaysInMonth = (
  isSelected: boolean,
  consumerId: number | undefined,
  calendarDate: Date,
  setSelectAllDaysInMonth: React.Dispatch<React.SetStateAction<boolean>>,
  parentOrderContext: ParentOrderContextInterface | undefined,
  setSelectedDates: React.Dispatch<React.SetStateAction<Date[]>>,
  letOrderInPast: boolean,
  minDate: Date,
  maxDate: Date
) => {
  setSelectAllDaysInMonth(isSelected);

  const startOfMonth = moment(calendarDate).startOf('month');
  const startOfMonthOps = moment(calendarDate).startOf('month'); //if it is the same object than it is changed
  const endOfMonth = moment(calendarDate).endOf('month');

  if (isSelected) {
    const allDaysInMonth = [];

    for (
      let day = startOfMonthOps;
      day.isBefore(endOfMonth.clone().add(1, 'day'));
      day.add(1, 'day')
    ) {
      const date = day.toDate();
      const currentDate = formatDateForComparison(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
      );

      const consumerContext = parentOrderContext?.consumerOrderContexts.find(
        (consumer) => consumer.consumerId === consumerId
      );
      let consumerOrderForDay;

      if (consumerContext) {
        consumerOrderForDay = consumerContext.orderDays.find((order) => order.when === currentDate);
      }

      if (consumerOrderForDay) {
        if (consumerOrderForDay.salesBlocked || !consumerOrderForDay.workingDay) {
          continue;
        }
      }

      if (!letOrderInPast && (day.isBefore(minDate) || day.isAfter(maxDate))) {
        continue;
      }

      if (day.month() === startOfMonth.month()) {
        allDaysInMonth.push(date);
      }
    }

    setSelectedDates(allDaysInMonth);
  } else {
    setSelectedDates([]);
  }
};
