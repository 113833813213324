import { TableContainer, Thead, Th, Box, Text, Flex } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../../context/ApiProvider';
import { PaginationInterface } from '../../../../shared/type/paginationInterface.type';
import {
  TransactionHistoryLogInterface,
  TransactionHistoryInterface
} from '../../../../shared/type/transactionHistoryInterface.type';
import CustomChakraButton from '../../../CustomChakraButton/CustomChakraButton';
import { DataTable } from '../../../DataTable/DataTable';
import Pagination from '../../../Pagination/Pagination';
import AddCustomOperation from './AddCustomOperation/AddCustomOperation';

interface Props {
  consumerId: number;
  userId: number;
}

const BalanceHistory = ({ consumerId, userId }: Props) => {
  const { apiConsumerFinancialHistoryController } = useApi();
  const { id: tenantId } = useParams();
  const columnHelper = createColumnHelper<TransactionHistoryLogInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [balanceHistory, setBalanceHistory] = useState<TransactionHistoryLogInterface[]>([]);
  const [parentUserId, setParentUserId] = useState<string>('0');
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 30,
    maxPageNumber: 0
  });
  const [showAddOperation, setShowAddOperation] = useState<boolean>(false);

  const fetchBalanceHistory = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<TransactionHistoryInterface> =
        await apiConsumerFinancialHistoryController('parent-transactions').get('', {
          params: {
            pageNumber: page.pageNumber,
            pageSize: page.pageSize,
            tenantId,
            parentUserId
          }
        });
      const { data, next, hasNextPage, totalCount } = response.data;
      setBalanceHistory(data);
      setActualPage({ next, hasNextPage, totalCount });
      setPage((prev) => ({
        ...prev,
        maxPageNumber: Math.ceil(totalCount / page.pageSize)
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      setParentUserId(userId.toString());
    }
  }, [userId]);

  useEffect(() => {
    if (Number(parentUserId) !== 0) {
      fetchBalanceHistory();
    }
  }, [parentUserId, page.pageNumber]);

  const columns = [
    columnHelper.accessor('when', {
      cell: (info) => {
        const when = info.getValue();

        return (
          <p className="text-grayLight-900 font-medium text-sm underline underline-offset-4">
            {`${when}`}
          </p>
        );
      },
      header: 'Data operacji'
    }),
    columnHelper.accessor('description', {
      cell: (info) => {
        const description = info.getValue();

        return <p className="text-grayLight-900 font-medium text-sm">{description}</p>;
      },
      header: 'Opis operacji'
    }),
    columnHelper.accessor('amount', {
      cell: (info) => {
        const amount = info.getValue();

        return <p className="text-grayLight-900 font-medium text-sm">{amount}</p>;
      },
      header: 'Kwota (zł)'
    }),
    columnHelper.accessor('saldoBeforeOperation', {
      cell: (info) => {
        const saldoBeforeOperation = info.getValue();

        return <p className="text-grayLight-900 font-medium text-sm">{saldoBeforeOperation}</p>;
      },
      header: 'Saldo przed (zł)'
    }),
    columnHelper.accessor('saldoAfterOperation', {
      cell: (info) => {
        const saldoAfterOperation = info.getValue();

        return <p className="text-grayLight-900 font-medium text-sm">{saldoAfterOperation}</p>;
      },
      header: 'Saldo po (zł)'
    }),
    columnHelper.accessor('performedByUser.lastName', {
      cell: (info) => {
        const { lastName, firstName } = info.row.original.performedByUser;

        return (
          <p className="text-grayLight-900 font-medium text-sm underline underline-offset-4">
            {lastName} {firstName}
          </p>
        );
      },
      header: 'Wykonana przez'
    })
  ];

  const handlePageChange = (pageNum: number) => {
    setPage((prev) => ({
      ...prev,
      pageNumber: pageNum
    }));
  };

  return (
    <Flex direction="column" h="800px" className="Branches__table bg-white rounded-lg">
      <TableContainer h="100%" flex="1" minH="500px" overflowY="auto">
        <DataTable
          disableFilters={true}
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base text-grayLight-900">
                        Historia salda
                      </Text>
                    </Box>
                  </div>
                  <div>
                    <CustomChakraButton
                      size="md"
                      hierarchy="secondaryColor"
                      iconPosition="left"
                      icon="plus"
                      buttonProps={{
                        type: 'button',
                        onClick: () => {
                          setShowAddOperation(true);
                        }
                      }}>
                      Edycja salda
                    </CustomChakraButton>
                  </div>
                </div>
              </Th>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={balanceHistory}
        />
        {showAddOperation ? (
          <AddCustomOperation
            currentSaldo={balanceHistory[0]?.saldoAfterOperation}
            consumerId={consumerId}
            isOpen={showAddOperation}
            handleClose={() => {
              setShowAddOperation(false);
              fetchBalanceHistory();
            }}
          />
        ) : null}
      </TableContainer>
      <Box position="relative" paddingTop="10px" paddingBottom="20px">
        <Pagination
          pageNumber={page.pageNumber}
          maxPageNumber={page.maxPageNumber}
          hasNextPage={actualPage.hasNextPage}
          onPageChange={handlePageChange}
        />
      </Box>
    </Flex>
  );
};

export default BalanceHistory;
