import React, { useState, useEffect } from 'react';
import { TableContainer, Thead, Th, Text, Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../../context/ApiProvider';
import { TenantStatuteInterface } from '../../../../shared';
import { DeletedElementInterface } from '../../../../shared/type/deletedElement.type';
import defaultAlertContext from '../../../../utils/defaultAlertContext';
import CustomAlert from '../../../CustomAlert/CustomAlert';
import CustomChakraButton from '../../../CustomChakraButton/CustomChakraButton';
import { DataTable } from '../../../DataTable/DataTable';
import { createColumnHelper } from '@tanstack/react-table';
import IconComponent from '../../../IconComponent/IconComponent';
import ImportTenantStatute from './ImportTenantStatute/ImportTenantStatute';

const SchoolStatutes = () => {
  const { id: tenant } = useParams();
  const { apiTenantStatuteController } = useApi();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statutes, setStatutes] = useState<TenantStatuteInterface[]>([]);
  const [deleteElement, setDeleteElement] = useState<DeletedElementInterface>(defaultAlertContext);

  const columnHelper = createColumnHelper<TenantStatuteInterface>();

  const fetchStatues = async () => {
    setIsLoading(true);
    try {
      const result = await apiTenantStatuteController('').get(`/${tenant}`);
      setStatutes(result.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStatues();
  }, [tenant]);

  useEffect(() => {
    fetchStatues();
  }, []);

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: 'Nazwa'
    }),
    columnHelper.accessor('startDate', {
      cell: (info) => info.getValue(),
      header: 'Od'
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Usuwanie',
      cell: (info) => {
        const dayOff = info.row.original;
        return (
          <div className="flex gap-spacing-sm">
            <button
              className="font-semibold text-sm text-grayLight-600 bg-transparent border-none"
              onClick={() => {
                setDeleteElement({ id: dayOff.id, isOpen: true });
              }}>
              Usuń
            </button>
          </div>
        );
      }
    })
  ];

  return (
    <section className="DaysOff p-spacing-xl w-full">
      <TableContainer h="100%" flex="1" minH="500px">
        <DataTable
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base normal-case text-grayLight-900">
                        Regulaminy oddziału
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm normal-case text-grayLight-700">
                        W tym miejscu możesz zarządzać regulaminami oddziału. Regulamin jest używany
                        na stronie rejestracji rodzica. Rodzic, który się rejestruje musi
                        zaakcpetować regulamin. Rodzice, którzy będą się rejestrować dostaną do
                        akceptacji najświeższy regulamin.
                      </Text>
                    </Box>
                  </div>
                  <div>
                    <CustomChakraButton
                      size="md"
                      hierarchy="primary"
                      iconPosition="left"
                      icon="plus"
                      buttonProps={{
                        disabled: Number(tenant) === 0,
                        onClick: () => {
                          setIsAddModalOpen(true);
                        }
                      }}>
                      Improtuj plik PDF z regulaminem
                    </CustomChakraButton>
                  </div>
                </div>
              </Th>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={statutes}
        />
      </TableContainer>
      <CustomAlert
        header="Usunąć regulamin?"
        content="Usunięcie regulaminu sprawi, że regulamin nie będzie dostępny dla rejestrujących się rodziców"
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        confirmButtonText="Usuń"
        onConfirmAction={async () => {
          try {
            await apiTenantStatuteController('').delete(`/${tenant}/${deleteElement.id}`);
          } catch (error) {
            console.warn(error);
          } finally {
            setDeleteElement(defaultAlertContext);
            fetchStatues();
          }
        }}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText="Anuluj"
        onCancelAction={() => {
          setDeleteElement(defaultAlertContext);
          fetchStatues();
        }}
        handleOpen={deleteElement.isOpen}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" />
          </div>
        }
      />
      <ImportTenantStatute
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          fetchStatues();
        }}
      />
    </section>
  );
};

export default SchoolStatutes;
