import { AxiosResponse, HttpStatusCode } from 'axios';
import React, {
  ReactNode,
  useState,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import { OrderForChildren } from '../shared/type/orderForParent.type';
import { useApi } from './ApiProvider';
import { useParentBasketContext } from './ParentBasketProvider';
import { ConsumerOrderContextInterface } from '../shared/type/consumerOrderContext.type';
import { ParentOrderContextInterface } from '../shared/type/parentOrderContext.type';

interface Props {
  children: ReactNode;
}

interface ConsumerOrderContextDateInterface {
  year: number;
  month: number;
}

interface ConsumerOrderContextProps {
  consumerOrderContext: ParentOrderContextInterface | undefined;
  setConsumerOrderContextDate: React.Dispatch<
    React.SetStateAction<ConsumerOrderContextDateInterface>
  >;
  consumerOrderContextDate: ConsumerOrderContextDateInterface;
  tenantId: number | undefined;
  setTenantId: React.Dispatch<React.SetStateAction<number | undefined>>;
  isConsumerOrderLoading: boolean;
  setLoadMeals: React.Dispatch<React.SetStateAction<boolean>>;
  setSkipFetchingConsumerOrderContext: React.Dispatch<React.SetStateAction<boolean>>;
  setUserId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const ConsumerOrderContextContext = createContext<ConsumerOrderContextProps | undefined>(undefined);

export const useConsumerOrderContextContext = () => {
  const context = useContext(ConsumerOrderContextContext);
  if (!context) {
    throw new Error(
      'useConsumerOrderContextContext must be used within a ConsumerOrderContextProvider'
    );
  }
  return context;
};

export const ConsumerOrderContextProvider = ({ children }: Props) => {
  const today = new Date();
  const { apiOrderController } = useApi();
  const [isConsumerOrderLoading, setIsConsumerOrderLoading] = useState(false);
  const { setUserData, addItemToBasket } = useParentBasketContext();
  const [consumerOrderContext, setConsumerOrderContext] = useState<ParentOrderContextInterface>();
  const [tenantId, setTenantId] = useState<number | undefined>(undefined);
  const [loadMeals, setLoadMeals] = useState(false);
  const [skipFetchingConsumerOrderContext, setSkipFetchingConsumerOrderContext] = useState(true);
  const [userId, setUserId] = useState<number | undefined>();
  const [skipDateInRequest, setSkipDateInRequest] = useState(true);
  const [consumerOrderContextDate, setConsumerOrderContextDate] =
    useState<ConsumerOrderContextDateInterface>({
      year: today.getFullYear(),
      month: today.getMonth() + 1
    });

  const fetchConsumerOrderContext = useCallback(async () => {
    try {
      setIsConsumerOrderLoading(true);
      const requestBody: any = {
        tenantId,
        consumerId: userId,
        month: consumerOrderContextDate.month,
        year: consumerOrderContextDate.year,
        loadMeals
      };

      const response: AxiosResponse<ParentOrderContextInterface> = await apiOrderController(
        'consumer-order-context'
      ).post('', requestBody);

      if (response.status !== HttpStatusCode.Ok) {
        return;
      }

      setConsumerOrderContext(response.data);
      setUserData(
        response.data.consumerOrderContexts.map((consumer) => ({
          consumerId: consumer.consumerId,
          saldo: consumer.saldo,
          creditLimit: consumer.creditLimit,
          provision: consumer.provision
        }))
      );

      response.data.consumerOrderContexts.forEach((consumer) => {
        consumer.orderDays.forEach((orderDay) => {
          orderDay.purchasableItems?.forEach((item) => {
            if (item.orderCount > 1) {
              const newOrder: OrderForChildren = {
                tenantId: consumer.tenantId,
                consumerId: consumer.consumerId,
                useCredit: false,
                orderedItems: [
                  {
                    purchasableItem: item,
                    count: item.orderCount,
                    when: orderDay.when
                  }
                ]
              };
              addItemToBasket(newOrder);
            }
          });
        });
      });
    } catch (error) {
      console.error('Error fetching consumer order context:', error);
    } finally {
      setIsConsumerOrderLoading(false);
      setSkipDateInRequest(false);
    }
  }, [tenantId, userId, loadMeals, consumerOrderContextDate, setUserData, skipDateInRequest]);

  useEffect(() => {
    if (tenantId && !skipFetchingConsumerOrderContext) {
      fetchConsumerOrderContext();
    }
  }, [tenantId, skipFetchingConsumerOrderContext, consumerOrderContextDate]);

  const contextValue = useMemo(
    () => ({
      consumerOrderContext,
      setConsumerOrderContextDate,
      fetchConsumerOrderContext,
      tenantId,
      setTenantId,
      isConsumerOrderLoading,
      setLoadMeals,
      setSkipFetchingConsumerOrderContext,
      setUserId,
      consumerOrderContextDate
    }),
    [
      consumerOrderContext,
      setConsumerOrderContextDate,
      fetchConsumerOrderContext,
      tenantId,
      setTenantId,
      isConsumerOrderLoading,
      setLoadMeals,
      setSkipFetchingConsumerOrderContext,
      consumerOrderContextDate
    ]
  );

  return (
    <ConsumerOrderContextContext.Provider value={contextValue}>
      {children}
    </ConsumerOrderContextContext.Provider>
  );
};
