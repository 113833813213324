import CustomHorizontalTabs from '../../../../CustomHorizontalTabs/CustomHorizontalTabs';

type ConsumerSelector = {
  consumers: { label: string; value: number }[];
  onConsumerSelect: (consumerId: number) => void;
};

export const ConsumerSelector = ({ consumers, onConsumerSelect }: ConsumerSelector) => {
  return (
    <div>
      <CustomHorizontalTabs
        onChange={(value) => {
          onConsumerSelect(value);
        }}
        data={consumers}
      />
    </div>
  );
};
