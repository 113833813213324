import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter, Divider
} from '@chakra-ui/react';
import CustomChakraButton from '../../../CustomChakraButton/CustomChakraButton';
import {useApi} from "../../../../context/ApiProvider";
import {PaymentInterface, PaymentStatusType} from "../../../../shared/type/payment.type";
import {useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import calculatePrice from "../../../../utils/calculators/calculatePrice";
import {useSafeTranslation} from "../../../../hooks/useSafeTranslation";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    paymentId: string;
}

const PaymentStatusPopUp = ({ isOpen, onClose, paymentId }: Props) => {
    const { t } = useSafeTranslation();
    const { apiParentPaymentController } = useApi();
    const [paymentStatus, setPaymentStatus] = useState<PaymentInterface>()

    const fetchPaymentInfo = async () => {
        try {
            const response: AxiosResponse<PaymentInterface> = await apiParentPaymentController('parent-payment-info').get('', {
                params: {
                    paymentId
                }
            });
            setPaymentStatus(response.data)
        } catch (error) {

        }
    };

    const getPaymentStatus = (paymentStatus: PaymentStatusType | undefined) => {
        if(!paymentStatus) return;

        switch (paymentStatus) {
            case 'READY_FOR_PAYMENT':
                return t('parent_dashboard.payment_status_pop_up.payment_status.ready_for_payment');
            case 'PAID':
                return t('parent_dashboard.payment_status_pop_up.payment_status.paid');
            case 'POST_PAYMENT_PROBLEM':
                return t('parent_dashboard.payment_status_pop_up.payment_status.post_payment_problem');
            case 'REJECTED':
                return t('parent_dashboard.payment_status_pop_up.payment_status.rejected');
        }
    }

    useEffect(() => {
        if (isOpen && paymentId) {
            fetchPaymentInfo();
        }
    }, [isOpen, paymentId]);

    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
                <ModalOverlay
                    sx={{
                        backdropFilter: 'blur(10px)',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    }}
                />
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-spacing-xl">
                        <div className="flex flex-col gap-spacing-xs">
                            <h2 className="font-semibold text-lg">
                                {t('parent_dashboard.payment_status_pop_up.header')}
                            </h2>
                        </div>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody className="flex flex-col gap-spacing-2xl">
                        <div className="flex">
                            <div className='basis-1/2 text-sm font-medium text-grayLight-700'>
                                {t('parent_dashboard.payment_status_pop_up.status')}

                            </div>
                            <div className='basis-1/2 text-sm font-medium text-grayLight-700'>
                                {getPaymentStatus(paymentStatus?.status)}
                            </div>
                        </div>
                        <div className="flex">
                            <div className='basis-1/2 text-sm font-medium text-grayLight-700'>
                                {t('parent_dashboard.payment_status_pop_up.payment_amount')}
                            </div>
                            <div className='basis-1/2 text-sm font-medium text-grayLight-700'>
                                {calculatePrice(paymentStatus?.paid, 'zł')}
                            </div>
                        </div>
                        {Array.isArray(paymentStatus?.orderInfo) && paymentStatus!.orderInfo.length > 0 && (
                            <>
                                <Divider />
                                <div className="flex flex-col gap-spacing-lg">
                                    <p className="text-sm font-semibold text-grayLight-900">
                                        {t('parent_dashboard.payment_status_pop_up.consumer_status')}
                                    </p>
                                    <div className="flex flex-col gap-spacing-md max-h-[300px] overflow-y-auto pr-2">
                                        {paymentStatus?.orderInfo.map((info) => (
                                            <div
                                                key={info.orderId}
                                                className="border border-grayLight-200 rounded-md p-spacing-md flex flex-col gap-1 bg-grayLight-50"
                                            >
                                                <div className="flex justify-between text-sm font-medium text-grayLight-900">
                                                    <span>{info.consumerName}</span>
                                                    <span>{getPaymentStatus(info.orderOutcomeStatus)}</span>
                                                </div>
                                                {info.errors.length > 0 && (
                                                    <>
                                                        <p className="text-sm font-semibold text-grayLight-900">
                                                            {t('parent_dashboard.payment_status_pop_up.error_header')}
                                                        </p>
                                                        <ul className="list-disc list-inside text-xs text-error-600 mt-1">
                                                            {info.errors.map((err, i) => (
                                                                <li key={i}>{err}</li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}


                    </ModalBody>
                    <ModalFooter className="w-full flex justify-between gap-spacing-lg">
                        <CustomChakraButton
                            hierarchy="secondaryColor"
                            size="lg"
                            iconPosition="onlyText"
                            buttonProps={{
                                className: 'grow',
                                type: 'button',
                                onClick: onClose
                            }}>
                            Zamknij
                        </CustomChakraButton>
                    </ModalFooter>
                </ModalContent>
        </Modal>
    );
};

export default PaymentStatusPopUp;
