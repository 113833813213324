import { useMediaQuery } from '@chakra-ui/react';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { Views } from 'react-big-calendar';
import { useParentBasketContext } from '../../../../context/ParentBasketProvider';
import { useParentDashboardContextContext } from '../../../../context/ParentDashboardContext';
import { useParentOrderContextContext } from '../../../../context/ParentOrderContext';
import { useThemeContext } from '../../../../context/ThemeProvider';
import { PurchasableItemInterface } from '../../../../shared/type/parentOrderContext.type';
import { getAvailableMeals } from '../../../../utils/calendarOrderHelper';

export const useOrderOnPeriodOfTime = () => {
  const { i18n } = useTranslation();
  const [isNotFullSizeScreen] = useMediaQuery('(max-width: 1024px)');
  const { parentDashboardContext, fetchParentDashboardContext, setBasketStatus } =
    useParentDashboardContextContext();
  const { parentOrderContext, tenantId, setSkipFetchingParentOrderContext } =
    useParentOrderContextContext();
  const { addItemToBasket, parentBasketContext } = useParentBasketContext();
  const { isMobile } = useThemeContext();

  const [selectedStake, setSelectedStake] = useState<number>();

  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [selectAllDaysInMonth, setSelectAllDaysInMonth] = useState(false);
  const [selectedConsumerId, setSelectedUserId] = useState<number>();
  const [commonMeal, setCommonMeals] = useState<PurchasableItemInterface[]>([]);

  const clearSelectedDates = () => {
    setSelectedDates([]);
    setSelectedStake(undefined);
  };

  moment.locale(i18n.language || 'pl');

  const views = useMemo(() => [Views.MONTH], []);
  const minOrderDate = moment(parentOrderContext?.minOrderDate);
  const maxOrderDate = moment(parentOrderContext?.maxOrderDate);

  const purchasableItem = useMemo(() => {
    if (!parentOrderContext || !selectedConsumerId) return [];

    const consumerContext = parentOrderContext.consumerOrderContexts.find(
      (consumer) => consumer.consumerId === selectedConsumerId
    );

    if (!consumerContext) return [];

    const uniqueMeals = new Map();

    consumerContext.orderDays
      .flatMap((orderDay) => orderDay.purchasableItems || [])
      .forEach((meal) => {
        if (!uniqueMeals.has(meal.stakeId)) {
          const priceInPLN = (meal.price / 100).toFixed(2);
          const label = `${meal.name} - ${priceInPLN}zł`;
          uniqueMeals.set(meal.stakeId, {
            label: label,
            value: meal.stakeId
          });
        }
      });

    return Array.from(uniqueMeals.values());
  }, [parentOrderContext, selectedConsumerId]);

  const consumersToSelect = useMemo(
    () =>
      parentOrderContext
        ? parentOrderContext.consumerOrderContexts.map((consumer) => {
            return {
              label: isMobile
                ? `${consumer.firstName}`
                : `${consumer.firstName} ${consumer.lastName}`,
              value: consumer.consumerId
            };
          })
        : [],
    [parentOrderContext, tenantId, isMobile]
  );

  const onConsumerSelect = useCallback(
    (consumerId: number) => {
      setSelectedUserId(consumerId);
    },
    [setSelectedUserId]
  );

  useEffect(() => {
    setSkipFetchingParentOrderContext(false);

    return () => {
      setSkipFetchingParentOrderContext(true);
    };
  }, []);

  useEffect(() => {
    if (!selectedConsumerId && parentDashboardContext && tenantId) {
      const tenant = parentDashboardContext.tenants.find((t) => t.tenantId === tenantId);
      const firstConsumerId = tenant?.consumers?.[0]?.consumerDto?.id;

      if (firstConsumerId) {
        setSelectedUserId(firstConsumerId);
      }
    }
  }, [parentDashboardContext, selectedConsumerId, tenantId]);

  useEffect(() => {
    if (!parentDashboardContext) {
      fetchParentDashboardContext();
    }
  }, [parentDashboardContext]);

  useEffect(() => {
    setCommonMeals(getAvailableMeals(parentOrderContext, selectedConsumerId, selectedDates));

    const availableStakeIds = purchasableItem.map(item => item.value);
    if (!availableStakeIds.includes(selectedStake)) {
      setSelectedStake(undefined);
    }
    // if (selectedDates.length === 0) {
    //   setSelectedStake(undefined);
    // }
  }, [selectedDates]);

  useEffect(() => {
    setBasketStatus({
      forceShow: !isNotFullSizeScreen,
      show: false
    });
  }, [isNotFullSizeScreen]);

  return {
    purchasableItem,
    views,
    clearSelectedDates,
    selectAllDaysInMonth,
    setSelectAllDaysInMonth,
    parentOrderContext,
    selectedConsumerId,
    selectedDates,
    setSelectedDates,
    selectedStake,
    setSelectedStake,
    addItemToBasket,
    tenantId,
    minOrderDate,
    maxOrderDate,
    commonMeal,
    consumersToSelect,
    onConsumerSelect,
    parentBasketContext
  };
};
