import { useLocation } from 'react-router-dom';

const LOCATION_WHERE_SELECTOR_SHOULD_BE_HIDE = ['credit-order', 'cash-order'];

export const useHideTenantSelector = () => {
  const location = useLocation();

  const hideTenantSelector = LOCATION_WHERE_SELECTOR_SHOULD_BE_HIDE.some((path) =>
    location.pathname.includes(path)
  );

  return hideTenantSelector;
};
