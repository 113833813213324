import React, { useEffect } from 'react';
import './Branches.css';
import {Route, Routes, useMatch, useParams} from 'react-router-dom';
import { useSideNavContext } from '../../../context/SideNavProvider';
import { SideMenuOptionInterface } from '../../../shared';
import Absences from '../../TenantAdminComponents/Absences/Absences';
import Kitchen from '../../TenantAdminComponents/Kitchen/Kitchen';
import MailBox from '../../TenantAdminComponents/MailBox/MailBox';
import SingleAdminMessage from '../../TenantAdminComponents/MailBox/Messages/SingleAdminMessage/SingleAdminMessage';
import Reports from '../../TenantAdminComponents/Reports/Reports';
import School from '../../TenantAdminComponents/School/School';
import Users from '../../TenantAdminComponents/Users/Users';
import BranchesTable from './BranchesTable/BranchesTable';
import SingleBranch from './SingleBranch/SingleBranch';
import { Dashboard } from '../../TenantAdminComponents/Dashboard/Dashboard';
import { HistoryOperation } from "./HistoryOperation/HistoryOperation";

const Branches = () => {
  const { setNavOptions } = useSideNavContext();
  // const { id: tenant } = useParams();

  const params = useParams();
  const match = useMatch('/superAdmin/branches/id/:tenantId/*');
  const tenant = match?.params.tenantId || params.id;

  const menuOptions: SideMenuOptionInterface[] = [
    {
      header: 'Strona Główna',
      icon: 'happyHomeIcon',
      link: `branches/id/${tenant}/dashboard`
    },
    {
      header: 'Zamówienia',
      icon: 'calendar',
      link: `branches/id/${tenant}/absences`
    },
    {
      header: 'Kuchnia',
      icon: 'cutlery',
      children: [
        {
          header: 'Alergeny',
          link: `branches/id/${tenant}/kitchen/allergens`
        },
        {
          header: 'Jadłospis',
          link: `branches/id/${tenant}/kitchen/food-menu/meal-plan`
        }
      ]
    },
    {
      header: 'Użytkownicy',
      icon: 'users',
      children: [
        {
          header: 'Dzieci',
          link: `branches/id/${tenant}/users/children/children-table`
        },
        {
          header: 'Rodzice',
          link: `branches/id/${tenant}/users/parents/parents-table`
        },
        {
          header: 'Nauczyciele',
          link: `branches/id/${tenant}/users/teachers/teachers-table`
        },
        {
          header: 'Pracownicy',
          link: `branches/id/${tenant}/users/employees/employees-table`
        }
      ]
    },
    {
      header: 'Raporty',
      icon: 'file',
      link: `branches/id/${tenant}/reports`
    },
    {
      header: 'Wiadomości',
      icon: 'message',
      link: `branches/id/${tenant}/mail-box/messages`
    },
    {
      header: 'Ustawienia',
      icon: 'graduationHat',
      children: [
        {
          header: 'Dane oddziału',
          link: `branches/id/${tenant}`
        },
        {
          header: 'Klasy',
          link: `branches/id/${tenant}/school/classes`
        },
        {
          header: 'Dni wolne / blokady zamówień',
          link: `branches/id/${tenant}/school/free-days`
        },
        {
          header: 'System sprzedaży',
          link: `branches/id/${tenant}/kitchen/sales-system/servings`
        },
        {
          header: 'Regulaminy',
          link: `branches/id/${tenant}/school/school-statutes`
        },
        {
          header: 'Instytucje dofinansowujące',
          link: `branches/id/${tenant}/school/co-financing-institutions`
        },
        {
          header: 'Administratorzy ',
          link: `branches/id/${tenant}/users/administrators/administrators-table`
        },
        {
          header: 'System',
          link: `branches/id/${tenant}/school/school-settings`
        }
      ]
    }
  ];

  useEffect(() => {
    setNavOptions(menuOptions);
  }, [tenant]);

  return (
    <section className="Branches h-screen bg-neutral-50">
      <Routes>
        <Route path="/" element={<BranchesTable />} />
        <Route path="/id/:id" element={<SingleBranch />} />
        <Route path="/id/:id/history/*" element={<HistoryOperation />} />
        <Route path="/id/:id/kitchen/*" element={<Kitchen />} />
        <Route path="/id/:id/school/*" element={<School />} />
        <Route path="/id/:id/absences/*" element={<Absences />} />
        <Route path="/id/:id/dashboard/*" element={<Dashboard />} />
        <Route path="/id/:id/users/*" element={<Users />} />
        <Route path="/id/:id/users/*" element={<Reports />} />
        <Route path="/id/:id/mail-box/messages/message/:id/*" element={<SingleAdminMessage />} />
        <Route path="/id/:id/mail-box/*" element={<MailBox />} />
      </Routes>
    </section>
  );
};
export default Branches;
