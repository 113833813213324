import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useApi } from "../../../../../../../context/ApiProvider";
import { BalanceHistoryInterface } from "../../../../../../../shared/type/order.type";
import { AxiosResponse } from "axios";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    paymentId: number | undefined;
}

const BalanceHistoryDetails = ({ isOpen, onClose, paymentId }: Props) => {
    const [orderHistory, setOrderHistory] = useState<BalanceHistoryInterface | undefined>();
    const { apiConsumerFinancialHistoryController } = useApi();

    const fetchOrderDetails = async () => {
        try {
            const response: AxiosResponse<BalanceHistoryInterface> =
                await apiConsumerFinancialHistoryController('payment').get('', {
                    params: { paymentId },
                });
            setOrderHistory(response.data);
        } catch (error) {
            console.error("Błąd podczas pobierania historii balansu:", error);
        }
    };

    useEffect(() => {
        if (paymentId !== undefined) {
            fetchOrderDetails();
        }
    }, [paymentId]);

    const allData = orderHistory?.orderDto.flatMap(entry =>
        entry.orderDto.boughtStakeDays.flatMap(day =>
            day.boughtStakes.map(item => ({
                date: new Date(day.when).toLocaleDateString(),
                name: item.name,
                price: item.price,
                paidAmount: item.paidAmount,
                totalCost: entry.orderDto.totalCost,
                totalPaid: entry.orderDto.totalPaid,
                consumerName: `${entry.consumerDto.firstName} ${entry.consumerDto.lastName}`
            }))
        )
    ) || [];

    const grouped: { [key: string]: typeof allData } = {};
    allData.forEach(item => {
        if (!grouped[item.date]) grouped[item.date] = [];
        grouped[item.date].push(item);
    });

    const rows = Object.entries(grouped).flatMap(([date, records]) => {
        return records.map((rec, i) => ({
            date: i === 0 ? date : '',
            rowSpan: i === 0 ? records.length : 0,
            name: rec.name,
            price: rec.price,
            paidAmount: rec.paidAmount,
            consumerName: rec.consumerName,
            totalCost: rec.totalCost,
            totalPaid: rec.totalPaid,
        }));
    });

    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
            <form onSubmit={(e) => e.preventDefault()}>
                <ModalOverlay
                    sx={{
                        backdropFilter: "blur(10px)",
                        backgroundColor: "rgba(0, 0, 0, 0.5)"
                    }}
                />
                <ModalContent>
                    <ModalHeader>
                        <div className='flex flex-col text-grayLight-900 font-semibold text-lg border-r border-grayLight-200'>
                            <h2 className="font-semibold text-lg">
                                Historia płatności #{orderHistory?.paymentId}
                            </h2>
                            <p className="font-normal text-grayLight-700 text-sm">
                                Osoba dokonująca: {orderHistory?.doneBy.firstName} {orderHistory?.doneBy.lastName}
                            </p>
                        </div>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody className="p-0 m-0" style={{ maxHeight: "80vh", overflowY: "auto" }}>
                        {/*<div className='w-96'>*/}
                        {/*    <table className="border border-gray-300 w-full">*/}
                        {/*        <thead>*/}
                        {/*        <tr className="border-b border-gray-300">*/}
                        {/*            <th className="p-2 border-r border-gray-300">*/}
                        {/*                <p className="text-xs font-medium text-grayLight-600">Saldo przed</p>*/}
                        {/*            </th>*/}
                        {/*            <th className="p-2 border-r border-gray-300">*/}
                        {/*                <p className="text-xs font-medium text-grayLight-600">Wpłata</p>*/}
                        {/*            </th>*/}
                        {/*            <th className="p-2">*/}
                        {/*                <p className="text-xs font-medium text-grayLight-600">Saldo po</p>*/}
                        {/*            </th>*/}
                        {/*        </tr>*/}
                        {/*        </thead>*/}
                        {/*        <tbody>*/}
                        {/*        <tr className="border-b border-gray-300">*/}
                        {/*            <td className="p-2 border-r border-gray-300 pt-spacing-xl">*/}
                        {/*                <p className="text-xs font-medium text-grayLight-600">{order?.saldoBefore}</p>*/}
                        {/*            </td>*/}
                        {/*            <td className="p-2 border-r border-gray-300">*/}
                        {/*                <p className="text-xs font-medium text-grayLight-600">{order?.paymentValue}</p>*/}
                        {/*            </td>*/}
                        {/*            <td className="p-2">*/}
                        {/*                <p className="text-xs font-medium text-grayLight-600">{order?.saldoAfter}</p>*/}
                        {/*            </td>*/}
                        {/*        </tr>*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*</div>*/}
                        <div className="w-full overflow-x-auto">
                            <table className="w-full border border-grayLight-200 border-collapse">
                                <thead className="bg-blue-25 sticky top-0 z-10">
                                <tr>
                                    <th className="p-spacing-md text-sm border">Dzień</th>
                                    <th className="p-spacing-md text-sm border text-left">Nazwa</th>
                                    <th className="p-spacing-md text-sm border text-left">Stawka</th>
                                    <th className="p-spacing-md text-sm border text-left">Wpłata</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rows.map((row, idx) => (
                                    <tr key={idx}>
                                        {row.date && (
                                            <td
                                                rowSpan={row.rowSpan}
                                                className="border text-sm font-normal text-grayLight-600 p-spacing-md"
                                            >
                                                {row.date}
                                            </td>
                                        )}
                                        <td className="border text-sm p-spacing-md">{row.name}</td>
                                        <td className="border text-sm p-spacing-md">{(row.price / 100).toFixed(2)} zł</td>
                                        <td className="border text-sm p-spacing-md">{(row.paidAmount / 100).toFixed(2)} zł</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                </ModalContent>
            </form>
        </Modal>
    );
};

export default BalanceHistoryDetails;