import React, { useState } from "react";
import { useEditAdministratorContext } from "../../../../../../context/EditAdministratorProvider";
import { ModuleType } from "../../../../../../shared/type/module.type";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import CheckboxInput from "../../../../../Forms/CheckboxInput/CheckboxInput";
import {Spinner} from "@chakra-ui/react";

const AdministratorRights = () => {
  const { adminEditContext, setAdminEditContext, modules } = useEditAdministratorContext();
  const [ isModified, setIsModifies] = useState<boolean>(false)

  if (!adminEditContext) {
    return <Spinner/>;
  }

  const { availableModules } = adminEditContext!.adminUserDto;

  const toggleStringInArray = (arr: string[], str: string): string[] => {
    const index = arr.indexOf(str);

    if (index === -1) {
      return [...arr, str];
    } else {
      return arr.filter((item) => item !== str);
    }
  };

  return (
    <div className="AdministratorSettings flex flex-col gap-spacing-xl">
      <div className="flex flex-row justify-between">
        <div className="AdministratorSettings__headerContainer flex flex-col gap-spacing-md">
          <h2 className="text-lg font-semibold text-grayLight-900">Uprawnienia admina do przypisanych oddziałów</h2>
          <p className="text-sm font-normal text-grayLight-600">Nadane uprawnienia dotyczą wszystkich przypisanych
            oddziałów.</p>
        </div>
        <div className="AdministratorSettings__actionButtons">
          <div className='flex gap-spacing-lg'>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="tertiaryColor"
              size="md"
              buttonProps={{
                disabled: !isModified
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="primary"
              size="md"
              buttonProps={{
                type: 'submit',
                disabled: !isModified
              }}
            >
              Zapisz zmiany
            </CustomChakraButton>
          </div>
        </div>
      </div>
      <div className="AdministratorSettings__formContainer pt-spacing-xl flex flex-col gap-spacing-xl">
        {modules.map((module) => (
          <CheckboxInput
            key={module.name}
            textSize="sm"
            checkboxParams={{
              value: module.name,
              isChecked: availableModules.includes(module.name),
              onChange: (e) => {
                setIsModifies(true);
                setAdminEditContext((prev) => ({
                  ...prev!,
                  adminUserDto: {
                    ...prev!.adminUserDto,
                    login: prev!.adminUserDto.login ?? "",
                    availableModules: toggleStringInArray(prev!.adminUserDto.availableModules, e.target.value) as ModuleType[],
                  },
                }));
              }
            }}
            text={
              <div>
                <p className='font-medium text-sm text-grayLight-700'>{module.displayName}</p>
                <p className='font-normal text-sm text-grayLight-600'>{module.description}</p>
              </div>
            }
          />
        ))}
      </div>
    </div>
  )
};

export default AdministratorRights;
