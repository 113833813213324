import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider
} from '@chakra-ui/react';
import { HttpStatusCode } from 'axios';
import { Select } from 'chakra-react-select';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAlertContext } from '../../../../../context/AlertProvider';
import { useApi } from '../../../../../context/ApiProvider';
import { NewAdministratorInterface } from '../../../../../shared/type/administrator.type';
import { ModuleInterface } from '../../../../../shared/type/module.type';
import { PasswordConditionInterface } from '../../../../../shared/type/passwordCondition.type';
import { AvailableTenantInterface } from '../../../../../shared/type/tenant.type';
import handlePhoneNumberChange from '../../../../../utils/handlePhoneNumberChange';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import AutoCheck from '../../../../Forms/AutoCheck/AutoCheck';
import CheckboxInput from '../../../../Forms/CheckboxInput/CheckboxInput';
import FormInput from '../../../../Forms/FormInput/FormInput';
import IconComponent from '../../../../IconComponent/IconComponent';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const defaultAdministrator: NewAdministratorInterface = {
  firstName: '',
  lastName: '',
  moduleIds: [0],
  newPassword: null,
  email: '',
  tenantIds: [],
  phone: ''
};

const AddAdministrator = ({ isOpen, onClose }: Props) => {
  const { apiTenantAdminController, apiTenantTenantController } = useApi();
  const { id: tenant } = useParams();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const [availableSchools, setAvailableSchools] = useState<AvailableTenantInterface[]>([]);
  const [step, setStep] = useState<1 | 2>(1);
  const [newAdministrator, setNewAdministrator] =
    useState<NewAdministratorInterface>(defaultAdministrator);
  const [modules, setModules] = useState<ModuleInterface[]>([]);
  const [nextButtonIsActive, setNextButtonIsActive] = useState<boolean>(false);
  const [passwordConditions, setPasswordConditions] = useState<PasswordConditionInterface>({
    length: false,
    upperCase: false,
    lowerCase: false
  });
  const [addButtonActive, setAddButtonActive] = useState<boolean>(false);
  const [isAddButtonDelayedActive, setIsAddButtonDelayedActive] = useState<boolean>(false);
  const [selectTouched, setSelectTouched] = useState(false);

  const isSelectValid = newAdministrator.tenantIds.length > 0;

  const handleClose = () => {
    setStep(1);
    setNewAdministrator(defaultAdministrator);
    onClose();
  };

  const fetchAvailableModules = async () => {
    try {
      const result = await apiTenantAdminController('modules').get('');
      setModules(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAvailableSchools = async () => {
    try {
      const response = await apiTenantTenantController('list-available-tenants').get(``, {
        params: {
          tenantId: tenant
        }
      });
      setAvailableSchools(response.data);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleAddAdministrator = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await apiTenantAdminController('').post('', {
        ...newAdministrator,
        phoneNumber: newAdministrator.phone,
        newPassword: newAdministrator.newPassword ? newAdministrator.newPassword : null
      });
      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 9000,
          title: 'Sukces',
          description: `Poprawnie dodano administratora`,
          status: 'success'
        });
        setShowAlert(true);
        onClose();
      }
    } catch (error: any) {
      const { violations, errorMessage } = error.response.data || {};
      const fieldError = violations?.[0];

      setAlertProperties({
        timeout: 9000,
        title: 'Błąd dodawania administratora',
        description: fieldError
          ? `Pole ${fieldError.fieldName} ${fieldError.message}`
          : errorMessage || '',
        status: 'error'
      });

      setShowAlert(true);
    }
  };

  const updateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewAdministrator((prev) => ({
      ...prev,
      [name]: value.length === 0 && name === 'newPassword' ? null : value
    }));
  };

  const handleCheckboxChange = (moduleId: number) => {
    setNewAdministrator((prev) => {
      const newModuleIds = prev.moduleIds.includes(moduleId)
        ? prev.moduleIds.filter((id) => id !== moduleId)
        : [...prev.moduleIds, moduleId];
      return { ...prev, moduleIds: newModuleIds };
    });
  };

  const checkPasswordConditions = (value: string) => {
    setPasswordConditions({
      length: value.length >= 8,
      upperCase: /[A-Z]/.test(value),
      lowerCase: /[a-z]/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      digit: /\d/.test(value)
    });
  };

  useEffect(() => {
    if (newAdministrator.newPassword !== null) {
      checkPasswordConditions(newAdministrator.newPassword);
    } else {
      setPasswordConditions({
        length: false,
        upperCase: false,
        lowerCase: false,
        specialChar: false,
        digit: false
      });
    }
  }, [newAdministrator.newPassword]);

  useEffect(() => {
    fetchAvailableSchools();
    fetchAvailableModules();
  }, []);

  useEffect(() => {
    if (
      newAdministrator.lastName.length > 3 &&
      newAdministrator.firstName.length > 3 &&
      newAdministrator.email.length > 3 &&
      isSelectValid &&
      (newAdministrator.newPassword === null ||
        (passwordConditions.upperCase && passwordConditions.lowerCase && passwordConditions.length))
    ) {
      setNextButtonIsActive(true);
    } else {
      setNextButtonIsActive(false);
    }
  }, [newAdministrator, passwordConditions, isSelectValid]);

  useEffect(() => {
    if (
      newAdministrator.lastName.length >= 3 &&
      newAdministrator.firstName.length >= 3 &&
      newAdministrator.email.length > 3 &&
      isSelectValid &&
      (newAdministrator.newPassword === null ||
        (passwordConditions.upperCase &&
          passwordConditions.lowerCase &&
          passwordConditions.length &&
          passwordConditions.digit))
    ) {
      setAddButtonActive(true);
    } else {
      setAddButtonActive(false);
    }
  }, [newAdministrator, passwordConditions, isSelectValid]);

  useEffect(() => {
    if (newAdministrator.newPassword?.length === 0) {
      setNewAdministrator((prev) => ({ ...prev, newPassword: null }));
    }
  }, [newAdministrator.newPassword]);

  useEffect(() => {
    if (step === 2) {
      const timer = setTimeout(() => {
        setIsAddButtonDelayedActive(true);
      }, 3000);

      return () => clearTimeout(timer);
    } else {
      setIsAddButtonDelayedActive(false);
    }
  }, [step]);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <form onSubmit={handleAddAdministrator}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <div className="flex items-center justify-center w-12 h-12 bg-brand-100 rounded-full mb-1.5 mt-1.5">
              <IconComponent iconName="userPlus" color="#FFAC05" />
            </div>
            <h2 className="font-semibold text-lg">Dodaj administratora ({step}/2)</h2>
            <p className="font-normal text-sm">
              Wpisz dane osoby uprawnionej do administracji placówką i nadaj jej uprawnienia w
              następnym kroku.{' '}
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            {step === 1 ? (
              <div className="flex flex-col gap-spacing-3xl">
                <FormInput
                  label="Imię"
                  placeholder="Wpisz imię"
                  inputParams={{
                    value: newAdministrator.firstName,
                    name: 'firstName',
                    required: true,
                    minLength: 3,
                    onChange: updateState
                  }}
                />
                <FormInput
                  label="Nazwisko"
                  placeholder="Wpisz nazwisko"
                  inputParams={{
                    value: newAdministrator.lastName,
                    name: 'lastName',
                    required: true,
                    minLength: 3,
                    onChange: updateState
                  }}
                />
                <FormInput
                  label="Adres email"
                  placeholder="Wpisz adres email"
                  inputParams={{
                    value: newAdministrator.email,
                    name: 'email',
                    required: true,
                    minLength: 3,
                    onChange: updateState
                  }}
                />
                <div className="flex flex-col gap-spacing-xs">
                  <label className="text-sm font-medium text-grayLight-700">Oddziały</label>
                  <Select
                    isMulti
                    placeholder="Wybierz oddziały"
                    value={availableSchools
                      .filter((unit) => newAdministrator.tenantIds.includes(unit.id))
                      .map((unit) => ({
                        label: unit.name,
                        value: unit.id
                      }))}
                    onChange={(value) => {
                      setNewAdministrator((prev) => ({
                        ...prev,
                        tenantIds: value.map((item: any) => item.value)
                      }));
                    }}
                    options={availableSchools.map((unit) => ({
                      label: unit.name,
                      value: unit.id
                    }))}
                    onBlur={() => setSelectTouched(true)}
                  />
                  {selectTouched && !isSelectValid && (
                    <p className="text-red-500 text-sm">Proszę wybrać co najmniej jeden oddział.</p>
                  )}
                </div>
                <FormInput
                  label="Numer telefonu"
                  placeholder="Wpisz numer telefonu"
                  inputParams={{
                    value: newAdministrator.phone === null ? '' : newAdministrator.phone,
                    name: 'phone',
                    required: true,
                    minLength: 11,
                    onChange: (e) => {
                      e.target.value = handlePhoneNumberChange(e.target.value);
                      updateState(e);
                    }
                  }}
                />
                <FormInput
                  label="Wpisz hasło tymczasowe"
                  placeholder="Wpisz hasło"
                  inputParams={{
                    value:
                      newAdministrator.newPassword === null ? '' : newAdministrator.newPassword,
                    name: 'newPassword',
                    type: 'text',
                    minLength: 3,
                    onChange: updateState
                  }}
                />
                <div className="grid gap-spacing-lg">
                  <AutoCheck
                    state={passwordConditions.length}
                    labelText="Hasło musi zawierać minimum 8 znaków"
                  />
                  <AutoCheck
                    state={passwordConditions.specialChar || false}
                    labelText="Hasło musi zawierać co najmniej jeden znak specjalny"
                  />
                  <AutoCheck
                    state={passwordConditions.upperCase || false}
                    labelText="Hasło musi zawierać co najmniej jedną dużą literę"
                  />
                  <AutoCheck
                    state={passwordConditions.digit || false}
                    labelText="Hasło musi zawierać co najmniej jedną cyfrę"
                  />
                  <AutoCheck
                    state={passwordConditions.lowerCase || false}
                    labelText="Hasło musi zawierać co najmniej jedną małą literę"
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-spacing-3xl">
                {modules.map((module) => (
                  <CheckboxInput
                    key={module.name}
                    textSize="sm"
                    checkboxParams={{
                      value: module.name,
                      isChecked: newAdministrator?.moduleIds.includes(module.id),
                      onChange: () => handleCheckboxChange(module.id)
                    }}
                    text={
                      <div>
                        <p className="font-medium text-sm text-grayLight-700">
                          {module.displayName}
                        </p>
                        <p className="font-normal text-sm text-grayLight-600">
                          {module.description}
                        </p>
                      </div>
                    }
                  />
                ))}
              </div>
            )}
          </ModalBody>
          <Divider className="pt-spacing-2xl pb-spacing-2xl" />
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            {step === 1 ? (
              <CustomChakraButton
                hierarchy="secondaryColor"
                size="lg"
                iconPosition="onlyText"
                buttonProps={{
                  type: 'button',
                  className: 'grow',
                  onClick: handleClose
                }}>
                Zamknij
              </CustomChakraButton>
            ) : (
              <CustomChakraButton
                hierarchy="secondaryColor"
                size="lg"
                iconPosition="onlyText"
                buttonProps={{
                  type: 'button',
                  className: 'grow',
                  onClick: () => setStep(1)
                }}>
                Wróć
              </CustomChakraButton>
            )}
            {step === 1 ? (
              <CustomChakraButton
                hierarchy="primary"
                size="lg"
                iconPosition="onlyText"
                buttonProps={{
                  disabled: !nextButtonIsActive,
                  className: 'grow',
                  type: 'button',
                  onClick: () => {
                    setStep(2);
                  }
                }}>
                Dalej
              </CustomChakraButton>
            ) : (
              <CustomChakraButton
                hierarchy="primary"
                size="lg"
                iconPosition="onlyText"
                buttonProps={{
                  disabled: !addButtonActive || !isAddButtonDelayedActive,
                  className: 'grow',
                  type: 'submit'
                }}>
                Dodaj
              </CustomChakraButton>
            )}
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddAdministrator;
