import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter, Divider
} from "@chakra-ui/react";
import { HttpStatusCode } from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import { NewConsumerInterface } from "../../../../shared/type/consumer.type";
import handlePhoneNumberChange from "../../../../utils/handlePhoneNumberChange";
import CustomChakraButton from "../../../CustomChakraButton/CustomChakraButton";
import FormInput from "../../../Forms/FormInput/FormInput";

interface Props{
  isOpen: boolean,
  mode: 'employee' | 'teacher',
  handleClose: () => void
}

const defaultUser = {
  tenantId: 0,
  firstName: '',
  lastName: '',
  email: '',
  cardNumber: null,
  phoneNumber: '',
  newPassword: ''
}

const AddEmployeeOrTeacher = ({isOpen, mode, handleClose}: Props) => {
  const { setShow, setAlertProperties } = useAlertContext();
  const { apiTenantSpecialConsumerController } = useApi();
  const [ newEmployeeOrTeacher, setNewEmployeeOrTeacher ] = useState<NewConsumerInterface>(defaultUser)
  const { id } = useParams();

  const addNewEmployeeOrTeacher = async (e: React.FormEvent) => {
    e.preventDefault();

    const alertSettings = (title: string, description: string, status: "success" | "warning" | "error") => ({
      timeout: 9000,
      title,
      description,
      status,
    });

    try {
      const response = await apiTenantSpecialConsumerController(mode).post('', {
        ...newEmployeeOrTeacher,
        tenantId: Number(id),
        newPassword: null
      });

      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties(alertSettings("Sukces", "Poprawnie dodano nowego użytkownika", "success"));
        handleClose();
      } else {
        setAlertProperties(alertSettings("Niepowodzenie", "Błąd dodawania użytkownika", "warning"));
      }
    } catch (error: any) {

      if(error.response.status){
        const { status, data } = error?.response;

        if (status === HttpStatusCode.Conflict) {
          setAlertProperties(alertSettings("Niepowodzenie", data.errorMessage, "warning"));
        } else {
          setAlertProperties(alertSettings("Błąd", "Błąd dodawania użytkownika", "error"));
        }
      } else {
        setAlertProperties(alertSettings("Błąd", "Błąd dodawania użytkownika", "error"));
      }
    } finally {
      setShow(true);
    }
  };

  const updateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmployeeOrTeacher((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const formId = "add-employee-or-teacher-form";

  return(
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <form id={formId} onSubmit={addNewEmployeeOrTeacher}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Dodaj użytkownika</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <FormInput
              label="Imię"
              placeholder="Podaj imię"
              inputParams={{
                name: "firstName",
                type: 'text',
                required: true,
                minLength: 3,
                onChange: updateState
              }}
            />
            <FormInput
              label="Nazwisko"
              placeholder="Podaj nazwisko"
              inputParams={{
                name: "lastName",
                type: 'text',
                required: true,
                minLength: 3,
                onChange: updateState
              }}
            />
            <FormInput
              label="Numer telefonu"
              placeholder="Podaj numer telefonu"
              inputParams={{
                name: "phoneNumber",
                type: 'text',
                required: true,
                minLength: 3,
                onChange: (e) => {
                  e.target.value = handlePhoneNumberChange(e.target.value);
                  updateState(e)
                }
              }}
            />
            <FormInput
              label="Numer identyfikatora"
              placeholder="Podaj numer identyfikatora"
              inputParams={{
                name: "cardNumber",
                type: 'text',
                pattern: "\\d{10}",
                title: "Numer identyfikatora musi składać się z dokładnie 10 cyfr lub może być pusty.",
                maxLength: 10,
                required: false,
                onWheel: (e) => (e.target as HTMLInputElement).blur(),
                onInput: (e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9]/g, '');
                },
                onChange: updateState
              }}
            />
            <FormInput
              label="Adres email"
              placeholder="Podaj adres email"
              bottomElement={
                <p className='font-normal text-sm text-grayLight-600'>
                  Jeśli użytkownik nie posiada adresu zostanie mu nadany automatycznie login tymczasowy. Znajdziesz go potem na liście pracowników i w danych użytkownika.
                </p>
              }
              inputParams={{
                name: "email",
                type: 'email',
                required: true,
                minLength: 3,
                onChange: updateState
              }}
            />
          </ModalBody>
          <Divider className='pt-spacing-2xl pb-spacing-2xl'/>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: handleClose
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "button",
                onClick: addNewEmployeeOrTeacher,
                form: formId,
              }}
            >
              Dodaj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
};

export default AddEmployeeOrTeacher;
