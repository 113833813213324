import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { AxiosResponse, HttpStatusCode } from "axios";
import { useAlertContext } from "../../../../../context/AlertProvider";
import { useApi } from "../../../../../context/ApiProvider";
import {
  MessageConversationInterfaceWithPaginationInterface,
  MessageConversationInterface
} from "../../../../../shared/type/message.type";
import { PaginationInterface } from "../../../../../shared/type/paginationInterface.type";
import ChatComponent from "../../../../ChatComponent/ChatComponent";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import IconComponent from "../../../../IconComponent/IconComponent";

const SingleAdminMessage = () => {
  const { apiTenantMessageController } = useApi();
  const { id: threadId } = useParams();
  const { setShow, setAlertProperties } = useAlertContext();
  const [messages, setMessages] = useState<MessageConversationInterface[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get("title");
  const basePath = location.pathname.split('/').slice(0, -2).join('/');

  const fetchMessages = async () => {
    try {
      const response: AxiosResponse<MessageConversationInterfaceWithPaginationInterface> = await apiTenantMessageController('message-list').get('', {
        params: {
          messageThreadId: threadId,
          pageNumber: 1,
          pageSize: 10000
        }
      });

      const { data, next, hasNextPage, totalCount } = response.data;

      setActualPage({ next, hasNextPage, totalCount });
      setMessages(data);

    } catch (error) {
      setAlertProperties({
        status: 'error',
        timeout: 9000,
        description: 'Błąd pobierania wiadomości',
        title: 'Błąd'
      });
      setShow(true);
    }
  };

  const sendNewMessage = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!newMessage.trim()) {
      setAlertProperties({
        status: 'warning',
        timeout: 9000,
        description: 'Wiadomość nie może być pusta',
        title: 'Błąd'
      });
      setShow(true);
      return;
    }

    try {
      const response = await apiTenantMessageController('add-message-to-thread').post('', {
        messageThreadId: Number(threadId),
        content: newMessage,
      });

      if (response.status === HttpStatusCode.Ok) {
        setNewMessage('');
        fetchMessages();
      }

    } catch (error) {
      setAlertProperties({
        status: 'error',
        timeout: 9000,
        description: 'Wystąpił problem podczas wysyłania wiadomości',
        title: 'Błąd'
      });
      setShow(true);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const response: AxiosResponse<MessageConversationInterfaceWithPaginationInterface> = await apiTenantMessageController('message-list').get('', {
          params: {
            messageThreadId: threadId,
            pageNumber: 1,
            pageSize: 50
          }
        });

        const { data } = response.data;

        if (data.length !== messages.length) {
          setMessages(data);
          setAlertProperties({
            status: 'info',
            timeout: 3000,
            description: 'Masz nowe wiadomości!',
            title: 'Nowa wiadomość'
          });
          setShow(true);
        }

      } catch (error) {
        console.error("Błąd podczas pobierania nowych wiadomości:", error);
      }
    }, 1000 * 60 * 2); // 2 min

    return () => clearInterval(intervalId);
  }, [messages, threadId]);

  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <section className="flex flex-col h-full gap-spacing-xl w-full pb-20">
      <div className='pt-spacing-md pr-spacing-3xl pb-spacing-md pl-spacing-3xl gap-spacing-md'>
        <Link
          to={`${basePath}`}
          className='flex items-center gap-spacing-sm pt-2.5 pr-spacing-xl pb-2.5 pl-spacing-xl'>
          <IconComponent iconName='arrowLeft' />
          <p style={{ fontSize: '16px' }} className='font-semibold text-grayWarm-950'>Wróć</p>
        </Link>
        <h2 className='text-2xl font-semibold text-grayLight-900'>{title || 'Temat'}</h2>
      </div>

      <div className='pr-spacing-7xl pl-spacing-7xl flex-grow overflow-y-auto'>
        <ChatComponent messages={messages} actor='admin' />
      </div>

      <div className='flex items-center border-t pr-spacing-5xl pl-spacing-5xl border-grayLight-200'>
        <form
          onSubmit={sendNewMessage}
          className='flex w-full pt-spacing-md pr-spacing-3xl pb-spacing-md pl-spacing-3xl gap-spacing-lg'>
          <textarea
            name=""
            id=""
            required
            rows={5}
            value={newMessage}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                sendNewMessage(e);
              }
            }}
            onChange={(e) => {
              setNewMessage(e.target.value);
            }}
            placeholder="Napisz wiadomość..."
            className='border resize-none border-grayLight-300 w-full flex-grow gap-spacing-md rounded-lg pt-2.5 pr-3.5 pb-2.5 pl-3.5'
          />
          <div>
            <CustomChakraButton
              buttonProps={{
                type: 'submit'
              }}
              size='lg'
              hierarchy='primary'
              iconPosition='right'
              iconColor='#292524'
              icon='send'>
              Wyślij
            </CustomChakraButton>
          </div>
        </form>
      </div>
    </section>
  )
};

export default SingleAdminMessage;