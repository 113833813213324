import { AxiosResponse, HttpStatusCode } from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAlertContext } from '../../../context/AlertProvider';
import { useApi } from '../../../context/ApiProvider';
import { PasswordConditionInterface } from '../../../shared/type/passwordCondition.type';
import handlePhoneNumberChange from '../../../utils/handlePhoneNumberChange';
import CustomAlert from '../../CustomAlert/CustomAlert';
import CustomChakraButton from '../../CustomChakraButton/CustomChakraButton';
import AutoCheck from '../../Forms/AutoCheck/AutoCheck';
import CheckboxInput from '../../Forms/CheckboxInput/CheckboxInput';
import FormInput from '../../Forms/FormInput/FormInput';
import ConfirmRegistrationEmail from '../ConfirmRegistrationEmail/ConfirmRegistrationEmail';
import {useSafeTranslation} from "../../../hooks/useSafeTranslation";

interface ParentRegistration {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  tenantUrlPart: string;
  mainStatuteAcceptance: boolean;
  tenantStatuteAcceptance: boolean;
}

interface ParentRegistrationContext {
  mainStatuteUrl: string;
  tenantStatuteUrl: string;
  tenantName: string;
  tenantUrlPart: string;
  enforcePhoneNumber: boolean;
  registrationActive: boolean;
  supportEmail: string
}

const Registration = () => {
    const {id} = useParams();
    const { t } = useSafeTranslation();
    const {apiParentRegistrationController} = useApi();
    const {setShow: setShowAlert, setAlertProperties} = useAlertContext();
    const [repeatPassword, setRepeatPassword] = useState<string>('')
    const [parentRegistrationContext, setParentRegistrationContext] = useState<ParentRegistrationContext>({
        mainStatuteUrl: '',
        tenantStatuteUrl: '',
        tenantName: '',
        tenantUrlPart: '',
        enforcePhoneNumber: false,
        supportEmail: '',
        registrationActive: false
    })
    const [parent, setParent] = useState<ParentRegistration>({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        tenantUrlPart: '',
        mainStatuteAcceptance: false,
        tenantStatuteAcceptance: false
    });
    const [passwordConditions, setPasswordConditions] = useState<PasswordConditionInterface>({
        length: false,
        specialChar: false,
        digit: false
    });
    const [isRegistrationButtonActive, setIsRegistrationButtonActive] = useState<boolean>(false);
    const [showWrongUrlAlert, setShowWrongUrlAlert] = useState<boolean>(false);
    const [showRegistrationConfirmation, setShowRegistrationConfirmation] = useState<boolean>(false);

    const registerUser = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {
            const response = await apiParentRegistrationController('').post('', {
                ...parent,
                tenantUrlPart: parentRegistrationContext.tenantUrlPart,
                language: 'pl'
            });

            if (response.status === HttpStatusCode.Created) {
                setShowRegistrationConfirmation(true);
            }
        } catch (error: any) {
            setShowAlert(true);
            setAlertProperties({
                status: 'error',
                timeout: 9000,
                title: 'Błąd',
                description: error.response.data.errorMessage
            });
        }
    };

    const fetchContext = async () => {
        try {
            const response: AxiosResponse<ParentRegistrationContext> =
                await apiParentRegistrationController('context').get(`/${id}`);
            setParentRegistrationContext(response.data);
            if (response.status !== HttpStatusCode.Ok) {
                setShowWrongUrlAlert(true);
            }
        } catch (error) {
            setShowWrongUrlAlert(true);
        }
    };

    const checkPasswordConditions = (value: string) => {
        setPasswordConditions({
            length: value.length >= 8,
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
            digit: /\d/.test(value)
        });
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setParent((prev) => ({...prev, password: value}));
        checkPasswordConditions(value);
    };

    const checkRequirements = () => {
        if (
            !parent.tenantStatuteAcceptance ||
            !parent.mainStatuteAcceptance ||
            !parent.password ||
            !parent.email ||
            !parent.firstName ||
            !parent.lastName
        ) {
            setIsRegistrationButtonActive(false);
            return;
        }

        if (
            !passwordConditions.length ||
            !passwordConditions.specialChar ||
            !passwordConditions.digit
        ) {
            setIsRegistrationButtonActive(false);
            return;
        }

        setIsRegistrationButtonActive(true);
    };

    useEffect(() => {
        checkRequirements();
    }, [parent, passwordConditions]);

    useEffect(() => {
        if (id) {
            fetchContext();
        }
    }, [id]);

    if(!parentRegistrationContext.registrationActive){
        return (
            <h2 className="font-semibold text-2xl text-grayLight-900">
                {t('register_page.registration_inactive')}
            </h2>
        )
    }

    return (
        <div
            className="LoginPage__login-form w-full flex flex-col justify-start items-start pt-20 lg:pt-44 overflow-y-auto no-scrollbar max-h-screen">
            <div className="grid gap-8 m-auto w-full pl-container-padding-mobile pr-container-padding-mobile">
                <div className="LoginPage__header flex flex-col items-start gap-3">
                    <h2 className="font-semibold text-4xl text-grayLight-900">
                        {t('register_page.title')}
                    </h2>
                    <p className="font-normal text-base text-grayLight-600">
                        {t('register_page.subtitle')}
                    </p>
                </div>
                <form className="max-w-2xl grid gap-spacing-3xl w-full pb-10" onSubmit={registerUser}>
                    <FormInput
                        label={t('register_page.first_name')}
                        inputParams={{
                            placeholder: t('register_page.first_name_placeholder'),
                            type: 'text',
                            name: 'firstName',
                            required: true,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                setParent((prev) => ({...prev, [e.target.name]: e.target.value}));
                            },
                            value: parent.firstName
                        }}
                    />
                    <FormInput
                        label={t('register_page.last_name')}
                        inputParams={{
                            placeholder: t('register_page.last_name_placeholder'),
                            type: 'text',
                            name: 'lastName',
                            required: true,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                setParent((prev) => ({...prev, [e.target.name]: e.target.value}));
                            },
                            value: parent.lastName
                        }}
                    />
                    <FormInput
                        label={t('register_page.email')}
                        inputParams={{
                            placeholder: t('register_page.email_placeholder'),
                            autoComplete: 'off',
                            type: 'email',
                            name: 'email',
                            required: true,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                setParent((prev) => ({...prev, [e.target.name]: e.target.value}));
                            },
                            value: parent.email
                        }}
                    />
                    <FormInput
                        label={t('register_page.password')}
                        bottomElement={
                            <p className="text-sm font-normal text-grayLight-600">
                                {t('register_page.password_hint')}
                            </p>
                        }
                        inputParams={{
                            placeholder: t('register_page.password_placeholder'),
                            autoComplete: 'new-password',
                            type: 'password',
                            name: 'password',
                            required: true,
                            onChange: handlePasswordChange,
                            value: parent.password
                        }}
                    />
                    <FormInput
                        label={t('register_page.repeat_password')}
                        inputParams={{
                            placeholder: t('register_page.repeat_password_placeholder'),
                            autoComplete: 'new-password',
                            type: 'password',
                            name: 'password',
                            required: true,
                            onChange: (e) => setRepeatPassword(e.target.value),
                            value: repeatPassword
                        }}
                    />
                    <div className="grid gap-spacing-lg">
                        <AutoCheck
                            state={passwordConditions.length}
                            labelText={t('register_page.password_conditions.min_length')}
                        />
                        <AutoCheck
                            state={passwordConditions.specialChar || false}
                            labelText={t('register_page.password_conditions.special_char')}
                        />
                        <AutoCheck
                            state={passwordConditions.digit || false}
                            labelText={t('register_page.password_conditions.digit')}
                        />
                    </div>
                    <FormInput
                        label={`${t('register_page.phone_number')}${parentRegistrationContext.enforcePhoneNumber ? '*' : ''}`}
                        inputParams={{
                            placeholder: t('register_page.phone_number_placeholder'),
                            autoComplete: 'off',
                            type: 'text',
                            name: 'phoneNumber',
                            required: parentRegistrationContext.enforcePhoneNumber,
                            onChange: (e) => {
                                setParent((prev) => ({...prev, phone: handlePhoneNumberChange(e.target.value)}));
                            },
                            value: parent.phone
                        }}
                    />
                    <CheckboxInput
                        textSize="sm"
                        checkboxParams={{
                            isChecked: parent.mainStatuteAcceptance,
                            onChange: () => {
                                setParent((prev) => ({
                                    ...prev,
                                    mainStatuteAcceptance: !prev.mainStatuteAcceptance
                                }));
                            }
                        }}
                        text={
                            <div className="flex gap-1">
                                <p className="text-sm font-medium text-red-500">*</p>
                                <p className="text-sm font-medium text-grayLight-600">
                                    {' '}
                                    {t('register_page.accept_terms_portal')}
                                </p>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={parentRegistrationContext.mainStatuteUrl}
                                    className="cursor-pointer">
                                    <p className="text-sm text-grayLight-900 font-semibold underline">
                                        {' '}
                                        {t('register_page.portal_terms')}
                                    </p>
                                </a>
                            </div>
                        }
                    />
                    <CheckboxInput
                        textSize="sm"
                        checkboxParams={{
                            isChecked: parent.tenantStatuteAcceptance,
                            onChange: () => {
                                setParent((prev) => ({
                                    ...prev,
                                    tenantStatuteAcceptance: !prev.tenantStatuteAcceptance
                                }));
                            }
                        }}
                        text={
                            <div className="flex gap-1">
                                <p className="text-sm font-medium text-red-500">*</p>
                                <p className="text-sm font-medium text-grayLight-600">
                                    {t('register_page.accept_terms_school')}
                                </p>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={parentRegistrationContext.tenantStatuteUrl}
                                    className="cursor-pointer">
                                    <p className="text-sm text-grayLight-900 font-semibold underline">
                                        {t('register_page.school_terms')}
                                    </p>
                                </a>
                            </div>
                        }
                    />
                    <CustomChakraButton
                        hierarchy="primary"
                        size="lg"
                        iconPosition="onlyText"
                        buttonProps={{
                            type: 'submit',
                            disabled: !isRegistrationButtonActive
                        }}>
                        {t('register_page.create_account')}
                    </CustomChakraButton>
                </form>
            </div>
            {showRegistrationConfirmation && (
                <div className="fixed z-50 top-0 left-0 w-screen h-screen">
                    <ConfirmRegistrationEmail
                        onBack={() => {
                            setShowRegistrationConfirmation(false);
                        }}
                        userEmail={parent.email}
                        supportEmail={parentRegistrationContext.supportEmail}
                    />
                </div>
            )}

            <CustomAlert
                onCancelAction={() => {
                    setShowWrongUrlAlert(false);
                }}
                onConfirmAction={() => {
                    setShowWrongUrlAlert(false);
                }}
                handleOpen={showWrongUrlAlert}
                header={<h2>{t('register_page.ups')}</h2>}
                content={
                    <p>
                        {t('register_page.registration_error_description')}
                    </p>
                }
                cancelButtonText=""
                confirmButton={{
                    iconPosition: 'onlyText',
                    hierarchy: 'primary',
                    size: 'sm'
                }}
                confirmButtonText={t('register_page.close_button')}
            />
        </div>
    );
};

export default Registration;
