import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { useApi } from '../../../../context/ApiProvider';
import { AdminDashboardDataDaysInterface } from '../../../../shared/type/administrator.type';
import { AxiosResponse, HttpStatusCode } from 'axios';
import moment from 'moment';

export const useAdminCalendar = () => {
  const today = new Date();
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState<{
    year: number;
    month: number;
    day: number;
  }>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });
  const [dashboardDays, setDashboardDays] = useState<
    AdminDashboardDataDaysInterface[] | undefined
  >();
  const [loading, setIsLoading] = useState(false);
  const { id: tenantId } = useParams<{ id: string }>();
  const { apiAdminDashboardDaysController } = useApi();

  const fetchDashboardDays = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<AdminDashboardDataDaysInterface[]> =
        await apiAdminDashboardDaysController('dashboard-days').get(``, {
          params: {
            tenantId,
            month: currentDate.month,
            year: currentDate.year
          }
        });

      if (response.status !== HttpStatusCode.Ok) {
        return;
      }

      setDashboardDays(response.data);
    } catch (error: any) {
      console.error('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setDashboardDays, tenantId, currentDate]);

  const onSlotClick = (date: Date) => {
    const formattedDate = date.toLocaleDateString('sv-SE');

    return navigate(`/tenantAdmin/id/${tenantId}/absences?currentDate=${formattedDate}`);
  };

  useEffect(() => {
    if (!!tenantId) {
      fetchDashboardDays();
    }
  }, [fetchDashboardDays, tenantId, currentDate]);

  return { loading, dashboardDays, tenantId, currentDate, setCurrentDate, onSlotClick };
};
