import { TableContainer, Thead, Th, Box, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { useApi } from '../../../../../context/ApiProvider';
import { useEditTeacherOrEmployeeContext } from '../../../../../context/EditTeacherOrEmployeeProvider';
import { AddEmployeeOrTeacherAlertInterface } from '../../../../../shared/type/addEmployeeOrTeacherAlert.type';
import { ConsumerInterface } from '../../../../../shared/type/consumer.type';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import { DataTable } from '../../../../DataTable/DataTable';
import AddEmployeeOrTeacher from '../../AddEmployeeOrTeacher/AddEmployeeOrTeacher';
import DeleteEmployeeOrTeacher from '../../DeleteEmployeeOrTeacher/DeleteEmployeeOrTeacher';
import CustomAlert from '../../../../CustomAlert/CustomAlert';
import IconComponent from '../../../../IconComponent/IconComponent';

const defaultDeleteElement = {
  id: 0,
  alertIsOpen: false
};

const defaultActivateUserPopUp = {
  isOpen: false,
  userId: 0
};

const TeachersTable = () => {
  const { setId, fetchUsers, setMode } = useEditTeacherOrEmployeeContext();
  const columnHelper = createColumnHelper<ConsumerInterface>();
  const location = useLocation();
  const [teachers, setTeachers] = useState<ConsumerInterface[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const { apiTenantSpecialConsumerController, apiTenantUserController } = useApi();
  const [deleteElement, setDeleteElement] = useState<{
    id: number;
    alertIsOpen: boolean;
  }>(defaultDeleteElement);
  const [addEmployeeOrTeacherAlert, setAddEmployeeOrTeacherAlert] =
    useState<AddEmployeeOrTeacherAlertInterface>({
      mode: 'teacher',
      isOpen: false
    });

  const [activateUserPopUp, setActivateUserPopUp] = useState(defaultActivateUserPopUp);

  const fetchTeachers = async () => {
    try {
      setIsLoading(true);
      const response = await apiTenantSpecialConsumerController('teacher-list').get(`/${id}`);
      setTeachers(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const activateUser = async () => {
    try {
      await apiTenantUserController('').post(`/activate/${activateUserPopUp.userId}`);
    } catch (error) {
      console.error(error);
    } finally {
      fetchTeachers();
    }
  };

  const columns = [
    columnHelper.accessor('lastName', {
      cell: (info) => {
        const { lastName, firstName, userId } = info.row.original;
        const newPath = location.pathname.replace(
          'teachers-table',
          `single-teacher/${userId}/list-of-orders`
        );
        return (
          <p className="text-grayLight-900 font-medium text-sm underline underline-offset-4">
            <Link to={newPath}>
              {lastName} {firstName}
            </Link>
          </p>
        );
      },
      header: 'Nazwisko Imię'
    }),
    columnHelper.accessor('login', {
      cell: (info) => {
        const login = info.getValue();

        return <p className="text-grayLight-900 font-medium text-sm">{login}</p>;
      },
      header: 'Adres email / login'
    }),
    columnHelper.accessor('phoneNumber', {
      cell: (info) => {
        const phoneNumber = info.getValue();

        return (
          <p className="text-grayLight-900 font-normal text-sm">{phoneNumber ? phoneNumber : ''}</p>
        );
      },
      header: 'Telefon'
    }),
    columnHelper.accessor('consumerId', {
      cell: (info) => {
        const consumerId = info.getValue();

        return <p className="text-grayLight-900 font-normal text-sm">{consumerId}</p>;
      },
      header: 'Identyfikator'
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Edycja',
      cell: (info) => {
        const { userId, active } = info.row.original;
        const newPath = location.pathname.replace(
          'teachers-table',
          `single-teacher/${userId}/balance-history`
        );
        return (
          <div className="flex gap-spacing-sm">
            <button
              onClick={() => {
                setDeleteElement({
                  id: userId,
                  alertIsOpen: true
                });
              }}
              className="font-semibold text-sm text-grayLight-600 bg-transparent border-none">
              Usuń
            </button>
            {!active && (
              <button
                onClick={() => {
                  setActivateUserPopUp({
                    isOpen: true,
                    userId: userId
                  });
                }}
                className="font-semibold text-sm text-grayLight-600 bg-transparent border-none">
                Aktywuj
              </button>
            )}
            <Link
              to={newPath}
              onClick={() => {
                setId(userId);
                setMode('teacher');
                fetchUsers();
              }}>
              <button className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none">
                Otwórz
              </button>
            </Link>
          </div>
        );
      }
    })
  ];

  useEffect(() => {
    fetchTeachers();
  }, [id]);

  return (
    <section className="TeachersTable p-spacing-xl w-full">
      <TableContainer h={800} overflowY="auto" className="Branches__table bg-white rounded-lg">
        <DataTable
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base normal-case text-grayLight-900">
                        Nauczyciele
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm normal-case text-grayLight-700">
                        Lista nauczycieli
                      </Text>
                    </Box>
                  </div>
                  <div>
                    <CustomChakraButton
                      size="md"
                      hierarchy="primary"
                      iconPosition="left"
                      icon="plus"
                      buttonProps={{
                        type: 'button',
                        onClick: () => {
                          setAddEmployeeOrTeacherAlert({ mode: 'teacher', isOpen: true });
                        }
                      }}>
                      Dodaj użytkownika
                    </CustomChakraButton>
                  </div>
                </div>
              </Th>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={teachers}
        />
      </TableContainer>
      {deleteElement.alertIsOpen ? (
        <DeleteEmployeeOrTeacher
          isOpen={deleteElement.alertIsOpen}
          deleteId={deleteElement.id}
          onCancel={() => {
            setDeleteElement(defaultDeleteElement);
            fetchTeachers();
          }}
          onConfirm={() => {
            setDeleteElement(defaultDeleteElement);
            fetchTeachers();
          }}
          userType="employee"
        />
      ) : null}
      {addEmployeeOrTeacherAlert.isOpen ? (
        <AddEmployeeOrTeacher
          handleClose={() => {
            setAddEmployeeOrTeacherAlert((prev) => ({ ...prev, isOpen: false }));
            fetchTeachers();
          }}
          isOpen={addEmployeeOrTeacherAlert.isOpen}
          mode={addEmployeeOrTeacherAlert.mode}
        />
      ) : null}
      <CustomAlert
        handleOpen={activateUserPopUp.isOpen}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" />
          </div>
        }
        header={'Aktywować Nauczyciela?'}
        content={
          'Aktywacja pozwoli nauczycielowi się logować bez konieczności skorzystatania z linku aktywacyjnego.'
        }
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText={'Anuluj'}
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        confirmButtonText={'Aktywuj'}
        onConfirmAction={() => {
          activateUser();
          setActivateUserPopUp(defaultActivateUserPopUp);
        }}
        onCancelAction={() => {
          setActivateUserPopUp(defaultActivateUserPopUp);
        }}
      />
    </section>
  );
};

export default TeachersTable;
