import { Switch } from '@chakra-ui/react';
import React from 'react';
import { useSettingFormContext } from '../../../../../../context/SettingsFormContext';

const OutputSettings = () => {
  const { settingsForm, updateForm } = useSettingFormContext();

    return (
        <div className="grow flex flex-col gap-spacing-lg">
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
                Ustawienia wydawki
            </div>
            <div className="flex flex-col gap-spacing-4xl">
                <div className="w-full flex flex-row gap-spacing-lg">
                    <div className="flex items-center gap-spacing-3xl">
                        <Switch
                            size="lg"
                            colorScheme="customOrange"
                            name="mealDistributionSalesActive"
                            onChange={(e) => {
                                updateForm(e.target.name, !settingsForm?.mealDistributionSalesActive);
                            }}
                            isChecked={settingsForm?.mealDistributionSalesActive}
                        />
                    </div>
                    <div className="Setting__sectionHeader">
                        <p>Sprzedaż na wydawce</p>
                        <p>Umożliwia sprzedaż posiłków na tablecie wydawki</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-spacing-4xl">
                <div className="w-full flex flex-row gap-spacing-lg">
                    <div className="flex items-center gap-spacing-3xl">
                        <Switch
                            size="lg"
                            colorScheme="customOrange"
                            name="allMealsCheckIn"
                            onChange={(e) => {
                                updateForm(e.target.name, !settingsForm?.allMealsCheckIn);
                            }}
                            isChecked={settingsForm?.allMealsCheckIn}
                        />
                    </div>
                    <div className="Setting__sectionHeader">
                        <p>Automatyczne odbieranie dań</p>
                        <p>
                            Podczas odbioru posiłków wszystkie dania zamówione na dany dzień są automatycznie
                            oznaczone jako odebrane.{' '}
                        </p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default OutputSettings;
