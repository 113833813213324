import { HttpStatusCode } from "axios";
import React, { useState } from "react";
import { ApiSuperAdminFixUrl } from "../../../api/axios.url";
import { useAlertContext } from "../../../context/AlertProvider";
import { useApi } from "../../../context/ApiProvider";
import CustomChakraButton from "../../CustomChakraButton/CustomChakraButton";

const SqlEditor = () => {
  const { apiSuperAdminFixController } = useApi();
  const { setShow, setAlertProperties } = useAlertContext();
  const [outcome, setOutcome] = useState<string>('');
  const [sql, setSql] = useState<{
    query: string,
  }>({
    query: ''
  });


  const [ textResponse, setTextResponse ] = useState<{
    saldoDifference: string | undefined,
    saldo: string | undefined,
    data: string | undefined
  }>({
    saldoDifference: undefined,
    saldo: undefined,
    data: undefined,
  })

  const handleSql = async () =>{
   try {
        const response = await apiSuperAdminFixController('perform-sql').post('', sql);
        if(response.status !== HttpStatusCode.Ok){
          setAlertProperties({
            timeout: 9000,
            title: "Błąd",
            description: `Błąd zapytania sql`,
            status: "error"
          });
          setShow(true);
        } else{
          setAlertProperties({
            timeout: 9000,
            title: "Udany fix",
            description: `Fix się udał`,
            status: "error"
          });
          setShow(true);
          setOutcome(response.data)
        }
      } catch (error: any) {
      }
  }


  return (
    <section className='w-full h-full p-spacing-4xl items-start justify-center'>
      <div className="Settings__header flex justify-between w-full">
        <p className="m-0 p-0 font-semibold text-lg text-grayLight-900">Zrób zapytanie</p>
        <textarea placeholder='Wpisz zapytanie' id="" cols={30} rows={10}
          required
          onChange={(e) => {
            setSql((prev) => ({ ...prev, query: e.target.value }));
          }}
          className='resize-none gap-spacing-3xl rounded-md border pt-2.5 pr-3.5 pb-2.5 pl-3.5 border-grayLight-300'/>
        <CustomChakraButton
          hierarchy="secondaryColor"
          size="sm"
          iconPosition="onlyText"
          buttonProps={{
            onClick: () => {handleSql()},
            className: "grow",
          }}
        >
          Zrób zapytanie
        </CustomChakraButton>
      </div>
      <h1>Odpowiedź</h1>
      <p dangerouslySetInnerHTML={{ __html: outcome.replace(/\n/g, "<br/>") }}>
      </p>
    </section>
  );
};

export default SqlEditor;
