import { Instruction } from "../../../../shared/type/instruction.interface";
import "./SingleTutorial.css";
import React from "react";

const SingleTutorial = (tutorial: Instruction) => {
    return (
        <div className="SingleTutorial flex flex-col border gap-spacing-xl p-spacing-xl rounded-xl">
            <div className="flex flex-col border-b box-border border-brand-300">
                <h3 className="text-2xl font-semibold text-grayLight-900">{tutorial.title}</h3>
                <p className="font-normal text-grayLight-600" style={{ fontSize: "16px" }}>
                    {tutorial?.description}
                </p>
            </div>
            <div className="video-wrapper flex item-center justify-center">
                {tutorial.url.includes("youtube.com") || tutorial.url.includes("youtu.be") ? (
                    <iframe
                        className='lg:w-[960px] lg:h-[600px] rounded-xl'
                        src={tutorial.url.replace("watch?v=", "embed/")}
                        title="Tutorial Video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                ) : (
                    <video controls>
                        <source src={tutorial.url} type="video/mp4" />
                        Twoja przeglądarka nie obsługuje elementu wideo.
                    </video>
                )}
            </div>
        </div>
    );
};

export default SingleTutorial;
