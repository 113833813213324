import './TenantAdminDashboard.css';
import React, { useEffect } from 'react';

import { MenuDivider, MenuItem } from '@chakra-ui/react';
import {Routes, Route, useParams, useNavigate, useLocation, useMatch} from 'react-router-dom';
import WideHeader from '../../component/Headers/WideHeader/WideHeader';
import Absences from '../../component/TenantAdminComponents/Absences/Absences';
import AdminSettings from '../../component/TenantAdminComponents/AdminSettings/AdminSettings';
import Kitchen from '../../component/TenantAdminComponents/Kitchen/Kitchen';
import MailBox from '../../component/TenantAdminComponents/MailBox/MailBox';
import SingleAdminMessage from '../../component/TenantAdminComponents/MailBox/Messages/SingleAdminMessage/SingleAdminMessage';
import Reports from '../../component/TenantAdminComponents/Reports/Reports';
import School from '../../component/TenantAdminComponents/School/School';
import Users from '../../component/TenantAdminComponents/Users/Users';
import { useApi } from '../../context/ApiProvider';

import { SideMenuOptionInterface } from '../../shared';
import IconComponent from '../../component/IconComponent/IconComponent';
import useAuth from '../../hooks/useAuth';
import { useSideNavContext } from '../../context/SideNavProvider';
import { Dashboard } from '../../component/TenantAdminComponents/Dashboard/Dashboard';
import Tutorials from "../../component/ChildOwnerComponents/Tutorials/Tutorials";
import {Instruction} from "../../shared/type/instruction.interface";

const TenantAdminDashboard = () => {
  // const { id: tenant } = useParams<{ id: string }>();
  const params = useParams();
  const match = useMatch('/tenantAdmin/id/:tenantId/*');
  const tenant = match?.params.tenantId || params.id;

  const { logout } = useAuth();
  const { setNavOptions, toggleShowNav, setToggleMode } = useSideNavContext();
  const { apiPrivateSecurityController } = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const notSetOverflowYAuto = location.pathname.includes('absences');

  useEffect(() => {
    setToggleMode('minimal');
  }, []);

  useEffect(() => {
    setNavOptions(menuOptions);
  }, [tenant]);

  const menuOptions: SideMenuOptionInterface[] = [
    {
      header: 'Strona Główna',
      icon: 'happyHomeIcon',
      link: `id/${tenant}/dashboard`
    },

    {
      header: 'Zamówienia',
      icon: 'calendar',
      link: `id/${tenant}/absences`
    },
    {
      header: 'Kuchnia',
      icon: 'cutlery',
      children: [
        {
          header: 'Jadłospis',
          icon: 'bookOpen2',
          link: `id/${tenant}/kitchen/food-menu/meal-plan`
        },
        {
          header: 'Alergeny',
          icon: 'alertTriangle',
          link: `id/${tenant}/kitchen/allergens`
        },
        {
          header: 'Podsumowanie',
          icon: 'layoutAlt03',
          link: `id/${tenant}/kitchen/summary`
        }
      ]
    },
    {
      header: 'Użytkownicy',
      icon: 'users',
      children: [
        {
          header: 'Dzieci',
          icon: 'faceSmile',
          link: `id/${tenant}/users/children/children-table`
        },
        {
          header: 'Rodzice',
          icon: 'users2',
          link: `id/${tenant}/users/parents/parents-table`
        },
        {
          header: 'Nauczyciele',
          icon: 'graduationHat',
          link: `id/${tenant}/users/teachers/teachers-table`
        },
        {
          header: 'Pracownicy',
          icon: 'userSquare',
          link: `id/${tenant}/users/employees/employees-table`
        }
      ]
    },
    {
      header: 'Raporty',
      icon: 'file',
      link: `id/${tenant}/reports`
    },
    {
      header: 'Wiadomości',
      icon: 'message',
      link: `id/${tenant}/mail-box/messages`
    },
    {
      header: 'Ustawienia',
      icon: 'settings04',
      children: [
        {
          header: `Klasy`,
          icon: 'stand',
          link: `id/${tenant}/school/classes`
        },
        {
          header: 'Dni wolne / blokady zamówień',
          icon: 'calendarDate',
          link: `id/${tenant}/school/free-days`
        },
        {
          header: 'System sprzedaży',
          icon: 'sliders02',
          link: `id/${tenant}/kitchen/sales-system/servings`
        },
        {
          header: 'Instytucje dofinansowujące',
          icon: 'coinsHand',
          link: `id/${tenant}/school/co-financing-institutions`
        },
        {
          header: 'Regulaminy',
          icon: 'file06',
          link: `id/${tenant}/school/school-statutes`
        },
        {
          header: 'Administratorzy',
          icon: 'imageUserCheck',
          link: `id/${tenant}/users/administrators/administrators-table`
        },
        {
          header: 'System',
          icon: 'sliders02',
          link: `id/${tenant}/school/school-settings`
        }
      ]
    },
    {
      header: 'Instrukcje',
      icon: 'question',
      link: 'instructions'
    }
  ];

  const menuList = (
    <>
      <MenuItem
        icon={
          <div className="Header__iconContainer">
            <IconComponent iconName="settings" className="w-full h-full" />
          </div>
        }
        onClick={function () {
          navigate('/tenantAdmin/settings');
        }}>
        Ustawienia
      </MenuItem>
      <MenuDivider />
      <MenuItem
        icon={
          <div className="Header__iconContainer">
            <IconComponent iconName="logOutDoor" className="w-full h-full" />
          </div>
        }
        onClick={async function () {
          await apiPrivateSecurityController('logout').get('');
          logout();
        }}>
        Wyloguj się
      </MenuItem>
    </>
  );

  useEffect(() => {
    toggleShowNav(true);
    setNavOptions(menuOptions);
  }, []);

  const instructions: Instruction[] = [
    {
      url: 'https://www.youtube.com/watch?v=xO11goSN2NM',
      title: 'Film bez opisu admina'
    },
    {
      url: 'https://www.youtube.com/watch?v=5F0ugWJNNv4',
      title: 'Bardzo długi tytuł. Bardzo długi tytuł. Bardzo długi tytuł. Bardzo długi tytuł. Bardzo długi tytuł. Bardzo długi tytuł. Bardzo długi tytuł. Bardzo długi tytuł.',
      description: "Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis. Bardzo długi opis."
    },
    {
      url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      title: "Film z opisem admin",
      description: "Krótki opis admin"
    }
  ]

  return (
    <section className="TenantAdminDashboard flex flex-row w-full h-screen">
      <div className="TenantAdminDashboard__contextContainer flex flex-col w-full h-screen">
        <div className="TenantAdminDashboard__headerContainer border-b border-brand-600">
          <WideHeader showLogo={false} dropdownMenuList={menuList} />
        </div>
        <div
          className={`ParentDashboard__greetingsContainer flex-grow min-h-0 ${notSetOverflowYAuto ? 'overflow-y-hidden' : 'overflow-auto'} overflow-x-hidden`}>
          <div className="ParentDashboard__contextContainer">
            <Routes>
              <Route path="/id/:id/dashboard/*" element={<Dashboard />} index />
              <Route path="/id/:id/school/*" element={<School />} />
              <Route path="/id/:id/kitchen/*" element={<Kitchen />} />
              <Route path="/id/:id/absences/*" element={<Absences />} />
              <Route path="/settings/*" element={<AdminSettings />} />
              <Route path="/id/:id/users/*" element={<Users />} />
              <Route path="/id/:id/reports/*" element={<Reports />} />
              <Route
                path="/id/:id/mail-box/messages/message/:id/*"
                element={<SingleAdminMessage />}
              />
              <Route path="/id/:id/mail-box/*" element={<MailBox />} />
              <Route path="/instructions" element={<Tutorials instructions={instructions} />} />
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TenantAdminDashboard;
