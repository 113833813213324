import { GB, PL, UA } from 'country-flag-icons/react/3x2';

import './LanguagesSelector.css';

const FlagWrapper = ({ children }: { children: JSX.Element }) => (
  <div className="w-6 h-6 rounded-full overflow-hidden flex items-center justify-center">
    <div className="w-full h-full mask-circle" style={{ clipPath: 'circle(50%)' }}>
      {children}
    </div>
  </div>
);

export const AVAILABLE_LANGUAGE_OPTIONS = [
  {
    code: 'en',
    label: 'English',
    flag: (
      <FlagWrapper>
        <GB className="w-6 h-6 object-cover" />
      </FlagWrapper>
    )
  },
  {
    code: 'pl',
    label: 'Język polski',
    flag: (
      <FlagWrapper>
        <PL className="w-6 h-6 object-cover" />
      </FlagWrapper>
    )
  },
  {
    code: 'uk',
    label: 'Українська',
    flag: (
      <FlagWrapper>
        <UA className="w-6 h-6 object-cover" />
      </FlagWrapper>
    )
  }
];
