import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { useApi } from '../../../context/ApiProvider';
import { AdminDashboardDataInterface } from '../../../shared/type/administrator.type';
import { AxiosResponse, HttpStatusCode } from 'axios';

export const useDashboard = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState<AdminDashboardDataInterface | undefined>();
  const [loading, setIsLoading] = useState(false);
  const { id: tenantId } = useParams<{ id: string }>();
  const { apiAdminDashboardController, apiReportController } = useApi();
  const today = new Date();

  const fetchDashboardData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<AdminDashboardDataInterface> =
        await apiAdminDashboardController('').get(``, {
          params: {
            tenantId
          }
        });

      if (response.status !== HttpStatusCode.Ok) {
        return;
      }

      setDashboardData(response.data);
    } catch (error: any) {
      console.error('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setDashboardData, tenantId]);

  const onDownloadReport = async (outputType: 'PDF' | 'XLSX') => {
    try {
      const response = await apiReportController('month-stake-statistics').get('', {
        params: {
          tenantId,
          month: today.getMonth() + 1,
          year: today.getFullYear(),
          outputType
        },
        responseType: 'blob'
      });

      const fileName = response.headers['content-disposition']?.split('filename=')[1];

      const blob = new Blob([response.data], {
        type: outputType === 'PDF' ? 'application/pdf' : 'application/vnd.ms-excel'
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${fileName}`;
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download report:', error);
    }
  };

  const onSeeMoreClick = () => navigate(`/tenantAdmin/id/${tenantId}/kitchen/summary`);

  useEffect(() => {
    if (!!tenantId) {
      fetchDashboardData();
    }
  }, [fetchDashboardData, tenantId]);

  return { loading, dashboardData, tenantId, onDownloadReport, onSeeMoreClick };
};
