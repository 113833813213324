import { useTranslation } from 'react-i18next';
import { Center, Divider, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { MealToDayDayInterface } from '../../../../shared/type/mealToDay.type';
import React, { useCallback } from 'react';
import BadgeWithMessage from '../../../BadgeWithMessage/BadgeWithMessage';
import { useApi } from '../../../../context/ApiProvider';
import IconComponent from '../../../IconComponent/IconComponent';

type MealsTableProps = {
  isLoading: boolean;
  mealPlans?: MealToDayDayInterface[];
  selectedTenant: number;
};

export const MealsTable = ({ isLoading, mealPlans, selectedTenant }: MealsTableProps) => {
  const { apiParentPdfMealMenuController } = useApi();
  const { t } = useTranslation();

  const downloadMealPlanPdf = useCallback(
      async (pdfId: number) => {
        try {
          const response = await apiParentPdfMealMenuController('').get(
              `/${selectedTenant}/${pdfId}/export`,
              {
                responseType: 'blob'
              }
          );

          if (response.data) {
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank');
          }
        } catch (error: any) {
          console.error('Error downloading PDF:', error);
        }
      },
      [apiParentPdfMealMenuController, selectedTenant]
  );

  return (
    <Table className="w-full">
      <Thead className="text-xs bg-white p-1 mb-4">
        <Tr>
          <Th
            className="border rounded-lg rounded-r-none border-gray-200 py-3 px-4"
            textTransform="capitalize"
            fontWeight={400}>
            {/* @ts-ignore */}
            {t('parent_meal_plan.meal_table.day')}
          </Th>
          <Th
            className="border border-x-0 border-gray-200 py-3 px-4"
            textTransform="capitalize"
            fontWeight={400}>
            {/* @ts-ignore */}
            {t('parent_meal_plan.meal_table.meal_categories')}
          </Th>
          <Th
            className="w-2/3 border border-x-0  border-gray-200 py-3 px-4 "
            textTransform="capitalize"
            fontWeight={400}>
            {/* @ts-ignore */}
            {t('parent_meal_plan.meal_table.meal_name')}
          </Th>
          <Th
            className="w-1/3 border border-x-0  border-gray-200 py-3 px-4 "
            textTransform="capitalize"
            fontWeight={400}>
            {/* @ts-ignore */}
            {t('parent_meal_plan.meal_table.meal_allergens')}
          </Th>
        </Tr>
      </Thead>
      <Tbody className="text-sm font-medium text-grayLight-900">
        {isLoading ? (
          <Tr>
            <Td colSpan={4}>
              <Center>
                columns.length
                <Spinner size="xl" />
              </Center>
            </Td>
          </Tr>
        ) : mealPlans && mealPlans.length === 0 ? (
          <Tr>
            <Td colSpan={4}>
              <Center>
                <Text>
                  {/* @ts-ignore */}
                  {t('parent_meal_plan.meal_table.empty_table')}
                </Text>
              </Center>
            </Td>
          </Tr>
        ) : (
          mealPlans &&
          mealPlans.map((item, index) => {
            const { when } = item;
            const date = new Date(when);

            const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
            const dayName = date.toLocaleDateString('pl-PL', options);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const isWorkingDay = item.workingDay;

            return (
              <Tr
                key={`${day}-${index}-${month}`}
                className={`border-t border-x ${isWorkingDay ? 'bg-white' : 'bg-gray-50'}`}>
                <Td
                  className="border-r border-gray-200 px-6 py-5 text-grayLight-600"
                  textTransform="capitalize">
                  <div className="flex flex-col">
                    <p className="text-grayLight-900 font-medium text-sm">{dayName}</p>
                    <p className="text-grayLight-600 font-normal text-sm">{`${day}.${month}`}</p>
                  </div>
                </Td>
                <Td className="px-6 py-5 text-grayLight-900" textTransform="capitalize">
                  {item.pdfMenu !== null && !!item.pdfMenu.id ? (
                    <button
                      className="flex gap-spacing-xs pt-2.5 pr-3 pb-2.5 pl-3"
                      onClick={() => {
                        downloadMealPlanPdf(Number(item.pdfMenu?.id));
                      }}>
                      <IconComponent iconName="pdf" />
                      <p className="text-sm font-semibold text-grayWarm-950">
                        {/* @ts-ignore */}
                        {t('parent_meal_plan.meal_table.see_meal_plan')}
                      </p>
                    </button>
                  ) : (
                    <div
                      className="flex flex-col"
                      style={{ height: 60 * (item.mealsToCourse?.length || 0) + 'px' }}>
                      {item.mealsToCourse?.map((meal, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                          <div
                            style={{ height: (1 / (item.mealsToCourse?.length || 0)) * 100 + '%' }}
                            className={`h-full text-grayLight-900 text-sm font-medium pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md`}>
                            {meal.courseName.charAt(0).toUpperCase() + meal.courseName.slice(1)}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </Td>
                <Td className="px-6 py-5 text-grayLight-900" textTransform="capitalize">
                  <div
                    className="flex flex-col h-full"
                    style={{ height: 60 * (item.mealsToCourse?.length || 0) + 'px' }}>
                    {item.mealsToCourse?.map((meal, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                        <div
                          style={{ height: (1 / (item.mealsToCourse?.length || 0)) * 100 + '%' }}
                          className={`flex-grow flex flex-col justify-between pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md`}>
                          <p className="text-grayLight-900 text-sm font-medium">
                            {meal.mealDto?.name || ''}
                          </p>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </Td>
                <Td className="px-6 py-5 text-grayLight-900" textTransform="capitalize">
                  <div
                    className="flex flex-col"
                    style={{ height: 60 * (item.mealsToCourse?.length || 0) + 'px' }}>
                    {item.mealsToCourse?.map((meal, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                        <div
                          style={{ height: (1 / (item.mealsToCourse?.length || 0)) * 100 + '%' }}
                          className={`basis-1/${item.mealsToCourse?.length} text-grayLight-600 flex-wrap flex text-sm font-medium gap-spacing-md pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md`}>
                          {Number(meal.mealDto?.allergens.length) > 0
                            ? meal.mealDto?.allergens.map((allergen) => (
                                <BadgeWithMessage
                                  tooltipLabel={
                                    <p className="rounded-lg pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg text-sm text-grayLight-900 font-medium">
                                      {allergen.name}
                                    </p>
                                  }>{`${allergen.formalNumber}`}</BadgeWithMessage>
                              ))
                            : ''}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </Td>
              </Tr>
            );
          })
        )}
        <Tr className={`border-t border-x bg-white`}>
          <Td className="px-6 py-8 h-14 text-grayLight-600" colSpan={4}></Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
