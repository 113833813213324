import CustomChakraButton from '../../CustomChakraButton/CustomChakraButton';

export const StakesReportSection = ({
  onDownloadReport
}: {
  onDownloadReport: (value: 'PDF' | 'XLSX') => void;
}) => {
  return (
    <section className="flex justify-between items-center px-4 py-5 gap-4 rounded-xl border border-gray-200 mt-4">
      <h3 className="text-lg font-semibold mb-1">Statystyka posiłków wg stawek</h3>
      <div>
        <CustomChakraButton
          hierarchy="tertiaryGray"
          size="md"
          iconPosition="right"
          dropdownOptions={[
            { label: 'PDF', action: () => onDownloadReport('PDF') },
            { label: 'Excel (.xls)', action: () => onDownloadReport('XLSX') }
          ]}
          buttonProps={{
            onClick: () => {
              return null;
            }
          }}>
          Pobierz raport
        </CustomChakraButton>
      </div>
    </section>
  );
};
