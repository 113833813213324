import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { EditTeacherOrEmployeeProvider } from '../../../../context/EditTeacherOrEmployeeProvider';
import { EditAdministratorProvider } from '../../../../context/EditAdministratorProvider';
import { TenantAdminBasketContextProvider } from '../../../../context/TenantAdminBasketProvider';

import TeachersTable from './TeachersTable/TeachersTable';
import SingleTeacher from './SingleTeacher/SingleTeacher';
import SetOrderOnCredit from '../Parents/ParentsTable/SingleParents/ListOfOrder/SetOrderOnCredit/SetOrderOnCredit';
import SetOrderOnCash from '../Parents/ParentsTable/SingleParents/ListOfOrder/SetOrderOnCash/SetOrderOnCash';

const Teachers = () => {
  const teachersLinks = [
    {
      link: 'teachers-table',
      element: <TeachersTable />
    },
    {
      link: 'single-teacher/:userId',
      element: <SingleTeacher />
    },
    {
      link: 'single-teacher/:userId/credit-order',
      element: (
        <TenantAdminBasketContextProvider>
          <SetOrderOnCredit />
        </TenantAdminBasketContextProvider>
      )
    },
    {
      link: 'single-teacher/:userId/cash-order',
      element: (
        <TenantAdminBasketContextProvider>
          <SetOrderOnCash />
        </TenantAdminBasketContextProvider>
      )
    }
  ];

  return (
    <EditTeacherOrEmployeeProvider>
      <EditAdministratorProvider>
        <Routes>
          {teachersLinks.map((link) => (
            <Route key={link.link} path={`/${link.link}/*`} element={link.element} />
          ))}
        </Routes>
      </EditAdministratorProvider>
    </EditTeacherOrEmployeeProvider>
  );
};

export default Teachers;
