import { Box, Text } from '@chakra-ui/react';

import CustomChakraButton from '../../../CustomChakraButton/CustomChakraButton';
import ChooseMonths from '../../../ChooseMonths/ChooseMonths';

import { SummaryTableItem } from './summary-table';
import { useSummary } from './use-summary';
import { MultipleSelect } from '../../../Forms/MultipleSelect/MultipleSelect';

const Summary = () => {
  const {
    currentTenantId,
    currentDate,
    setCurrentDate,
    mainSummaryData,
    isLoading,
    tenantsOption,
    selectedTenants,
    setSelectedTenants,
    tenantsSummariesData,
    onDownloadReport
  } = useSummary();

  return (
    <section className="SalesSystem py-4 px-6 w-full">
      <div className="relative mb-8">
        <div className="absolute bottom-[-16px] left-[-24px] w-screen h-[1px] bg-gray-200"></div>
        <div className="w-full flex items-center justify-between">
          <div className="flex flex-col basis-5/12">
            <Box>
              <Text
                whiteSpace="normal"
                overflow="hidden"
                textOverflow="ellipsis"
                className="font-semibold text-base normal-case text-grayLight-900">
                Podsumowanie posiłków do wydania
              </Text>
              <Text
                whiteSpace="normal"
                overflow="hidden"
                textOverflow="ellipsis"
                className="font-normal text-sm normal-case text-grayLight-700">
                Tutaj znajdziesz informacje na temat produkcji na wybrany dzień (statystyki
                zamówionych stawek oraz kategorii posiłków).
              </Text>
            </Box>
          </div>
          <div>
            <CustomChakraButton
              hierarchy="primary"
              size="md"
              iconPosition="right"
              dropdownOptions={[
                { label: 'PDF', action: () => onDownloadReport('PDF') },
                { label: 'Excel (.xls)', action: () => onDownloadReport('XLSX') }
              ]}
              buttonProps={{
                onClick: () => {
                  return null;
                }
              }}>
              Pobierz raport
            </CustomChakraButton>
          </div>
        </div>
        <div
          className={`mt-9 w-full flex ${tenantsOption.length > 1 ? 'justify-between' : 'justify-end'}`}>
          {tenantsOption.length > 1 && (
            <MultipleSelect
              selected={selectedTenants}
              setSelected={setSelectedTenants}
              options={tenantsOption}
              placeholder="Wybierz oddział lub oddziały"
            />
          )}
          <ChooseMonths setDate={setCurrentDate} currentDate={currentDate} mode="day" />
        </div>
      </div>
      <div className="my-4">
        <Text size="md" fontWeight="bold">
          Podsumowanie wybranych oddziałów{' '}
          {tenantsOption.length > 1 &&
            selectedTenants.filter((id) => id !== currentTenantId).length > 0 &&
            `(${selectedTenants.length})`}
        </Text>
      </div>
      <SummaryTableItem kitchenSummaryData={mainSummaryData} isLoading={isLoading} hideTitle />
      {tenantsOption.length > 1 &&
        selectedTenants.length > 0 &&
        tenantsSummariesData &&
        tenantsSummariesData.map((summaryData) => {
          return (
            <SummaryTableItem
              customTitle={summaryData.tenantName}
              kitchenSummaryData={summaryData}
              isLoading={isLoading}
            />
          );
        })}
    </section>
  );
};

export default Summary;
