import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Select as SingleSelect,
  ModalFooter
} from '@chakra-ui/react';
import { AxiosResponse, HttpStatusCode } from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { useAlertContext } from '../../../../../../context/AlertProvider';
import { useApi } from '../../../../../../context/ApiProvider';
import { NewCourseInterface, CourseInterface } from '../../../../../../shared/type/course.type';
import { MealDistributionInterface } from '../../../../../../shared/type/mealDistribution.type';
import { RateInterface } from '../../../../../../shared/type/rate.type';
import CustomChakraButton from '../../../../../CustomChakraButton/CustomChakraButton';
import FormInput from '../../../../../Forms/FormInput/FormInput';

interface Props {
  mode: 'add' | 'edit';
  isOpen: boolean;
  initData?: CourseInterface;
  onClose: () => void;
}

const defaultMealCategory: NewCourseInterface = {
  name: '',
  mealDistributionId: 0,
  stakeIds: []
};

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '8px',
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    padding: '4px' + '',
    borderColor: '#ccc',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#aaa'
    }
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#FFF7E6' : 'white',
    color: '#101828',
    padding: '8px',
    borderRadius: '8px'
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    color: '#333'
  })
};

const AddEditMealCategory = ({ mode, initData, isOpen, onClose }: Props) => {
  const { setShow, setAlertProperties } = useAlertContext();
  const {
    apiTenantStakeController,
    apiTenantMealDistributionController,
    apiTenantCourseController
  } = useApi();
  const { id: tenantId } = useParams();
  const [mealDistributions, setMealDistributions] = useState<MealDistributionInterface[]>([]);
  const [stakes, setStakes] = useState<RateInterface[]>([]);
  const [mealCategory, setMealCategory] = useState<NewCourseInterface>(defaultMealCategory);

  const fetchMealDistributions = async () => {
    try {
      const response = await apiTenantMealDistributionController('').get(`/${tenantId}`);
      setMealDistributions(response.data);
    } catch (error) {}
  };

  const fetchStakes = async () => {
    try {
      const response = await apiTenantStakeController('').get(`/${tenantId}`);
      setStakes(response.data);
    } catch (error) {}
  };

  const handleConfirmForm = async (e: React.FormEvent) => {
    e.preventDefault();
    let response: AxiosResponse;

    const dataObj = {
      name: mealCategory.name,
      mealDistributionId: mealCategory.mealDistributionId,
      stakeIds: mealCategory.stakeIds
    };

    try {
      if (mode === 'add') {
        response = await apiTenantCourseController('create').post(`/${tenantId}`, dataObj);
      } else {
        response = await apiTenantCourseController('update').post('', {
          ...dataObj,
          id: initData?.id
        });
      }
      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 9000,
          title: 'Sukces',
          description: `Poprawnie ${mode === 'add' ? 'dodano nową' : 'edytowano'} kategorię`,
          status: 'success'
        });
        setShow(true);
        onClose();
      } else {
        setAlertProperties({
          timeout: 9000,
          title: 'Nieowodzenia',
          description: `Błąd ${mode === 'add' ? 'dodawania' : 'edytowania'} kategorii`,
          status: 'warning'
        });
        setShow(true);
      }
    } catch (error) {
      setAlertProperties({
        timeout: 9000,
        title: 'Nieowodzenia',
        description: `Błąd dodawania kategorii`,
        status: 'warning'
      });
      setShow(true);
    }
  };

  useEffect(() => {
    fetchStakes();
    fetchMealDistributions();
  }, []);

  useEffect(() => {
    if (mode === 'edit' && initData) {
      const { name, mealDistributionDto, stakes } = initData;
      setMealCategory({
        name,
        mealDistributionId: mealDistributionDto.id,
        stakeIds: stakes.map((stake) => stake.id)
      });
    }
  }, [isOpen, initData, mode]);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={handleConfirmForm}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">
              {mode === 'add' ? 'Dodaj' : 'Edytuj'} kategorię
            </h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <FormInput
              label="Nazwa"
              placeholder="Podaj nazwę kategorii, np. Desery"
              inputParams={{
                value: mealCategory.name,
                onChange: (e) => {
                  setMealCategory((prev) => ({ ...prev, name: e.target.value }));
                },
                required: true,
                name: 'name'
              }}
            />
            <div className="flex flex-col gap-spacing-md">
              <label className="text-grayLight-700 text-sm font-medium">Przypisz wydawkę</label>
              <Select
                styles={customStyles}
                required
                placeholder="Wybierz..."
                value={
                  mealDistributions
                    .filter((item) => item.id === mealCategory.mealDistributionId)
                    .map((item) => ({ label: item.name, value: item.id }))[0] || null
                }
                onChange={(selectedOption: any) => {
                  setMealCategory((prev) => ({
                    ...prev,
                    mealDistributionId: selectedOption?.value || 0
                  }));
                }}
                options={mealDistributions.map((item) => ({
                  label: item.name,
                  value: item.id
                }))}
              />
            </div>
            <div className="flex flex-col gap-spacing-md">
              <label className="text-grayLight-700 text-sm font-medium">Przypisz stawkę</label>
              <Select
                styles={customStyles}
                required
                isMulti
                placeholder="Wybierz..."
                value={stakes
                  .filter((unit) => mealCategory.stakeIds.includes(unit.id))
                  .map((unit) => ({
                    label: unit.name,
                    value: unit.id
                  }))}
                onChange={(value) => {
                  setMealCategory((prev) => ({
                    ...prev,
                    stakeIds: value.map((item: any) => item.value)
                  }));
                }}
                options={stakes.map((unit) => ({
                  label: unit.name,
                  value: unit.id,
                  variant: 'outline'
                }))}
              />
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                onClick: onClose
              }}>
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: 'submit'
              }}>
              {mode === 'add' ? 'Dodaj' : 'Zapisz'}
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddEditMealCategory;
