import { HttpStatusCode } from 'axios';
import React from 'react';
import { useAlertContext } from '../../../../../../context/AlertProvider';
import { useApi } from '../../../../../../context/ApiProvider';
import CustomAlert from '../../../../../CustomAlert/CustomAlert';
import IconComponent from '../../../../../IconComponent/IconComponent';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  consumerIds: number[];
  afterAction?: () => void;
}

const ResetSaldo = ({ isOpen, handleClose, consumerIds, afterAction }: Props) => {
  const { apiTenantConsumerTransactionsController } = useApi();
  const { setShow, setAlertProperties } = useAlertContext();

  return (
    <CustomAlert
      handleOpen={isOpen}
      icon={
        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
          <IconComponent iconName="question" />
        </div>
      }
      header={`Wyzeruj saldo użytkowników (${consumerIds.length})`}
      content={'Ta operacja ma wpływ na salda użytkowników.'}
      cancelButton={{
        iconPosition: 'onlyText',
        size: 'lg',
        hierarchy: 'secondaryGray'
      }}
      cancelButtonText={'Anuluj'}
      confirmButton={{
        iconPosition: 'onlyText',
        size: 'lg',
        hierarchy: 'primary'
      }}
      confirmButtonText={'Wyzeruj'}
      onConfirmAction={async () => {
        try {
          let response = await apiTenantConsumerTransactionsController(
            'zero-saldo-for-consumers'
          ).post('', {
            consumerIds: consumerIds.map((item) => item)
          });
          if (response.status === HttpStatusCode.Ok) {
            setAlertProperties({
              timeout: 9000,
              title: 'Sukces',
              description: `Poprawnie zresetowano saldo`,
              status: 'success'
            });
            setShow(true);
            handleClose();
            afterAction && afterAction();
          }
        } catch (e) {
          console.error(e);
          setAlertProperties({
            timeout: 9000,
            title: 'Błąd',
            description: `Nie udało się zresetować salda`,
            status: 'error'
          });
          setShow(true);
        }
      }}
      onCancelAction={() => {
        handleClose();
      }}
    />
  );
};

export default ResetSaldo;
