import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { EditTeacherOrEmployeeProvider } from '../../../../context/EditTeacherOrEmployeeProvider';
import EmployeesTable from './EmployeesTable/EmployeesTable';
import SingleEmployee from './SingleEmployee/SingleEmployee';
import { TenantAdminBasketContextProvider } from '../../../../context/TenantAdminBasketProvider';
import SetOrderOnCredit from '../Parents/ParentsTable/SingleParents/ListOfOrder/SetOrderOnCredit/SetOrderOnCredit';
import SetOrderOnCash from '../Parents/ParentsTable/SingleParents/ListOfOrder/SetOrderOnCash/SetOrderOnCash';
import { EditAdministratorProvider } from "../../../../context/EditAdministratorProvider";

const Employees = () => {
  const employeesLinks = [
    {
      link: 'employees-table',
      element: <EmployeesTable />
    },
    {
      link: 'single-employee/:userId',
      element: <SingleEmployee />
    },
    {
      link: 'single-employee/:userId/credit-order',
      element: (
        <TenantAdminBasketContextProvider>
          <SetOrderOnCredit />
        </TenantAdminBasketContextProvider>
      )
    },
    {
      link: 'single-employee/:userId/cash-order',
      element: (
        <TenantAdminBasketContextProvider>
          <SetOrderOnCash />
        </TenantAdminBasketContextProvider>
      )
    }
  ];

  return (
    <EditTeacherOrEmployeeProvider>
      <EditAdministratorProvider>
        <Routes>
          {employeesLinks.map((link) => (
            <Route key={link.link} path={`/${link.link}/*`} element={link.element} />
          ))}
        </Routes>
      </EditAdministratorProvider>
    </EditTeacherOrEmployeeProvider>
  );
};

export default Employees;
