import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParentDashboardContextContext } from '../../../context/ParentDashboardContext';
import { useThemeContext } from '../../../context/ThemeProvider';
import { NavOptionsWithElementInterface } from '../../../shared/type/navOptions.type';
import CustomChakraButton from '../../CustomChakraButton/CustomChakraButton';
import Navigation from '../../Navigation/Navigation';
import CreateNewThread from './CreateNewThread/CreateNewThread';
import ParentMessage from './ParentMessage/ParentMessage';

const ParentMailBox = () => {
  const { t } = useTranslation();
  const { parentDashboardContext } = useParentDashboardContextContext();
  const [chosenTenantId, setChosenTenantId] = useState<number>();
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [refreshMessages, setRefreshMessages] = useState<() => void>(() => () => {});
  const { isMobile } = useThemeContext();
  const location = useLocation();
  const { setBasketStatus } = useParentDashboardContextContext();

  useEffect(() => {
    if (parentDashboardContext) {
      setChosenTenantId(parentDashboardContext.tenants[0].tenantId);
    }
  }, [parentDashboardContext]);

  const navOptions: NavOptionsWithElementInterface[] = [
    {
      link: 'messages',
      // @ts-ignore
      name: t('parent_mailbox.title'),
      component: (
        <ParentMessage
          tenantId={chosenTenantId?.toString() || ''}
          setRefreshMessages={setRefreshMessages}
        />
      )
    }
  ];

  useEffect(() => {
    setBasketStatus(() => ({ show: false, forceShow: false }));
  }, []);

  return (
    <section className="pt-spacing-3xl pb-12 gap-spacing-3xl">
      <div className="flex justify-between pr-spacing-4xl pl-spacing-3xl">
        <div>
          <h2 className="font-semibold text-grayLight-900" style={{ fontSize: '30px' }}>
            {/* @ts-ignore */}
            {t('parent_mailbox.title')}
          </h2>
        </div>
        {location.pathname.includes('messages') &&
          (isMobile ? (
            <div className="fixed bottom-28 right-10 lg:flex">
              <CustomChakraButton
                iconPosition={'left'}
                icon="plus"
                iconColor="#292524"
                hierarchy="primary"
                size={'xl'}
                buttonProps={{
                  onClick: () => {
                    setShowPopUp(true);
                  }
                }}>
                {/* @ts-ignore */}
                {t('parent_mailbox.new')}
              </CustomChakraButton>
            </div>
          ) : (
            <div className="flex">
              <CustomChakraButton
                iconPosition={'right'}
                icon="plus"
                iconColor="#292524"
                hierarchy="primary"
                size={'md'}
                buttonProps={{
                  onClick: () => {
                    setShowPopUp(true);
                  }
                }}>
                {/* @ts-ignore */}
                {t('parent_mailbox.new_message')}
              </CustomChakraButton>
            </div>
          ))}
      </div>

      <div className="flex flex-col">
        <div className="pt-spacing-xl pr-spacing-4xl pb-spacing-xl pl-spacing-3xl gap-spacing-xl">
          {/*<CustomHorizontalTabs*/}
          {/*  onChange={(value) => {*/}
          {/*    setChosenTenantId(value);*/}
          {/*  }}*/}
          {/*  data={*/}
          {/*    parentDashboardContext*/}
          {/*      ? parentDashboardContext.tenants.map((tenant) => ({*/}
          {/*          label: tenant.tenantName,*/}
          {/*          value: tenant.tenantId*/}
          {/*        }))*/}
          {/*      : null*/}
          {/*  }*/}
          {/*/>*/}
        </div>

        <div className="pr-spacing-4xl">
          <Navigation navOptions={navOptions} />
        </div>

        <div className="pr-8">
          <Routes>
            {navOptions.map((item) => (
              <Route key={item.link} path={`/${item.link}/*`} element={item.component} />
            ))}
          </Routes>
        </div>
      </div>

      {showPopUp && (
        <CreateNewThread
          isOpen={showPopUp}
          onClose={() => {
            refreshMessages();
            setShowPopUp(false);
          }}
        />
      )}
    </section>
  );
};

export default ParentMailBox;
