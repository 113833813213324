import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Select,
  Divider
} from "@chakra-ui/react";
import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import { SimpleCourseInterface } from "../../../../../../shared/type/course.type";
import { MealInterface, MealWithCategoryInterface } from "../../../../../../shared/type/mealsMenu.type";
import BadgeWithMessage from "../../../../../BadgeWithMessage/BadgeWithMessage";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import IconComponent from "../../../../../IconComponent/IconComponent";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedMeals: MealInterface[]
}

const AddToMealPlan = ({ isOpen, onClose, selectedMeals }: Props) => {
  const { apiTenantCourseController, apiTenantMealToDayController } = useApi();
  const { setAlertProperties, setShow } = useAlertContext();
  const { id: tenantId } = useParams();
  const [mealCategories, setMealCategories] = useState<SimpleCourseInterface[]>([]);
  const [meals, setMeals] = useState<MealWithCategoryInterface[]>(selectedMeals.map(meal => ({
    ...meal,
    categoryId: 0
  })));
  const [assignedMeals, setAssignedMeals] = useState<MealWithCategoryInterface[]>([]);
  const [mealPlanDate, setMealPlanDate] = useState<string>('');

  const fetchMealCategories = async () => {
    try {
      const response: AxiosResponse<SimpleCourseInterface[]> = await apiTenantCourseController('simple-list').get(`/${tenantId}`);
      setMealCategories(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMealCategories();
  }, []);

  const handleCategoryChange = (mealId: number, categoryId: number) => {
    setMeals(prevMeals =>
      prevMeals.map(meal =>
        meal.id === mealId ? { ...meal, categoryId: categoryId } : meal
      )
    );
  };

  const handleAddMeal = (meal: MealWithCategoryInterface) => {
    setMeals(prevMeals => prevMeals.filter(m => m.id !== meal.id));
    setAssignedMeals(prevAssignedMeals => [...prevAssignedMeals, meal]);
  };

  const handleDeleteMeal = (meal: MealWithCategoryInterface) => {
    setAssignedMeals(prevMeals => prevMeals.filter(m => m.id !== meal.id));
    setMeals(prevMeals => [...prevMeals, meal]);
  }

  const getCategoryName = (categoryId: number) => {
    const category = mealCategories.find(cat => cat.id === categoryId);
    return category ? category.name : 'Unknown Category';
  };

  const uniqueCategories = Array.from(new Set(assignedMeals.map(meal => meal.categoryId)));

  const handleConfirmAssigment = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      const response = await apiTenantMealToDayController('create-assignments').post('',
          assignedMeals.map((meal) => ({
            tenantId,
            mealId: meal.id,
            courseId: meal.categoryId,
            when: mealPlanDate
          }))
        )
      if(response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 9000,
          title: "Sukces",
          description: `Poprawnie dodano ${assignedMeals.length > 1 ? 'nowe potrawy' : 'nową potrawę'} do jadłospisu`,
          status: "success"
        });
        setShow(true);
        onClose();
      } else {
        setAlertProperties({
          timeout: 9000,
          title: "Błað",
          description: `Błąd dodawania`,
          status: "error"
        });
        setShow(true);
      }
    } catch (error) {
      setAlertProperties({
        timeout: 9000,
        title: "Błað",
        description: `Błąd dodawania`,
        status: "error"
      });
      setShow(true);
    }
  }

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="70-vw" isCentered>
      <form onSubmit={handleConfirmAssigment}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex">
            <div className='basis-5/12 flex flex-col text-grayLight-900 font-semibold text-lg border-r border-grayLight-200'>
              <h2 className="font-semibold text-lg">Dodaj do jadłospisu</h2>
              <p className='text-grayLight-600 text-sm font-normal'>Aby dodać jadłospis wybierz datę a następnie przypisz kategorie do wybranych dań.</p>
            </div>
            <div className='basis-7/12 flex items-center justify-between text-grayLight-700 font-semibold text-xl box-border border-l border-grayLight-200'>
              <h2 className="font-semibold text-lg pr-spacing-3xl pl-spacing-3xl">Podgląd do jadłospisu</h2>
              <h2 className="font-semibold text-lg pr-spacing-3xl pl-spacing-3xl">{mealPlanDate || 'Data'}</h2>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex">
            <div className="basis-5/12 border-r border-grayLight-200 flex flex-col gap-spacing-xl">
              <div className="flex flex-col gap-spacing-xs">
                <label className="text-sm font-medium text-grayLight-700">Dzień obowiązywania jadłospisu</label>
                <div className='w-44'>
                  <Input
                    placeholder="Wybierz datę"
                    type="date"
                    lang="pl"
                    required
                    onChange={(e) => { setMealPlanDate(e.target.value) }}
                  />
                </div>
              </div>
              <div className='flex flex-col gap-spacing-3xl'>
                {meals.map((meal) => (
                  <div className='flex flex-col gap-spacing-xl' key={meal.id}>
                    <div className="flex flex-col gap-spacing-xs">
                      <p className='text-sm font-medium text-grayLight-900'>{meal.name}</p>
                      <p className='text-sm font-medium text-grayLight-600'>{meal.description}</p>
                    </div>
                    <div className='flex justify-between gap-spacing-xl'>
                      <div className='flex flex-col w-96'>
                        <label className="text-sm font-medium text-grayLight-700">Przypisz kategorię</label>
                        <Select
                          placeholder="Wybierz"
                          onChange={(e) => handleCategoryChange(meal.id, Number(e.target.value))}
                        >
                          {mealCategories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <CustomChakraButton
                        hierarchy='tertiaryColor'
                        iconPosition='left'
                        icon='plus'
                        size='lg'
                        buttonProps={{
                          onClick: () => handleAddMeal(meal),
                          type: 'button',
                          disabled: meal.categoryId === 0
                        }}
                      >Dodaj</CustomChakraButton>
                    </div>
                    <Divider className="bg-grayLight-200 mt-5 mb-5" />
                  </div>
                ))}
              </div>
            </div>
            <div className={`basis-7/12 border-l border-grayLight-200 flex flex-col ${assignedMeals.length === 0 ? ' items-center justify-center' : ''}`}>
              {assignedMeals.length === 0 ? (
                <div className='flex flex-col items-center justify-center gap-spacing-xl'>
                  <div>
                    <IconComponent iconName='cutlery' className='w-10 h-10' />
                  </div>
                  <p>Tu pojawi się lista dań dodanych do jadłospisu na dany dzień.</p>
                </div>
              ) : (
                <div className='p-spacing-3xl'>
                  <ul className='list-disc ml-5 flex flex-col gap-spacing-4xl'>
                    {uniqueCategories.map(categoryId => (
                      <li key={categoryId} className="text-lg font-semibold list-none text-grayLight-900">
                        <h3 className="text-lg font-semibold">{getCategoryName(categoryId).charAt(0).toUpperCase() + getCategoryName(categoryId).slice(1)}</h3>
                        <ul className='flex flex-col gap-spacing-4xl'>
                          {assignedMeals.filter(meal => meal.categoryId === categoryId).map(meal => (
                            <li key={meal.id} className='text-sm font-medium text-grayLight-700'>
                              <div className='flex flex-col gap-spacing-xs'>
                                <div className="flex justify-between border-b border-brand-300">
                                  <p className="font-normal text-sm text-grayLight-900 pt-spacing-xs pr-spacing-md pb-spacing-xs pl-spacing-md">{meal.name}</p>
                                  <button className='bg-transparent border-none' onClick={() => {
                                    handleDeleteMeal(meal)
                                  }}>
                                    <IconComponent iconName='xClose' className='w-3 h-3' />
                                  </button>
                                </div>
                                <p
                                  className="text-grayLight-600 pt-spacing-xs pr-spacing-md pb-spacing-xs pl-spacing-md">{meal.description}</p>
                                <div className='flex gap-spacing-xs flex-wrap'>
                                  {meal.allergens.map(allergen => (
                                    <BadgeWithMessage tooltipLabel={allergen.name}>{`${allergen.formalNumber}`}</BadgeWithMessage>

                                  ))}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "basis-5/12",
                disabled: assignedMeals.length === 0 && mealPlanDate.length === 0,
                type: "submit"
              }}
            >
              Zakończ
            </CustomChakraButton>
            <div className='basis-7/12'>

            </div>

          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddToMealPlan;
