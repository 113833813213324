import React from 'react';
import { AdminDashboardDataDaysInterface } from '../../../../shared/type/administrator.type';
import { useThemeContext } from '../../../../context/ThemeProvider';

export interface DayCellProps {
  date: Date;
  dayCount?: AdminDashboardDataDaysInterface;
  disabled?: boolean;
}

export const DayCell: React.FC<DayCellProps> = ({ date, dayCount }) => {
  const { isMobile } = useThemeContext();
  return (
    <div
      className="custom-day-cell z-0 p-1 pr-1 lg:p-2 lg:pr-4"
      style={{
        minHeight: '100px'
      }}>
      <div className="xs:text-sm lg:text-lg">{date.getDate()}</div>
      {!isMobile && (
        <div className="w-24 flex flex-col items-start justify-end overflow-hidden">
          <p className="font-bold text-xs lg:text-sm mb-1 text-ellipsis text-nowrap">
            {dayCount?.toDistribute || 0} do wydania
          </p>
          <p className="text-xs lg:text-sm mb-1 text-ellipsis text-nowrap">
            {dayCount?.absences || 0} nieobecności
          </p>
          <p className="text-xs lg:text-sm text-ellipsis text-nowrap">
            {dayCount?.totalCount || 0} zamówienia
          </p>
        </div>
      )}
    </div>
  );
};
