import React from 'react';
import { useMediaQuery } from '@chakra-ui/react';

import { addOrderToBasket } from '../../../../../../../../../utils/calendarOrderHelper';
import { ConsumerSelector } from '../../../../../../../../ChildOwnerComponents/ParentOrder/OrderOnPeriodOfTime/ConsumerSelector/ConsumerSelector';
import { OrderingCalendar } from '../../../../../../../../ChildOwnerComponents/ParentOrder/OrderOnPeriodOfTime/OrderingCalendar/OrderingCalendar';
import { StakeSelector } from '../../../../../../../../ChildOwnerComponents/ParentOrder/OrderOnPeriodOfTime/StakeSelector/StakeSelector';
import CustomChakraButton from '../../../../../../../../CustomChakraButton/CustomChakraButton';

import { BasketForCashOrder } from './BasketForCashOrder/BasketForCashOrder';
import { useCompleteCashOrder } from './useCompleteCashOrder';

const CompleteCashOrder = ({ navigationSlot }: { navigationSlot?: React.ReactNode }) => {
  const [isNotFullSizeScreen] = useMediaQuery('(max-width: 1024px)');
  const containerSizeForMainWrapper = !isNotFullSizeScreen ? 'flex flex-row' : 'flex flex-col px-4';
  const containerSizeForCalendarPart = !isNotFullSizeScreen ? 'w-2/3 pb-32' : 'w-full pb-52';
  const containerStylesForStakesSelectorBar = !isNotFullSizeScreen
    ? 'w-2/3 bottom-0 '
    : 'w-full bottom-[102px] border-b border-b-amber-400';
  const {
    userOrderContext,
    purchasableItem,
    commonMeal,
    tenantId,
    onConsumerSelect,
    consumersToSelectOptions,
    clearSelectedDates,
    minOrderDate,
    maxOrderDate,
    selectedStake,
    setSelectedStake,
    selectAllDaysInMonth,
    setSelectAllDaysInMonth,
    selectedDates,
    setSelectedDates,
    addItemToBasket,
    parentBasketContext,
    selectedConsumerId,
    consumersToSelect
  } = useCompleteCashOrder();

  return (
    <section
      className={`overflow-hidden w-full max-h-[92vh] ${containerSizeForMainWrapper} bg-grayLight-50 relative`}>
      <div
        className={`relative ${containerSizeForCalendarPart} overflow-y-scroll gap-spacing-3xl flex flex-col lg:pt-spacing-md lg:px-spacing-3xl pb`}>
        <div className="flex flex-col gap-spacing-lg">
          {navigationSlot && navigationSlot}
          <div className="flex flex-col px-spacing-xl lg:px-spacing-0 gap-spacing-lg">
            <p className="font-semibold text-2xl text-grayLight-900">Wybierz posiłek i daty</p>
            <p className="font-normal text-grayLight-600" style={{ fontSize: '16px' }}>
              Wybierz rodzaj posiłku oraz dni w jakie dziecko ma otrzymać posiłek, następnie dodaj
              je do koszyka.
            </p>
          </div>
          <ConsumerSelector
            consumers={consumersToSelectOptions}
            onConsumerSelect={onConsumerSelect}
          />

          <div className="OrderOnPeriodOfTime__selectServing mt-5 lg:mt-10">
            <StakeSelector
              stakesToSelect={purchasableItem}
              selectedStake={selectedStake}
              onStakeSelect={setSelectedStake}
            />
          </div>
        </div>

        <div className="flex flex-col z-10">
          <OrderingCalendar
            clearSelectedDates={clearSelectedDates}
            selectAllDaysInMonth={selectAllDaysInMonth}
            setSelectAllDaysInMonth={setSelectAllDaysInMonth}
            selectedConsumerId={selectedConsumerId}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            minOrderDate={minOrderDate}
            maxOrderDate={maxOrderDate}
            basketContext={parentBasketContext}
            letOrderInPast={false}
            selectedStake={selectedStake}
          />
          {isNotFullSizeScreen && (
            <div className="w-full border-l bg-white mt-8">
              <BasketForCashOrder parentKids={consumersToSelect} showMobile={true} />
            </div>
          )}
        </div>
      </div>
      {!isNotFullSizeScreen && (
        <div className="w-1/3 border-l bg-white">
          <BasketForCashOrder parentKids={consumersToSelect} showMobile={false} />
        </div>
      )}
      <div
        className={`${containerStylesForStakesSelectorBar} absolute left-0 flex items-end gap-4 px-6 pt-6 pb-8 bg-white border-t border-t-amber-400 z-40`}>
        <CustomChakraButton
          hierarchy="secondaryColor"
          iconPosition="onlyText"
          icon="xClose"
          size="sm"
          fullWidth
          buttonProps={{
            disabled: !selectedStake,
            className: 'flex',
            type: 'button',
            onClick: () => {
              addOrderToBasket(
                commonMeal,
                selectedStake,
                userOrderContext,
                selectedConsumerId,
                selectedDates,
                addItemToBasket,
                tenantId?.toString()
              );
            }
          }}>
          Dodaj do koszyka
        </CustomChakraButton>
      </div>
    </section>
  );
};

export default CompleteCashOrder;
