const monthNames: Record<string, string[]> = {
  pl: [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień'
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  uk: [
    'Січень',
    'Лютий',
    'Березень',
    'Квітень',
    'Травень',
    'Червень',
    'Липень',
    'Серпень',
    'Вересень',
    'Жовтень',
    'Листопад',
    'Грудень'
  ]
};

/**
 * Zwraca nazwę miesiąca na podstawie numeru (1-12) w wybranym języku.
 * @param {number} value - Numer miesiąca (1-12).
 * @param {string} [language='pl'] - Kod języka ('pl', 'en', 'uk').
 * @returns {string} - Nazwa miesiąca w wybranym języku.
 */
function getMonthFromNumber(value: number, language: string | undefined = 'pl'): string {
  if (value < 1 || value > 12) {
    throw new Error('Numer miesiąca musi być w zakresie 1-12');
  }

  const selectedLanguage = monthNames[language] ? language : 'pl';
  return monthNames[selectedLanguage][value - 1];
}

export default getMonthFromNumber;
