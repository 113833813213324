import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { useApi } from '../../../../../../context/ApiProvider';
import { OrganisationUnitInterface } from '../../../../../../shared';
import { NewKidInterface } from '../../../../../../shared/type/kid.type';
import CustomChakraButton from '../../../../../CustomChakraButton/CustomChakraButton';
import FormInput from '../../../../../Forms/FormInput/FormInput';
import { Divider } from '@chakra-ui/react';

interface Props {
  mode: 'add' | 'assign';
  setMode: React.Dispatch<React.SetStateAction<'add' | 'assign'>>;
  defaultChild: NewKidInterface;
  newChildren: NewKidInterface[];
  setNewChildren: React.Dispatch<React.SetStateAction<NewKidInterface[]>>;
}

interface SelectOption {
  label: string;
  value: number;
}

const AddKidToParentAdd = ({ defaultChild, newChildren, setNewChildren }: Props) => {
  const { apiTenantOrganisationUnitController } = useApi();
  const { id: tenantId } = useParams<{ id: string; parentId: string }>();
  const [organizationUnits, setOrganizationUnits] = useState<OrganisationUnitInterface[]>([]);

  const handleInputChange = (index: number, name: string, value: string) => {
    const updatedChildren = newChildren.map((child, i) =>
      i === index ? { ...child, [name]: value } : child
    );
    setNewChildren(updatedChildren);
  };

  const handleSelectChange = (index: number, value: SelectOption | null) => {
    const updatedChildren = newChildren.map((child, i) =>
      i === index ? { ...child, organisationUnitId: value ? value.value : 0 } : child
    );
    setNewChildren(updatedChildren);
  };

  const addNewChild = () => {
    setNewChildren([...newChildren, { ...defaultChild }]);
  };

  const fetchOrganizationUnits = async () => {
    try {
      const response = await apiTenantOrganisationUnitController('').get(`/${tenantId}`);
      setOrganizationUnits(response.data);
      console.log(response.data);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    fetchOrganizationUnits();
  }, [tenantId]);

  return (
    <div className="flex flex-col gap-spacing-2xl">
      {newChildren.map((childForm, index) => (
        <div key={index} className="flex flex-col gap-spacing-2xl">
          <FormInput
            label="Imię"
            placeholder="Wpisz imię"
            inputParams={{
              name: 'firstName',
              value: childForm.firstName,
              onChange: (e) => handleInputChange(index, e.target.name, e.target.value),
              required: true,
              minLength: 3
            }}
          />
          <FormInput
            label="Nazwisko"
            placeholder="Wpisz nazwisko"
            inputParams={{
              name: 'lastName',
              value: childForm.lastName,
              onChange: (e) => handleInputChange(index, e.target.name, e.target.value),
              required: true,
              minLength: 3
            }}
          />
          <Select
            styles={{
              option: (provided, state) => ({
                ...provided,
                color: 'black',
                backgroundColor: state.isSelected ? '#FFF7E6' : 'white'
              })
            }}
            placeholder="Wybierz klasę"
            value={
              organizationUnits.find((unit) => unit.id === childForm.organisationUnitId)
                ? {
                    label: organizationUnits.find(
                      (unit) => unit.id === childForm.organisationUnitId
                    )!.name,
                    value: childForm.organisationUnitId
                  }
                : null
            }
            onChange={(value) => handleSelectChange(index, value as SelectOption)}
            options={organizationUnits.map((unit) => ({
              label: unit.name,
              value: unit.id
            }))}
          />
          {index < newChildren.length - 1 && <Divider />}
        </div>
      ))}
      <div>
        <CustomChakraButton
          hierarchy="linkGray"
          size="lg"
          icon="plus"
          iconPosition="left"
          buttonProps={{
            onClick: () => {
              addNewChild();
              console.log(newChildren);
            },
            className: 'grow',
            type: 'button'
          }}>
          Dodaj kolejne dziecko
        </CustomChakraButton>
      </div>
    </div>
  );
};

export default AddKidToParentAdd;
