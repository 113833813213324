import { useParams } from 'react-router-dom';
import { useApi } from '../../../../context/ApiProvider';
import { AxiosResponse } from 'axios';
import {
  ParentOrderHistoryInterface,
  ParentOrderHistoryInterfaceWithPagination
} from '../../../../shared/type/parentOrderHistory.type';
import { useCallback, useEffect, useState } from 'react';
import { PaginationInterface } from '../../../../shared/type/paginationInterface.type';

export const useUserOrderList = () => {
  const { apiOrderHistoryController } = useApi();
  const { userId, id: tenantId } = useParams<{ userId: string; id: string }>();
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 30,
    maxPageNumber: 0
  });
  const [listOfOrder, setListOfOrder] = useState<ParentOrderHistoryInterface[]>([]);

  const fetchListOfOrder = useCallback(async () => {
    try {
      const response: AxiosResponse<ParentOrderHistoryInterfaceWithPagination> =
        await apiOrderHistoryController('grouped-list').post('', {
          tenantId: Number(tenantId),
          parentId: Number(userId),
          pageNumber: page.pageNumber,
          pageSize: page.pageSize
        });

      const { data, next, hasNextPage, totalCount } = response.data;
      setListOfOrder(data);
      setActualPage({ next, hasNextPage, totalCount });
      setPage((prev) => ({
        ...prev,
        maxPageNumber: Math.ceil(totalCount / page.pageSize)
      }));
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, [tenantId, userId, page.pageNumber, page.pageSize]);

  const handlePageChange = useCallback(
    (pageNum: number) => {
      setPage((prev) => ({
        ...prev,
        pageNumber: pageNum
      }));
    },
    [page]
  );

  useEffect(() => {
    if (userId != null) {
      fetchListOfOrder();
    }
  }, [userId, page.pageNumber]);

  return {
    handlePageChange,
    page,
    listOfOrder,
    actualPage,
    fetchListOfOrder
  };
};
