import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import i18n from '../i18n';
import { useApi } from './ApiProvider';
import { HttpStatusCode } from 'axios';
import useAuth from '../hooks/useAuth';

const LanguageContext = createContext<
  { language: string; setLanguage: (lang: string) => void } | undefined
>(undefined);

const getLanguageFromCookies = (): string => {
  const match = document.cookie.match(/language=([a-zA-Z-]+)/);
  return match ? match[1] : 'pl';
};

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const { apiPrivateSecurityController } = useApi();
  const authContext = useAuth();

  const [language, setLanguageState] = useState('pl');

  //w przyszłości jeśli chcemy mieć tłumaczenia z backendu
  /* const fetchTranslations = useCallback(async (lang: string) => {
    try {
      const response = await fetch(`/api/translations?lang=${lang}`);
      if (!response.ok) throw new Error('Failed to fetch translations');
      return await response.json();
    } catch (error) {
      console.error('Error fetching translations:', error);
      return {};
    }
  }, []); */

  const updateBackendLanguage = useCallback(
    async (lang: string) => {
      try {
        const response = await apiPrivateSecurityController('update-language').put('', {
          language: lang
        });

        if (response.status !== HttpStatusCode.Ok)
          throw new Error('Failed to update language on backend');

        console.log('Language updated on backend:', lang);
      } catch (error) {
        console.error('Error updating language on backend:', error);
      }
    },
    [apiPrivateSecurityController]
  );

  const initializeLanguage = useCallback(async () => {
    const cookieLang = getLanguageFromCookies();
    i18n.changeLanguage(cookieLang);
    //const remoteTranslations = await fetchTranslations(cookieLang);
    i18n.addResourceBundle(cookieLang, 'translation', {}, true, true);
    setLanguageState(cookieLang);
  }, []);

  useEffect(() => {
    initializeLanguage();
  }, [initializeLanguage]);

  const setLanguage = (lang: string) => {
    document.cookie = `language=${lang}; path=/; max-age=31536000`;
    i18n.changeLanguage(lang);
    setLanguageState(lang);

    /* fetchTranslations(lang).then((remoteTranslations) => {
      i18n.addResourceBundle(lang, 'translation', remoteTranslations, true, true);
    }); */

    if (authContext.auth.jwToken.jwToken) {
      updateBackendLanguage(lang);
    }
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
