import React from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { addOrderToBasket } from '../../../../utils/calendarOrderHelper';
import CustomChakraButton from '../../../CustomChakraButton/CustomChakraButton';
import { useOrderOnPeriodOfTime } from './useOrderOnPeriodOfTime';
import { ConsumerSelector } from './ConsumerSelector/ConsumerSelector';
import { StakeSelector } from './StakeSelector/StakeSelector';
import { OrderingCalendar } from './OrderingCalendar/OrderingCalendar';
import 'moment/locale/pl';
import Basket from '../../../Basket/Basket';

import 'react-big-calendar/lib/css/react-big-calendar.css';

type OrderOnPeriodOfTimeProps = {
  navigationSlot?: React.ReactNode;
};

const OrderOnPeriodOfTime = ({ navigationSlot }: OrderOnPeriodOfTimeProps) => {
  const [isNotFullSizeScreen] = useMediaQuery('(max-width: 1024px)');
  const { t } = useTranslation();
  const {
    purchasableItem,
    clearSelectedDates,
    selectAllDaysInMonth,
    setSelectAllDaysInMonth,
    parentOrderContext,
    selectedConsumerId,
    selectedDates,
    setSelectedDates,
    selectedStake,
    setSelectedStake,
    addItemToBasket,
    tenantId,
    minOrderDate,
    maxOrderDate,
    commonMeal,
    consumersToSelect,
    onConsumerSelect,
    parentBasketContext
  } = useOrderOnPeriodOfTime();
  const containerSizeForMainWrapper = !isNotFullSizeScreen ? 'flex flex-row' : 'flex flex-col px-4';
  const containerSizeForCalendarPart = !isNotFullSizeScreen ? 'w-2/3 pb-32' : 'w-full pb-52';
  const containerStylesForStakesSelectorBar = !isNotFullSizeScreen
    ? 'w-2/3 bottom-0 '
    : 'w-full bottom-[30px] border-b border-b-amber-400';

  return (
    <section
      className={`OrderOnPeriodOfTime overflow-hidden w-full h-[95%] ${containerSizeForMainWrapper} bg-grayLight-50 relative`}>
      <div
        className={`relative ${containerSizeForCalendarPart} overflow-y-scroll gap-spacing-3xl flex flex-col lg:pt-spacing-md lg:px-spacing-3xl pb`}>
        <div className="flex flex-col gap-spacing-lg">
          {navigationSlot && navigationSlot}
          <div className="flex flex-col px-spacing-xl lg:px-spacing-0 gap-spacing-lg">
            <p className="font-semibold text-2xl text-grayLight-900">
              {/* @ts-ignore */}
              {t('parent_order.period_order.title')}
            </p>
            <p className="font-normal text-grayLight-600" style={{ fontSize: '16px' }}>
              {/* @ts-ignore */}
              {t('parent_order.period_order.subtitle')}
            </p>
          </div>
          <ConsumerSelector consumers={consumersToSelect} onConsumerSelect={onConsumerSelect} />

          <div className="OrderOnPeriodOfTime__selectServing mt-5 lg:mt-10">
            <StakeSelector
              stakesToSelect={purchasableItem}
              selectedStake={selectedStake}
              onStakeSelect={setSelectedStake}
              useTranslations
            />
          </div>
        </div>

        <div className="OrderOnPeriodOfTime__calendarContainer flex flex-col z-10">
          <OrderingCalendar
            clearSelectedDates={clearSelectedDates}
            selectAllDaysInMonth={selectAllDaysInMonth}
            setSelectAllDaysInMonth={setSelectAllDaysInMonth}
            selectedConsumerId={selectedConsumerId}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            minOrderDate={minOrderDate}
            maxOrderDate={maxOrderDate}
            basketContext={parentBasketContext}
            letOrderInPast={false}
            useTranslations
            selectedStake={selectedStake}
          />
        </div>
      </div>
      {!isNotFullSizeScreen && (
        <div
          className={`w-1/3 border-l bg-white transform transition-transform duration-300 ease-in-out`}>
          <Basket />
        </div>
      )}
      <div
        className={`${containerStylesForStakesSelectorBar} absolute left-0 flex items-center gap-4 px-6 pt-6 pb-8 bg-white border-t border-t-amber-400 z-40`}>
        <CustomChakraButton
          hierarchy="primary"
          iconPosition="onlyText"
          icon="xClose"
          size="sm"
          fullWidth
          buttonProps={{
            disabled: !selectedStake || selectedDates.length === 0,
            className: 'flex',
            type: 'button',
            onClick: () => {
              addOrderToBasket(
                commonMeal,
                selectedStake,
                parentOrderContext,
                selectedConsumerId,
                selectedDates,
                addItemToBasket,
                tenantId?.toString()
              );
            }
          }}>
          {/* @ts-ignore */}
          {t('parent_order.period_order.add_to_cart')}
        </CustomChakraButton>
      </div>
    </section>
  );
};

export default OrderOnPeriodOfTime;
