import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useAlertContext } from '../../../context/AlertProvider';
import { useParentDashboardContextContext } from '../../../context/ParentDashboardContext';
import { HttpStatusCode } from 'axios';
import useAuth from '../../../hooks/useAuth';
import CustomChakraButton from '../../CustomChakraButton/CustomChakraButton';
import CustomHorizontalTabs from '../../CustomHorizontalTabs/CustomHorizontalTabs';
import IconComponent from '../../IconComponent/IconComponent';
import ParentAddKidPopUp from './AddKidNotification/ParentAddKidPopUp/ParentAddKidPopUp';
import AddKidNotification from './AddKidNotification/AddKidNotification';
import KidCharacterCard from './KidCharacterCard/KidCharacterCard';
import StakeCharacterCard from './StakeCharacterCard/StakeCharacerCard.tsx';
import { useApi } from '../../../context/ApiProvider';
import './ParentMain.css';
import { useTranslation } from 'react-i18next';
import AddKidNotPossibleNotification from "./AddKidNotPossibleNotification/AddKidNotPossibleNotification";
import PaymentStatusPopUp from "./PaymentStatusPopUp/PaymentStatusPopUp";

const ParentMain = () => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const { parentDashboardContext, setBasketStatus, fetchParentDashboardContext } =
    useParentDashboardContextContext();
  const [chosenTenantId, setChosenTenantId] = useState<number | null>(null);
  const [chosenKidId, setChosenKidId] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const searchParams = new URLSearchParams(location.search);
  const paymentId = searchParams.get('paymentId');
  const { apiParentPaymentSimulationController } = useApi();
  const [showAddKidPopUp, setShowAddKidPopUp] = useState(false);
  const [showAddKidNotPossiblePopUp, setShowAddKidNotPossiblePopUp] = useState(false);
  const [ paymentStatusPopUp, setPaymentStatusPopUp ] = useState<{
    paymentId: string;
    show: boolean;
  }>({
    paymentId: '',
    show: false,
  });

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(e.pageX - (cardContainerRef.current?.offsetLeft || 0));
    setScrollLeft(cardContainerRef.current?.scrollLeft || 0);
  };

  const handleMouseLeaveOrUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - (cardContainerRef.current?.offsetLeft || 0);
    const walk = (x - startX) * 1.5;
    if (cardContainerRef.current) {
      cardContainerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const simulatePaymentSuccess = async (paymentId: string) => {
    try {
      const response = await apiParentPaymentSimulationController('').post(
        `/simulate-transaction-success?paymentId=${paymentId}`
      );

      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          // @ts-ignore
          description: t('parent_dashboard.test_transaction_success'),
          // @ts-ignore
          title: t('parent_dashboard.success'),
          timeout: 9000,
          status: 'success'
        });
        setShowAlert(true);
      }
    } catch (error: any) {
      setAlertProperties({
        //@ts-ignore
        description: `${error.response?.data?.errors?.[0] || t('parent_dashboard.error_description')}`,
        timeout: 9000,
        // @ts-ignore
        title: t('parent_dashboard.error'),
        status: 'error'
      });
      setShowAlert(true);
    }
  };

  useEffect(() => {
    setBasketStatus(() => ({ show: false, forceShow: false }));
  }, []);

  useEffect(() => {
    if (parentDashboardContext && parentDashboardContext.tenants.length > 0) {
      const firstTenant = parentDashboardContext.tenants[0];
      setChosenTenantId(firstTenant.tenantId);

      if (firstTenant.consumers.length > 0) {
        setChosenKidId(firstTenant.consumers[0].consumerDto.id);
      }
    }
  }, [parentDashboardContext]);

  useEffect(() => {
    if (paymentId) {
      setPaymentStatusPopUp({
        show: true,
        paymentId,
      })
      const pathSegments = location.pathname.split('/');
      const status = pathSegments[pathSegments.length - 1];

      if (window.location.hostname === 'localhost') {
        simulatePaymentSuccess(paymentId);
      }

      if (status === 'success') {
        setAlertProperties({
          timeout: 9000,
          // @ts-ignore
          description: t('parent_dashboard.test_transaction_success'),
          // @ts-ignore
          title: t('parent_dashboard.success'),
          status: 'success'
        });
      } else {
        setAlertProperties({
          timeout: 9000,
          // @ts-ignore
          title: t('parent_dashboard.error'),
          // @ts-ignore
          description: t('parent_dashboard.complete_payment'),
          status: 'error'
        });
      }

      setShowAlert(true);
      fetchParentDashboardContext();
      navigate('/parent/main');
    }
  }, [paymentId]);

  return (
    <section className="ParentMainPage w-full h-full flex flex-col gap-spacing-3xl pt-spacing-3xl lg:pr-spacing-4xl pb-spacing-3xl lg:pl-spacing-3xl bg-grayLight-50">
      <div className="ParentMainPage__header flex flex-col lg:flex-row  justify-between pr-spacing-4xl pl-spacing-3xl lg:p-0">
        <div className="ParentMainPage__header__greetings flex flex-col gap-spacing-xs">
          <h1 className="text-3xl font-semibold text-grayLight-900">
            {/* @ts-ignore */}
            {t('parent_dashboard.greeting', { name: auth.loggedUserDto.firstName })}
          </h1>
          <p className="font-normal text-grayLight-600" style={{ fontSize: '16px' }}>
            {/* @ts-ignore */}
            {t('parent_dashboard.subtitle')}
          </p>
        </div>
        <div className="ParentMainPage__header__actionButtons gap-spacing-lg sm:gap-spacing-xl flex flex-col-reverse sm:flex-row justify-start items-center pt-spacing-2xl lg:pt-0 lg:items-start lg:flex-row lg:gap-spacing-2xl">
          {parentDashboardContext?.showPaySaldoButton && (
            <div className="w-auto lg:hidden">
              <Link
                className="lg:w-auto w-full"
                to={location.pathname.replace('main', `payoff-balance`)}>
                <CustomChakraButton
                  size="lg"
                  hierarchy="secondaryColor"
                  iconPosition="left"
                  icon="wallet"
                  buttonProps={{
                    className: 'lg:w-auto w-full',
                    onClick: undefined
                  }}>
                  {/* @ts-ignore */}
                  {t('parent_dashboard.pay_balance')}
                </CustomChakraButton>
              </Link>
            </div>
          )}
          <div className="w-auto">
            <Link className="lg:w-auto w-full" to={location.pathname.replace('main', `absences`)}>
              <CustomChakraButton
                size="lg"
                hierarchy="secondaryColor"
                iconPosition="left"
                buttonProps={{
                  className: 'lg:w-auto w-full'
                }}>
                {/* @ts-ignore */}
                {t('parent_dashboard.report_absence')}
              </CustomChakraButton>
            </Link>
          </div>
          <div className="w-auto">
            <Link className="lg:w-auto w-full" to={location.pathname.replace('main', `order`)}>
              <CustomChakraButton
                size="lg"
                hierarchy="primary"
                iconPosition="left"
                icon="plus"
                iconColor="#292524"
                buttonProps={{
                  className: 'lg:w-auto w-full'
                }}>
                {/* @ts-ignore */}
                {t('parent_dashboard.order_meal')}
              </CustomChakraButton>
            </Link>
          </div>
        </div>
      </div>

      <div className="ParentMainPage__content flex flex-col gap-spacing-3xl overflow-hidden">
        <div className="ParentMainPage__content__chooseSchool">
          <CustomHorizontalTabs
            onChange={(value) => {
              setChosenTenantId(value)

              setChosenTenantId(value);

              const selectedTenant = parentDashboardContext?.tenants.find(
                  (tenant) => tenant.tenantId === value
              );

              if (selectedTenant && selectedTenant.consumers.length > 0) {
                const firstKidId = selectedTenant.consumers[0].consumerDto.id;
                setChosenKidId(firstKidId);
              } else {
                setChosenKidId(null);
              }
            }}
            data={
              parentDashboardContext
                ? parentDashboardContext.tenants.map((tenant) => ({
                    label: tenant.tenantName,
                    value: tenant.tenantId
                  }))
                : null
            }
          />
        </div>

        <div className="ParentMainPage__content__chooseKid flex flex-row gap-1">
          <div className="grow">
            <CustomHorizontalTabs
              onChange={(value) => {
                setChosenKidId(value);
              }}
              data={
                parentDashboardContext && chosenTenantId
                  ? parentDashboardContext.tenants
                      .find((tenant) => tenant.tenantId === chosenTenantId)
                      ?.consumers.map((consumer) => {
                        const { firstName, lastName, id } = consumer.consumerDto;
                        return {
                          value: id,
                          label: firstName + ' ' + lastName
                        };
                      })
                  : null
              }
            />
          </div>
          <div className="rounded-xl p-spacing-sm bg-grayNeutral-50 border box-border border-grayLight-200">
            <button
              style={{
                boxShadow: '0px 1px 3px 0px #1018281A'
              }}
              onClick={() => {
                if(parentDashboardContext?.tenants?.find(tenant => chosenTenantId === tenant.tenantId)?.childrenRegistrationActive){
                  setShowAddKidPopUp(true);
                } else {
                  setShowAddKidNotPossiblePopUp(true);
                }
              }}
              className="rounded-md w-full h-full bg-base-white px-spacing-lg py-spacing-md">
              <IconComponent iconName="plus" color="#44403C" />
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-spacing-5xl">
        {(() => {
          if (chosenTenantId === null || chosenKidId === null) return <></>;

          const tenant = parentDashboardContext?.tenants.find(
            (tenant) => tenant.tenantId === chosenTenantId
          );

          const consumer = tenant?.consumers.find(
            (consumer) => consumer.consumerDto.id === chosenKidId
          );

          if (!tenant || !consumer) return <></>;

          const { tenantName } = tenant;
          const { firstName, lastName, remarks, organisationUnitName, saldo, cardNumber } =
            consumer.consumerDto;

          return (
            <KidCharacterCard
              saldo={saldo}
              organisationUnitName={organisationUnitName}
              remarks={remarks}
              cardNumber={cardNumber}
              firstName={firstName}
              lastName={lastName}
              tenantName={tenantName}
            />
          );
        })()}
      </div>

      {parentDashboardContext?.tenants?.flatMap((tenant) => tenant.consumers)?.length === 0 && (
        <AddKidNotification />
      )}

      {showAddKidPopUp && (
        <ParentAddKidPopUp
          onClose={() => {
            setShowAddKidPopUp(false);
            fetchParentDashboardContext();
          }}
          isOpen={showAddKidPopUp}
        />
      )}

      {showAddKidNotPossiblePopUp && (
          <AddKidNotPossibleNotification
              tenantId={chosenTenantId}
              onClose={() => {
                setShowAddKidNotPossiblePopUp(false);
              }}
              isOpen={showAddKidNotPossiblePopUp}
          />
      )}

      {paymentStatusPopUp.show && (
          <PaymentStatusPopUp
              onClose={() => {setPaymentStatusPopUp({show: false, paymentId: ""})}}
              isOpen={paymentStatusPopUp.show}
              paymentId={paymentStatusPopUp.paymentId}
          />
      )}

      <div className="flex flex-col gap-spacing-3xlp pr-spacing-xl pt-spacing-4xl pb-spacing-xl pl-spacing-xl lg:p-0">
        <div className="flex justify-between">
          <p className="text-grayLight-900 font-semibold" style={{ fontSize: '18px' }}>
            {/* @ts-ignore */}
            {t('parent_dashboard.next_meals')}
          </p>
          <Link className="hidden lg:flex" to={location.pathname.replace('main', `absences`)}>
            <CustomChakraButton
              hierarchy="tertiaryColor"
              size="sm"
              iconPosition="right"
              icon="arrowRight"
              buttonProps={{
                onClick: () => {}
              }}>
              {/* @ts-ignore */}
              {t('parent_dashboard.view_all_orders')}
            </CustomChakraButton>
          </Link>
        </div>
        <div
          ref={cardContainerRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeaveOrUp}
          onMouseUp={handleMouseLeaveOrUp}
          onMouseMove={handleMouseMove}
          className="flex gap-spacing-xl pb-spacing-3xl pl-spacing-md overflow-x-auto custom-scroll-hide">
          {((): JSX.Element[] | JSX.Element => {
            if (chosenTenantId === null || chosenKidId === null) return <></>;

            const tenant = parentDashboardContext?.tenants.find(
              (tenant) => tenant.tenantId === chosenTenantId
            );

            const consumer = tenant?.consumers.find(
              (consumer) => consumer.consumerDto.id === chosenKidId
            );

            if (!tenant || !consumer) return <></>;

            return consumer.closestStakesToTake
              .slice(0, 6)
              .sort((a, b) => {
                let dateComparison: number;
                dateComparison = new Date(a.date).getTime() - new Date(b.date).getTime();
                if (dateComparison !== 0) {
                  return dateComparison;
                }
                return a.stakeName.localeCompare(b.stakeName);
              })
              .map((stake) => (
                <StakeCharacterCard
                  key={`StakeCard-${stake.boughtStakeId}`}
                  stake={stake}
                  consumerId={chosenKidId}
                />
              ));
          })()}
        </div>
        <Link
          className="lg:hidden pb-spacing-9xl flex items-center justify-center"
          to={location.pathname.replace('main', `absences`)}>
          <CustomChakraButton
            hierarchy="tertiaryColor"
            size="sm"
            iconPosition="right"
            icon="arrowRight"
            buttonProps={{
              onClick: () => {}
            }}>
            {/* @ts-ignore */}
            {t('parent_dashboard.view_all_orders')}
          </CustomChakraButton>
        </Link>
      </div>
    </section>
  );
};

export default ParentMain;
