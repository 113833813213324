import React from 'react';
import { Link } from 'react-router-dom';

interface UserProfileLinkProps {
  href: string;
  fullName: string;
}

const UserProfileLink: React.FC<UserProfileLinkProps> = ({ href, fullName }) => {
  const getInitials = (name: string) => {
    const initials = name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .join('')
      .slice(0, 2);
    return initials;
  };

  return (
    <Link to={href} className="flex items-center gap-3 text-gray-900">
      <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center text-sm font-semibold text-gray-500">
        {getInitials(fullName)}
      </div>
      <span className="text-sm font-semibold underline">{fullName}</span>
    </Link>
  );
};

export default UserProfileLink;
