import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useParentDashboardContextContext } from '../../../../context/ParentDashboardContext';
import { useThemeContext } from '../../../../context/ThemeProvider';
import { OrderType } from '../ParentOrder';

interface Props {
  updateOrderType: (type: OrderType) => void;
}

const ChooseOrderType = ({ updateOrderType }: Props) => {
  const { t } = useTranslation();
  const { setBasketStatus } = useParentDashboardContextContext();
  const { isMobile } = useThemeContext();

  useEffect(() => {
    setBasketStatus({
      forceShow: false,
      show: false
    });
  }, []);

  return (
    <section className="flex flex-col md:self-center items-center justify-center mt-spacing-4xl lg:mt-0">
      <div
        style={{ transform: isMobile ? 'translateY(0)' : 'translateY(-50%)' }}
        className="flex flex-col gap-spacing-6xl lg:pb-spacing-6xl pr-spacing-3xl pl-spacing-3xl">
        <div className="flex flex-col items-center justify-center gap-spacing-lg">
          <h2 className="text-2xl font-semibold text-grayLight-900">
            {' '}
            {/* @ts-ignore */}
            {t('parent_order.choose_order_type.title')}
          </h2>
          <p className="font-normal text-grayLight-600" style={{ fontSize: '16px' }}>
            {/* @ts-ignore */}
            {t('parent_order.choose_order_type.title')}
          </p>
        </div>
        <div className="flex lg:flex-row items-center flex-col gap-10 justify-around">
          <button
            onClick={() => {
              updateOrderType('period');
            }}
            className="ChooseSchool__schoolTab border box-border rounded-xl p-spacing-xl border-brand-300 max-w-96">
            <p className="text-grayLight-900 text-lg font-semibold text-wrap">
              {/* @ts-ignore */}
              {t('parent_order.choose_order_type.period_order')}
            </p>
            <p className="text-grayLight-700 font-normal text-wrap" style={{ fontSize: '16px' }}>
              {/* @ts-ignore */}
              {t('parent_order.choose_order_type.period_order_description')}
            </p>
          </button>
          <button
            onClick={() => {
              updateOrderType('day');
            }}
            className="ChooseSchool__schoolTab border box-border rounded-xl p-spacing-xl border-brand-300 max-w-96">
            <p className="text-grayLight-900 text-lg font-semibold text-wrap">
              {/* @ts-ignore */}
              {t('parent_order.choose_order_type.day_order')}
            </p>
            <p className="text-grayLight-700 font-normal text-wrap" style={{ fontSize: '16px' }}>
              {/* @ts-ignore */}
              {t('parent_order.choose_order_type.day_order_description')}
            </p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default ChooseOrderType;
