import { AxiosResponse } from 'axios';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useApi } from '../../../../../../../../../context/ApiProvider';
import { useTenantAdminBasketContext } from '../../../../../../../../../context/TenantAdminBasketProvider';
import { ParentWithKidInterface } from '../../../../../../../../../shared/type/parent.type';
import { PurchasableItemInterface } from '../../../../../../../../../shared/type/parentOrderContext.type';
import { getAvailableMeals } from '../../../../../../../../../utils/calendarOrderHelper';
import { BasicKidInterface } from '../../../../../../../../../shared/type/kid.type';
import { useUserOrderContext } from '../../../../../../useUserOrderContext';

export const useCompleteCreditOrder = () => {
  const { addItemToBasket, setUserData, parentBasketContext } = useTenantAdminBasketContext();
  const { userOrderContext, setSkipFetchingUserOrderContext } = useUserOrderContext();
  const { apiTenantParentController } = useApi();
  const { id: tenantId, userId } = useParams<{ id: string; userId: string }>();

  const [consumersToSelect, setConsumersToSelect] = useState<BasicKidInterface[]>();

  const [selectedConsumerId, setSelectedConsumerId] = useState<number | undefined>(undefined);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [selectAllDaysInMonth, setSelectAllDaysInMonth] = useState(false);
  const [selectedStake, setSelectedStake] = useState<number>();
  const [commonMeal, setCommonMeals] = useState<PurchasableItemInterface[]>([]);

  moment.locale('pl');
  const minOrderDate = moment(userOrderContext?.minOrderDate);
  const maxOrderDate = moment(userOrderContext?.maxOrderDate);

  const clearSelectedDates = () => {
    setSelectedDates([]);
    setSelectedStake(undefined);
  };

  const fetchKids = useCallback(async () => {
    try {
      const response: AxiosResponse<ParentWithKidInterface> = await apiTenantParentController(
        'details'
      ).get(`/${tenantId}/${userId}`);
      const defaultSelectedUser =
        response.data.consumers && response.data.consumers.length > 0
          ? response.data.consumers[0].id
          : undefined;

      setConsumersToSelect(response.data.consumers);
      setSelectedConsumerId(defaultSelectedUser);
    } catch (error) {
      console.error(error);
    }
  }, [tenantId, userId, setConsumersToSelect, setSelectedConsumerId, apiTenantParentController]);

  const consumersToSelectOptions =
    consumersToSelect?.map((consumer) => ({
      label: `${consumer.firstName} ${consumer.lastName}`,
      value: consumer.id
    })) || [];

  const onConsumerSelect = useCallback(
    (consumerId: number) => {
      setSelectedConsumerId(consumerId);
    },
    [setSelectedConsumerId]
  );

  const purchasableItem = useMemo(() => {
    if (!userOrderContext || !selectedConsumerId) return [];

    const consumerContext = userOrderContext.consumerOrderContexts.find(
      (consumer) => consumer?.consumerId === selectedConsumerId
    );

    if (!consumerContext) return [];

    const uniqueMeals = new Map();

    consumerContext.orderDays
      .flatMap((orderDay) => orderDay.purchasableItems || [])
      .forEach((meal) => {
        if (!uniqueMeals.has(meal.stakeId)) {
          const priceInPLN = (meal.price / 100).toFixed(2);
          const label = `${meal.name} - ${priceInPLN}zł`;
          uniqueMeals.set(meal.stakeId, {
            label: label,
            value: meal.stakeId
          });
        }
      });

    return Array.from(uniqueMeals.values());
  }, [userOrderContext, selectedConsumerId]);

  useEffect(() => {
    setCommonMeals(getAvailableMeals(userOrderContext, selectedConsumerId, selectedDates));
  }, [selectedDates, selectedConsumerId, userOrderContext]);

  useEffect(() => {
    setSkipFetchingUserOrderContext(false);

    return () => {
      setSkipFetchingUserOrderContext(true);
    };
  }, [selectedDates, setSkipFetchingUserOrderContext]);

  useEffect(() => {
    fetchKids();
  }, [fetchKids]);

  useEffect(() => {
    if (userOrderContext) {
      setUserData(
        userOrderContext.consumerOrderContexts.map((consumer) => ({
          consumerId: consumer.consumerId,
          saldo: consumer.saldo,
          creditLimit: consumer.creditLimit,
          provision: consumer.provision
        }))
      );
    }
  }, [userOrderContext, setUserData]);

  return {
    parentOrderContext: userOrderContext,
    purchasableItem,
    commonMeal,
    tenantId,
    onConsumerSelect,
    consumersToSelectOptions,
    clearSelectedDates,
    minOrderDate,
    maxOrderDate,
    selectedStake,
    setSelectedStake,
    selectAllDaysInMonth,
    setSelectAllDaysInMonth,
    selectedDates,
    setSelectedDates,
    addItemToBasket,
    parentBasketContext,
    selectedConsumerId,
    consumersToSelect
  };
};
