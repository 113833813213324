import { HttpStatusCode } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAlertContext } from '../../../../context/AlertProvider';
import { useApi } from '../../../../context/ApiProvider';
import { useParentDashboardContextContext } from '../../../../context/ParentDashboardContext';
import { CustomButtonType } from '../../../../shared/type/customButton.type';
import { ClosestStakeToTakeInterface } from '../../../../shared/type/parentDashboardContext.type';

import './StakeCharacterCard.css';
import GetButtonMessage from '../../../../utils/GetButtonMessage';
import CustomBadge from '../../../CustomBadge/CustomBadge';
import CustomChakraButton from '../../../CustomChakraButton/CustomChakraButton';

const StakeCharacterCard = ({
  stake,
  consumerId
}: {
  stake: ClosestStakeToTakeInterface;
  consumerId: number;
}) => {
  const { t } = useTranslation();
  const { apiAbsenceAndCourseController } = useApi();
  const {
    dayOfTheWeekName,
    date,
    stakeName,
    actualPrice,
    oldPrice,
    courses,
    boughtStakeId,
    absence,
    absenceChangePossible
  } = stake;
  const { updateStake, fetchParentDashboardContext } = useParentDashboardContextContext();
  const { setAlertProperties, setShow: setShowAlert } = useAlertContext();

  const reportAbsence = async () => {
    try {
      const response = await apiAbsenceAndCourseController('report-or-revoke-absence').post('', {
        consumerId: consumerId,
        boughtStakesId: [boughtStakeId],
        absence: !absence
      });
      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          // @ts-ignore
          description: t(
            // @ts-ignore
            absence
              ? 'parent_dashboard.stake_character_card.absence_revoked'
              : 'parent_dashboard.stake_character_card.absence_reported',
            { stakeName }
          ),
          timeout: 9000,
          // @ts-ignore
          title: t('parent_dashboard.stake_character_card.success'),
          status: 'success'
        });
        setShowAlert(true);
        const updatedStake = { ...stake, absence: !absence };
        updateStake(consumerId, boughtStakeId, updatedStake);
        fetchParentDashboardContext();
      }
    } catch (error: any) {
      setAlertProperties({
        // @ts-ignore
        description: t('parent_dashboard.stake_character_card.absence_error', {
          action: absence ? 'odwoływania' : 'zgłaszania',
          error: error.response?.data?.errors?.[0] || 'Nieznany błąd'
        }),
        timeout: 9000,
        // @ts-ignore
        title: t('parent_dashboard.stake_character_card.error'),
        status: 'error'
      });
      setShowAlert(true);
    }
  };

  const getButton = (absencePossible: boolean, presence: boolean) => {
    let button: CustomButtonType = {
      size: 'sm',
      iconPosition: 'left',
      icon: 'xClose',
      iconColor: '#B42318',
      hierarchy: 'destructiveSecondary'
    };

    // @ts-ignore
    let buttonText = t('parent_dashboard.stake_character_card.cancel');

    if (!absencePossible && presence) {
      button.iconColor = '#98A2B3';
      button.buttonProps = { disabled: true };
    }

    if (absencePossible && !presence) {
      // @ts-ignore
      buttonText = t('parent_dashboard.stake_character_card.restore');
      button.hierarchy = 'primary';
      button.icon = 'rollBack';
      button.iconPosition = 'right';
      button.iconColor = '#292524';
    }

    if (!absencePossible && !presence) {
      // @ts-ignore
      buttonText = t('parent_dashboard.stake_character_card.restore');
      button.hierarchy = 'primary';
      button.icon = 'rollBack';
      button.iconPosition = 'right';
      button.iconColor = '#98A2B3';
      button.buttonProps = { disabled: true };
    }

    return (
      <CustomChakraButton
        buttonProps={{
          className: 'w-full lg:w-auto',
          type: 'button',
          ...button.buttonProps,
          onClick: reportAbsence
        }}
        size={button.size}
        iconPosition={button.iconPosition}
        iconColor={button.iconColor}
        icon={button.icon}
        hierarchy={button.hierarchy}>
        {buttonText}
      </CustomChakraButton>
    );
  };

  return (
    <div className="StakeCharacterCard flex flex-col p-spacing-xl gap-spacing-xs rounded-xl min-w-72 lg:min-w-96 lg:w-96 bg-white">
      <div className="flex gap-spacing-xs">
        <p className="text-grayLight-900 text-sm">{dayOfTheWeekName}</p>
        <p className="text-grayLight-600 text-sm">{date}</p>
      </div>
      <div className="flex flex-col justify-between grow">
        <div className="flex flex-col gap-spacing-xs">
          <div className="flex flex-col lg:flex-row justify-between">
            <div>
              <p className="text-lg text-grayLight-900 font-semibold">{stakeName}</p>
            </div>
            <div className="flex gap-spacing-xs">
              <p className="text-lg text-grayLight-500 font-medium line-through">{oldPrice}</p>
              <p className="text-lg text-grayLight-900 font-semibold">{actualPrice}</p>
            </div>
          </div>
          <div className="flex flex-col gap-spacing-xs overflow-y-scroll max-h-40">
            {courses.map((course) => (
              <div key={`Course-${course.mealToDayId}`}>
                <div className="flex justify-end border-b box-border border-brand-300">
                  <p className="text-sm font-normal text-grayLight-500">{course.courseName}</p>
                </div>
                <div className="flex flex-col">
                  <div className="flex">
                    <p className="StakeCharacterCard__16 text-grayLight-600 font-semibold">
                      {course.mealDto.name}
                    </p>
                    <div className="flex gap-spacing-xs">
                      {course.mealDto.allergens.map((allergen) => (
                        <CustomBadge key={`AllergenKey-${allergen.id}`} color="brand" size="sm">
                          {allergen.formalNumber}
                        </CustomBadge>
                      ))}
                    </div>
                  </div>
                  <div>
                    <p className="font-normal text-grayLight-600" style={{ fontSize: '16px' }}>
                      {course.mealDto.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-start lg:items-end justify-end gap-spacing-lg lg:gap-0">
          <div className="w-full lg:w-auto">{getButton(absenceChangePossible, !absence)}</div>
          <div>
            <GetButtonMessage absencePossible={absenceChangePossible} presence={!absence} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeCharacterCard;
