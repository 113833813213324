type CurrencyType = 'zł' | 'PLN'

const calculatePrice = function (price: number | undefined | null, currency: CurrencyType | null | undefined): string {

  const getCurrency = (currency: CurrencyType | null | undefined) => {
    if (!currency) return '';
    return ' ' + currency;
  }

  if(!price) {
    return `0,00${getCurrency(currency)}`;
  }

  const priceInCurrency = price / 100;
  const formattedPrice = priceInCurrency.toFixed(2);
  const [integerPart, decimalPart] = formattedPrice.split('.');
  const result = `${integerPart},${decimalPart}`;
  return `${result}${getCurrency(currency)}`;
};

export default calculatePrice;

