import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import CustomHorizontalTabs from '../../CustomHorizontalTabs/CustomHorizontalTabs';
import { useParentDashboardContextContext } from '../../../context/ParentDashboardContext';
import { useApi } from '../../../context/ApiProvider';
import { ParentSupportData } from '../../../shared/type/support.type';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

export const Contact = () => {
  const { t } = useTranslation();
  const { parentDashboardContext } = useParentDashboardContextContext();
  const { apiParentSupportController } = useApi();

  const [chosenTenantId, setChosenTenantId] = useState<number | null>(null);
  const [supportData, setSupportData] = useState<ParentSupportData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchTenantContactData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<ParentSupportData> = await apiParentSupportController('').get(
        '',
        {
          params: {
            tenantId: chosenTenantId
          }
        }
      );
      setSupportData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [chosenTenantId]);

  useEffect(() => {
    if (parentDashboardContext) {
      setChosenTenantId(parentDashboardContext.tenants[0].tenantId);
    }
  }, [parentDashboardContext]);

  useEffect(() => {
    if (chosenTenantId) {
      fetchTenantContactData();
    }
  }, [chosenTenantId]);

  return (
    <section className="ParentAbsences flex flex-col bg-grayLight-50 overflow-y-auto">
      <div className="pt-spacing-3xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl">
        <h1 className="p-0 m-0 font-semibold text-grayLight-900" style={{ fontSize: '30px' }}>
          {/*  @ts-ignore */}
          {t('parent_support.title')}
        </h1>
      </div>
      <div className="flex flex-col pr-spacing-4xl pl-spacing-3xl pb-spacing-xl gap-spacing-xl">
        <CustomHorizontalTabs
          onChange={(value) => {
            setChosenTenantId(value);
          }}
          data={
            parentDashboardContext
              ? parentDashboardContext.tenants.map((tenant) => ({
                  label: tenant.tenantName,
                  value: tenant.tenantId
                }))
              : null
          }
        />
      </div>
      <div className="flex flex-col gap-spacing-xl pt-spacing-md pr-spacing-xl pb-spacing-md pl-spacing-xl">
        {isLoading || !supportData ? (
          <LoadingSpinner state={isLoading}>
            <>
              {/*  @ts-ignore */}
              {t('parent_support.loading')}
            </>
          </LoadingSpinner>
        ) : (
          <>
            <div>
              <h2 className="text-md font-bold">
                {/*  @ts-ignore */}
                {t('parent_support.contact_for_orders')}
              </h2>
              {/* @ts-ignore */}
              <ContactInfoRow label={t('parent_support.email')} value={supportData.supportEmail} />
              {/* @ts-ignore */}
              <ContactInfoRow label={t('parent_support.phone')} value={supportData.supportPhone} />
            </div>
            <div className="mt-4">
              <h2 className="text-md font-bold">
                {/*  @ts-ignore */}
                {t('parent_support.contact_for_support')}
              </h2>
              {/* @ts-ignore */}
              <ContactInfoRow label={t('parent_support.email')} value="kontakt@jemwszkole.pl" />
              {/* @ts-ignore */}
              <ContactInfoRow label={t('parent_support.phone')} value="+48 535 818 986" />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

interface ContactInfoRowProps {
  label: string;
  value: string;
}

const ContactInfoRow: React.FC<ContactInfoRowProps> = ({ label, value }) => {
  return (
    <div>
      <div className="border-t border-gray-200 mb-2" />
      <div className="flex items-start py-5 gap-2">
        <p className="w-1/4 text-sm text-gray-700 font-semibold">{label}</p>
        <p className="text-sm text-gray-900 text-right">{value}</p>
      </div>
    </div>
  );
};
