import { useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';

import { useParentOrderContextContext } from '../../../../../context/ParentOrderContext';
import { useThemeContext } from '../../../../../context/ThemeProvider';
import { CalendarEventsInterface } from '../../../../../shared/type/calendarEvents.type';
import { OrderForParentInterface } from '../../../../../shared/type/orderForParent.type';
import { useTranslation } from 'react-i18next';
import { useUserOrderContext } from '../../../../TenantAdminComponents/Users/useUserOrderContext';

type useOrderingCalendarArgs = {
  minOrderDate: Moment;
  maxOrderDate: Moment;
  setSelectedDates: React.Dispatch<React.SetStateAction<Date[]>>;
  selectedConsumerId: number | undefined;
  selectedDates: Date[];
  basketContext: OrderForParentInterface;
  useTranslations?: boolean;
};

export const useOrderingCalendar = ({
  minOrderDate,
  maxOrderDate,
  setSelectedDates,
  selectedConsumerId,
  selectedDates,
  basketContext,
  useTranslations
}: useOrderingCalendarArgs) => {
  const { i18n } = useTranslation();
  moment.locale(useTranslations ? i18n.language : 'pl');
  const { isMobile } = useThemeContext();
  const { userOrderContext, setUserOrderContextDate, tenantId } = useUserOrderContext();
  const [viewDate, setViewDate] = useState(new Date());
  const [fixedEvents, setFixedEvents] = useState<CalendarEventsInterface[]>([]);

  const getNewDate = (action: Date | 'PREV' | 'NEXT' | 'TODAY') => {
    if (action === 'PREV') {
      return moment(viewDate).subtract(1, 'month');
    } else if (action === 'NEXT') {
      return moment(viewDate).add(1, 'month');
    } else if (action === 'TODAY') {
      return moment();
    } else {
      return moment(action);
    }
  };

  const handleNavigate = (action: Date | 'PREV' | 'NEXT' | 'TODAY') => {
    const newDate = getNewDate(action);
    if (newDate.isBefore(minOrderDate, 'month') || newDate.isAfter(maxOrderDate, 'month')) {
      return;
    }

    setSelectedDates([]);

    setViewDate(newDate.toDate());
    setUserOrderContextDate({
      year: newDate.year(),
      month: newDate.month() + 1
    });
  };

  useEffect(() => {
    if (minOrderDate && new Date(minOrderDate.toDate()) > viewDate) {
      setViewDate(new Date(minOrderDate.toDate()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateFixedEvents = useCallback(() => {
    const consumerOrderContext = userOrderContext?.consumerOrderContexts.find(
      (consumer) => consumer.consumerId === selectedConsumerId
    );
    if (!consumerOrderContext) {
      setFixedEvents([]);
      return;
    }

    const boughtItems = consumerOrderContext.orderDays.flatMap((day) => {
      return (day.purchasableItems || [])
        .filter((item) => item.alreadyBoughtCount > 0)
        .map((item) => ({
          title: (
            <>
              <p className={`font-medium text-xs text-grayBlue-700 text-ellipsis overflow-hidden`}>
                {isMobile ? `${item.name}` : `${item.name} (${item.alreadyBoughtCount} szt.)`}
              </p>
            </>
          ),
          start: moment(day.when, 'YYYY-MM-DD').startOf('day').toDate(),
          end: moment(day.when, 'YYYY-MM-DD').endOf('day').toDate(),
          purchasableItemId: item.stakeId,
          isFixed: true,
          consumerId: selectedConsumerId
        }));
    });

    const holidays = consumerOrderContext.orderDays
      .filter((day) => !day.workingDay && !day.salesBlocked && !!day.comment.length)
      .map((day) => {
        return {
          title: (
            <>
              <p className={`font-medium text-xs text-grayBlue-700`}>{day.comment}</p>
            </>
          ),
          start: moment(day.when, 'YYYY-MM-DD').startOf('day').toDate(),
          end: moment(day.when, 'YYYY-MM-DD').endOf('day').toDate(),
          isFixed: true,
          consumerId: selectedConsumerId,
          workingDay: day.workingDay
        };
      });
    setFixedEvents([...boughtItems, ...holidays]);
  }, [userOrderContext, setFixedEvents, isMobile, selectedConsumerId]);

  const basketItems = useMemo(() => {
    if (!basketContext?.ordersForChildren) {
      return [];
    }

    return basketContext.ordersForChildren
      .filter((orderForChild) => orderForChild.consumerId === selectedConsumerId)
      .flatMap((orderForChild) =>
        orderForChild.orderedItems.map((orderedItem) => ({
          title: (
            <>
              <p
                className={`font-medium ${isMobile && 'bg-grayLight-50 rounded box-border border font-medium border-grayLight-200 text-grayLight-700 p-spacing-xxs '} text-xs text-grayBlue-700`}>
                {isMobile
                  ? `${orderedItem.purchasableItem.name}`
                  : `${orderedItem.purchasableItem.name} (${orderedItem.count} szt.)`}
              </p>
            </>
          ),
          start: moment(orderedItem.when, 'YYYY-MM-DD').startOf('day').toDate(),
          end: moment(orderedItem.when, 'YYYY-MM-DD').endOf('day').toDate(),
          isInBasket: true
        }))
      );
  }, [basketContext, selectedConsumerId, isMobile]);

  const events = useMemo(() => {
    const combinedEvents = [
      ...fixedEvents.filter((event) => event.consumerId === selectedConsumerId),
      ...basketItems,
      ...selectedDates.map((date) => ({
        start: date,
        end: date,
        isInBasket: false,
        isFixed: false
      }))
    ];

    return combinedEvents;
  }, [fixedEvents, basketItems, selectedDates, selectedConsumerId]);

  useEffect(() => {
    generateFixedEvents();
  }, [selectedConsumerId, userOrderContext, generateFixedEvents]);

  return {
    events,
    fixedEvents,
    handleNavigate,
    viewDate,
    tenantId,
    userOrderContext
  };
};
