import { HttpStatusCode } from 'axios';
import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
  RadioGroup,
  Stack,
  Radio
} from '@chakra-ui/react';
import { useAlertContext } from '../../../../../context/AlertProvider';
import { useApi } from '../../../../../context/ApiProvider';
import { CustomOperationInterface } from '../../../../../shared/type/customOperation.type';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import CustomInput from '../../../../CustomInput/CustomInput';
import CustomInputRadio from '../../../../Forms/CustomInputRadio/CustomInputRadio';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  consumerId: number;
  currentSaldo: string;
}

const AddCustomOperation = ({ isOpen, handleClose, consumerId, currentSaldo }: Props) => {
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();

  const [customOperation, setCustomOperation] = useState<CustomOperationInterface>({
    consumerId: 0,
    targetSaldoAmount: 0,
    description: ''
  });

  // const { user } = useEditTeacherOrEmployeeContext();

  const { apiTenantConsumerTransactionsController } = useApi();

  const handleAddCustomOperation = async () => {
    try {
      const { targetSaldoAmount, description } = customOperation;
      const response = await apiTenantConsumerTransactionsController('set-consumer-saldo').post(
        '',
        {
          consumerId,
          targetSaldoAmount,
          description
        }
      );
      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 9000,
          title: 'Sukces',
          description: `Poprawnie dodanie nową operację`,
          status: 'success'
        });
        setShowAlert(true);
        handleClose();
      }
    } catch (error: any) {
      setAlertProperties({
        timeout: 9000,
        title: 'Błąd',
        description: `Błąd dodawania nowej operacji [${error.response.data.violations[0].fieldName} - ${error.response.data.violations[0].message}]`,
        status: 'error'
      });
      setShowAlert(true);
    }
  };

  // useEffect(() => {
  //   if(teacherId){
  //     setCurrentUserId(teacherId)
  //   }
  //   if(employeeId){
  //     setCurrentUserId(employeeId)
  //   }
  // }, []);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="3xl" isCentered>
      <form onSubmit={handleAddCustomOperation}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Edycja salda</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col gap-spacing-3xl">
            <div className="flex justify-between items-center gap-4">
              <CustomInput
                inputParams={{
                  step: 0.01,
                  name: 'currentSaldo',
                  value: currentSaldo,
                  disabled: true,
                  min: 0
                }}
                type="default"
                size="md"
                destructive={false}
                label="Aktualne saldo (zł)"
              />
              <CustomInput
                inputParams={{
                  step: 0.01,
                  name: 'targetSaldoAmount',
                  onChange: (e) => {
                    setCustomOperation((prev) => ({ ...prev, [e.target.name]: e.target.value }));
                  },
                  required: true,
                  min: 0
                }}
                type="default"
                size="md"
                destructive={false}
                label="Docelowe saldo (zł)"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-grayLight-700 text-sm">Opis</label>
              <textarea
                cols={30}
                rows={3}
                required={true}
                name="description"
                style={{ resize: 'none' }}
                onChange={(e) => {
                  setCustomOperation((prev) => ({ ...prev, [e.target.name]: e.target.value }));
                }}
                maxLength={500}
                className="border border-gray-300 rounded-lg p-spacing-lg"
              />
              {/* <div className="flex justify-between"> */}
              {/*   <label className="text-grayLight-700 text-sm">maks. 500 znaków</label> */}
              {/*   <label className="text-grayLight-700 text-sm">{newKidInterface.remarks?.length}/500</label> */}
              {/* </div> */}
            </div>
          </ModalBody>
          <Divider className="pt-spacing-2xl pb-spacing-2xl" />
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                onClick: handleClose
              }}>
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: 'button',
                onClick: handleAddCustomOperation
              }}>
              Zapisz
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddCustomOperation;
